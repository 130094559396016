import {
  Badge,
  Button,
  ButtonGroup,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  FormControl,
  FormLabel,
  Row,
} from "react-bootstrap";
import { SWIPELINC_API } from "../utils/constant";
import { useParams } from "react-router-dom";
//AccountPhase3Imports
import swal from "sweetalert";
import {
  MDRTypeFetch,
  WithdrawType,
  checkIsFileIsPDF,
  uploadMerchantDocs,
} from "../utils/ApiServices";
import { stopCharacterInput, stopSpecialCharacterInput } from "../utils/helper";

// import RemoveIcon from '@mui/icons-material/RemoveIcon';

const AccountPhase1 = ({ setShowLoader }) => {
  const [merchantData, setMerchantData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [paymentService, setPaymentServices] = useState([]);
  const [callAgain, setCallAgain] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    mid: "",
    fullName: "",
  });
  const [MDRTypes, setMDRType] = useState([]);
  const [MDRData, setMDRData] = useState([]);
  const [withdrawType, setWithdrawType] = useState([]);
  const [option, setOptions] = useState(0);
  const [accountData, setAccountData] = useState({
    bussinessYear: 0,
    Chargeback: 0,
    fillingYears: 0,
    ticketSize: 0,
    monthlyVolume: 0,
    monthlyTicket: 0,
    withdraw: Number,
    withdrawType: false,
    holdPercentage: 0,
    depositeLumpsum: 0,
    depositePercentage: 0,
    guarantyAmount: 0,
    withdrawType: 0,
    withdrawalFlag: false,
  });

  const [token, setToken] = useState(localStorage.getItem("token"));
 
  const fetchtheTypeOfoption = async () => {
    setMDRData([]);
    setMDRType([]);
    const MDRType = await MDRTypeFetch(formData?.mid, token);
    setMDRType(MDRType?.data);

    let keys = MDRType?.data?.map((elem) => elem.paymentTypeId);
    // console.log("keys", keys);

    if (keys != null) {
      for (let d of keys) {
        setMDRData((prevData) => {
          return { ...prevData, [d]: 0 };
        });
      }
    }

    const withdrawType = await WithdrawType(token);
    setWithdrawType(withdrawType?.data);
    console.log(withdrawType);
  };

  useEffect(() => {
    fetchtheTypeOfoption(formData.mid, token);
  }, [formData.mid]);

  useEffect(() => {
    setShowLoader(true);
    axios
      .get(
       ` ${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getallforAccount`,

        {
          headers: {
            Authentication:" Bearer " +token,
          },
        } 
      )
      .then((response) => {
        if (response.status === 200) {
          setMerchantData(response.data.data);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setShowLoader(false);
      });
  }, [callAgain]);

  const handleCompanyChange = (event) => {
    console.log(event.target.value);
    const selectedCompany = event.target.value;
    if (selectedCompany === "Blank") {
      setFormData({
        email: "",
        mid: "",
        paymentService: "",
        fullName: "",
      });
      setSelectedCompany("");
      return;
    }

    setSelectedCompany(selectedCompany);

    const selectedMerchant = merchantData.find(
      (merchant) => merchant.mid === selectedCompany
    );

    if (selectedMerchant) {
      setPaymentServices(selectedMerchant.paymentService);
      setFormData({
        mid: selectedMerchant.mid || "",
        email: selectedMerchant.email || "",
        paymentService: selectedCompany.paymentService,
        fullName: selectedMerchant.authorizedPersonName || "",
      });
    } else {
      setFormData({
        email: "",
        mid: "",
        paymentService: "",
        fullName: "",
      });
    }
  };

  // const handleInputChange = (metric, year, value) => {
  //   if (stopCharacterInput(value, false) || stopSpecialCharacterInput(value)) {
  //     return;
  //   }

  //   setFinancialData((prevData) => ({
  //     ...prevData,
  //     [metric]: {
  //       ...prevData[metric],
  //       [year]: value === "" ? 0 : parseInt(value),
  //     },
  //   }));
  // };

  // const handleCountChange = (e, name) => {
  //   if (
  //     stopCharacterInput(e.target.value, false) ||
  //     stopSpecialCharacterInput(e.target.value)
  //   ) {
  //     return;
  //   }

  //   console.log(e.target.value, e.target.name);
  //   setAccountData({
  //     ...accountData,
  //     [e.target.name]: e.target.value === "" ? 0 : parseInt(e.target.value),
  //   });
  // };

  const handleAccoundPhase3data = (e, name) => {
    const value = e.target.value;
    if (
      stopCharacterInput(e.target.value, true) ||
      stopSpecialCharacterInput(e.target.value)
    ) {
      return;
    }
    if (value.includes("-") || value.includes("+")) {
      return;
    }

    setAccountData({
      ...accountData,
      [e.target.name]: parseFloat(e.target.value),
    });
  };

  // AccountPhase2

  // Function to update the form data when an input changes
  const handleMDRChange = (fieldName, value, mdrId) => {
    if (stopCharacterInput(value, true) || stopSpecialCharacterInput(value)) {
      return;
    }

    setMDRData({ ...MDRData, [mdrId]: parseFloat(value) });
    console.log(MDRData);
  };

  const handleFormSubmit = async (e) => {
    let data = Object.entries(MDRData);

    console.log("DATA", data);

    for (let d of data) {
      // console.log(" >>>> >>> ",d[1]);
      if (isNaN(d[1])) {
        swal({
          title: "ALERT",
          text: "Please Enter MDR",
          icon: "error",
        });
        return;
      }
    }

    let mdrdata = data?.map((elem) => {
      return {
        paymentTypeId: elem[0],
        mdrPercentage: elem[1],
        mid: formData.mid,
      };
    });

    console.log("MDRTypes", MDRTypes);
    console.log("mdrdata", mdrdata);
    const payload = {
      chargebackDeclarationPercentage: accountData.Chargeback || 0,
      depositeLumpsum: accountData.depositeLumpsum || 0,
      depositePercentage: accountData.depositePercentage || 0,
      // guarantyAmount: accountData.guarantyAmount || 0,
      holdPercentage: accountData.holdPercentage || 0,
      mid: formData.mid || 0,
      mdrDataRequests: mdrdata,
    };

    console.log("payload", JSON.stringify(payload));

    setShowLoader(true)
        try {
          const response = await axios.post(
            `${SWIPELINC_API}MerchantAdminPanel/merchant/Account/details/upload`,
            payload,
            {
              headers: {
                Authentication: "Bearer " + token,
              },
            }
          );

          if (response.data.statusCode === 200) {
           swal({
            title: "Success",
            text: "Application Saved Successfully",
            icon: "success",
           })
            setAccountData({
              Chargeback: 0,
              holdPercentage: 0,
              depositeLumpsum: 0,
              depositePercentage: 0,
              guarantyAmount: 0,
            });
            setMDRData({})
            setFormData({
              email: "",
              mid: "",
              paymentService: "",
              fullName: "",
            })
            setSelectedCompany("")
            setCallAgain(!callAgain)

          }

          else if (response.data.statusCode === 306) {
            swal({
              title: "Alert!",
              text: response.data.message,
              icon: "error",
            });
          }else{
            swal({
              title: "Alert!",
              text: response.data.message,
              icon: "error",
            });
          }
          setShowLoader(false)
        } catch (error) {
          console.error("Error sending OTP:", error);
        }
  };

  //   const [monthlyTicket, setMonthlyTicket] = useState(0);
  return (
    <>
      <section className="admin-view-details card mb-2">
        <Container>
          <Card className=" border-0 mb-4 ds-header-card d-flex gap-5">
            <Card.Body>
              <Row>
                <Col xs={12} xl={12} lg={12} sm={12} className="mb-5">
                  <div className="row mt-4">
                    <div className="col-sm-12 col-md-6 offset-md-3">
                      <label className="form-label">
                        Select Merchant
                        <sup className="text-danger">*</sup>
                      </label>
                      <select
                        id="companyDropdown"
                        className="form-control"
                        onChange={handleCompanyChange}
                        value={selectedCompany}
                        style={{ border: "1px solid black" }}
                      >
                        <option   value={"Select"}>
                           Select
                          </option>
                        {merchantData?.map((merchant) => (
                          <option key={merchant.mid} value={merchant.mid}>
                            {merchant.companyName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <label htmlFor="email">Merchant ID</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Merchant ID"
                      value={formData.mid}
                      readOnly
                    />
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <label htmlFor="fullName">Full Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="fullName"
                      placeholder="Full Name"
                      value={formData.fullName}
                      readOnly
                    />
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <label htmlFor="fullName">e-Mail ID</label>
                    <input
                      type="text"
                      className="form-control"
                      id="fullName"
                      placeholder="Full Name"
                      value={formData.email}
                      readOnly
                    />
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </section>
      <section className="lincpay-dashboard-sec merchant-admin-info-form company-add-form card">
        <Container>
          <div className="cart-view-sec mt-4">
            <Row>
              <Col xs={12} lg={12} md={12}>
                {/* Tab content start here */}
                <Card className="border-0 mb-3 chart-boxes merchant-info-form-sec">
                  <Card.Body>
                    {/* <Card.Title className="h3">
                        Company Address Details
                      </Card.Title> */}
                    <div className="merchant-form-info">
                      <form
                        className="form-section-form-group"
                        // onSubmit={handleSubmit}
                      >
                        <Row>
                          <Col xs={12} lg={4} md={6} className="mt-5">
                            <div className="form-group border border-1 p-3">
                              <label className="form-label">
                                Deposit
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="d-flex gap-2 flex-column  justify-content-evenly p-2">
                                <div className="form-group">
                                  <label className="form-label">
                                    In Lumsum
                                    <sup className="text-danger">*</sup>
                                  </label>
                                  <div className="d-flex gap-2">
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="depositeLumpsum"
                                      value={accountData.depositeLumpsum}
                                      onChange={handleAccoundPhase3data}
                                    />
                                  </div>
                                  <span
                                    id="regAddErr"
                                    className=" text-danger d-none"
                                  >
                                    Please Enter Regisered Address*
                                  </span>
                                </div>
                                <div className="form-group  ">
                                  <label className="form-label">
                                    In Percentage
                                    <sup className="text-danger">*</sup>
                                  </label>
                                  <div className="d-flex gap-2">
                                    <input
                                      className="form-control"
                                      type="number"
                                      name="depositePercentage"
                                      value={accountData.depositePercentage}
                                      onChange={handleAccoundPhase3data}
                                    />
                                  </div>
                                  <span
                                    id="regAddErr"
                                    className=" text-danger d-none"
                                  >
                                    Please Enter Regisered Address*
                                  </span>
                                </div>
                              </div>
                              <span
                                id="regAddErr"
                                className=" text-danger d-none"
                              >
                                Please Enter Regisered Address*
                              </span>
                            </div>
                            {/* ./form-group */}
                          </Col>

                          <Col xs={12} lg={4} md={6} className="mt-5">
                            <div className="form-group border border-1 p-3">
                              <label className="form-label">
                                Hold
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="d-flex gap-2 flex-column  justify-content-evenly p-2">
                                <div className="form-group">
                                  <label className="form-label">
                                    In Percentage
                                    <sup className="text-danger">*</sup>
                                  </label>
                                  <div className="d-flex gap-2">
                                    <input
                                      className="form-control"
                                      type="number"
                                      name="holdPercentage"
                                      value={accountData.holdPercentage}
                                      onChange={handleAccoundPhase3data}
                                    />
                                  </div>
                                  <span
                                    id="regAddErr"
                                    className=" text-danger d-none"
                                  >
                                    Please Enter Regisered Address*
                                  </span>
                                </div>
                              </div>
                              <span
                                id="regAddErr"
                                className=" text-danger d-none"
                              >
                                Please Enter Regisered Address*
                              </span>
                            </div>
                            {/* ./form-group */}
                          </Col>

                          <Col xs={12} lg={4} md={6} className="mt-5">
                            <div className="form-group border border-1 p-3">
                              <label className="form-label">
                                Chargeback declaration
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="d-flex gap-2  flex-column justify-content-evenly p-2">
                                <div className="form-group">
                                  <label className="form-label">
                                    In Percentage
                                    <sup className="text-danger">*</sup>
                                  </label>
                                  <div className="d-flex gap-2">
                                    <input
                                      className="form-control"
                                      name="Chargeback"
                                      type="number"
                                      value={accountData.Chargeback}
                                      onChange={handleAccoundPhase3data}
                                    />
                                  </div>
                                  <span
                                    id="regAddErr"
                                    className=" text-danger d-none"
                                  >
                                    Please Enter Regisered Address*
                                  </span>
                                </div>
                              </div>
                              <span
                                id="regAddErr"
                                className=" text-danger d-none"
                              >
                                Please Enter Regisered Address*
                              </span>
                            </div>
                            {/* ./form-group */}
                          </Col>
                        </Row>
                      </form>
                    </div>
                  </Card.Body>
                </Card>
                {/* Tab content end here */}
              </Col>
              {/* ./col */}
            </Row>
          </div>
        </Container>
      </section>
      <section className="lincpay-dashboard-sec merchant-admin-info-form company-add-form card  mt-2 mb-3">
        <Container>
          <div className="p-5 mt-2">
            <Row className="mt-5">
              <label className="form-label fw-bold mb-2">
                MERCHANT DISCOUNT RATE
                <sup className="text-danger">*</sup>
              </label>
              <label
                className="fw-semi-bold text-danger mb-5"
                style={{ fontSize: "13px" }}
              >
                (Please enter the MDR rate in Percentage.)
              </label>

              {MDRTypes?.map((service) => (
                <Col xs={12} lg={3} md={5} key={service.paymentTypeId}>
                  <div className="form-group mt-3">
                    <label className="form-label">
                      {service.paymentservice}
                      <sup className="text-danger">*</sup>
                    </label>
                    <input
                      type="number"
                      name={service.paymentTypeId}
                      className="form-control"
                      value={MDRData[service.paymentTypeId]} // Set value correctly
                      onChange={(e) =>
                        handleMDRChange(
                          service.paymentservice,
                          e.target.value,
                          service.paymentTypeId
                        )
                      }
                      required
                    />
                    <span id="regAddErr" className="text-danger d-none">
                      Please Enter Registered Address*
                    </span>
                  </div>
                </Col>
              ))}

              <Col xs={12} lg={12} md={12}>
                <div className="d-flex justify-content-end mt-3">
                  <div className="form-group tool-bar-btn mb-0 mt-3">
                    <button
                      className="submit-button submit_link_info btn btn-primary"
                      onClick={handleFormSubmit}
                      id="submitDetailsBtn"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Col>
              {/* {MDRTypes?.length === 0 ? "" : (
                <Col xs={12} lg={12} md={12}>
                  <div className="d-flex justify-content-end mt-3">
                    <div className="form-group tool-bar-btn mb-0 mt-3">
                      <button
                        className="submit-button submit_link_info btn btn-success"
                        onClick={handleMDRsubmit}
                        id="mdrSave"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Col>
              )} */}
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default AccountPhase1;