import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { SWIPELINC_API } from "../utils/constant";
import swal from "sweetalert";
import { Container, Row, Col, Card } from "react-bootstrap";
import MyLoader from "../common/MyLoader";
import { fetchAllMerchantMaster } from "../utils/ApiServices";
import { useSelector } from "react-redux";

const ViewFile = ({id,handleClose}) => {
  const navigate = useNavigate();
const { privilagesData,prefix, type,masterId } = useSelector((state) => state.privilages);
 
  const [merchantData, setMerchantData] = useState(null);
  const [open, setOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(Boolean);
 const [paymentservice, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [showPaymentServicesList, setShowPaymentServicesList] = useState(false);
  const [commissionPercentageInput, setCommissionPercentageInput] =
    useState("");
  const token = localStorage.getItem("token");
  const [merchantMaster, setMerchantMaster] = useState("");
  const [merchantMasterData, setMerchantMasterData] = useState([]);
  const [mccCode, setMccCode] = useState("");
  
 const handleApprove = () => {
    if (merchantMaster === "" && prefix === "ALL") {
      swal({
        title: "ALERT",
        text: "Please Select Merchant Master.",
        icon: "error",
      });
      return;
    }
    if (mccCode === "") {
      swal({
        title: "ALERT",
        text: "Please Select MCC Code.",
        icon: "error",
      });
      return;
    }

    swal({
      title: "Are you sure?",
      text: "Do you want to approve this Application?",
      icon: "warning",
      buttons: ["Cancel", "Yes, approve it!"],
      dangerMode: true,
    }).then((isConfirmed) => {
      if (isConfirmed) {
        const payload = {
          applicationStatus: "Approved",
          bankVerified: true,
          registrationId: id,
          rejectionRemark: "NA",
          masterMerchantId: prefix === "ALL" ? merchantMaster : masterId,
          commissionPercentage: parseFloat(commissionPercentageInput),
          mccCode: mccCode,
        };
        setShowLoader(true);
        axios
          .put(
            `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/updateStatus`,
            payload,
            {
              headers: {
                Authentication: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            swal("Success", "Application approved!", "success").then(() => {
              setOpen(false);
              
            });
            setShowLoader(false);
          })
          .catch((error) => {
            console.error("Error approving the application:", error.message);
            setShowLoader(false);
          });
      } else {
        setOpen(false);
      }
    });
  };

  const handleReject = () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to reject this vendor?",
      icon: "warning",
      buttons: ["Cancel", "Yes, reject it!"],
      dangerMode: true,
      content: {
        element: "input",
        attributes: {
          type: "text",
          placeholder: "Enter rejection remark",
        },
      },
    }).then((remark) => {
      if (remark === "") {
        swal("Warning", "Please fill in the rejection remark.", "warning");
      } else if (remark) {
        const payload = {
          applicationStatus: "rejected",
          bankVerified: true,
          registrationId: id,
          rejectionRemark: remark,
        };
        setShowLoader(true);
        axios
          .put(
            `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/updateStatus`,
            payload,
            {
              headers: {
                Authentication: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            swal("Success", "Application Rejected!", "success").then(() => {
              setOpen(false);
            });
            setShowLoader(false);
            
          })
          .catch((error) => {
            console.error("Error rejecting the application:", error.message);
            setShowLoader(false);
          });
      } else {
        setOpen(false);
      }
    });
  };

  useEffect(() => {
    setShowLoader(true);
    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getmerchantById/${id}`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setMerchantData(response?.data?.data);
        setSelectedServices(
          response?.data?.data?.paymentService?.map(
            (service) => service.paymentService
          )
        );
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching merchant details:", error.message);
        setShowLoader(false);
      });

    // Fetch payment services

    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/paymentType/getALLPaymentType`
      )
      .then((response) => {
        setServices(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching payment services:", error.message);
      });
  }, [id]);

  const handleSave = () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to save ?",
      icon: "warning",
      buttons: ["Cancel", "Yes, Save it!"],
      dangerMode: true,
    }).then((isConfirmed) => {
      if (isConfirmed) {
        const ids = [];
        console.log(paymentservice);

        for (let i = 0; i < selectedServices.length; i++) {
          const payId = paymentservice.find(
            (item) => item.paymentService === selectedServices[i]
          );
          console.log(payId);
          ids.push(payId.paymentTypeId);
        }
        const payload = {
          paymentTypeId: ids,
          registrationId: id,
        };
        console.log(payload);
        setShowLoader(true);
        axios
          .put(
            `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/updatePaymentTypeService/${id}`,
            payload,
            {
              headers: {
                Authentication: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            swal("Success", "Services Saved Successfully", "success").then(
              () => {
                setOpen(false);
              }
            );
            setShowLoader(false);
          })
          .catch((error) => {
            console.error("Error updating payment services:", error.message);
            setShowLoader(false);
          });
      } else {
        setOpen(false);
      }
    });
  };

  const handleServiceChange = (e) => {
    const found = selectedServices?.find((item) => item === e.target.value);
    if (found) {
      setSelectedServices(selectedServices?.filter((item) => item !== found));
    } else {
      setSelectedServices([...selectedServices, e.target.value]);
    }
  };
  useEffect(() => {
    const getAllMerchantMaster = async () => {
      try {
        const response = await fetchAllMerchantMaster(token);
        if (response?.statusCode === 200) {
          setMerchantMasterData(response?.data);
        } else {
          setMerchantMasterData([]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAllMerchantMaster();
  }, []);

  return (
    <>
      {showLoader ? <MyLoader /> : ""}
      <section className="admin-view-details">
        <Container>
          <Card className="shadow border-0 mb-4 ds-header-card">
            <Card.Body>
              <Row>
                <Col xs={12} xl={12} lg={12} sm={12}>
                  <div className="two-column-layout">
                    <div className="vertical-table">
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Company Name
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.companyName}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Registration Type
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.registrationType}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Business Category
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.businessType}
                        </div>
                      </div>
                      {merchantData?.businessType === "OTHERS" && (
                        <div className="vertical-table-row">
                          <div className="vertical-table-cell text-left">
                            Other Business Category
                          </div>
                          <div className="vertical-table-cell-value text-left">
                            {merchantData?.businessTypeName}
                          </div>
                        </div>
                      )}

                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Business Type
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.companyType}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Business As
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.businessAs}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Company PAN Number
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.companyPanNumber}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          GST Number
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.gstNumber}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Authorized Person Name
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.authorizedPersonName}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          e-Mail ID
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.email}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Mobile Number
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.mobile}
                        </div>
                      </div>

                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Authorized Person PAN
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.authorizedPersonPan}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Aadhaar Number
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.aadharNumber}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Website URL
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.websiteUrl}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Application URL
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.aapUrl}
                        </div>
                      </div>

                      <div className="vertical-table-row">
                        <div className="vertical-table-cell ">Services</div>
                        <div className="vertical-table-cell-value">
                          {/* Render the payment status button here */}
                          <button
                            className="btn btn-primary"
                            onClick={() =>
                              setShowPaymentServicesList(
                                !showPaymentServicesList
                              )
                            }
                          >
                            View Services
                          </button>
                          {/* Render the payment services list with checkboxes */}

                          {showPaymentServicesList && (
                            <div className="payment-services-list">
                              {paymentservice?.map((service) => (
                                <label key={service.paymentTypeId}>
                                  <input
                                    type="checkbox"
                                    value={service.paymentService}
                                    checked={
                                      selectedServices.includes(
                                        service.paymentService
                                      )
                                        ? true
                                        : false
                                    }
                                    onChange={handleServiceChange}
                                  />
                                  <span
                                    className={
                                      selectedServices.includes(
                                        service.paymentService
                                      )
                                        ? "selected-service"
                                        : ""
                                    }
                                  >
                                    {service.paymentService}
                                  </span>
                                </label>
                              ))}
                              <button
                                className="btn btn-success"
                                onClick={handleSave}
                              >
                                Save
                              </button>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Company Registered Address
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.companyAddress}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Pin Code
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.companyAddressPinCode}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          City
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.companyAddressCity}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          State
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.companyAddressState}
                        </div>
                      </div>
                    </div>

                    <div className="vertical-table">
                      {/* Second half of the table */}

                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Authorized Signatory Address
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.registeredOfficeAddress}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          {" "}
                          Pin Code
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.registeredOfficeAddressPinCode}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          City
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.registeredOfficeAddressCity}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          {" "}
                          State
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.registeredOfficeAddressState}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Company Communication Address
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.communicationAddress}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Pin Code
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.communicationAddressPinCode}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          City
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.communicationAddressCity}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          State
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.communicationAddressState}
                        </div>
                      </div>

                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Mode of Registration
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.modeOfRegistration}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Modification Date
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.modificationDate}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Submission Date
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.submitDate}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Submission Time
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.submitTime}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Referred By
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {merchantData?.referredBy}
                        </div>
                      </div>
                      {prefix === "ALL" ? (
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Master Merchant
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          <select
                            onChange={(e) => {
                              setMerchantMaster(e.target.value);
                            }}
                            value={merchantMaster}
                          >
                            <option value="">Select a Merchant</option>
                            {merchantMasterData?.map((merchant) => (
                              <option
                                key={merchant.masterMerchantId}
                                value={merchant.masterMerchantId}
                              >
                                {merchant.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      ):null}
                      {merchantData?.referredBy !== null ? (
                        <div className="vertical-table-row">
                          <div className="vertical-table-cell text-left">
                            Commision Percentage
                          </div>
                          <div className="vertical-table-cell-value">
                            <input
                              className="commision-per"
                              type="text"
                              value={commissionPercentageInput}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (
                                  !inputValue ||
                                  parseFloat(inputValue) >= 0
                                ) {
                                  setCommissionPercentageInput(inputValue);
                                }
                              }}
                              placeholder="Enter commission percentage"
                            />
                            {parseFloat(commissionPercentageInput) < 0 && (
                              <p className="error-message">
                                Commission percentage must be positive
                              </p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="vertical-table-row">
                          <div className="vertical-table-cell text-left"></div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">
                      MCC Code
                    </div>
                    <div className="vertical-table-cell-value text-left">
                      <select
                        onChange={(e) => {
                          setMccCode(e.target.value);
                        }}
                        value={mccCode}
                      >
                        <option value="">Select an MCC Code</option>
                        <option value="gaming-101">Gaming 101</option>
                        <option value="gaming-203">Gaming 203</option>
                        <option value="gaming-204">Gaming 204</option>
                        <option value="ecommerce-101">Ecommerce 101</option>
                        <option value="ecommerce-103">Ecommerce 103</option>
                        <option value="5262">5262</option>
                        <option value="5816">5816</option>
                        <option value="4900">4900</option>
                        <option value="6300">6300</option>
                      </select>
                    </div>
                  </div>
                  <div className="">
                    {privilagesData.includes(2593) ? (
                      <div className="button-toolbar groups-btn text-end">
                        <button
                          className="btn btn-success"
                          onClick={() => handleApprove()}
                        >
                          Approve
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={() => handleReject()}
                        >
                          Reject
                        </button>
                        
                       
                      </div>
                    ) : null}
                    <>
                    <button type="button" class="btn btn-primary" onClick={handleClose}>
                           Close
                        </button>
                    </>
                  </div>
                </Col>
              </Row>
            
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
};
export default ViewFile;
