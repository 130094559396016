import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import {
  GET_ALL_AGGREGATOR,
  GET_ALL_PRIVILAGES,
  GET_ALL_PRIVILAGES_BY_ID,
} from "../../../utils/constant";
import {
  getAllFunctionForApiCall,
  getAllFunctionForApiCalls,
  updateAggregatorData,
} from "../../../utils/ApiServices";
import swal from "sweetalert";
import { isEmailIdRegexIsValid, getKeyPressed } from "../../../utils/helper";

const EditSingleSubmittedAggregator = ({
  handleClose,
  AggregatorId,
  setShowLoader,
}) => {
  const [loading, setLoading] = useState(false);
  const [aggregatorData, setAggregatorData] = useState({});
  const [privilagesData, setPrivilagesData] = useState();
  const [privilages, setPrivilages] = useState([]);
  const [HeadPrivilages, setHeadPrivilages] = useState([]);
  const [pressedKeyName, setPressedKeyName] = useState("");
  const token = localStorage.getItem("token");

  const [formDataInputs, setFormDataInputs] = useState({
    AggregatorName: "",
    masterMerchantName: "",
    password: "",
    MobileNumber: "",
    Prifix: "",
    emailId: "",
  });

  useEffect(() => {
    setFormDataInputs({
      AggregatorName: aggregatorData.name,
      masterMerchantName: aggregatorData.masterMerchantId,
      password: aggregatorData.password,
      MobileNumber: aggregatorData.mobile,
      Prifix: aggregatorData.midprifix,
      emailId: aggregatorData.email,
    });
  }, [aggregatorData]);

  const getAllPrivilages = async () => {
    try {
      const response = await getAllFunctionForApiCalls(
        GET_ALL_PRIVILAGES,
        token
      );

      if (response.statusCode === 200) {
        setPrivilagesData(response?.data);
        if(response.data){
          checkHeadPri(response.data)
        }
        console.log(response?.data);
        console.log(privilagesData);
      } else {
        setPrivilagesData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAggregatorData = async () => {
    try {
      const response = await getAllFunctionForApiCall(
        GET_ALL_AGGREGATOR + AggregatorId,
        token
      );
      if (response.statusCode === 200) {
        setAggregatorData(response?.data[0]);
      } else {
        setAggregatorData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllPrivilagesbyID = async () => {
    try {
      const response = await getAllFunctionForApiCall(
        GET_ALL_PRIVILAGES_BY_ID + AggregatorId,
        token
      );
      if (response.statusCode === 200) {
        const res = response?.data?.map((item) => {
          
          return item.adminPrivilegeSubTypeId + "";
        });

        setPrivilages(res);
       
      } else {
        setPrivilages([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

 

  const handleformDataInputs = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (name === "password" || name === "emailId") {
      setFormDataInputs({ ...formDataInputs, [name]: value });
    } else {
      value = value.toUpperCase();
      if (name === "MobileNumber") {
        // Remove any non-numeric characters
        value = value.replace(/\D/g, "");
      }
      setFormDataInputs({ ...formDataInputs, [name]: value });
    }
  };
  const handlePrivilages = (e) => {
    const adminPrivilegeSubTypeId = e.target.name;
    const head = e.target.dataset.head + "";
    console.log(head);

    // setHeadPrivilages((prevPrivilages) => {
    //   if (!e.target.checked) {
    //     // If any sub-privilege is unchecked, remove the head privilege
    //     return prevPrivilages.filter((id) => id !== head);
    //   }
    //   return prevPrivilages;
    // });

    setPrivilages((prevPrivilages) => {
      if (e.target.checked) {
        if (!prevPrivilages.includes(adminPrivilegeSubTypeId)) {
          return [...prevPrivilages, adminPrivilegeSubTypeId];
        }
      } else {
        if (prevPrivilages.includes(adminPrivilegeSubTypeId)) {
          return prevPrivilages.filter((id) => id !== adminPrivilegeSubTypeId);
        }
      }
      return prevPrivilages;
    });
  };
  const handleHeadPrivilages = (e) => {
    const adminPrivilegeId = e.target.name;
    console.log(e.target.checked);

    const subtypeArray = privilagesData.find((item) => {
      return String(item.adminPrivilegeId) === adminPrivilegeId;
    });

    const array = subtypeArray?.subTypes.map((item) => {
      return item.adminPrivilegeSubTypeId + "";
    });

    if (e.target.checked) {
      setPrivilages((prev) => [...prev, ...array]);
      setHeadPrivilages((prevPrivilages) => [
        ...prevPrivilages,
        adminPrivilegeId,
      ]);
    } else {
      setPrivilages((prev) => prev.filter((id) => !array.includes(id)));
      setHeadPrivilages((prevPrivilages) =>
        prevPrivilages.filter((id) => id !== adminPrivilegeId)
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        await getAggregatorData();
        await getAllPrivilagesbyID();
        await getAllPrivilages();
        
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();

    
  }, []);

  const checkHeadPri = (privilagesData) => {
    const updatedHeadPrivilages = [];
  console.log(privilages)
    privilagesData?.forEach((item1,index )=> {
      const kjhfd = item1.subTypes.map(item => privilages.includes(item.adminPrivilegeSubTypeId+""));
          console.log(kjhfd.includes(false),`${index}`)
      if (!kjhfd.includes(false)) {
        console.log("yesINSIDE")
        // updatedHeadPrivilages.push(item1.adminPrivilegeId+'');
        setHeadPrivilages((prev)=>{
          return [...prev,item1.adminPrivilegeId+""]
        });
      }
    });
  
 
  };
  
  useEffect(() => {
    console.log(HeadPrivilages);
  }, [HeadPrivilages]);
  
    const handleSubmit = async (e) => {
      if (formDataInputs.AggregatorName === "") {
        swal({
          title: "Alert!",
          text: "Please Enter Aggregator Name",
          icon: "error",
        });
        return;
      }
      if (formDataInputs.MobileNumber === "") {
        swal({
          title: "Alert!",
          text: "Please Enter Mobile Number",
          icon: "error",
        });
        return;
      }
      if (formDataInputs.Prifix === "") {
        swal({
          title: "Alert!",
          text: "Please Enter Prifix",
          icon: "error",
        });
        return;
      }
      if (formDataInputs.emailId === "") {
        swal({
          title: "Alert!",
          text: "Please Enter Email Id",
          icon: "error",
        });
        return;
      }
      if (formDataInputs.password === "") {
        swal({
          title: "Alert!",
          text: "Please Enter password",
          icon: "error",
        });
        return;
      }
      if (!isEmailIdRegexIsValid(formDataInputs.emailId)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Merchant E-Mail ID !",
          icon: "error",
        }).then(() => {
          setFormDataInputs({ ...formDataInputs });
        });
        return;
      }
      if (privilages.length === 0) {
        swal({
          title: "Alert!",
          text: "Please select at least one privilege",
          icon: "error",
        });
        return;
      }
      const privilegs = privilages?.map((item) => {
        return parseInt(item);
      });
      const payload = {
        name: formDataInputs.AggregatorName.trim(),
        masterMerchantId: formDataInputs.masterMerchantName,
        password: formDataInputs.password.trim(),
        mobile: formDataInputs.MobileNumber.trim(),
        midprifix: formDataInputs.Prifix.trim(),
        email: formDataInputs.emailId.trim(),
        adminPrivilege: privilegs,
      };
  
      console.log(payload);
  
      e.preventDefault();
      const swalConfirm = await swal({
        title: "Are you sure?",
        text: "Do you want to submit the application?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });
      if (swalConfirm) {
        setShowLoader(true);
        //   const res = 200;
        const res = await updateAggregatorData(payload, token);
        setShowLoader(false);
  
        if (res.statusCode === 200) {
          swal({
            title: "Success",
            text: "Aggregator Updated Successfully.",
            icon: "success",
          });
          setFormDataInputs({
            AggregatorName: "",
            password: "",
            MobileNumber: "",
            Prifix: "",
            emailId: "",
          });
          setPrivilages([]);
          setPrivilagesData([]);
          getAllPrivilages();
          handleClose();
        } else {
          swal({
            title: "Failed",
            text: res.data.message,
            icon: "error",
          });
        }
      }
    };
    const handleValidations = (e) => {
      if (e.target.name === "email" && e.target.value !== "") {
        if (!isEmailIdRegexIsValid(formDataInputs.emailId)) {
          swal({
            title: "Alert!",
            text: "Please Enter Valid Merchant E-Mail ID !",
            icon: "error",
          }).then(() => {
            formDataInputs({ ...formDataInputs, email: "" });
          });
          return;
        }
      }
    };

  
  

  useEffect(() => {
    console.log("lkjhgfdfghj")
      checkHeadPri(privilagesData);
   
  }, [privilagesData])

  return (
    <>
      {loading ? null : (
        <>
          <Card.Header className="d-flex justify-content-between">
            <Card.Title>Merchant Master Edit</Card.Title>
            <Button onClick={handleClose}>Close</Button>
          </Card.Header>
          <Card className="shadow border-0 mb-4">
            <Card.Body>
              <div className="merchant-report-view">
                <div className="report-table-view">
                  <div>
                    <Row className="px-4">
                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="form-label">
                           Master Merchant Name
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              className="form-control"
                              name="AggregatorName"
                              value={formDataInputs.AggregatorName}
                              onChange={handleformDataInputs}
                            />
                          </div>
                        </div>
                        {/* ./form-group */}
                      </Col>

                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="form-label">
                            e-Mail Id
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              type="email"
                              className="form-control"
                              name="emailId"
                              value={formDataInputs.emailId}
                              onChange={handleformDataInputs}
                              onKeyDown={(e) =>
                                setPressedKeyName(getKeyPressed(e))
                              }
                              onBlur={handleValidations}
                            />
                          </div>
                        </div>
                        {/* ./form-group */}
                      </Col>

                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label htmlFor="MobileNumber" className="form-label">
                            Merchant Mobile Number
                            <sup className="text-danger">*</sup>{" "}
                          </label>
                          <input
                            maxLength={10}
                            minLength={10}
                            name="MobileNumber"
                            value={formDataInputs.MobileNumber}
                            onChange={handleformDataInputs}
                            required
                            pattern="[0-9]{10}"
                            title="Please enter a 10-digit mobile number"
                            className="form-control"
                          />
                        </div>
                      </Col>
                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="form-label">
                            Prifix
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              className="form-control"
                              name="Prifix"
                              value={formDataInputs.Prifix}
                              onChange={handleformDataInputs}
                            />
                          </div>
                        </div>
                        {/* ./form-group */}
                      </Col>
                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="form-label">
                            Password
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              className="form-control"
                              name="password"
                              value={formDataInputs.password}
                              onChange={handleformDataInputs}
                            />
                          </div>
                        </div>
                        {/* ./form-group */}
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <lable className="fs-3 fw-bold mx-3 mt-2 mb-3">
                        Privilages
                      </lable>
                      <div className="d-flex flex-column gap-4">
                      
{/* ... */}
{privilagesData &&
  privilagesData.map((itemMain, i) => (
    <div key={i} className="d-flex flex-column">
      <Card.Header className="form-label mt-4 mb-3 fs-4 mx-3 gap-3">
        <div className="s-lg form-check">
          {itemMain.privilegeName}
          <input
            id="flexSwitchCheckDefault"
            className="form-check-input fs-4"
            type="checkbox"
            name={itemMain.adminPrivilegeId}
            checked={HeadPrivilages.includes(itemMain.adminPrivilegeId + "")}
            onChange={handleHeadPrivilages}
          />
        </div>
      </Card.Header>

      <div className="d-flex flex-wrap">
        {itemMain.subTypes.map((item, i) => (
          <div
            key={i}
            className="bg-light d-flex justify-content-center align-items-center gap-5 px-4 py-1 rounded rounded-5 mt-2 mx-2   form-group"
          >
            <label className="form-label mt-1 fs-4 mx-1">
              {item.subTypePrivilegeName}
            </label>
            <div className="s-lg form-check form-switch">
              <input
                id="flexSwitchCheckDefault"
                className="form-check-input fs-4"
                data-head={itemMain.adminPrivilegeId}
                type="checkbox"
                name={item.adminPrivilegeSubTypeId}
                checked={privilages.includes(item.adminPrivilegeSubTypeId + "")}
                onChange={handlePrivilages}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  ))}
{/* ... */}

                      </div>
                    </Row>
                    <div className="d-flex justify-content-center mt-4">
                      <button
                        className="btn btn-primary"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};

export default EditSingleSubmittedAggregator;