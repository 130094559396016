import React from 'react'
import InactiveServices from './components/InactiveServices'
function Payment() {
  return (
    <>
      <InactiveServices location={"dfdh"}/> 
    </>
  )
}

export default Payment
