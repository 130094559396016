import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import InactiveServices from "../components/InactiveServices";
import AccountPhase1 from "./AccountPhase1";
import AccountPhase2 from "./AccountPhase2";
import axios from "axios";
import { SWIPELINC_API } from "../utils/constant";
import AccountPhase3 from "./AccountPhase3";
import SubmittedAccountApplications from "./SubmittedAccountApplications";
import MyLoader from "../common/MyLoader";
import { useSelector } from "react-redux";
import useCheckPrivilages from "../utils/checkPrivilages";

const SubTabForAccounts = ({ setShowLoader }) => {
  const [activePhase, setActivePhase] = useState("");

  const { privilagesData } = useSelector((state) => state?.privilages);
  const hasPrivilages2639=useCheckPrivilages(2639)
  const hasPrivilages2640=useCheckPrivilages(2640)

  useEffect(() => {
    setActivePhase("");
  }, []);

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  return (
    <>
      {/* {showLoader ? <MyLoader/> :""} */}
      
      <section className="dashboard-header-sec subtab-card">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-3  ds-inner-card">
                  <Card.Body>
                    <div className="merchant-tab-btn">
 
                        <ul className="report-link nav-report-btn submenu-tab-admin">
                        {hasPrivilages2639 ? (<li
                            className={activePhase === "Phase1" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase1")}
                          >
                            MDR Form {activePhase === "Phase1"}
                          </li>): null}

                          {hasPrivilages2640 ? ( <li
                            className={activePhase === "Phase2" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase2")}
                          >
                            Submitted Applications {activePhase === "Phase2"}
                          </li>) : null}
                           
                          
                        </ul>
                      

                      {/* <li
                          className={activePhase === "Phase3" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase3")}
                        >
                          Account phase 3 {activePhase === "Phase3"}
                        </li> */}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <div className="admin-tab-content">
              {activePhase === "Phase1" ? (
                <AccountPhase1 setShowLoader={setShowLoader} />
              ) : activePhase === "Phase2" ? (
                <SubmittedAccountApplications setShowLoader={setShowLoader} />
              ) : (
                null
              )}
            </div>
          </div>
        </Container>
      </section>
      
    </>
  );
};

export default SubTabForAccounts;
