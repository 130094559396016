import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Card, Row, Button, Col } from "react-bootstrap";
import DownloadCsv from "../../common/DownloadCsv";
import { getAllFunctionForApiCall } from "../../utils/ApiServices";
import { GET_ALL_DAILY_TRANSACTION_DATA_PAYOUT } from "../../utils/constant";


function Payoutacknowledgment({reportType,setShowLoader,dropDownData}) {
  const [data, setData] = useState();
  const [show2, setShow2] = useState(false);
  const [currentPage,setCurrentPage] = useState(0);
  const [showCallBack,setShowCallBack] = useState([]);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
const entriesPerPageOptions = [5,10, 20, 30,40,50]; 
  const token = localStorage.getItem("token");

  
  const navigate = useNavigate();

  const getDailyTransactionData = (token,page=0,size=entriesPerPage) => {
    setShowLoader(true)
    const response = getAllFunctionForApiCall(GET_ALL_DAILY_TRANSACTION_DATA_PAYOUT,token,page,size)

      .then((response) => {
        if (response.statusCode === 200) {
          setData(response?.data);
          setShowLoader(false)
        } else {
          setData([]);
          console.error("Error fetching data:", response.data.message);
          setShowLoader(false)
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setShowLoader(false)
      });
  };

  const handleShowOrNot = (index) => {
    setShowCallBack((prevShowCallBack) => {
      const newShowCallBack = [...prevShowCallBack];
      newShowCallBack[index] = !newShowCallBack[index];
      return newShowCallBack;
    });
  };

  const handlePageChange = (newPage) => {
    console.log("New page:", newPage);
    setCurrentPage(newPage);
    getDailyTransactionData(token,newPage)
  };
  const handleEntriesChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setEntriesPerPage(value);
      setCurrentPage(0);
      getDailyTransactionData(token, 0, value);
    }
  };


  const columns = [
    { label: "Merchant ID", field: "mid", width: "auto" },
    { label: "Company Name", field: "companyName", width: "auto" },
    
    { label: "Txn Request time", field: "txnRequestTime", width: "auto" },
    { label: "Txn Response Time", field: "txnResponseTime", width: "auto" },
    {
      label: "Txn Callback Received",
      field: "callbackReceiveTime",
      width: "auto",
    },
    {
      label: "Txn Callback Send Time",
      field: "callbackSendTime",
      width: "auto",
    },
    {
      label: "Txn Acknowlegment Received",
      field: "merchantcallbackResponseTime",
      width: "auto",
    },
    { label: "Callback Json Received from bank", field: "callbackJsonReceived", width: "auto" },
    { label: "Callback Json", field: "callbackJSON", width: "auto" },
    {
      label: "Merchant Callback Received",
      field: "merchantCallbackRecived",
      width: "auto",
    },
    { label: "Merchant Callback json", field: "merchantcallbackResponse", width: "auto" },
    { label: "Amount", field: "amount", width: "auto" },
    { label: "e-Mail", field: "email", width: "auto" },
    { label: "Mobile Number", field: "mobile", width: "auto" },
    { label: "Reference Id", field: "reference", width: "auto" },
    { label: "VPA / UPI", field: "upi", width: "auto" },
    { label: "Created Date", field: "createDate", width: "auto" },
    { label: "Created Time", field: "createTime", width: "auto" },
    
    { label: "UTR Number", field: "rrn", width: "auto" },
    { label: "Transaction Id", field: "txnId", width: "auto" },
    { label: "Transfer Type", field: "transferType", width: "auto" },
    { label: "Payment By", field: "paymentBy", width: "auto" },
    { label: "Transaction Status", field: "txnStatus", width: "auto" },
    { label: "Payout Status", field: "payoutStatus", width: "auto" },
    { label: "Category", field: "category", width: "auto" },
  ];
 
  const mappedData = data?.map((item, index) => {
    return {
      ...item,
       "date":item.createDate.split(" ")[0],
       "time":item.createDate.split(" ")[1],
       "time":item.createDate.split(" ")[1],
       "merchantCallbackRecived":item.merchantCallbackRecived=== "1" ? "Received" : "Not Received",
       "callbackJsonReceived":item.callbackJson ? "Received" : "Not Received",
      callbackJSON: item.callbackJson ? (
        <>
          <Button className="mb-2" onClick={() => handleShowOrNot(index)}>
            {showCallBack[index] ? "Hide" : "Show"}
          </Button>
          <div>

          {showCallBack[index] ? item.callbackJson : null}
          </div>
        </>
      ) : null,
    };
  });
   
useEffect(() => {
    getDailyTransactionData(token,currentPage);
  }, [currentPage,entriesPerPage]);

  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    handlePageChange(nextPage);
  };

  const handlePreviousPage = () => {
    const previousPage = currentPage - 1;
    handlePageChange(previousPage);
  };
  return (
    <>
      <section className="lincpay-dashboard-sec">
      <div className="d-flex align-items-center">
          <label htmlFor="entriesPerPage">Entries per page:</label>
          <select
            id="entriesPerPage"
            value={entriesPerPage}
            onChange={handleEntriesChange}
            className="form-select form-select-sm" 
            style={{ maxWidth: '100px' }}
          >
            {entriesPerPageOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="dasboard-view-count-boxes">
          <Card.Body>
            <div className="report-grid-view">
              <div className="material-table-records mb-3">
                <MDBDataTable
                  className="dataTable"
                  striped
                  bordered
                  small
                  data={{
                    columns: columns,
                    rows: mappedData,
                  }}
                  searching={true}
                  responsive
                  sorting={true}
                  hover
                  footer="none"
                  page={currentPage}
                  onPageChange={handlePageChange}
                  pagination={false}
                  paging={false}
                />
              </div>
              <DownloadCsv
            DownLoadData={data}
               Name={`Payout`}
              />
                <div className="pagination-buttons d-flex justify-content-end gap-2">
        <Button onClick={handlePreviousPage} disabled={currentPage === 0}>
          Previous
        </Button>
        <Button onClick={handleNextPage}>Next</Button>
      </div>
            </div>
          </Card.Body>
         </div>

      </section>
    </>
  );
}

export default Payoutacknowledgment;