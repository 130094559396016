import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import swal from "sweetalert";
import {
  SaveAggregatorUsers,
  getAllFunctionForApiCall,
  getAllFunctionForApiCalls,
} from "../../../utils/ApiServices";
import { GET_ALL_PRIVILAGES } from "../../../utils/constant";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { getTokenFromLocalStorage } from "../../../utils/tokenService";
import { isEmailIdRegexIsValid, getKeyPressed } from "../../../utils/helper";

const UserManagement = ({ setShowLoader }) => {
  const [privilagesData, setPrivilagesData] = useState();
  const [privilages, setPrivilages] = useState([]);
  const [pressedKeyName, setPressedKeyName] = useState("");
  const token = getTokenFromLocalStorage();

  const decodedToken = jwtDecode(token);
  const { masterId } = decodedToken;

  const [formDataInputs, setFormDataInputs] = useState({
    userName: "",
    password: "",
    MobileNumber: "",
    emailId: "",
  });

  const privilageSlice = useSelector((state) => state.privilages);

  const getAllPrivilages = async () => {
    try {
      const response = await getAllFunctionForApiCalls(
        GET_ALL_PRIVILAGES,
        token
      );

      if (response.statusCode === 200) {
        setPrivilagesData(response?.data);
        console.log(response.data);
      } else {
        setPrivilagesData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPrivilages();
  }, []);

  const handleformDataInputs = (e) => {
    const name = e.target.name;
    let value = e.target.value;
  
    if (name === "password" || name === "emailId") {
      setFormDataInputs({ ...formDataInputs, [name]: value });
    } else {
      value = value.toUpperCase();
      if (name === "MobileNumber") {
        // Remove any non-numeric characters
        value = value.replace(/\D/g, '');
      }
      setFormDataInputs({ ...formDataInputs, [name]: value });
    }
  };

  const handlePrivilages = (e) => {
    const adminPrivilegeSubTypeId = e.target.name;
    console.log(e.target.checked);

    setPrivilages((prevPrivilages) => {
      if (e.target.checked) {
        if (!prevPrivilages.includes(adminPrivilegeSubTypeId)) {
          return [...prevPrivilages, adminPrivilegeSubTypeId];
        }
      } else {
        if (prevPrivilages.includes(adminPrivilegeSubTypeId)) {
          return prevPrivilages.filter((id) => id !== adminPrivilegeSubTypeId);
        }
      }

      // Return the previous state if no changes are made
      return prevPrivilages;
    });
  };

  useEffect(() => {
    console.log(privilages);
  }, [privilages]);

  function convertCamelCaseToSentence(inputString) {
    // Use regex to insert a space before each capital letter, number before capital letter,
    // and consecutive capital letters with a space before the last one
    const spacedString = inputString
      .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
      .replace(/([a-zA-Z])([0-9])/g, "$1 $2")
      .replace(/([A-Z])(?=[A-Z])/g, "$1 ");

    // Capitalize the first letter of the result
    const capitalizedString =
      spacedString.charAt(0).toUpperCase() + spacedString.slice(1);

    return capitalizedString;
  }
const handleSubmit = async (e) => {
    if (formDataInputs.userName === "") {
      swal({
        title: "Alert!",
        text: "Please Enter User Name",
        icon: "error",
      });
      return;
    }
    if (formDataInputs.MobileNumber === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Mobile Number",
        icon: "error",
      });
      return;
    }
   
    if (formDataInputs.emailId === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Email Id",
        icon: "error",
      });
      return;
    }
    if (!privilages.length) {
      swal({
        title: "Alert!",
        text: "Please select atleast one privilage",
        icon: "error",
      });
      return;
    }
    if (!isEmailIdRegexIsValid(formDataInputs.emailId)) {
      swal({
        title: "Alert!",
        text: "Please Enter Valid Merchant E-Mail ID !",
        icon: "error",
      }).then(() => {
        setFormDataInputs({ ...formDataInputs});
      });
      return;
    }
    const privilegs = privilages?.map((item) => {
      return parseInt(item);
    });

    const payload = {
      email: formDataInputs.emailId.trim(),
      masterMerchantId: masterId,
      mobile: formDataInputs.MobileNumber.trim(),
      name: formDataInputs.userName.trim(),
      password: formDataInputs.password.trim(),
      privilegeId: privilegs,
      
    };

    console.log(payload);

    e.preventDefault();
    const swalConfirm = await swal({
      title: "Are you sure?",
      text: "Do you want to submit the application?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (swalConfirm) {
      setShowLoader(true);

      const res = await SaveAggregatorUsers(payload, token);

      console.log("RES >>>>", res);
      setShowLoader(false);

      if (res.statusCode === 200) {
        swal({
          title: "Success",
          text: "User Saved Successfully.",
          icon: "success",
        });
        setFormDataInputs({
          userName: "",
          password: "",
          MobileNumber: "",
          emailId: "",
        });
        setPrivilages([]);
        setPrivilagesData([]);
        getAllPrivilages();
      } else {
        swal({
          title: "Failed",
          text: res.message,
          icon: "error",
        });
      }
    }
  };
  const handleValidations = (e) => {
    if (e.target.name === "email" && e.target.value !== "") {
      if (!isEmailIdRegexIsValid(formDataInputs.emailId)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Merchant E-Mail ID !",
          icon: "error",
        }).then(() => {
          formDataInputs({ ...formDataInputs, email: "" });
        });
        return;
      }
    }
  };
  return (
    //   <Card className="shadow border-0 mb-4">
    //     <Card.Body>
    <div className="merchant-report-view">
      <div className="report-table-view">
        <div>
          <Row className="px-4">
            <Col xs={12} lg={4} md={6} className="mt-5">
              <div className="form-group">
                <label className="form-label">
                  Name
                  <sup className="text-danger">*</sup>
                </label>
                <div className="d-flex gap-2">
                  <input
                    className="form-control"
                    name="userName"
                    value={formDataInputs.userName}
                    onChange={handleformDataInputs}
                  />
                </div>
              </div>
              {/* ./form-group */}
            </Col>

            <Col xs={12} lg={4} md={6} className="mt-5">
              <div className="form-group">
                <label className="form-label">
                  e-Mail Id
                  <sup className="text-danger">*</sup>
                </label>
                <div className="d-flex gap-2">
                  <input
                    type="email"
                    className="form-control"
                    name="emailId"
                    value={formDataInputs.emailId}
                    onChange={handleformDataInputs}
                    onKeyDown={(e) => setPressedKeyName(getKeyPressed(e))}
                    onBlur={handleValidations}
                  />
                </div>
              </div>
              {/* ./form-group */}
            </Col>

            <Col xs={12} lg={4} md={6} className="mt-5">
              <div className="form-group">
                <label htmlFor="MobileNumber" className="form-label">
                   Mobile Number
                  <sup className="text-danger">*</sup>{" "}
                </label>
                <input
                  maxLength={10}
                  minLength={10}
                  name="MobileNumber"
                  value={formDataInputs.MobileNumber}
                  onChange={handleformDataInputs}
                  required
                  pattern="[0-9]{10}"
                  title="Please enter a 10-digit mobile number"
                  className="form-control"
                />
              </div>
            </Col>
            <Col xs={12} lg={4} md={6} className="mt-5">
              <div className="form-group">
                <label className="form-label">
                  Password
                  <sup className="text-danger">*</sup>
                </label>
                <div className="d-flex gap-2">
                  <input
                    className="form-control"
                    name="password"
                    value={formDataInputs.password}
                    onChange={handleformDataInputs}
                  />
                </div>
              </div>
              {/* ./form-group */}
            </Col>
          </Row>
          <Row className="mt-3">
            <lable className="fs-3 fw-bold mx-3 mt-2 mb-3">Privileges</lable>
            <div className="d-flex flex-column gap-4">
              {privilagesData &&
                privilagesData.map((item, i) => (
                  <div key={i} className="d-flex flex-column">
                    <Card.Header className="form-label mt-4 mb-3 fs-4 mx-1">
                      {item.privilegeName}
                    </Card.Header>
                    <div className="d-flex flex-wrap">
                      {item.subTypes.map(
                        (item, i) =>
                          privilageSlice?.privilagesData?.includes(
                            parseInt(item.adminPrivilegeSubTypeId)
                          ) && (
                            <div
                              key={i}
                              className="bg-light d-flex justify-content-center align-items-center gap-5 px-4 py-1 rounded rounded-5 mt-2 mx-2   form-group"
                            >
                              <label className="form-label mt-1 fs-4 mx-1">
                                {item.subTypePrivilegeName}
                              </label>
                              <div className="s-lg form-check form-switch ">
                                <input
                                  id="flexSwitchCheckDefault"
                                  className="form-check-input fs-4"
                                  type="checkbox"
                                  name={item.adminPrivilegeSubTypeId}
                                  value={
                                    privilages.includes(
                                      item.adminPrivilegeSubTypeId
                                    )
                                      ? "true"
                                      : "false"
                                  }
                                  onChange={handlePrivilages}
                                />
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </Row>
          <div className="d-flex justify-content-center mt-4">
            <button className="btn btn-primary" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    //     </Card.Body>
    //   </Card>
  );
};

export default UserManagement;
