import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { getAllFunctionForApiCall } from "../../utils/ApiServices"
import DownloadCsv from "../../common/DownloadCsv";
import { FETCH_PAYIN_FOR_ACKNOWLEDGMENT } from "../../utils/constant";
import { render } from "@testing-library/react";

const Payinacknowledgment = ({ setShowLoader }) => {
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [showServerCallBack, setShowServerCallBack] = useState([]);
  const [showCallBack, setShowCallBack] = useState([]);
  const entriesPerPageOptions = [5, 10, 20, 30, 40, 50];
  const token = localStorage.getItem("token");

  const fetchpayinforacknowledgment = async (token, page = 0, size = entriesPerPage) => {
    setShowLoader(true)
    const response = await getAllFunctionForApiCall(FETCH_PAYIN_FOR_ACKNOWLEDGMENT, token, page, size)
      .then((response) => {
        if (response?.statusCode === 200) {
          setTableData(response?.data);
          setShowLoader(false)
        } else {
          setTableData([]);
          console.error("Error fetching data:", response?.data?.message);
          setShowLoader(false)
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setShowLoader(false)
      });
  };

  useEffect(() => {
    fetchpayinforacknowledgment(token, currentPage);
  }, [currentPage, entriesPerPage]);

  const handlePageChange = (newPage) => {
    console.log("New page:", newPage);
    setCurrentPage(newPage);
    fetchpayinforacknowledgment(token, newPage)
  };

  const handleShowOrNot = (index) => {
    setShowCallBack((prevShowCallBack) => {
      const newShowCallBack = [...prevShowCallBack];
      newShowCallBack[index] = !newShowCallBack[index];
      return newShowCallBack;
    });
  };

  const handleServerShowOrNot = (index) => {
    setShowServerCallBack((prevShowCallBack) => {
      const newShowCallBack = [...prevShowCallBack];
      newShowCallBack[index] = !newShowCallBack[index];
      return newShowCallBack;
    });
  };
  const handleEntriesChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setEntriesPerPage(value);
      setCurrentPage(0);
      fetchpayinforacknowledgment(token, 0, value);
    }
  };
  const columns = [
    { label: "Company Name", field: "companyName", width: "auto" },
    { label: "MID", field: "mid", width: "auto" },
    { label: "SID", field: "sid", width: "auto" },
    { label: "Amount", field: "amount", width: "auto" },
    { label: "Transaction Status", field: "txnStatus", width: "auto" },
    { label: "Payment Type", field: "paymentType", width: "auto" },
    { label: "Order Number", field: "orderNo", width: "auto" },
    { label: "Transaction ID", field: "txn_id", width: "auto" },
    { label: "Transaction Date", field: "txnDate", width: "auto" },
    { label: "Transaction Time", field: "txnTime", width: "auto" },
    { label: "Sent To Bank Date", field: "sentToBankDate", width: "auto" },
    { label: "Acknowledgement From Bank Date", field: "ackFromBankDate", width: "auto" },
    { label: "QR Sent To Merchant Date", field: "qrSentToMerchantDate", width: "auto" },
    { label: "Callback Sent To Merchant Date", field: "callbackSentToMerchantDate", width: "auto" },
    { label: "Acknowledgement Received From Merchant Date", field: "ackReceivedFromMerchantDate", width: "auto" },
    { label: "Merchant Response", field: "responseCode", width: "auto" },
    // { label: "Merchant Response", field: "responseCode", width: "auto" },
    { label: "Callback Response", field: "callbackRespJson", width: "auto" },
    { label: "Server Response Json", field: "serverRespJson", width: "auto" },
    {label: "Merchant Callback Received",field: "render",width: "auto",},
    { label: "Response Date", field: "responseDate", width: "auto" },
    { label: "UTR Number", field: "custRefNo", width: "auto" },
    { label: "Payer VPA", field: "userVpa", width: "auto" },
    { label: "Mobile Number", field: "mobileNo", width: "auto" },
    { label: "e-Mail", field: "email", width: "auto" },
    { label: "Customer Location", field: "customerLocation", width: "auto" },
    { label: "Customer IFSC", field: "customerIfsc", width: "auto" },
    { label: "Bank Location", field: "bankLocation", width: "auto" },
    
  ];
const MappedData = tableData?.map((item,index) => {
    return {
      ...item,
      "txnDate" : item.txnDate?.split(" ")[0],
      "txnTime" : item.txnTime?.split(" ")[1],
      "render" : item.responseCode === "205" ? "Received" : "Not Received",
      "responseDate" : item.responseDate?.split(" ")[1],
      // evokTxnCode: item.txnStatus === "success" ? "0" : item.evokTxnCode,
      "serverRespJson": item.serverRespJson ? (
        <>
          <Button className="mb-2" onClick={() => handleServerShowOrNot(index)}>
            {showServerCallBack[index] ? "Hide" : "Show"}
          </Button>
          <div>

          {showServerCallBack[index] ? item.serverRespJson : null}
          </div>
        </>
      ) : null,
      "callbackRespJson": item.callbackRespJson ? (
        <>
          <Button className="mb-2" onClick={() => handleShowOrNot(index)}>
            {showCallBack[index] ? "Hide" : "Show"}
          </Button>
          <div>

          {showCallBack[index] ? item.callbackRespJson : null}
          </div>
        </>
      ) : null,
    };
  });

  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    handlePageChange(nextPage);
  };

  const handlePreviousPage = () => {
    const previousPage = currentPage - 1;
    handlePageChange(previousPage);
  };
  return (
    <>
      <section className="lincpay-dashboard-sec">
        <div className="d-flex align-items-center">
          <label htmlFor="entriesPerPage">Entries per page:</label>
          <select
            id="entriesPerPage"
            value={entriesPerPage}
            onChange={handleEntriesChange}
            className="form-select form-select-sm"
            style={{ maxWidth: '100px' }}
          >
            {entriesPerPageOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="dasboard-view-count-boxes">
          <Card.Body>
            <div className="report-grid-view">
              <div className="material-table-records mb-3">
                <MDBDataTable
                  className="dataTable"
                  striped
                  small
                  bordered
                  hover
                  responsive
                  data={{
                    columns: columns,
                    rows: MappedData,
                  }}
                  searching={true}
                  entries={50}
                  paginationLabel={["Previous", "Next"]}
                  sortable={true}
                  footer="none"
                  page={currentPage}
                  onPageChange={handlePageChange}
                  pagination={false}
                  paging={false}
                />

              </div>
              <DownloadCsv
                DownLoadData={tableData}
                Name={`payin-acknowledgment`}
              />
              <div className="pagination-buttons d-flex justify-content-end gap-2">
                <Button onClick={handlePreviousPage} disabled={currentPage === 0}>
                  Previous
                </Button>
                <Button onClick={handleNextPage}>Next</Button>
              </div>
            </div>
          </Card.Body>
        </div>
      </section>
    </>
  );
};

export default Payinacknowledgment;