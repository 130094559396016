import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { SWIPELINC_API } from "../../utils/constant";
import axios from "axios";
import MyModal from "../../components/Tools/ALL PAYOUT REPORTS/MyModel";
import MyModalGuarenteeAmount from "./MyModalGuarenteeAmount";
import { Row, FormLabel } from "react-bootstrap";
import InactiveServices from "../../components/InactiveServices";
import useCheckPrivilages from "../../utils/checkPrivilages";

const GuarenteeAmount = ({setShowLoader,data}) => {
  const [merchantData, setMerchantData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [formData, setFormData] = useState({
    email: "",
    mid: "",
    mobile: "",
    fullName: "",
  });

  const hasPrivilage=useCheckPrivilages(2693)
   

  const [option, setOption] = useState("blank");

  const [showModal, setShowModal] = useState(false);
 const resetData=()=>{

   setFormData({
     email: "",
     mid: "",
     mobile: "",
     fullName: "",
    })
    setSelectedCompany('')
    // setMerchantData([])
    setOption('blank')
  }
 

 
  useEffect(() => {
    setMerchantData(data)
  }, []);

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    setSelectedCompany(selectedCompany);

    const selectedMerchant = merchantData.find(
      (merchant) => `${merchant.mid} :- ${merchant.merchantName}` === selectedCompany
    );

    if (selectedMerchant) {
      setFormData({
        mid: selectedMerchant.mid || "",
        
      });
      
    } else {
      // Reset form fields if no company is selected
      setFormData({
        mid: "",
      });
    }
  };
 
const handleOptionsChange=(e)=>{
   const value= e.target.value
   setOption(value)
   console.log(option);
   setShowModal(true)
}


const closeModal=()=>{
  setShowModal(false)
  resetData()
}
  
console.log(option);
  return (
    <>
      {/* {hasPrivilage ? (  */}
         <div className="containerflag">
      {/* <h1 className="mt-3">Select</h1> */}
      <div className="row mt-4">
        <div className="col-sm-12 col-md-6 offset-md-3">
          <select
            id="companyDropdown"
            className="form-control"
            onChange={handleCompanyChange}
            value={selectedCompany}
          >
            <option value="">Select a Company</option>
            {merchantData.map((merchant) => (
              <option key={merchant.mid} value={`${merchant.mid} :- ${merchant.merchantName}`}>
              {merchant.mid} :- {merchant.merchantName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-sm-12 col-md-6 offset-md-3">
          <div className="form-group">
            <label htmlFor="email">Merchant ID</label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Merchant ID"
              value={formData.mid}
              readOnly
            />
          </div>
 
          <div className="form-group">
            <FormLabel>Select</FormLabel>
          <select
             
            className="form-control"
            onChange={handleOptionsChange}
            value={option}
          >
             
            <option key={0} value="blank">Select</option>
            <option key={1} value="credit">Receive</option>
            <option key={2} value="debit">Return</option>
          </select>
        </div>
          
        </div>
        {(formData.mid && (option==='credit' || option === "debit"))?(

          <MyModalGuarenteeAmount showModal={showModal} closeModal={closeModal}  mid={formData.mid} transactionType={option}/> 
          ):''}
      </div>
    </div>
    {/* ) : (<InactiveServices location={"privilages"}/>)} */}
    </>
    
  );
};
export default GuarenteeAmount;
