import React, { useEffect, useState } from "react";
import axios from "axios";
import ChargeBackFile from "../assets/templateFiles/ChargeBack.csv";
import { SWIPELINC_API } from "../utils/constant";
import { Container, Card, Row, Col } from "react-bootstrap";
import swal from "sweetalert";
import useCheckPrivilages from "../utils/checkPrivilages";
 
import InactiveServices from "../components/InactiveServices";

const ChargebackUpload = ({ setShowLoader }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [failureMessage, setFailureMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [showUploadButton, setShowUploadButton] = useState(true);
  const [token, setToken] = useState(localStorage.getItem("token"));

  const handleFileUpload = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const hasPrivilage = useCheckPrivilages(2699);

  const handleUpload = () => {
    if (!selectedFile) {
      setFailureMessage("Please select a file to upload.");
      setShowMessage(true);

      return;
    }
    let formdata = new FormData();
    formdata.append("file", selectedFile);
    setShowLoader(true);
    axios
      .post(`${SWIPELINC_API}MerchantAdminPanel/chargeBack/upload`, formdata, {
        headers: {
          Authentication: `Bearer ${token}`,
        },
      })

      .then((response) => {
        console.log(response);
        if (response.data.statusCode === 200) {
          console.log(response.data);

          swal({
            title: "Success",
            text: `${response.data?.size} File Uploaded Successfully.`,
            icon: "success",
          });
        } else if (response.data.statusCode === 1001) {
          const errorData = response.data.data;
          const errorDublicateData = response.data.duplicateData;

          console.log(errorDublicateData);

          let errorMessage = " \n  File uploaded partially as:\n";
          let errorDublicateMessage = " \n  Data \n";
          let SuccessMsg = `${response.data.size} files uploaded Successfully. \n`;

          const formattedErrors = errorData.map(
            ([rowNumber, errorDescription]) => {
              return ` ${rowNumber} RRN Number ${errorDescription}`;
            }
          );
          const formattedDubliCateErrors = errorDublicateData?.map(
            ([rowNumber, errorDescription]) => {
              return ` ${rowNumber} is ${errorDescription}`;
            }
          );

          errorMessage += formattedErrors.join("\n");
          errorDublicateMessage += formattedDubliCateErrors.join("\n");
          const finaltext =
            SuccessMsg + "\n" + errorMessage + "\n" + errorDublicateMessage;

          swal({
            title: "Error",
            text: finaltext,
            icon: "error",
          });
        } else if (response.data.statusCode === 422) {
          const errorData = response.data.data;
          const errorDublicateData = response.data.duplicateData;

          console.log(errorDublicateData);

          let errorMessage = "File not uploaded as:\n";
          let errorDublicateMessage = " \n  Data \n";

          const formattedErrors = errorData.map(
            ([rowNumber, errorDescription]) => {
              return ` ${rowNumber} RRN Number ${errorDescription}`;
            }
          );
          const formattedDubliCateErrors = errorDublicateData?.map(
            ([rowNumber, errorDescription]) => {
              return ` ${rowNumber} is ${errorDescription}`;
            }
          );

          errorMessage += formattedErrors.join("\n");
          errorDublicateMessage += formattedDubliCateErrors.join("\n");
          const finaltext = errorMessage + "\n" + errorDublicateMessage;

          swal({
            title: "Error",
            text: finaltext,
            icon: "error",
          });
        } else if (response.data.statusCode === 600) {
          const errorMessage =
            response.data.message || "Error occurred while reading the file.";
          setFailureMessage(errorMessage);
          swal({
            title: `${response.data.status}`,
            text: errorMessage,
            icon: "error",
          });
        } else if (response.data.statusCode === 500) {
          swal({
            title: "Failed",
            text: "Please Upload CSV File",
            icon: "error",
          });
        } else if (response.data.statusCode === 101) {
          swal({
            title: "Failed",
            text: "File Not Uploaded Because Data Is Missing or InCorrect",
            icon: "error",
          });
        } else {
          setFailureMessage("File Not Uploaded, Please Upload Valid File.");
        }
        setShowMessage(true);
        setShowLoader(false);
        document.getElementById("fileUpload").value = "";
        formdata = null;
        setSelectedFile(null);
      })

      .catch((error) => {
        setSuccessMessage("");
        setFailureMessage("File upload failed.");
        setShowMessage(true);
        setShowLoader(false);
        console.error(error);
      });
  };

  return (
    <>
      {/* {hasPrivilage ? ( */}
        <section className="lincpay-dashboard-sec chargeback-reports-sec">
          <Container>
            <div className="cart-view-sec mt-2">
              <Row>
                <Col xs={12} lg={12} md={12}>
                  {/* Tab content start here */}
                  <Card className="shadow-cst border-0 mb-3 chart-boxes">
                    <Card.Body>
                      <Card.Title className="h3 mb-3 card-header-btn">
                        Chargeback Report
                        <div className="button-toolbar">
                          <a
                            className="btn btn-success"
                            title="Click here to download Chargeback file template"
                            href={ChargeBackFile}
                            download
                          >
                            Download Template
                          </a>
                        </div>
                      </Card.Title>
                      <div className="card-chart-box">
                        <div className="excel-uploader">
                          {showUploadButton && (
                            <div className="form-group upload-field">
                              <div className="input-upload-file">
                                <input
                                  type="file"
                                  className="form-control"
                                  onChange={handleFileUpload}
                                  id="fileUpload"
                                />
                              </div>
                              <div className="toolbar-btn">
                                <button
                                  className="btn btn-primary"
                                  onClick={handleUpload}
                                >
                                  Upload
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                  {/* Tab content end here */}
                </Col>
                {/* ./col */}
              </Row>
            </div>
          </Container>
        </section>
      {/* ) : (
        <InactiveServices location={"privilages"} />
      )} */}
    </>
  );
};

export default ChargebackUpload;
