import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { fetchAllFRMUsers } from "../../../utils/ApiServices";
import swal from "sweetalert";
import axios from "axios";
import { SWIPELINC_API } from "../../../utils/constant";

const SubmittedForm = ({setShowLoader}) => {
  const [userData, setUserData] = useState([]);
  const [callAgain, setCallAgain] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));

  const disableAggregator = async (e, id) => {
    
    const EnableOrDisable=e.target.value

   swal({
     title: "Alert",
     text: `Are you sure, you want to ${EnableOrDisable}`,
     icon: "warning",
     buttons: true,
     dangerMode: true,
   }).then((willDisable) => {
     if (willDisable) {
      
       setShowLoader(true);

       axios
         .put(
           `${SWIPELINC_API}/MerchantAdminPanel/merchant/frm-block/${EnableOrDisable}/${id}`, 
           "",
           {
             headers: {
               Authentication: `Bearer ${token}`,
             },
           }
         )
         .then((res) => {
           if(res.data.statusCode===200){
             swal({
               title: "Success",
               text: `Aggregator ${EnableOrDisable} Successfully.`,
               icon: "success",
             })
             setCallAgain(prevState => !prevState);
             getAllFRMUsers(token);
           }else{
             swal({
               title: "Success",
               text: `Failed to ${EnableOrDisable} Aggregator.`,
               icon: "error",
             })
           }
           setShowLoader(false);
         })
         .catch((error) => {
           console.error("Error fetching data:", error);
           setShowLoader(false);
         });
     } else {
       
       setShowLoader(false);
     }
   });
 };

  
  const columns = [
    // { label: 'Company Name', field: 'companyName', width: 'auto' },
    // { label: 'Merchant ID', field: 'mid', width: 'auto' },
    // { label: 'ID', field: 'id', width: 'auto' },
    { label: 'VPA', field: 'vpa', width: 'auto' },
    // { label: 'Do Block', field: 'doBlock', width: 'auto' },
    { label: 'IMEI Number', field: 'imei', width: 'auto' },
    { label: 'Latitude', field: 'letitude', width: 'auto' },
    { label: 'Longitude', field: 'longitude', width: 'auto' },
    { label: 'e-Mail', field: 'email', width: 'auto' },
    { label: 'Mobile', field: 'mobile', width: 'auto' },
    { label: 'IFSC Code', field: 'ifscCode', width: 'auto' },
    { label: "Enable / Disable", field: "doBlock", width: "auto" },

  ];
const mappedData = userData?.map((item) => {
    const mappedItem = { ...item};
        mappedItem.doBlock = (
          <div>
            <button
              className={`btn ${!item.doBlock ? "btn-danger" : "btn-primary"}`}
              value={item.doBlock ? "enable" : "disable"}
              onClick={(e) => disableAggregator(e, item.id)}
            >
              {item.doBlock ? "Enable" : "Disable"}
            </button>
          </div>
        );  
    return mappedItem;
})
  const data = {
    columns: columns,
    rows: mappedData,
  };

  const getAllFRMUsers = async () => {
    let res = await fetchAllFRMUsers(token)
    
    if(res.statusCode === 200)
    {
        setUserData(res?.data)
    }
    else
    setUserData(res?.data)
  }
 useEffect(() => {
    getAllFRMUsers()
  }, [])

  return (
    <Container>
    <section className="w-100 lincpay-dashboard-sec merchant-admin-info-form company-add-form card py-3">
      {/* {showLoader ? <MyLoader /> : ""} */}
      <div className="containerflag">
        <div className="row mt-4 mx-2">
          <MDBDataTable
            className="dataTable"
            striped
            bordered
            small
            data={data}
            searching={true}
            responsive
            sorting={true}
            hover
            footer="none"
          />
        </div>
      </div>
    </section>
    </Container>
  );
};

export default SubmittedForm;
