import React, { useState } from "react";
import {
  frmBlockVPA,
  getMechantForPayoutApiTest,
} from "../../../utils/ApiServices";
import swal from "sweetalert";
import { Card, Container } from "react-bootstrap";
import axios from "axios";
import { SWIPELINC_API } from "../../../utils/constant";
import frmTemplate from "../../../assets/templateFiles/frmTemplate.csv";

const parameter = [
  { id: 1, name: "VPA", payloadName: "vpa" },
  { id: 2, name: "IMEI Number / Device Id", payloadName: "imei" },
  { id: 3, name: "Email ID", payloadName: "email" },
  { id: 4, name: "Mobile Number", payloadName: "mobile" },
  { id: 5, name: "IFSC Code", payloadName: "ifscCode" },
  { id: 6, name: "Location", payloadName: "location" },
];

const FraudManagement = ({ setShowLoader }) => {
  const [selectParameterData, setSelectParameterData] = useState(parameter);
  const [selectedParameter, setSelectedParameter] = useState([]);
  const [merchantData, setMerchantData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const [successMessage, setSuccessMessage] = useState("");
  const [failureMessage, setFailureMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const [mid, setMid] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const token = localStorage.getItem("token");
  const [formData, setFormData] = useState({
    mid: "",
    merchantName: "",
  });

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    setSelectedCompany(selectedCompany);

    const selectedMerchant = merchantData.find(
      (merchant) => merchant.merchantName === selectedCompany
    );

    if (selectedMerchant) {
      setMid(selectedMerchant.mid);
      setFormData({
        mid: selectedMerchant.mid || "",
        merchantName: selectedMerchant.merchantName || "",
      });
    } else {
      setMid("");
      setFormData({
        mid: "",
        merchantName: "",
      });
    }
  };
  const getAllMerchantForApiTest = async () => {
    setShowLoader(true);
    let res = await getMechantForPayoutApiTest(token);
    setShowLoader(false);
    setMerchantData(res?.data);
  };

  const handleFileUpload = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (!selectedFile) {
      setFailureMessage("Please select a file to upload.");
      setShowMessage(true);

      return;
    }
    let formdata = new FormData();
    formdata.append("file", selectedFile);
    setShowLoader(true);
    axios
      .post(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/frm-block/upload`,
        formdata,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )

      .then((response) => {
        console.log(response);
        if (response.data.statusCode === 200) {
          console.log(response.data);

          swal({
            title: "Success",
            text: `${response.data?.size} File Uploaded Successfully.`,
            icon: "success",
          });
        } else if (response.data.statusCode === 1001) {
          const errorData = response.data.data;
          const errorDublicateData = response.data.duplicateData;

          console.log(errorDublicateData);

          let errorMessage = " \n  File uploaded partially as:\n";
          let errorDublicateMessage = " \n  Data \n";
          let SuccessMsg = `${response.data.size} files uploaded Successfully. \n`;

          const formattedErrors = errorData.map(
            ([rowNumber, errorDescription]) => {
              return ` ${rowNumber} RRN Number ${errorDescription}`;
            }
          );
          const formattedDubliCateErrors = errorDublicateData?.map(
            ([rowNumber, errorDescription]) => {
              return ` ${rowNumber} is ${errorDescription}`;
            }
          );

          errorMessage += formattedErrors.join("\n");
          errorDublicateMessage += formattedDubliCateErrors.join("\n");
          const finaltext =
            SuccessMsg + "\n" + errorMessage + "\n" + errorDublicateMessage;

          swal({
            title: "Error",
            text: finaltext,
            icon: "error",
          });
        } else if (response.data.statusCode === 422) {
          const errorData = response.data.data;
          const errorDublicateData = response.data.duplicateData;

          console.log(errorDublicateData);

          let errorMessage = "File not uploaded as:\n";
          let errorDublicateMessage = " \n  Data \n";

          const formattedErrors = errorData.map(
            ([rowNumber, errorDescription]) => {
              return ` ${rowNumber} RRN Number ${errorDescription}`;
            }
          );
          const formattedDubliCateErrors = errorDublicateData?.map(
            ([rowNumber, errorDescription]) => {
              return ` ${rowNumber} is ${errorDescription}`;
            }
          );

          errorMessage += formattedErrors.join("\n");
          errorDublicateMessage += formattedDubliCateErrors.join("\n");
          const finaltext = errorMessage + "\n" + errorDublicateMessage;

          swal({
            title: "Error",
            text: finaltext,
            icon: "error",
          });
        } else if (response.data.statusCode === 600) {
          const errorMessage =
            response.data.message || "Error occurred while reading the file.";
          setFailureMessage(errorMessage);
          swal({
            title: `${response.data.status}`,
            text: errorMessage,
            icon: "error",
          });
        } else if (response.data.statusCode === 500) {
          swal({
            title: "Failed",
            text: "Please Upload CSV File",
            icon: "error",
          });
        } else if (response.data.statusCode === 101) {
          swal({
            title: "Failed",
            text: "File Not Uploaded Because Data Is Missing or InCorrect",
            icon: "error",
          });
        } else {
          setFailureMessage("File Not Uploaded, Please Upload Valid File.");
        }
        setShowMessage(true);
        setShowLoader(false);
        document.getElementById("fileUpload").value = "";
        formdata = null;
        setSelectedFile(null);
      })

      .catch((error) => {
        setSuccessMessage("");
        setFailureMessage("File upload failed.");
        setShowMessage(true);
        setShowLoader(false);
        console.error(error);
      });
  };

  useState(() => {
    getAllMerchantForApiTest();
  }, []);

  const handelParameterChange = (e) => {
    // setData({ ...data, [e.target.name]: e.target.value });

    // const selectedOption = e.target.options[e.target.selectedIndex];
    // const prifix = selectedOption.getAttribute("data-prifix");

    let val = e.target.value;

    // console.log(typeof val);
    // console.log(selectParameterData);

    let select = selectParameterData.filter(
      (data) => data.id === parseInt(val)
    );

    // console.log("select >>>>", select);

    const remaining = selectedParameter.filter((elem) => {
      return elem.id !== select[0].id;
    });

    // console.log("remaining >>>>>>", remaining);

    setSelectedParameter([...remaining, ...select]);

    // console.log(selectedParameter);

    setSelectParameterData(
      selectParameterData.filter((elem) => {
        return elem.id !== parseInt(val);
      })
    );
  };
  //   console.log("mid is :",mid)

  const removeParameter = (id, elem) => {
    setSelectedParameter(
      selectedParameter?.filter((elem) => {
        return elem.id !== id;
      })
    );
    setSelectParameterData([...selectParameterData, elem]);
  };

  const handelParameterInput = (e, id) => {
    if (id === 6) {
      let data = selectParameterData?.map((elem) => {
        if (elem.id === id) {
          return { ...elem, [e.target.name]: e.target.value };
        }
        return elem;
      });
      setSelectParameterData(data);

      let data1 = selectedParameter?.map((elem) => {
        if (elem.id === id) {
          return { ...elem, [e.target.name]: e.target.value };
        }
        return elem;
      });
      setSelectedParameter(data1);
    } else {
      let data = selectParameterData?.map((elem) => {
        if (elem.id === id) {
          return { ...elem, inputVal: e.target.value };
        }
        return elem;
      });
      setSelectParameterData(data);

      let data1 = selectedParameter?.map((elem) => {
        if (elem.id === id) {
          return { ...elem, inputVal: e.target.value };
        }
        return elem;
      });
      setSelectedParameter(data1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      doBlock: true,
      email: "",
      ifscCode: "",
      imei: "",
      letitude: "",
      longitude: "",
      // mid: mid,
      mobile: "",
      vpa: "",
    };

    selectedParameter.forEach((elem) => {
      if (elem.id === 1) payload.vpa = elem.inputVal;
      if (elem.id === 2) payload.imei = elem.inputVal;
      if (elem.id === 3) payload.email = elem.inputVal;
      if (elem.id === 4) payload.mobile = elem.inputVal;
      if (elem.id === 5) payload.ifscCode = elem.inputVal;
      if (elem.id === 6) {
        payload.letitude = elem.Latitude;
        payload.longitude = elem.Longitude;
      }
    });

    // console.log("payload >>>", payload);
    setShowLoader(true);
    let res = await frmBlockVPA(payload, token);
    setShowLoader(false);
    // console.log("RES >>>>", res);
    if (res.statusCode === 200) {
      swal({
        title: "Success!",
        text: res.message,
        icon: "success",
      });
      setSelectParameterData(parameter);
      setSelectedParameter([]);
      setMerchantData([]);
    } else {
      swal({
        title: "Alert!",
        text: res.message,
        icon: "error",
      });
    }
  };

  return (
    <>
      <Container>
        <section className="w-100 lincpay-dashboard-sec merchant-admin-info-form company-add-form card py-3 ">
          {/* {showLoader ? <MyLoader /> : ""} */}
          <div className="w-100">
            <div className="d-flex gap-2">
              <div className=" w-50 p-5 mt-3 ">
                <div className="form-group upload-field  d-flex align-items-end">
                  <div className="input-upload-file">
                    <lable className="fw-semibold fs-5">FRM CSV</lable>
                    <input
                      type="file"
                      className="form-control"
                      onChange={handleFileUpload}
                      id="fileUpload"
                    />
                  </div>
                  <div className="toolbar-btn">
                    <button className="btn btn-primary" onClick={handleUpload}>
                      Upload
                    </button>
                  </div>
                </div>
                <Card.Title className="h3 mb-3 card-header-btn mt-4">
                  FRM csv Template
                  <div className="button-toolbar">
                    <a
                      className="btn btn-success"
                      title="Click here to download Chargeback file template"
                      href={frmTemplate}
                      download
                    >
                      Download Template
                    </a>
                  </div>
                </Card.Title>
              </div>
              <div className="w-100  mt-5">
                <div className="d-flex flex-column align-items-center">
                  <div className="col-sm-12 col-md-8 offset-md-3">
                    <label htmlFor="email">Select A Parameter</label>
                    <select
                      id="companyDropdown"
                      className="form-control"
                      onChange={handelParameterChange}
                      //   value={data.merchantSelect}
                      name="merchantSelect"
                    >
                      <option value="">Select a Parameter</option>
                      {selectParameterData?.map((parameter) => (
                        <option key={parameter.id} value={parameter.id}>
                          {parameter.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-sm-12 col-md-8 offset-md-3">
                    {selectedParameter?.map((elem, index) => {
                      if (elem.id === 6) {
                        return (
                          <>
                            <div key={elem.sid} className=" mt-2  py-2  ">
                              <div className="form-group  mt-2 ">
                                <div className="d-flex ">
                                  <div className="col-md-11">
                                    <label htmlFor="email">
                                      {elem.name} (Latitude)
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="txnAmt"
                                      name="Latitude"
                                      placeholder={`Enter ${elem.name}`}
                                      value={elem.Latitude}
                                      // onKeyDown={getKeyName}
                                      onChange={(e) =>
                                        handelParameterInput(e, elem.id)
                                      }
                                    />
                                  </div>
                                  <div className="col-md-11 mx-3">
                                    <label htmlFor="email">
                                      {elem.name} (Longitude)
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="txnAmt"
                                      name="Longitude"
                                      placeholder={`Enter ${elem.name}`}
                                      value={elem.Longitude}
                                      // onKeyDown={getKeyName}
                                      onChange={(e) =>
                                        handelParameterInput(e, elem.id)
                                      }
                                    />
                                  </div>
                                  <div className="d-flex flex-column justify-content-end align-items-end">
                                    <span
                                      onClick={() => {
                                        removeParameter(elem.id, elem);
                                      }}
                                      style={{ cursor: "pointer" }}
                                      className="text-danger fs-4 "
                                    >
                                      <i className="bi bi-trash-fill"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      } else
                        return (
                          <>
                            <div key={elem.sid} className=" mt-2  py-2  ">
                              <div className="form-group  mt-2 ">
                                <div className="d-flex ">
                                  <div className="col-md-11">
                                    <label htmlFor="email">{elem.name}</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="txnAmt"
                                      name="txnAmt"
                                      placeholder={`Enter ${elem.name}`}
                                      value={elem.inputVal}
                                      // onKeyDown={getKeyName}
                                      onChange={(e) =>
                                        handelParameterInput(e, elem.id)
                                      }
                                    />
                                  </div>
                                  <div className="d-flex flex-column justify-content-end align-items-end mx-3">
                                    <span
                                      onClick={() => {
                                        removeParameter(elem.id, elem);
                                      }}
                                      style={{ cursor: "pointer" }}
                                      className="text-danger fs-4 "
                                    >
                                      <i className="bi bi-trash-fill"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                    })}
                  </div>
                </div>

                <div className="d-flex justify-content-center  ">
                  <div className=" "></div>
                </div>
                <div className="d-flex justify-content-center mt-4 align-self-center">
                  <button className="btn btn-primary" onClick={handleSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </>
  );
};

export default FraudManagement;
