import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import swal from "sweetalert";
import { SWIPELINC_API } from "../../../utils/constant";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { getWalletBalnceByMid } from "../../../utils/ApiServices";
import { Percent } from "react-bootstrap-icons";
import useCheckPrivilages from "../../../utils/checkPrivilages";
import InactiveServices from "../../InactiveServices";

const DropCredit = ({ setShowLoader,data }) => {
  const [merchantData, setMerchantData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [merchantWalletBalance, setMerchantWalletBalance] = useState("");
  const [finalWalletBalance, setFinalWalletBalance] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [mid, setMid] = useState("");
  const [fullName, setFullName] = useState("");

  const hasPrivilage = useCheckPrivilages(2709)
  
  useEffect(() => {
    setMerchantData(data)
  }, [token]);
  useEffect(() => {
    getWalletBalance(mid, token);
  }, [mid]);
  useEffect(() => {
    setFinalWalletBalance(parseInt(percentage(merchantWalletBalance)));
    console.log(finalWalletBalance);
  }, [merchantWalletBalance]);
  console.log(merchantWalletBalance);
  const percentage = (num) => {
    return (num / 100) * 95;
  };

  
  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    setSelectedCompany(selectedCompany);

    const selectedMerchant = merchantData.find(
      (merchant) => `${merchant.mid} :- ${merchant.merchantName}` === selectedCompany
    );

    if (selectedMerchant) {
      setMid(selectedMerchant.mid);
      
    }
  };

  const handleWithdrawAmountChange = (e) => {
   
    const sanitizedValue = e.target.value.replace(/^-|[^0-9]/g, "");
    if (/^0[0-9]*$/.test(sanitizedValue)) {
    
      setWithdrawAmount(sanitizedValue.slice(1));
    } else {
      setWithdrawAmount(sanitizedValue);
    }
  };

  
  const handleSubmit = () => {
   
    if (withdrawAmount > finalWalletBalance) {
     
      swal({
        title: "Failed",
        text: "Amount Can not be more than wallet balance.",
        icon: "warning",
      });
      return;
    }

    if (!mid.length || !withdrawAmount.length) {
      swal({
        title: "Warning",
        text: "Please fill the mandatory fields.",
        icon: "warning",
      });
      return;
    }

    const apiPayload = {
      mid: mid,
      amount: withdrawAmount,
    };

    axios
      .post(
        `${SWIPELINC_API}MerchantAdminPanel/admin/dropCredit/save`,
        apiPayload,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          swal({
            title: "Saved",
            text: "Data saved successfully.",
            icon: "success",
          });
          setMid("");
          // setUtrNumber("");
          setWithdrawAmount("");
          
          setSelectedCompany("");
        } else if (response.data.statusCode === 306) {
          swal({
            title: "error",
            text: response.data.message,
            icon: "error",
          });
        }
        console.log("Data saved successfully:", response.data);
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };
  const getWalletBalance = (mid, token) => {
    const response = getWalletBalnceByMid(mid, token)
      .then((response) => {
        if (response.statusCode === 200) {
          console.log("yesssssssssssss");
          setMerchantWalletBalance(response?.data?.totalwalletBalance);
        } else {
          console.error("Error fetching data:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  return (
    <>
      {/* {hasPrivilage ? ( */}
        <div className="containerflag">
      {/* <h1 className="mt-3">Select</h1> */}
      <div className="row mt-4">
        <div className="col-sm-12 col-md-6 offset-md-3">
          <lable>Select Merchant *</lable>
          <select
            id="companyDropdown"
            className="form-control"
            onChange={handleCompanyChange}
            value={selectedCompany}
          >
            <option value="">Select a Company</option>
            {merchantData.map((merchant) => (
              <option key={merchant.mid} value={`${merchant.mid} :- ${merchant.merchantName}`}>
              {merchant.mid} :- {merchant.merchantName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-sm-12 col-md-6 offset-md-3">
          <div className="form-group">
            <label htmlFor="email">Merchant ID</label>
            <input
              required
              type="email"
              className="form-control"
              id="email"
              placeholder="Merchant ID"
              value={mid}
              readOnly
            />
          </div>

          <div className="form-group mt-2">
            <label htmlFor="holdAmount">Withdraw Amount (in Rupees) *</label>
            <div className="d-flex gap-4">
              <input
                required
                type="text"
                className="form-control"
                id="holdAmount"
                placeholder="Withdraw Amount"
                value={withdrawAmount}
                onChange={handleWithdrawAmountChange}
                inputMode="numeric"
                pattern="[0-9]*"
              />
            </div>
          </div>
       
          <div className="button-container mt-2">
            <button className="btn btn-primary" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    {/* ) : (<InactiveServices location={"privilages"}/>)} */}
    </>
    
  );
};
export default DropCredit;
