import axios from "axios";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { SWIPELINC_API } from "../../utils/constant";
import {
  fetchAllMerchantMaster,
  fetchAllVehicleMasterForm,
  getAllSubmittedEkycRoutingTools,
  getMechantForEkycOnBoarding,
  getMechantForPayoutApiTest,
} from "../../utils/ApiServices";
import { Card } from "react-bootstrap";
import NumberInWords from "../../masters/NumberInWords";
import { useSelector } from "react-redux";

const EkycRouting = ({ location, setShowLoader }) => {
  console.log(location)
  const [merchantData, setMerchantData] = useState([]);
  const [callAgain, setCallAgain] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  // const [masterName, setMasterName] = useState("");
  const [vehicleData, setVehicleData] = useState([]);
  const [merchantMasterData, setMerchantMasterData] = useState([]);
  const [data, setData] = useState({
    vehicle: "",
    merchantMasterId: "",
    merchantMasterName: "",
  });

  const {privilagesData} = useSelector(state=>state?.privilages)

  const token = localStorage.getItem("token");

  const [formData, setFormData] = useState({
    mid: "",
    merchantName: "",
  });

  const handelDataChange = (e) => {
    if (e.target.name === "vehicle") {
      const selectedOption = e.target.options[e.target.selectedIndex];
      const master = selectedOption.getAttribute("data-master");
      const masterId = selectedOption.getAttribute("data-masterId");
      console.log(masterId);
      setData({
        ...data,
        [e.target.name]: e.target.value,
        merchantMasterName: master,
        merchantMasterId: masterId,
      });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }

    console.log(data);
  };

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    setSelectedCompany(selectedCompany);

    const selectedMerchant = merchantData.find(
      (merchant) => merchant.merchantName === selectedCompany
    );

    if (selectedMerchant) {
      setFormData({
        mid: selectedMerchant.mid || "",
        merchantName: selectedMerchant.merchantName || "",
      });
    } else {
      setFormData({
        mid: "",
        merchantName: "",
      });
    }
  };

  const getAllMerchantForApiTest = async () => {
    setShowLoader(true);
    let res = await getAllSubmittedEkycRoutingTools(token);
    setShowLoader(false);
    setMerchantData(res?.data);
  };
  const getAllMerchantForOnBoardingRoutingEkyc = async () => {
    setShowLoader(true);
    let res = await getMechantForEkycOnBoarding(token);
    setShowLoader(false);
    setMerchantData(res.data);
  };

  useState(() => {
   
      getAllMerchantForApiTest();
    
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const showConfirmationAlert = () => {
      swal({
        title: "Are you sure?",
        text: "Do you want to submit this application ?",
        icon: "warning",
        buttons: true,
      }).then((willSubmit) => {
        if (willSubmit) {
          if (formData.merchantName === "") {
            swal({
              title: "Alert!",
              text: "Please Select Merchant",
              icon: "error",
            });
            return;
          }

          if (data.vehicle === "") {
            swal({
              title: "Alert!",
              text: "Please Select Vehicle",
              icon: "error",
            });
            return;
          }

          const payload = {
            company: formData.merchantName.trim(),
            // maxTxnAmount: data.txnAmt.trim(),
            mid: formData.mid.trim(),
            // sid: data.sid.trim(),
            vehicleMasterId: data.vehicle.trim(),
            masterMerchantId: data.merchantMasterId.trim(),
          };

        
          setShowLoader(true);
          axios
            .post(
              ` ${SWIPELINC_API}MerchantAdminPanel/Admin/ekycRouting/save`,
              payload,
              {
                headers: {
                  Authentication: "Bearer " + token,
                },
              }
            )
            .then((response) => {
              console.log(response);

              if (response.data.statusCode === 200) {
                swal({
                  title: "Success!",
                  text: response.data.message,
                  icon: "success",
                });
                setFormData({
                  mid: "",
                  merchantName: "",
                });
                setData({
                  vehicle: "",
                  merchantMasterId: "",
                  merchantMasterName: "",
                });
                setSelectedCompany("");
             
                  getAllMerchantForApiTest();
                
              } else {
                swal({
                  title: "Alert!",
                  text: response.data.message,
                  icon: "error",
                });
              }
              setShowLoader(false);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
              setShowLoader(false);
            });
        } else {
          swal("Submission Cancelled!", {
            icon: "info",
          });
        }
      });
    };

    showConfirmationAlert();
  };

  const getAllVehicle = async () => {
    try {
      const response = await fetchAllVehicleMasterForm(token); // Add the await keyword here

      if (response.statusCode === 200) {
        setVehicleData(response?.data);
      } else {
        setVehicleData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllMerchantMaster = async () => {
    try {
      const response = await fetchAllMerchantMaster(token); // Add the await keyword here
      if (response?.statusCode === 200) {
        setMerchantMasterData(response?.data);
      } else {
        setMerchantMasterData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllVehicle();
    getAllMerchantMaster();
  }, []);

  return (
    <Card className="shadow border-0 mb-4">
      <Card.Body>
        <div className="merchant-report-view">
          <div className="report-table-view">
            <div className="containerflag">
              <div className="row mt-4">
                <div className="col-md-6 offset">
                  <div className="col-sm-12 col-md-6  offset-md-3">
                    <label htmlFor="email">Select A Merchant</label>
                    <select
                      id="companyDropdown"
                      className="form-control"
                      onChange={handleCompanyChange}
                      value={selectedCompany}
                    >
                      <option value="">Select a Merchant</option>
                      {merchantData?.map((merchant) => (
                        <option
                          key={merchant.mid}
                          value={merchant.merchantName}
                        >
                          {merchant.merchantName}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="row mt-2">
                    <div className="col-sm-12 col-md-6 offset-md-3">
                      <div className="form-group mt-2">
                        <label htmlFor="email">Merchant MID</label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          placeholder="Company Name"
                          value={formData.mid}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="col-sm-12 col-md-6  offset-md-3 mt-3">
                    <label htmlFor="email">Select A Vehicle</label>
                    <select
                      id="companyDropdown"
                      className="form-control"
                      onChange={handelDataChange}
                      name="vehicle"
                      value={data.vehicle}
                    >
                      <option value="">Select a Vehicle</option>
                      {vehicleData?.map((vehicle) => (
                        <option
                          key={vehicle.id}
                          data-master={vehicle.masterMerchantName}
                          data-masterId={vehicle.masterMerchantId}
                          value={vehicle.id}
                        >
                          {vehicle.vehicleName}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-sm-12 col-md-6  offset-md-3 mt-3">
                    <label htmlFor="email">Select Master Merchant</label>
                    <input
                      id="companyDropdown"
                      className="form-control"
                      // onChange={handelDataChange}
                      readOnly
                      name="merchantMaster"
                      value={data.merchantMasterName}
                    />
                  </div>

                  {/* <div className="col-sm-12 col-md-6 offset-md-3">
                        <div className="form-group mt-2">
                          <label htmlFor="email">Enter SID</label>
                          <input
                            type="text"
                            className="form-control"
                            id="sid"
                            name="sid"
                            placeholder="Enter SID"
                            value={data.sid}
                            onChange={handelDataChange}
                          />
                        </div>
                      </div> */}

                  <div className="row mt-2"></div>
                </div>
                <div className="d-flex justify-content-center mt-4">
                  <button className="btn btn-primary" onClick={handleSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card.Body> 
    </Card>
  );
};

export default EkycRouting;
