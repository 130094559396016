import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { getKeyPressed } from "../utils/helper";
import {
  SaveVehicleMasterUpdateForm,
  fetchAllAggeregatorData,
  fetchAllBank,
  fetchAllMerchantMaster,
  fetchAllScheme,
  fetchAllSwitchData,
  fetchAllVehicleSubTypelWithActive,
  fetchAllVehicleTypelWithActive,
  getPhase2VehicleMasterSubmittedByID,
} from "../utils/ApiServices";
import { Col, Container, Modal, Row } from "react-bootstrap";
import DropDowns from "./DropDowns";

const inputBoxStyle = { fontSize: "16px", borderRadius: "0px" };

const VehicleMasterEditForm = ({
  id,
  data,
  isModalOpen,
  handleCloseModal,
  setShowLoader,
  aggregatorNameData,
  switchNameData,
  schemeNameData,
  bankNameData,
}) => {


  

  const [schemeData, setSchemeData] = useState([]);
  const [bankData, setBankData] = useState([]);
  const [aggeregatorData, setAggeregatorData] = useState([]);
  const [switchData, setSwitchData] = useState([]);

  const [paymentTypes, setPaymentTypes] = useState([]);
  const [paymentSubTypes, setPaymentSubTypes] = useState([]);
  const [selectedPaymentTypes, setSelectedPaymentTypes] = useState([]);
  const [selectedPaymentSubTypes, setSelectedPaymentSubTypes] = useState([]);
  const [pressedKeyName, setPressedKeyName] = useState("");
  const [selectedType, setSelectedType] = useState({
    schemeId: "",
    aggeregatorId: "",
    switchId: "",
    vehicleName: "",
    bankId: "",
    remark: "",
    masterMerchantId: "",
  });
  const [token, setToken] = useState(localStorage.getItem("token"));

  const [vehicleData, setVehicleData] = useState(null);
  const [paymentSubType, setPaymentSubType] = useState([]);
  const [masterMerchantData, setMasterMerchantData] = useState([]);
  const {bankName, setBankName} = bankNameData;
  const {schemeName, setSchemeName} = schemeNameData;
  const {switchName, setSwitchName} = switchNameData;
  const {aggeregatorName, setAggeregatorName} = aggregatorNameData;

  const GetVehicalMasterDataById = async (token) => {
    setShowLoader(true);
    try {
      const response = await getPhase2VehicleMasterSubmittedByID(id, token);
setShowLoader(false);
      if (response.statusCode === 200) {
        
        setVehicleData(response.data[0]);
        setPaymentSubType(response.data[0].paymentSubTypes[0]);
        setPaymentTypes(response.data[0].paymentTypes)
        console.log(vehicleData);
      } else {
        setVehicleData([]);
        setPaymentSubType([]);
      }
    } catch (error) {
      console.log(error);
    }
    setShowLoader(false);
  };

  const getAllMerchantMaster = async (token) => {
    try {
      setShowLoader(true);
      const response = await fetchAllMerchantMaster(token);
      setShowLoader(false);
      if (response.statusCode === 200) {
        setMasterMerchantData(
          response?.data?.map((item) => {
            return {
              type: item.name,
              Id: item.masterMerchantId,
            };
          })
        );
      } else {
        setMasterMerchantData([]);
      }
    } catch (error) {
      
    }
    setShowLoader(false);
  };

  const setDataToFeilds = (data) => {
    // console.log("DATA>>>>>", data);

    setSelectedType({
      schemeId: data.schemeId + "",
      aggeregatorId: data.aggregatorId + "",
      switchId: data.switchId + "",
      vehicleName: data.vehicleName,
      masterMerchantId: data.masterMerchantId,
      bankId: data.bankId + "",
      remark: data.remark,
    });

    const PaymentTypes = data?.paymentTypes?.map((item) => {
      return item.paymentTypeId;
    });
    setSelectedPaymentTypes(PaymentTypes);
    console.log(selectedPaymentTypes);
    // console.log(PaymentTypes);
    const PaymentSubTypes = data?.paymentSubTypes?.map((item) => {
      return item.paymentSubTypeId;
    });
    setSelectedPaymentSubTypes(PaymentSubTypes);
    console.log(selectedPaymentSubTypes);
    // console.log(PaymentSubTypes);
  };

  const handelPasteEvent = (event, name) => {
    let pastedText = (event.clipboardData || window.clipboardData).getData(
      "text"
    );

    const remarkLength = 200;

    if (
      name === "remark" &&
      selectedType.remark?.length + pastedText?.length > remarkLength
    ) {
      swal({
        title: "Alert!",
        text: `Remark must be lesser than ${remarkLength} characters`,
        icon: "error",
      });
      return;
    }
  };

  useEffect(() => {
    setDataToFeilds(data);
  }, []);

  useEffect(() => {
    GetScheme(token);
    GetAllBankData(token);
    GetAllAggregatorData(token);
    GetAllSwitchData(token);
    getAllMerchantMaster(token);

    GetVehicleType(token);
    GetVehicleSubType(token);
    // GetVehicalMasterDataById(token);
  }, []);

  const GetScheme = async (token) => {
    try {
      const response = await fetchAllScheme(token);

      // console.log("SCHEME>>>>>>", response);

      if (response.statusCode === 200) {
        setSchemeData(
          response?.data?.map((item) => {
            return {
              type: item.name,
              Id: item.schemeId,
            };
          })
        );
      } else {
        setSchemeData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllBankData = async (token) => {
    try {
    setShowLoader(true);
      const response = await fetchAllBank(token); // Add the await keyword here
setShowLoader(false);
      if (response.statusCode === 200) {
        setBankData(
          response?.data?.map((item) => {
            return {
              type: item.name,
              Id: item.bankId,
            };
          })
        );
      } else {
        setBankData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllSwitchData = async (token) => {
    try {
    setShowLoader(true);
      const response = await fetchAllSwitchData(token); // Add the await keyword here
setShowLoader(false);
      if (response.statusCode === 200) {
        setSwitchData(
          response?.data?.map((item) => {
            return {
              type: item.name,
              Id: item.switchId,
            };
          })
        );
      } else {
        setSwitchData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllAggregatorData = async (token) => {
    try {
    setShowLoader(true);
      const response = await fetchAllAggeregatorData(token); // Add the await keyword here
    setShowLoader(false);
      if (response.statusCode === 200) {
        setAggeregatorData(
          response?.data?.map((item) => {
            return {
              type: item.name,
              Id: item.aggregatorId,
            };
          })
        );
      } else {
        setAggeregatorData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetVehicleType = async (token) => {
    try {
    setShowLoader(true);
      const response = await fetchAllVehicleTypelWithActive(token); // Add the await keyword here
    setShowLoader(false);
      if (response.statusCode === 200) {
        setPaymentTypes(response.data);
      } else {
        setPaymentTypes([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetVehicleSubType = async (token) => {
    try {
    setShowLoader(true);
      const response = await fetchAllVehicleSubTypelWithActive(token); // Add the await keyword here
    setShowLoader(false);
      if (response.statusCode === 200) {
        setPaymentSubTypes(response?.data);
      } else {
        setPaymentSubTypes([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTypeChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    console.log(name, value);

    // if (pressedKeyName === "Backspace" && name === "remark") {
    //   setSelectedType({ ...selectedType, [name]: value });
    // }

    // if (name === "remark" && selectedType.remark.length >= 200) {
    //   return;
    // }

    if (
      name === "platformFeePercentage" ||
      name === "processingFeePercentage" ||
      name === "nodelFeePercentage" ||
      name === "interchangeFeePercentage" ||
      name === "masterMerchantPercentage"
    ) {
      if (value >= 0 && value <= 100) {
        setPaymentSubType({ ...paymentSubType, [name]: value });
      }
      return;
    }

    setPaymentSubType({ ...paymentSubType, [name]: value });
  };

  const handleTypeNameAndDropdownChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    console.log(name, value);

    if (pressedKeyName === "Backspace" && name === "remark") {
      setSelectedType({ ...selectedType, [name]: value });
    }

    if (name === "remark" && selectedType.remark.length >= 200) {
      return;
    }

    if (name === "schemeId" && value !== "") {
      let elem = schemeData.filter((elem) => elem.Id + "" === value);
      // console.log("ELEMMM >>>>>>", elem[0]);
      setSchemeName(elem[0].type);
    }

    if (name === "bankId" && value !== "") {
      let elem = bankData.filter((elem) => elem.Id + "" === value);
      // console.log("ELEMMM >>>>>>", elem[0]);
      setBankName(elem[0].type);
    }

    if (name === "switchId" && value !== "") {
      let elem = bankData.filter((elem) => elem.Id + "" === value);
      // console.log("ELEMMM >>>>>>", elem[0]);
      setSwitchName(elem[0].type);
    }

    if (name === "aggeregatorId" && value !== "") {
      let elem = aggeregatorData.filter((elem) => elem.Id + "" === value);
      // console.log("ELEMMM >>>>>>", elem[0]);
      setAggeregatorName(elem[0].type);
    }

    setSelectedType({ ...selectedType, [name]: value });
  };

  const handleTypeCheckBox = (e) => {
    const found = selectedPaymentTypes?.find(
      (item) => item === parseInt(e.target.value)
    );
    // console.log(parseInt(e.target.value));

    if (found) {
      setSelectedPaymentTypes(
        selectedPaymentTypes.filter((item) => item !== found)
      );
    } else {
      setSelectedPaymentTypes([
        ...selectedPaymentTypes,
        parseInt(e.target.value),
      ]);
    }
  };

  const handleSubTypeCheckBox = (e) => {
    const paymentSubTypeId = parseInt(e.target.value);

    // Find the corresponding paymentType for the selected paymentSubType
    const correspondingPaymentType = paymentTypes.find((type) =>
      type.paymentSubTypedtos.some(
        (subType) => subType.paymentSubTypeId === paymentSubTypeId
      )
    );

    if (
      !correspondingPaymentType ||
      !selectedPaymentTypes?.includes(correspondingPaymentType.paymentTypeId)
    ) {
      swal({
        title: "Alert!",
        text: "Please select the corresponding Vehicle Type first.",
        icon: "error",
      });
      return;
    }

    const found = selectedPaymentSubTypes.find(
      (item) => item === paymentSubTypeId
    );

    if (found) {
      setSelectedPaymentSubTypes(
        selectedPaymentSubTypes.filter((item) => item !== found)
      );
    } else {
      setSelectedPaymentSubTypes([
        ...selectedPaymentSubTypes,
        paymentSubTypeId,
      ]);
    }
  };

  const generatePayload = () => {
    return {
      aggregatorId: parseInt(selectedType.aggeregatorId),
      bankId: parseInt(selectedType.bankId),
      id: parseInt(id),
      masterMerchantId: selectedType.masterMerchantId ,
      paymentSubTypes: selectedPaymentSubTypes,
      paymentTypes: selectedPaymentTypes,
      remark: selectedType.remark,
      schemeId: parseInt(selectedType.schemeId),
      switchId: parseInt(selectedType.switchId),
      vehicleName: selectedType.vehicleName,

      // interchangeFeeFix: schemeName !== "NA" ? paymentSubType.interchangeFeeFix : 0,
      // interchangeFeePercentage: schemeName !== "NA" ? paymentSubType.interchangeFeePercentage : 0,
      // nodelFeeFix: bankName !== "NA" ? paymentSubType.nodelFeeFix : 0,
      // nodelFeePercentage: bankName !== "NA" ? paymentSubType.nodelFeePercentage : 0,
      // platformFeeFix: aggeregatorName !== "NA" ?  paymentSubType.platformFeeFix : 0,
      // platformFeePercentage: aggeregatorName !== "NA" ? paymentSubType.platformFeePercentage : 0,
      // processingFeeFix: switchName !=="NA"  ? paymentSubType.processingFeeFix : 0,
      // processingFeePercentage: switchName !=="NA"  ? paymentSubType.processingFeePercentage : 0,
      // masterMerchantFess: selectedType.masterMerchantId !== "" ? paymentSubType.masterMerchantFess : 0,
      
    };
  };

  const handleSubmit = async () => {
    const payload = generatePayload();

    if (selectedType.vehicleName === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Vehicle Name.",
        icon: "error",
      });
      return;
    }

    if (selectedType.schemeId === "") {
      swal({
        title: "Alert!",
        text: "Please Select Scheme.",
        icon: "error",
      });
      return;
    }

    if (selectedType.bankId === "") {
      swal({
        title: "Alert!",
        text: "Please Select Bank.",
        icon: "error",
      });
      return;
    }

    if (selectedType.switchId === "") {
      swal({
        title: "Alert!",
        text: "Please Select Switch.",
        icon: "error",
      });
      return;
    }

    if (selectedType.aggeregatorId === "") {
      swal({
        title: "Alert!",
        text: "Please Select Aggeregator.",
        icon: "error",
      });
      return;
    }

    // if (
    //   selectedType.schemeId !== "5" &&
    //   selectedType.interchargeFix === "" &&
    //   selectedType.interchargePercentage === ""
    // ) {
    //   swal({
    //     title: "Alert!",
    //     text: "Please Enter Intercharge Fix or Intercharge Percentage.",
    //     icon: "error",
    //   });
    //   return;
    // }

    // if (
    //   selectedType.bankId !== "3" &&
    //   selectedType.nodelFeeFix === "" &&
    //   selectedType.nodelFeePercentage === ""
    // ) {
    //   swal({
    //     title: "Alert!",
    //     text: "Please Enter Nodel Fee Fix or Nodel Fee Percentage.",
    //     icon: "error",
    //   });
    //   return;
    // }

    // if (
    //   selectedType.switchId !== "3" &&
    //   selectedType.processingFeeFix === "" &&
    //   selectedType.processingFeePercentage === ""
    // ) {
    //   swal({
    //     title: "Alert!",
    //     text: "Please Enter Processing Fee Fix or Processing Fee Percentage.",
    //     icon: "error",
    //   });
    //   return;
    // }

    // if (
    //   selectedType.aggeregatorId !== "3" &&
    //   selectedType.platformFeeFix === "" &&
    //   selectedType.platformFeeFix === ""
    // ) {
    //   swal({
    //     title: "Alert!",
    //     text: "Please Enter Platform Fee Fix or Platform Fee Percentage.",
    //     icon: "error",
    //   });
    //   return;
    // }

    if (!selectedPaymentTypes.length) {
      swal({
        title: "Alert!",
        text: "Please Select Vehicle Types.",
        icon: "error",
      });
      return;
    }

    const paymentTypesWithSubtypes = paymentTypes.filter(
      (type) => type.paymentSubTypedtos.length > 0
    );

    for (const paymentType of paymentTypesWithSubtypes) {
      if (
        selectedPaymentTypes?.includes(paymentType.paymentTypeId) &&
        !selectedPaymentSubTypes.some((subType) =>
          paymentType.paymentSubTypedtos.some(
            (subtype) => subtype.paymentSubTypeId === subType
          )
        )
      ) {
        swal({
          title: "Alert!",
          text: `Please select all subtypes for '${paymentType.paymentService}'`,
          icon: "error",
        });
        return;
      }
    }

    try {
    setShowLoader(true);
      const response = await SaveVehicleMasterUpdateForm(payload, "", token);
   setShowLoader(false);
      if (response.statusCode === 200) {
        swal({
          title: "Success",
          text: "Updated Successfully",
          icon: "success",
        });

        setSelectedType({
          schemeId: "",
          aggeregatorId: "",
          switchId: "",
          vehicleName: "",
          bankId: "",
          remark: "",
          interchargeFix: "",
          interchargePercentage: "",
          nodelFeeFix: "",
          nodelFeePercentage: "",
          processingFeeFix: "",
          processingFeePercentage: "",
          platformFeeFix: "",
          platformFeePercentage: "",
        });
        setSelectedPaymentTypes([]);
        setSelectedPaymentSubTypes([]);
        handleCloseModal();
      } else {
        swal({
          title: "Failed",
          text: "Vehicle Master Failed To Update.",
          icon: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal show={isModalOpen} onHide={handleCloseModal} size="xl">
      <Modal.Header closeButton>
        <Modal.Title> Edit Vehicle Master:</Modal.Title>
      </Modal.Header>
      <Modal.Body className="w-70  ">
        <Container>
          <Row className="">
            <Col xs={11} xl={4} lg={4} md={6} sm={11} className="mb-3  ">
              <label htmlFor="email">
                Vehicle Name<sup className="text-danger">*</sup>
              </label>
              <input
                name="vehicleName"
                id="vehicleName"
                className="form-control"
                value={selectedType?.vehicleName}
                onChange={handleTypeNameAndDropdownChange}
              />
            </Col>
            <Col xs={11} xl={4} lg={4} md={6} sm={11} className="mb-3 ">
              <DropDowns
                fieldName={"Scheme"}
                HandleChange={handleTypeNameAndDropdownChange}
                SelectedType={selectedType?.schemeId}
                AllTypes={schemeData}
                Name="schemeId"
              />
            </Col>

            <Col xs={11} xl={4} lg={4} md={6} sm={11} className="mb-3 ">
              <DropDowns
                fieldName={"Bank"}
                HandleChange={handleTypeNameAndDropdownChange}
                SelectedType={selectedType?.bankId}
                AllTypes={bankData}
                Name="bankId"
              />
            </Col>

            <Col xs={11} xl={4} lg={4} md={6} sm={11} className="mb-3 ">
              <DropDowns
                fieldName={"Switch"}
                HandleChange={handleTypeNameAndDropdownChange}
                SelectedType={selectedType?.switchId}
                AllTypes={switchData}
                Name="switchId"
              />
            </Col>

            <Col xs={11} xl={4} lg={4} md={6} sm={11} className="mb-3 ">
              <DropDowns
                fieldName={"Aggeregator"}
                HandleChange={handleTypeNameAndDropdownChange}
                SelectedType={selectedType?.aggeregatorId}
                AllTypes={aggeregatorData}
                Name="aggeregatorId"
              />
            </Col>

           
            <Col xs={11} xl={4} lg={4} md={6} sm={11} className="mb-3 ">
              <DropDowns
                fieldName={"Master Merchant"}
                HandleChange={handleTypeNameAndDropdownChange}
                SelectedType={selectedType?.masterMerchantId}
                AllTypes={masterMerchantData}
                Name="masterMerchantId"
              />
            </Col>

           
            <Col xs={11} xl={4} lg={4} md={6} sm={11} className="mb-3     ">
              <label htmlFor="email">Remark</label>
              <input
                name="remark"
                id="remark"
                className="form-control"
                value={selectedType?.remark}
                onPaste={(e) => handelPasteEvent(e, "remark")}
                onKeyDown={(e) => {
                  setPressedKeyName(getKeyPressed(e));
                }}
                onChange={handleTypeNameAndDropdownChange}
              />
              <div className="d-flex justify-content-end">
                <span className="text-danger">
                  {`Remaining Characters ${
                    200 - selectedType?.remark?.length
                  } / 200`}{" "}
                </span>
              </div>
            </Col>
          </Row>
        </Container>

        {/* <Row>
          <Col xs={12} xl={12} lg={12} sm={12} className="mb-5">
            <div className="row mt-4">
              <div className="col-md-6 offset mt-2">
                <div className="col-sm-12 col-md-6  offset-md-3">
                  <label htmlFor="email">
                    Vehicle Name<sup className="text-danger">*</sup>
                  </label>
                  <input
                    name="vehicleName"
                    id="vehicleName"
                    className="form-control"
                    value={selectedType.vehicleName}
                    onChange={handleTypeChange}
                  />
                </div>
              </div>

              <DropDowns
                Name={"schemeId"}
                HandleChange={handleTypeChange}
                AllTypes={schemeData}
                SelectedType={selectedType.schemeId}
              />
              <DropDowns
                Name={"bankId"}
                HandleChange={handleTypeChange}
                SelectedType={selectedType.bankId}
                AllTypes={bankData}
              />

              <DropDowns
                Name={"switchId"}
                HandleChange={handleTypeChange}
                SelectedType={selectedType.switchId}
                AllTypes={switchData}
              />

              <DropDowns
                Name={"aggeregatorId"}
                HandleChange={handleTypeChange}
                SelectedType={selectedType.aggeregatorId}
                AllTypes={aggeregatorData}
              />

              <div className="col-md-6 offset mt-2">
                <div className="col-sm-12 col-md-6  offset-md-3">
                  <label htmlFor="email">Remark</label>
                  <input
                    name="remark"
                    id="remark"
                    className="form-control"
                    value={selectedType.remark}
                    onPaste={(e) => handelPasteEvent(e, "remark")}
                    onKeyDown={(e) => {
                      setPressedKeyName(getKeyPressed(e));
                    }}
                    onChange={handleTypeChange}
                  />
                  <div className="d-flex justify-content-end">
                    <span className="text-danger">
                      {`Remaining Characters ${
                        200 - selectedType.remark?.length
                      } / 200`}{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row> */}
        <div className="row mt-4">
          <div className="col-9 mx-auto ">
            <div className="d-flex  ">
              <label htmlFor="email" className="w-50 d-flex flex-center">
                Vehicle Types
              </label>
              <label htmlFor="email" className="w-50 d-flex flex-start">
                Vehicle Sub-Types
              </label>
            </div>
            {paymentTypes?.map((paymentType, i) => (
              <div
                style={{ minHeight: "4vw" }}
                className="row  border border-1  "
                key={i}
              >
                <div className=" col-4 d-flex justify-content-center align-items-center border border-right-1  ">
                  <div className="w-75 d-flex gap-4 align-items-center">
                    <input
                      type="checkbox"
                      style={{ border: "1px solid grey", scale: "2" }}
                      name="typeOfServices"
                      className="form-check-inpu larger"
                      value={paymentType.paymentTypeId}
                      checked={
                        selectedPaymentTypes?.includes(paymentType.paymentTypeId)
                          ? true
                          : false
                      }
                      onChange={handleTypeCheckBox}
                    />
                    <label
                      className="form-label mx-2 mt-2"
                      style={{ scale: "1" }}
                    >
                      {paymentType.paymentService?.toUpperCase()}
                    </label>{" "}
                  </div>
                </div>
                <div className="col-8 d-flex flex-wrap py-2">
                  {paymentType?.paymentSubTypedtos?.map((paymentSubType, i) => (
                    <div
                      className="d-flex align-items-center border border-1 mt-1 py-1 px-4 rounded-5 bg-light"
                      key={i}
                    >
                      <input
                        type="checkbox"
                        style={{ border: "1px solid grey" }}
                        name="typeOfServices"
                        className="form-check-inpu"
                        value={paymentSubType.paymentSubTypeId}
                        checked={
                          selectedPaymentSubTypes?.includes(
                            paymentSubType.paymentSubTypeId
                          )
                            ? true
                            : false
                        }
                        onChange={handleSubTypeCheckBox}
                      />
                      <label
                        className="fw-semibold mx-1 mt-1"
                        style={{ fontSize: "0.8vw" }}
                      >
                        {paymentSubType.paymentSubService}
                      </label>{" "}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="d-flex justify-content-center mt-4">
          <button className="btn btn-primary" onClick={handleSubmit}>
            Update
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VehicleMasterEditForm;
