import React, { useEffect, useState } from "react";
import {
  fetchAccountDetailsByMid,
  fetchDocsByMidForaccounts,
} from "../utils/ApiServices";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { SWIPELINC_API } from "../utils/constant";
import swal from "sweetalert";

const ViewSingleSubmittedApplication = ({ mid, setOpenView, openView }) => {
  const [responseData, setResponseData] = useState({});
  const [token, setToken] = useState(localStorage.getItem("token"));

  const [pdfFilenames, setPdfFilenames] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [viewDataClicked, setViewDataClicked] = useState(false);

  const FetchDataBymid = async (mid, token) => {
    try {
      const apiResponse = await fetchAccountDetailsByMid(mid, token);
      if (apiResponse.statusCode === 200) {
        setResponseData(apiResponse?.data);

        // setFinancialData(initializeFinancialData(responseData));
        console.log(apiResponse?.data);
      } else {
        swal({
          title: "ALERT!",
          text: "Something went wrong",
          icon: "warning",
        });
        setOpenView(false);
      }
    } catch (error) {
      swal({
        title: "ALERT!",
        text: "Your Application Is Under Process!!",
        icon: "warning",
      });
      setOpenView(false);
    }
  };

  useEffect(() => {
    FetchDataBymid(mid, token);

    // setFinancialData(initializeFinancialData(responseData));
  }, []);

  useEffect(() => {
    // FetchDataBymid(mid, token);

    setFinancialData(initializeFinancialData(responseData));
  }, [responseData]);

  const initializeFinancialData = (responseData) => ({
    netSales: {
      firstYear: responseData.netSalesInFirstYear,
      secondYear: responseData.netSalesInSecondYear,
      thirdYear: responseData.netSalesInThirdYear,
    },
    netPurchase: {
      firstYear: responseData.netPurchaseInFirstYear,
      secondYear: responseData.netPurchaseInSecondYear,
      thirdYear: responseData.netPurchaseInThirdYear,
    },
    netProfit: {
      firstYear: responseData.netProfitInFirstYear,
      secondYear: responseData.netProfitInSecondYear,
      thirdYear: responseData.netProfitInthirdyear,
    },
    netExpenses: {
      firstYear: responseData.netExpensesInFirstyear,
      secondYear: responseData.netExpensesInSeconYear,
      thirdYear: responseData.netExpensesInThirdYear,
    },
  });

  const [financialData, setFinancialData] = useState(
    initializeFinancialData(responseData)
  );

  //   useEffect(() => {
  //     FetchDataBymid(mid, token);
  //     setFinancialData(initializeFinancialData(responseData));
  //   }, []);

  //   const FetchDataBymid=async (mid,token)=>{
  //     try {
  //         const apiResponse =await  fetchAccountDetailsByMid(mid,token)
  //         if (apiResponse.statusCode === 200) {
  //               setResponseData(apiResponse?.data);
  //             console.log(apiResponse?.data);
  //         } else {
  //         }
  //     } catch (error) {
  //         // console.error(error);
  //        console.log("Something went wrong. Please try again.");
  //     }
  //   }

  const handleViewData = async (mid, prev) => {
    try {
      const apiResponse = await fetchDocsByMidForaccounts(mid, token);
      if (apiResponse.statusCode === 200) {
        setPdfFilenames(apiResponse.data);
        setViewDataClicked((prev) => !prev);
      } else {
      }
    } catch (error) {
      // console.error(error);
      console.log("Something went wrong. Please try again.");
    }
  };

  const handleViewPDF = (filename) => {
    const encodedFilename = encodeURIComponent(filename);
    const headers = {
      Authentication: `Bearer ${token}`,
    };

    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/view-pdf/${encodedFilename}`,
        {
          responseType: "arraybuffer",
          headers,
        }
      )
      .then((response) => {
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");
        // setSelectedFile(pdfUrl);
      })
      .catch((error) => {
        console.error("Error fetching PDF:", error.message);
      });
  };

  return (
    <>
      <section className="admin-view-details">
        <Container>
          <Card className="shadow border-0 mb-4 ds-header-card">
            <Card.Body>
              <Row>
                <Col xs={12} xl={12} lg={12} sm={12}>
                  <div className="two-column-layout">
                    <div className="vertical-table mb-4">
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Merchant Name
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.companyName}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">MID</div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.mid}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          e-Mail ID
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.email}
                        </div>
                      </div>
                      {/* <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Number of Business Years
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.numberOfBusinessYear}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Number of Years Compliences
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.numberOfYearFillingCompliance}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Average of Ticket Size
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.ticketSize}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Average Monthly Volume
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.avgValueMonthly}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Number of Ticket Monthly
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.numberOfTicketMonthly}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Withdraw Mode
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.withdrawMode
                            ? responseData.withdrawType
                            : "Manual"}
                        </div>
                      </div> */}
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Deposit In Lumpsum
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.depositeLumpsum}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Deposit in Percentage
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.depositePercentage}
                        </div>
                      </div>

                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Hold In Percentage
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.holdPercentage}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Guarantee Amount
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.guarantyAmount}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          Chargeback Declaration In Percentage
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {responseData.chargebackDeclarationPercentage}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <lable className="fs-5 fw-semi-bold mb-3">
                    MDR Percentage
                  </lable>
                  <div className="two-column-layout">
                    <div className="vertical-table mb-4">
                      {responseData?.mdrData?.map((item) => (
                        <div className="vertical-table-row">
                          <div className="vertical-table-cell text-left">
                            {item.service} Percentage
                          </div>
                          <div className="vertical-table-cell-value text-left">
                            {item.mdrPercentage}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Col>
              </Row>
              {/* <Row className="d-flex flex-column">
                <Col className="w-100">
                  <div className="w-100 d-flex flex-column gap-2">
                    <div>
                      <lable className="fs-5 fw-semi-bold">
                        Last 3 Years Balance Sheet
                      </lable>
                      <sup className="text-danger">*</sup>
                    </div>
                    <div className="w-75 d-flex align-items-center justify-content-between w-100 border border-2 p-3 mb-5">
                      <table className="w-100">
                        <thead>
                          <tr>
                            <td scope="col"></td>
                            <th scope="col">1st Year</th>
                            <th scope="col">2nd Year</th>
                            <th scope="col">3rd Year</th>
                          </tr>
                        </thead>
                        <tbody>
                          {responseData &&
                            Object.keys(financialData).map((metric) => (
                              <tr key={metric} className="mx-2">
                                <td className="fw-bold">
                                  {metric === "netSales"
                                    ? "Net Sales"
                                    : metric === "netPurchase"
                                    ? "Net Purchase"
                                    : metric === "netExpenses"
                                    ? "Net Expences"
                                    : metric === "netProfit"
                                    ? "Net Profit"
                                    : ""}{" "}
                                </td>
                                <td>
                                  <input
                                    className="w-100 p-1"
                                    type="number"
                                    value={financialData[metric]["firstYear"]}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    className="w-100 p-1"
                                    type="number"
                                    value={financialData[metric]["secondYear"]}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    className="w-100 p-1"
                                    type="number"
                                    value={financialData[metric]["thirdYear"]}
                                    readOnly
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="button-toolbar groups-btn text-end">
                    <button
                      className="btn btn-warning"
                      onClick={() => handleViewData(mid)}
                    >
                      {viewDataClicked ? "Close Documents" : "View Documents"}
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => setOpenView(false)}
                    >
                      Go Back
                    </button>
                  </div>
                </Col>
              </Row> */}
              <Row>
                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-primary"
                    onClick={() => setOpenView(false)}
                  >
                    Go Back
                  </button>
                </div>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
};

export default ViewSingleSubmittedApplication;
