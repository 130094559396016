import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import InactiveServices from "../components/InactiveServices";
import VehicleMasterForm from "./VehicleMasterForm";
import SubmittedVehicleMasterForms from "./SubmittedVehicleMasterForms";
import MyLoader from "../common/MyLoader";
import VehicleMasterFormPhase2 from "./VehicleMasterFormPhase2";
import { useSelector } from "react-redux";

const VehicleMasterFormSubTab = ( ) => {
  const [activePhase, setActivePhase] = useState("Phase1");
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setActivePhase("Phase1");
  }, []);

  const {prefix}=useSelector(state=>state.privilages)

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  return (
    <>
    {showLoader ? <MyLoader/> : null}
      <section className="dashboard-header-sec subtab-card">
        <div className="ds-header-sec">
          <Row>
            <Col xs={12} lg={12} md={12}>
              <Card className="shadow border-0 mb-3  ds-inner-card">
                <Card.Body>
                  <div className="merchant-tab-btn">
                    <ul className="report-link nav-report-btn submenu-tab-admin">
                      <li
                        className={activePhase === "Phase1" ? "active" : ""}
                        onClick={() => handlePhaseClick("Phase1")}
                      >
                        Phase 1 Form {activePhase === "Phase1"}
                      </li>
                      <li
                        className={activePhase === "Phase3" ? "active" : ""}
                        onClick={() => handlePhaseClick("Phase3")}
                      >
                        Phase 2 Form {activePhase === "Phase3"}
                      </li>
                      <li
                        className={activePhase === "Phase2" ? "active" : ""}
                        onClick={() => handlePhaseClick("Phase2")}
                      >
                        Submitted {activePhase === "Phase2"}
                      </li>
                    </ul>
                  </div>
                </Card.Body>
              </Card>
              {/* ./card */}
            </Col>
          </Row>

          <div className="admin-tab-content">
            {activePhase === "Phase1" && prefix === "ALL" ? (
              <VehicleMasterForm setShowLoader={setShowLoader} />
            ) : activePhase === "Phase2" && prefix === "ALL" ? (
              <SubmittedVehicleMasterForms setShowLoader={setShowLoader} />
            ) : activePhase === "Phase3" && prefix === "ALL" ? (
              <VehicleMasterFormPhase2 setShowLoader={setShowLoader} />
            ) : (
              <InactiveServices location={"priages"}/>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default VehicleMasterFormSubTab;
