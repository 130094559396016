import React from 'react'
import InactiveServices from './components/InactiveServices'
function Earnings() {
  return (
    <div>
     <InactiveServices location={"jggj"}/>
    </div>
  )
}

export default Earnings
