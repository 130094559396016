import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { SWIPELINC_API } from "../utils/constant";
import { Container, Row, Col, Card } from "react-bootstrap";
import "../stylesheet/ViewDetails.css";
import swal from "sweetalert";
import MyLoader from "../common/MyLoader";
import { useSelector } from "react-redux";
import { fetchAllMerchantMaster } from "../utils/ApiServices";

const MoaDetails = ({ id, handleClose }) => {
  const navigate = useNavigate();
  const [merchantData, setMerchantData] = useState(null);
  const [showLoader, setShowLoader] = useState(Boolean);
  const [paymentservice, setServices] = useState([]);
  const [viewDataClicked, setViewDataClicked] = useState(false);
  const [merchantMaster, setMerchantMaster] = useState("");
  const [merchantMasterData, setMerchantMasterData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [pdfFilenames, setPdfFilenames] = useState([]);
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("token");

  const [mccCode, setMccCode] = useState("");

  // const { id } = useParams();
  // console.log(id);

  const handleClosePDF = () => {
    window.close();
  };

  const { privilagesData, prefix, type, masterId } = useSelector(
    (state) => state.privilages
  );

  useEffect(() => {
    const getAllMerchantMaster = async () => {
      try {
        const response = await fetchAllMerchantMaster(token);
        if (response?.statusCode === 200) {
          setMerchantMasterData(response?.data);
        } else {
          setMerchantMasterData([]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAllMerchantMaster();
  }, []);

  const handleApprove = () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to approve this vendor?",
      icon: "warning",
      buttons: ["Cancel", "Yes, approve it!"],
      dangerMode: true,
    }).then((isConfirmed) => {
      if (isConfirmed) {
        const payload = {
          applicationStatus: "Approved",
          payoutAutoApprovel: true,
          registrationId: id,
          rejectionRemark: "NA",
          // masterMerchantId: prefix === "ALL" ? merchantMaster : masterId,
          showReferralTransaction: true,
          // mccCode: mccCode,
          vendorAutoApproved: true,
        };
        setShowLoader(true);
        axios
          .put(
            `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/updateStage2`,
            payload,
            {
              headers: {
                Authentication: "Bearer " + token,
              },
            }
          )
          .then((response) => {
            if(response.data.statusCode===200){
            swal("Success", "Application approved!", "success").then(() => {
              // setOpen(false);
            })}
            else{
              swal ("Error" ,response.data.message,"error")
            }
            setShowLoader(false);
          })
          .catch((error) => {});
      } else {
        setOpen(false);
      }
    });
  };
  // handle rejection:

  const handleReject = () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to reject this vendor?",
      icon: "warning",
      buttons: ["Cancel", "Yes, reject it!"],
      dangerMode: true,
      content: {
        element: "input",
        attributes: {
          type: "text",
          placeholder: "Enter rejection remark",
        },
      },
    }).then((remark) => {
      if (remark) {
        const payload = {
          applicationStatus: "rejected",
          payoutAutoApprovel: false,
          registrationId: id,
          rejectionRemark: remark,
          showReferralTransaction: false,
          vendorAutoApproved: false,
        };
        setShowLoader(true);
        axios
          .put(
            `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/updateStage2`,
            payload,
            {
              headers: {
                Authentication: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            if(response.data.statusCode===200){
            swal("Success", "Application Rejected!", "success").then(() => {})}
            else{
              swal("Error", response.data.message ,"error")
            }
            setShowLoader(false);
          })
          .catch((error) => {});
      } else {
        // setOpen(false);
      }
    });
  };

  useEffect(() => {
    // setShowLoader(true);
    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getmerchantById/${id}`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setMerchantData(response?.data?.data);
        // setSelectedServices(
        //   response.data.data.paymentService?.map(
        //     (service) => service.paymentService
        //   )
        // );
        // setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching merchant details:", error.message);
      });
  }, [id]);

  const handleViewData = (mid) => {
    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/viewData/${mid}`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )

      .then((response) => {
        if (response.data.statusCode === 200) {
          setPdfFilenames(response?.data?.data);
          setViewDataClicked(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error.message);
      });
  };

  const handleViewFile = (filename) => {
    const encodedFilename = encodeURIComponent(filename);
    const headers = {
        Authentication: `Bearer ${token}`,
    };

    axios
        .get(
            `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/view-pdf/${encodedFilename}`,
            {
                responseType: "arraybuffer",
                headers,
            }
        )
        .then((response) => {
            const contentType = response.headers["content-type"];
            const blobType = contentType.includes("pdf")
                ? "application/pdf"
                : contentType;

            const fileBlob = new Blob([response.data], { type: blobType });
            const fileUrl = URL.createObjectURL(fileBlob);
            window.open(fileUrl, "_blank");
        })
        .catch((error) => {
            console.error("Error fetching file:", error.message);
        });
};

  useEffect(() => {
    handleViewData();
  }, []);

  return (
    <>
      {showLoader ? <MyLoader /> : ""}
      <section className="admin-view-details">
        <Container>
          <Card className="shadow border-0 mb-4 ds-header-card">
            <Card.Body>
              <Row>
                <Col xs={12} xl={12} lg={12} sm={12}>
                  <div className="two-column-layout">
                    <div className="vertical-table">
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Company Name
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData?.companyName}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Authorized Person Name
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData?.authorizedPersonName}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Payment Services
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          <ul>
                            {merchantData?.paymentService?.map(
                              (service, index) => (
                                <li key={index}>{service.paymentService}</li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>

                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Merchant Bank Name
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData?.merchantBankName}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Merchant Account Number
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData?.merchantAccountNumber}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Merchant IFSC Code
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData?.merchantIfscCode}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Website
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData?.websiteUrl}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Payin CallBack URL
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData?.callBackUrl}
                        </div>
                      </div>

                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Payout CallBack URL
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData?.payoutCallbackUrl}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Chargeback CallBack URL
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData?.chargebackCallbackUrl}
                        </div>
                      </div>

                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Referred By
                        </div>
                        <div className="vertical-table-cell-value  text-left">
                          {merchantData?.referredBy}
                        </div>
                      </div>
                      {/* <div className="vertical-table-row">
                        <div className="vertical-table-cell text-left">
                          MCC Code
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          <select
                            onChange={(e) => {
                              setMccCode(e.target.value);
                            }}
                            value={mccCode}
                          >
                            <option value="">Select an MCC Code</option>
                            <option value="gaming-101">Gaming 101</option>
                            <option value="gaming-203">Gaming 203</option>
                            <option value="gaming-204">Gaming 204</option>
                            <option value="ecommerce-101">Ecommerce 101</option>
                            <option value="ecommerce-103">Ecommerce 103</option>
                            <option value="5262">5262</option>
                            <option value="5816">5816</option>
                            <option value="4900">4900</option>
                            <option value="6300">6300</option>
                          </select>
                        </div>
                      </div>
                      {prefix === "ALL" ? (
                        <div className="vertical-table-row">
                          <div className="vertical-table-cell text-left">
                            Master Merchant
                          </div>
                          <div className="vertical-table-cell-value text-left">
                            <select
                              onChange={(e) => {
                                setMerchantMaster(e.target.value);
                              }}
                              value={merchantMaster}
                            >
                              <option value="">Select a Merchant</option>
                              {merchantMasterData?.map((merchant) => (
                                <option
                                  key={merchant.masterMerchantId}
                                  value={merchant.masterMerchantId}
                                >
                                  {merchant.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      ) : null} */}
                    </div>
                    {viewDataClicked && (
                      <div className="pdf-list-container report-grid-view">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>File Name</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {pdfFilenames?.map((fileObject, index) => (
                              <tr key={index}>
                                {/* <td>{fileObject["Orignal name"]}</td> */}
                                <td>{fileObject["File name"]}</td>
                                <td>
                                  <button
                                    className="btn btn-secondary"
                                    onClick={() =>
                                      handleViewFile(fileObject["Orignal name"])
                                    }
                                  >
                                    View  
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}

                    <div className="button-toolbar groups-btn text-end">
                      <button
                        className="btn btn-warning"
                        onClick={() => handleViewData(merchantData.mid)}
                      >
                        View Data
                      </button>

                      {privilagesData.includes(2594) ? (
                        <>
                          <button
                            className="btn btn-success"
                            onClick={() => handleApprove()}
                          >
                            Approve
                          </button>
                          <button
                            className="btn btn-danger"
                            onClick={() => handleReject()}
                          >
                            Reject
                          </button>
                        </>
                      ) : null}
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={handleClose}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </section>

      {selectedFile && (
        <div className="pdf-viewer">
          <iframe src={selectedFile} title="PDF Viewer" />

          <div className="button-toolbar groups-btn text-center">
            <button className="btn btn-danger" onClick={handleClosePDF}>
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default MoaDetails;
