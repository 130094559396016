import React, { useEffect, useState } from "react";
import { getAllMerchantPhase2Approved } from "../../../utils/ApiServices";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import { CheckBox } from "@mui/icons-material";

const NewTab = ({ setShowLoader }) => {
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [mids, setMids] = useState([]);
  const [merchantData, setMerchantData] = useState([]);
  const [personName, setPersonName] = useState([]);
  const token = localStorage.getItem("token");

  const getMerchantData = async (token) => {
    try {
      setShowLoader(true);
      const response = await getAllMerchantPhase2Approved(token);
      setShowLoader(false);
      if (response?.statusCode === 200) {
        setMerchantData(response?.data);
      } else {
        setMerchantData([]);
      }
    } catch (error) {
      setShowLoader(false);
      setMerchantData([]);
    }
  };

  useEffect(() => {
    getMerchantData(token);
  }, []);

  useEffect(() => {
    console.log(mids);
    console.log(personName)
  }, [mids]);

  const handleCompanyChange = (event, selectedMid) => {
    const value =event.target.name
    
    if (event.target.checked) {
      setMids((prevMids) => [...prevMids, selectedMid]);
    } else {
      setMids((prevMids) => prevMids.filter((mid) => mid !== selectedMid));
    }

    const isSelected = personName.includes(value);
    // Update personName state
    setPersonName((prevPersonName) => {
      if (isSelected) {
        // If the merchant is already selected, remove it
        return prevPersonName.filter((name) => name !== value);
      } else {
        // If the merchant is not selected, add it
        return [...prevPersonName, value];
      }
    });

    

  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log(value);

    // Extract merchant name and prefix from the selected value
    // const [prefix, merchantName] = value.split(" :- ");

    // Check if the merchant is already selected
    const isSelected = personName.includes(value);

    // Update personName state
    setPersonName((prevPersonName) => {
      if (isSelected) {
        // If the merchant is already selected, remove it
        return prevPersonName.filter((name) => name !== value);
      } else {
        // If the merchant is not selected, add it
        return [...prevPersonName, value];
      }
    });

    
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <section className="lincpay-dashboard-sec chargeback-reports-sec">
      <div className="cart-view-sec">
        <div className="containerflag">
          <div className="card">
            <div className="card-body">
              <div className="row mt-4">
                <div className="col-sm-12 col-md-6 offset-md-3">
                  <div className="">
                    <FormControl sx={{ width: 600 }}>
                      <InputLabel id="demo-multiple-checkbox-label">
                        Select Merchants
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={personName}
                        // onChange={handleChange}
                        input={<OutlinedInput label="Select Merchants" />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {merchantData.map((merchant, i) => (
                          <MenuItem
                            key={i}
                            value={`${merchant.prifix} :- ${merchant.merchantName}`}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input mx-2 mb-1"
                              name={`${merchant.prifix} :- ${merchant.merchantName}`}
                              value={personName.includes(
                                `${merchant.prifix} :- ${merchant.merchantName}`
                              )}
                              onChange={
                                (event) =>
                                  handleCompanyChange(event, merchant.mid) // Pass the mid value to the function
                              }
                            />
                            <ListItemText
                              primary={`${merchant.prifix} :- ${merchant.merchantName}`}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="d-flex flex-wrap p-5 mt-2">
                  {personName?.map((item) => (
                    <div key={item}>
                      <span className="fs-4 p-2 px-2 border border-2 rounded rounded-3 mx-2">
                        {item}
                      </span>
                    </div>
                  ))}
                </div>
                <div className="d-flex flex-wrap p-5 mt-2">
                  {personName?.map((item) => (
                    <div key={item}>
                      <span className="fs-4 p-2 px-2 border border-2 rounded rounded-3 mx-2">
                        {item}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewTab;
