import React, { useEffect, useState } from "react";
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import { SWIPELINC_API } from "../utils/constant";
import { Link } from "react-router-dom";
import { Container, Card, Row, Col } from "react-bootstrap";
import DownloadCsv from "../common/DownloadCsv";
import { headerLabelsForAllMerchant } from "../utils/DownloadHeaders";

function MerchantTransaction({ setShowLoader }) {
  const [xyzReportData, setxyzReportData] = useState([]);
  const [selectedMids, setSelectedMids] = useState([]);
  const [totals, setTotals] = useState({
    TotalAmounts: "Total Amounts",
  });
  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    fetchMerchantTransaction();
  }, []);

  const handleCheckbox = (e) => {
    const mid = e.target.name;
    const checked = e.target.checked;

    if (checked) {
      setSelectedMids((prevSelectedMids) => [...prevSelectedMids, mid]);
    } else {
      setSelectedMids((prevSelectedMids) =>
        prevSelectedMids.filter((selectedMid) => selectedMid !== mid)
      );
    }
  };

  const fetchMerchantTransaction = async () => {
    setShowLoader(true);
    try {
      const response = await axios.get(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/getAllMerchantWithTransaction`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      );

      if (response.data.statusCode === 200) {
        setxyzReportData(response?.data?.data);
      } else {
        console.error("Api response error:", response.data);
      }
      setShowLoader(false);
    } catch (error) {
      console.error("Error fetching transaction report:", error);
      setShowLoader(false);
    }
  };

  const columns = [
    { label: "Company Name", field: "name", width: "auto" },
    { label: "Select Merchant", field: "checkbox", width: "auto" },
    { label: "Merchant ID", field: "mid", width: "auto" },
    { label: "PayIn Amount", field: "totalTransactionAmount", width: "auto" },
    { label: "Settlement Amount", field: "settlmentAmount", width: "auto" },
    { label: "ChargeBack Amount", field: "chargeBackAmount", width: "auto" },
    { label: "Hold Amount", field: "holdAmount", width: "auto" },
    { label: "Deposit", field: "securityDeposit", width: "auto" },
    { label: "Payout Amount", field: "payoutAmount", width: "auto" },
    { label: "Withdrawl Amount", field: "withdrawalAmount", width: "auto" },
    { label: "Balance (Pay In)", field: "remaningAmount", width: "auto" },
    {
      label: "Balance (Payout UPI)",
      field: "ballancePayoutUpi",
      width: "auto",
    },
    {
      label: "Balance (Payout IMPS)",
      field: "ballancePayoutImps",
      width: "auto",
    },
    { label: "Guarantee Amount", field: "guaranteeAmount", width: "auto" },
    { label: "Available Credit Amount", field: "creditBalance", width: "auto" },
    {
      label: "Settlement Adjustment amount",
      field: "sumOfSettlemenetAdjustAmount",
      width: "auto",
    },
    { label: "Service Charge", field: "serviceCharge", width: "auto" },
    { label: "Vendors", field: "totalVendors", width: "auto" },
    { label: "Release Amount", field: "releaseAmount", width: "auto" },
    { label: "Wallet Balance", field: "walletBalance", width: "auto" },
  ];

  const columnsTotal = [
    { label: "Company Name", field: "name", width: "auto" },
    { label: "Select Merchant", field: "selectedMids", width: "auto" },
    { label: "Merchant ID", field: "mid", width: "auto" },

    { label: "PayIn Amount", field: "totalTransactionAmount", width: "auto" },
    { label: "Settlement Amount", field: "settlmentAmount", width: "auto" },
    { label: "ChargeBack Amount", field: "chargeBackAmount", width: "auto" },
    { label: "Hold Amount", field: "holdAmount", width: "auto" },
    { label: "Deposit", field: "securityDeposit", width: "auto" },
    { label: "Payout Amount", field: "payoutAmount", width: "auto" },
    { label: "Withdrawl Amount", field: "withdrawalAmount", width: "auto" },
    { label: "Balance (Pay In)", field: "remaningAmount", width: "auto" },
    {
      label: "Balance (Payout UPI)",
      field: "ballancePayoutUpi",
      width: "auto",
    },
    {
      label: "Balance (Payout IMPS)",
      field: "ballancePayoutImps",
      width: "auto",
    },
    { label: "Guarantee Amount", field: "guaranteeAmount", width: "auto" },
    { label: "Available Credit Amount", field: "creditBalance", width: "auto" },
    {
      label: "Settlement Adjustment amount",
      field: "sumOfSettlemenetAdjustAmount",
      width: "auto",
    },
    { label: "Service Charge", field: "serviceCharge", width: "auto" },
    { label: "Vendors", field: "totalVendors", width: "auto" },
    { label: "Release Amount", field: "releaseAmount", width: "auto" },
    { label: "Wallet Balance", field: "walletBalance", width: "auto" },
  ];

  const mappedData = xyzReportData?.map((item) => {
    const mappedItem = {};
    columns.forEach((column) => {
      if (column.field === "totalTransactionAmount") {
        mappedItem[column.field] = (
          <Link to={`/TransactionReport/${item.mid}`}>
            {(item[column.field] !== null) & (item[column.field] !== "")
              ? parseInt(item[column.field])
              : 0}
          </Link>
        );
      } else if (column.field === "name") {
        mappedItem[column.field] = item.name;
      } else if (column.field === "mid") {
        mappedItem[column.field] = item.mid;
      } else if (column.field === "checkbox") {
        mappedItem[column.field] = (
          <input
            type="checkbox"
            name={item.mid}
            checked={selectedMids.includes(item.mid)}
            onChange={handleCheckbox}
          />
        );
      } else if (column.field === "chargeBackAmount") {
        mappedItem[column.field] = (
          <Link to={`/chargeBackAmount/${item.mid}`}>
            {(item[column.field] !== null) & (item[column.field] !== "")
              ? parseInt(item[column.field])
              : 0}
          </Link>
        );
      } else if (column.field === "settlmentAmount") {
        mappedItem[column.field] = (
          <Link to={`/settlmentAmount/${item.mid}`}>
            {(item[column.field] !== null) & (item[column.field] !== "")
              ? parseInt(item[column.field])
              : 0}
          </Link>
        );
      } else if (column.field === "withdrawalAmount") {
        mappedItem[column.field] = (
          <Link to={`/withdrawalAmount/${item.mid}`}>
            {(item[column.field] !== null) & (item[column.field] !== "")
              ? parseInt(item[column.field])
              : 0}
          </Link>
        );
      } else if (column.field === "guaranteeAmount") {
        mappedItem[column.field] = (
          <Link to={`/guaranteeAmount/${item.mid}`}>
            {(item[column.field] !== null) & (item[column.field] !== "")
              ? parseInt(item[column.field])
              : 0}
          </Link>
        );
      } else if (column.field === "payoutAmount") {
        mappedItem[column.field] = (
          <Link to={`/payoutAmount/${item.mid}`}>
            {(item[column.field] !== null) & (item[column.field] !== "")
              ? parseInt(item[column.field])
              : 0}
          </Link>
        );
      } else if (column.field === "creditBalance") {
        mappedItem[column.field] = (
          <Link to={`/availableCreditAmount/${item.mid}`}>
            {(item[column.field] !== null) & (item[column.field] !== "")
              ? parseInt(item[column.field])
              : 0}
          </Link>
        );
      } else if (column.field === "sumOfSettlemenetAdjustAmount") {
        mappedItem[column.field] = (
          <Link to={`/SettlementAdjustmentAmount/${item.mid}`}>
            {(item[column.field] !== null) & (item[column.field] !== "")
              ? parseInt(item[column.field])
              : 0}
          </Link>
        );
      } else if (column.field === "totalVendors") {
        mappedItem[column.field] = (
          <Link to={`/merchantVendors/${item.mid}`}>
            {(item[column.field] !== null) & (item[column.field] !== "")
              ? parseInt(item[column.field])
              : 0}
          </Link>
        );
      } else if (column.field === "releaseAmount") {
        mappedItem[column.field] = (
          <Link to={`/releaseAmount/${item.mid}`}>
            {(item[column.field] !== null) & (item[column.field] !== "")
              ? parseInt(item[column.field])
              : 0}
          </Link>
        );
      } else if (column.field === "walletBalance") {
        mappedItem[column.field] = (
          <Link to={`/walletBalance/${item.mid}`}>
            {(item[column.field] !== null) & (item[column.field] !== "")
              ? parseInt(item[column.field])
              : 0}
          </Link>
        );
      } else if (column.field === "serviceCharge") {
        mappedItem[column.field] =
          (item[column.field] !== null) & (item[column.field] !== "")
            ? parseInt(item[column.field])
            : 0;
      } else if (column.field === "holdAmount") {
        mappedItem[column.field] = (
          <Link to={`/holdAmount/${item.mid}`}>
            {(item[column.field] !== null) & (item[column.field] !== "")
              ? parseInt(item[column.field])
              : 0}
          </Link>
        );
      } else if (column.field === "securityDeposit") {
        mappedItem[column.field] = (
          <Link to={`/securityDeposit/${item.mid}`}>
            {(item[column.field] !== null) & (item[column.field] !== "")
              ? parseInt(item[column.field])
              : 0}
          </Link>
        );
      } else if (column.field === "remaningAmount") {
        mappedItem[column.field] =
          (item[column.field] !== null) & (item[column.field] !== "")
            ? parseInt(item[column.field])
            : 0;
      } else if (column.field === "ballancePayoutUpi") {
        mappedItem[column.field] =
          (item[column.field] !== null) & (item[column.field] !== "")
            ? parseInt(item[column.field])
            : 0;
      } else if (column.field === "ballancePayoutImps") {
        mappedItem[column.field] =
          (item[column.field] !== null) & (item[column.field] !== "")
            ? parseInt(item[column.field])
            : 0;
      } else {
        mappedItem[column.field] = parseInt(item[column.field]);
      }
    });
    return mappedItem;
  });

  useEffect(() => {
    console.log(selectedMids);
  }, [selectedMids]);

  // Calculate totals whenever selectedMids or xyzReportData change
  useEffect(() => {
    if (selectedMids.length > 0 && xyzReportData.length > 0) {
      const totals = {};
      selectedMids.forEach((mid) => {
        const merchantData = xyzReportData.find((item) => item.mid === mid);
        if (merchantData) {
          Object.keys(merchantData).forEach((key) => {
            // Exclude fields "email", "mobile", and "name"
            if (["email", "mobile", "name", "mid"].includes(key)) return;

            const value = parseFloat(merchantData[key]);
            if (!isNaN(value)) {
              totals[key] = (totals[key] || 0) + value;
            }
          });
        }
      });
      setTotals(totals);
    } else {
      setTotals({});
    }
    console.log(totals);
  }, [selectedMids, xyzReportData]);

  return (
    <>
      <Row>
        <Col xs={12} lg={12} md={12}>
          <Card className="shadow border-0 mb-4">
            <Card.Body>
              <div className="report-grid-view">
                <div className="material-table-records mb-3">
                  <MDBDataTable
                    className="dataTable"
                    striped
                    small
                    bordered
                    hover
                    responsive
                    data={{
                      columns: columns,
                      rows: [...mappedData],
                    }}
                    searching={true}
                    entries={50}
                    paginationLabel={["Previous", "Next"]}
                    sortable={true}
                    footer="none"
                  />
                </div>
                <div className="material-table-records mb-3">
                  <MDBDataTable
                    responsive
                    striped
                    data={{
                      columns: columnsTotal,
                      rows: [{ ...totals, name: "Total Sum" }],
                    }}
                  />
                </div>

                <DownloadCsv
                  DownLoadData={xyzReportData}
                  Header={headerLabelsForAllMerchant}
                  Name={"All-Merchant-transaction"}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default MerchantTransaction;
