import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import queryString from "query-string";
import { SWIPELINC_API } from "../utils/constant";
import MyLoader from "../common/MyLoader";
import { MDBDataTable } from 'mdbreact';
import MoaDetails from "./MoaDetails";

const Stage2Applications = ({ setShowLoader }) => {
  const [applications, setApplications] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [showPaymentServices, setShowPaymentServices] = useState(false);
  // const [message, setMessage] = useState(true);
  const [id,setId] = useState ("");
  const [openView,setOpenView] = useState(false)
  const [token, setToken] = useState(localStorage.getItem("token"));

  const handleSelect = () => {
    setShowPaymentServices(
      (prevShowPaymentServices) => !prevShowPaymentServices
    );
  };

  // const navigate = useNavigate();

  useEffect(() => {
   setShowLoader(true);
    axios
      .get(`${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/findbystage2/Submitted`,
      {
        headers: {
          Authentication: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.statusCode === 200) {
          console.log("application:", response.data.data);
          setApplications(response?.data?.data);
        } else if (response.data.statusCode === 304) {
          console.log("NO APPLICATIONS FOUND:", response.data.data);
          setApplications(response?.data?.data);
          // setMessage("No Data Found", response.data.data);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching applications:", error.message);
        setShowLoader(false);
      });
  }, [openView]);

  const handleViewDetails = (registrationId) => {
    // const queryParams = queryString.stringify({
    //   selectedServices: JSON.stringify(selectedServices),
    // });
    setOpenView(true);
    setSelectedServices({ ...selectedServices, [registrationId]: showPaymentServices[registrationId] });
    setId (registrationId);
  };
  const handleClose = () => {
    setId("");
    setSelectedServices({});
    setOpenView(false)
  };
  const columns = [
    { label: "Company Name", field: "companyName", width: "auto" },
    { label: "Authorized Person Name", field: "authorizedPersonName", width: "auto" },
    { label: "e-Mail ID", field: "email", width: "auto" },
    { label: "Mobile Number", field: "mobile", width: "auto" },
    { label: "Company PAN", field: "companyPanNumber", width: "auto" },
    { label: "GST Number", field: "gstNumber", width: "auto" },
    { label: "Action", field: "action", width: "150px" },
  ];

  const mappedData =applications?.map((application) => {
      const mappedItem = { ...application };
      mappedItem.action = (
        <button className="btn btn-primary btn-sm"
          type="button"
          onClick={() => handleViewDetails(application.registrationId)}
        >
          View Details
        </button>
      );
      return mappedItem;
    })
    
    return (
    <>
      <div className="report-grid-view">
        {!openView ? (
        <MDBDataTable
          className='dataTable'
          striped
          small
          bordered
          hover
          responsive
          data={{
            columns: columns,
            rows: mappedData,
          }}
          searching={true}
          entries={50}
          exportToCSV
          theadColor="#333"
          paginationLabel={['Previous', 'Next']}
          sortable={true}
          footer="none"
        />
        ): openView? (
          <MoaDetails
          id={id}
          handleClose={handleClose}
          />
        ):null}
      </div>
    </>
  );
};

export default Stage2Applications;