import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import SubmittedRoutingPayout from "../../components/Tools/ALL PAYOUT REPORTS/SubmittedRoutingPayout";
import InactiveServices from "../../components/InactiveServices";
import OnBording from "./PayoutRoutingOnBording";
import useCheckPrivilages from "../../utils/checkPrivilages";
import PayoutRoutingOnBording from "./PayoutRoutingOnBording";

const RoutingSubTabForPayoutOnBording = ({ location, setShowLoader }) => {
  const [activePhase, setActivePhase] = useState("");

  const { privilagesData } = useSelector((state) => state?.privilages);
  const hasPrivilages2635 = useCheckPrivilages(2635);
  const hasPrivilages2636 = useCheckPrivilages(2636);

  useEffect(() => {
    setActivePhase("");
    //   setActiveSubtab("MerchantManagement");
  }, []);

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  return (
    <>
      <>
        <section className="dashboard-header-sec">
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4 ds-header-card">
                  <Card.Body>
                    {/* <Card.Title className="h3">Reports</Card.Title>  */}
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn">
                        {hasPrivilages2636 ? (
                          <li
                            className={activePhase === "Phase1" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase1")}
                          >
                            Routing
                          </li>
                        ) : null}
                        {hasPrivilages2635 ? (
                          <li
                            className={activePhase === "Phase2" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase2")}
                          >
                            Submitted
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </section>
        {activePhase === "" ? null : (
          <div>
            {activePhase === "Phase1" ? (
              <PayoutRoutingOnBording
                location={location}
                setShowLoader={setShowLoader}
              />
            ) : activePhase === "Phase2" ? (
              <SubmittedRoutingPayout 
              showEditButton={false}
              showEnaDisaButton={false}
              setShowLoader={setShowLoader} />
            ) : (
              // ) : activePhase === "Phase3" ? (
              // <SubmittedRoutingPayin setShowLoader={setShowLoader} />
              //   <ViewMappedSID setShowLoader={setShowLoader} />
              <InactiveServices location={"priviges"} />
            )}
          </div>
        )}
      </>
    </>
  );
};

export default RoutingSubTabForPayoutOnBording;
