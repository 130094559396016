import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import swal from "sweetalert";
import { SWIPELINC_API } from "../utils/constant";
import axios from "axios";
import {
  disableVehicleMaster,
  enableVehicleMaster,
  fetchAllActiveAndDisableVehicleMaster,
  fetchAllVehicleMasterForm,
  getPhase2VehicleMasterSubmitted,
} from "../utils/ApiServices";

import VehicleMasterEditForm from "./VehicleMasterEditForm";
import VehicleMasterFormDetails from "./VehicleMasterFormDetails";
import EditVicleMasterPhase2 from "./EditVicleMasterPhase2";

const SubmittedVehicleMasterForms = ({ setShowLoader }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [vehicalMasterData, setVehicalMasterData] = useState([]);
  const [callAgain, setCallAgain] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [registrationId, setRegistrationId] = useState("");
  const [data, setData] = useState({});

  const [data2, setData2] = useState({});
  const [schemeName2, setSchemeName2] = useState("");
  const [bankName2, setBankName2] = useState("");
  const [switchName2, setSwitchName2] = useState("");
  const [aggeregatorName2, setAggeregatorName2] = useState("");
  const [openEdit2, setOpenEdit2] = useState(false);

  const [schemeName, setSchemeName] = useState("");
  const [bankName, setBankName] = useState("");
  const [switchName, setSwitchName] = useState("");
  const [aggeregatorName, setAggeregatorName] = useState("");

  useEffect(() => {
    GetVehicalMasterData(token);
  }, [callAgain, openEdit]);

  const GetVehicalMasterData = async (token) => {
    setShowLoader(true);
    try {
      const response = await getPhase2VehicleMasterSubmitted(token);

      if (response.statusCode === 200) {
        setVehicalMasterData(response?.data);
      } else {
        setVehicalMasterData([]);
      }
    } catch (error) {
      console.log(error);
    }
    setShowLoader(false);
  };

  const columns = [
    { label: "Vehical Name", field: "vehicleName", width: "auto" },
    { label: "Scheme", field: "schemeName", width: "auto" },
    { label: "Bank", field: "bankName", width: "auto" },
    { label: "Switch", field: "switchName", width: "auto" },
    { label: "Aggeregator", field: "aggregatorName", width: "auto" },
    { label: "Remark", field: "remark", width: "auto" },
    { label: "Payment Services", field: "PaymentServices", width: "auto" },
    {label: "Payment Sub Services",field: "PaymentSubServices",width: "auto", },
    { label: "Action", field: "View", width: "auto" },
     { label: "Edit", field: "Edit", width: "auto" },
    // { label: "Status", field: "active", width: "auto" },
  ];

  // const disableVehicle = async (regId) => {
  //   setShowLoader(true);
  //   let res = await disableVehicleMaster(token, regId);
  //   if (res.statusCode === 200) {
  //     swal({
  //       title: "Success!",
  //       text: "Vehicle Disable Successfully.",
  //       icon: "success",
  //     }).then(() => {
  //       GetVehicalMasterData(token);
  //     });
  //   } else {
  //     swal({
  //       title: "Alert!",
  //       text: res.message,
  //       icon: "error",
  //     });
  //   }
  //   setShowLoader(false);
  // };

  // const enableVehicle = async (regId) => {
  //   setShowLoader(true);
  //   let res = await enableVehicleMaster(token, regId);
  //   if (res.statusCode === 200) {
  //     swal({
  //       title: "Success!",
  //       text: "Vehicle Enable Successfully.",
  //       icon: "success",
  //     }).then(() => {
  //       GetVehicalMasterData(token);
  //     });
  //   } else {
  //     swal({
  //       title: "Alert!",
  //       text: res.message,
  //       icon: "error",
  //     });
  //   }
  //   setShowLoader(false);
  // };

  const mappedData = vehicalMasterData
    ? vehicalMasterData?.map((item, i) => {
        const mappedItem = { ...item };

        // if (mappedItem.active) {
        mappedItem.View = (
          <div className="d-flex gap-2 justify-content-evenly">
            <button
              className="btn btn-primary btn-sm"
              key={i}
              onClick={(e) => ViewVehicleDetails(item.id)}
            >
              View Details
            </button>

            {/* <button
                className="btn btn-danger btn-sm"
                key={i}
                onClick={(e) => disableVehicle(item.registrationId)}
              >
                Disable
              </button> */}
          </div>
        );
        // }

        mappedItem.PaymentServices = (
          <div className="d-flex gap-2 justify-content-evenly">
            {item.paymentTypes?.map((item) => {
              return (
                <div className="border border-1 p-2 bg-light rounded-5 px-3 mt-1">
                  {item.paymentservice}
                </div>
              );
            })}
          </div>
        );
        // console.log("ITEM >>>", item);

        mappedItem.Edit = (
          <div className="d-flex gap-2 justify-content-evenly">
            <button
              className="btn btn-warning btn-sm"
              key={i}
              onClick={(e) => OpenEditPhase1(item.id, { ...item })}
            >
              Edit Phase 1
            </button>
            <button
              className="btn btn-warning btn-sm"
              key={i}
              onClick={(e) => OpenEditPhase2(item.id, { ...item })}
            >
              Edit Phase 2
            </button>
          </div>
        );
        mappedItem.PaymentSubServices = (
          <div className="d-flex gap-2 justify-content-evenly">
            {item.paymentSubTypes?.map((item) => {
              return (
                <div className="border border-1 p-2 bg-light rounded-5 px-3 mt-1">
                  {item.type}
                </div>
              );
            })}
          </div>
        );

        return mappedItem;
      })
    : [];

  const OpenEditPhase1 = (id, data) => {
    setRegistrationId(id);
    setData(data);
    console.log("DATA FROM SUBMITTED ?>>>>>", data);
    setBankName(data.bankName);
    setAggeregatorName(data.aggregatorName);
    setSwitchName(data.switchName);
    setSchemeName(data.schemeName);
    setOpenEdit(true);
  };

  const OpenEditPhase2 = (id, data) => {
    setRegistrationId(id);
    setData2(data);
    console.log("DATA PHASE 2 >>>>", data);
    setBankName2(data.bankName);
    setAggeregatorName2(data.aggregatorName);
    setSwitchName2(data.switchName);
    setSchemeName2(data.schemeName);
    setOpenEdit2(true);
  };

  const ViewVehicleDetails = (id) => {
    console.log(id);
    setRegistrationId(id);
    setShowDetails(true);
  };

  const handleCloseModal = () => {
    setRegistrationId("");
    setData({});
    setOpenEdit(false);
  };

  const handleCloseModal2 = () => {
    setRegistrationId("");
    setData({});
    setOpenEdit2(false);
    GetVehicalMasterData(token);
  };

  return (
    <>
      <Row>
        <Col xs={12} lg={12} md={12}>
          <Card className="shadow border-0 mb-4">
            <Card.Body>
              {showDetails ? (
                <VehicleMasterFormDetails
                  Id={registrationId}
                  setShowLoader={setShowLoader}
                  setShowDetails={setShowDetails}
                />
              ) : openEdit ? (
                <VehicleMasterEditForm
                  id={registrationId}
                  data={data}
                  isModalOpen={openEdit}
                  handleCloseModal={handleCloseModal}
                  setShowLoader={setShowLoader}
                  bankNameData={{ bankName, setBankName }}
                  schemeNameData={{ schemeName, setSchemeName }}
                  switchNameData={{ switchName, setSwitchName }}
                  aggregatorNameData={{ aggeregatorName, setAggeregatorName }}
                />
              ) : openEdit2 ? (
                <EditVicleMasterPhase2
                  id={registrationId}
                  data={data2}
                  isModalOpen2={openEdit2}
                  handleCloseModal={handleCloseModal2}
                  setShowLoader={setShowLoader}
                  bankNameData={{ bankName2, setBankName2 }}
                  schemeNameData={{ schemeName2, setSchemeName2 }}
                  switchNameData={{ switchName2, setSwitchName2 }}
                  aggregatorNameData={{ aggeregatorName2, setAggeregatorName2 }}
                />
              ) : (
                <div className="report-grid-view">
                  <div className="material-table-records mb-3">
                    <MDBDataTable
                      className="dataTable"
                      striped
                      small
                      bordered
                      hover
                      responsive
                      data={{
                        columns: columns,
                        rows: mappedData,
                      }}
                      searching={true}
                      entries={50}
                      exportToCSV
                      theadColor="#333"
                      paginationLabel={["Previous", "Next"]}
                      sortable={true}
                      footer="none"
                    />
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SubmittedVehicleMasterForms;
