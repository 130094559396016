import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import DropDowns from "./DropDowns";
import ShowField from "./ShowField";
import {
  SaveVehicleMasterForm,
  SaveVehicleMasterPhase2Form,
  fetchAllActiveAndDisableVehicleMaster,
} from "../utils/ApiServices";
import swal from "sweetalert";

const VehicleMasterFormPhase2 = ({ setShowLoader }) => {
  const [selectedType, setSelectedType] = useState({
    vehicleId: Number,
  });

  const [paymentSubType, setPaymentSubType] = useState([]);
  const [MDRData, setMDRData] = useState([]);
  const [MerchantMDRData, setMerchantMDRData] = useState([]);
  const [SchemeMDRData, setMDRDataForScheme] = useState([]);
  const [AggregatorMDRData, setMDRDataForAggreGator] = useState([]);
  const [SwitchMDRData, setMDRDataForSwitch] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [formData, setFormData] = useState({});
  const [vehicleData, setVehicleData] = useState([]);
  
  // const [vehicleData, setVehicleData] = useState([])

  useEffect(() => {
    GetVehicalMasterData(token);
  }, [selectedType]);

  const GetVehicalMasterData = async (token) => {
    setShowLoader(true);
    try {
      const response = await fetchAllActiveAndDisableVehicleMaster(token);

      if (response.statusCode === 200) {
        const transformedData = response?.data?.map((item) => {
          return {
            ...item,
            Id: item.id,
            type: item.vehicleName,
          };

          // Include other properties as needed
        });
        setVehicleData(transformedData);
      } else {
        setVehicleData([]);
      }
    } catch (error) {
      console.log(error);
    }
    setShowLoader(false);
  };

  const handleTypeChange = (event) => {
    const selectedVehicleValue = event.target.value;
    const name = event.target.name;

    // console.log(selectedVehicleValue, name);

    setSelectedType((prevSelectedType) => ({
      ...prevSelectedType,
      vehicleId: selectedVehicleValue,
    }));

    const selectedVehicle = vehicleData.find(
      (vehicle) => vehicle.Id === parseInt(selectedVehicleValue)
    );

    console.log(selectedVehicle);

    if (selectedVehicle) {
      setFormData({
        aggregatorName: selectedVehicle.aggregatorName,
        bankName: selectedVehicle.bankName,
        schemeName: selectedVehicle.schemeName,
        switchName: selectedVehicle.switchName, // Fix: Use 'switchName' instead of 'merchantName'
        masterMerchantName: selectedVehicle.masterMerchantName,
        remark: selectedVehicle.remark, // Assuming 'remark' is a property in the vehicle object
      });

      setPaymentSubType(selectedVehicle.paymentSubTypes);

      console.log(formData);
    } else {
    }

    setMDRData(
      selectedVehicle?.paymentSubTypes?.map((service) => {
        return {
          vehicleMasterSubPaymentTypeServiceId:
            service?.vehicleMasterSubPaymentTypeServiceId,
          serviceId: service?.paymentSubTypeId,
          fixAmount: "",
          percentage: "",
          // MerchantFix: "",
          // MerchantPercentage: "",
        };
      })
    );
    setMerchantMDRData(
      selectedVehicle?.paymentSubTypes?.map((service) => {
        return {
          vehicleMasterSubPaymentTypeServiceId:
            service?.vehicleMasterSubPaymentTypeServiceId,
          serviceId: service?.paymentSubTypeId,
          fixAmount: "",
          percentage: "",
          // MerchantFix: "",
          // MerchantPercentage: "",
        };
      })
    );
    setMDRDataForAggreGator(
      selectedVehicle?.paymentSubTypes?.map((service) => {
        return {
          vehicleMasterSubPaymentTypeServiceId:
            service?.vehicleMasterSubPaymentTypeServiceId,
          serviceId: service?.paymentSubTypeId,
          fixAmount: "",
          percentage: "",
          // MerchantFix: "",
          // MerchantPercentage: "",
        };
      })
    );
    setMDRDataForScheme(
      selectedVehicle?.paymentSubTypes?.map((service) => {
        return {
          vehicleMasterSubPaymentTypeServiceId:
            service?.vehicleMasterSubPaymentTypeServiceId,
          serviceId: service?.paymentSubTypeId,
          fixAmount: "",
          percentage: "",
          // MerchantFix: "",
          // MerchantPercentage: "",
        };
      })
    );
    setMDRDataForSwitch(
      selectedVehicle?.paymentSubTypes?.map((service) => {
        return {
          vehicleMasterSubPaymentTypeServiceId:
            service?.vehicleMasterSubPaymentTypeServiceId,
          serviceId: service?.paymentSubTypeId,
          fixAmount: "",
          percentage: "",
          // MerchantFix: "",
          // MerchantPercentage: "",
        };
      })
    );
  };

  const handleMDRDataForBank = (
    paymentSubservice,
    dataType,
    value,
    paymentSubTypeId
  ) => {
    // Find the item in MDRData array
    console.log(dataType, value);
    const foundItem = MDRData?.find(
      (item) => item.serviceId === paymentSubTypeId
    );

    // console.log(foundItem);
    // If found, update the value for the corresponding paymentSubTypeId
    if (foundItem) {
      const updatedMDRData = MDRData?.map((item) =>
        item.serviceId === paymentSubTypeId
          ? { ...item, [dataType]: value }
          : item
      );
      //   console.log(updatedMDRData);
      setMDRData(updatedMDRData);
    }
  };

  const handleMDRDataForMerchant = (
    paymentSubservice,
    dataType,
    value,
    paymentSubTypeId
  ) => {
    // Find the item in MDRData array
    console.log("Master----------------in", dataType, value);
    const foundItem = MerchantMDRData.find(
      (item) => item.serviceId === paymentSubTypeId
    );

    console.log(foundItem);
    // If found, update the value for the corresponding paymentSubTypeId
    if (foundItem) {
      const updatedMDRData = MerchantMDRData?.map((item) =>
        item.serviceId === paymentSubTypeId
          ? { ...item, [dataType]: value }
          : item
      );
      //   console.log(updatedMDRData);
      setMerchantMDRData(updatedMDRData);
    }
  };

  const handleMDRDataSwitch = (
    paymentSubservice,
    dataType,
    value,
    paymentSubTypeId
  ) => {
    // Find the item in MDRData array
    // console.log(dataType);
    const foundItem = SwitchMDRData.find(
      (item) => item.serviceId === paymentSubTypeId
    );

    // console.log(foundItem);
    // If found, update the value for the corresponding paymentSubTypeId
    if (foundItem) {
      const updatedMDRData = SwitchMDRData?.map((item) =>
        item.serviceId === paymentSubTypeId
          ? { ...item, [dataType]: value }
          : item
      );
      //   console.log(updatedMDRData);
      setMDRDataForSwitch(updatedMDRData);
    }
  };

  const handleMDRDataForAggregator = (
    paymentSubservice,
    dataType,
    value,
    paymentSubTypeId
  ) => {
    // Find the item in MDRData array
    console.log(dataType, value);
    const foundItem = AggregatorMDRData.find(
      (item) => item.serviceId === paymentSubTypeId
    );

    // console.log(foundItem);
    // If found, update the value for the corresponding paymentSubTypeId
    if (foundItem) {
      const updatedMDRData = AggregatorMDRData?.map((item) =>
        item.serviceId === paymentSubTypeId
          ? { ...item, [dataType]: value }
          : item
      );
      console.log(updatedMDRData);
      setMDRDataForAggreGator(updatedMDRData);
    }
  };

  const handleMDRDataForScheme = (
    paymentSubservice,
    dataType,
    value,
    paymentSubTypeId
  ) => {
    console.log(dataType, value);
    const foundItem = SchemeMDRData.find(
      (item) => item.serviceId === paymentSubTypeId
    );
    console.log(foundItem);
    if (foundItem) {
      const updatedMDRData = SchemeMDRData?.map((item) =>
        item.serviceId === paymentSubTypeId
          ? { ...item, [dataType]: value }
          : item
      );
      // console.log(updatedMDRData);
      setMDRDataForScheme(updatedMDRData);
    }
  };

  //   console.log(MDRData);
  //   console.log(SchemeMDRData);

  const generatePayload = (Data, type) => {
    const result = Data?.map((item) => {
      // console.log(item)
      return {
        type: type,
        feeFix: item.fixAmount,

        vehicleMasterSubPaymentTypeServiceId:
          item?.vehicleMasterSubPaymentTypeServiceId,

        feePercentage: item.percentage,
        // MerchantPercentage: item.MerchantPercentage,
        // subpaymentTypeId: item.serviceId,
        // vehicleMasterId: selectedType.vehicleId,
      };
    });

    return result;
  };

  const resultForAggregator = generatePayload(AggregatorMDRData, "AGGREGATOR");
  const resultForScheme = generatePayload(SchemeMDRData, "SCHEME");
  const resultForBank = generatePayload(MDRData, "BANK");
  const resultForSwitch = generatePayload(SwitchMDRData, "SWITCH");
  const resultForMerchant = generatePayload(MerchantMDRData, "MASTER");

  const finalPayload = (
    resultForAggregator,
    resultForScheme,
    resultForBank,
    resultForSwitch,
    resultForMerchant,
    formData
  ) => {
    console.log(formData);
    const payload = [];

    if (formData.aggregatorName !== "NA") {
      // console.log(resultForAggregator)
      payload.push(...resultForAggregator);
    }

    if (formData.schemeName !== "NA") {
      console.log("insideScheme---", formData.schemeName);
      payload.push(...resultForScheme);
    }

    if (formData.switchName !== "NA") {
      console.log("insideSwitch---", formData.switchName);
      payload.push(...resultForSwitch);
    }

    if (formData.bankName !== "NA") {
      console.log("insidebank---", formData.bankName);

      payload.push(...resultForBank);
    }

    if (formData.masterMerchantName !== "NA") {
      console.log("insideMasterMerchant---", formData.masterMerchantName);

      payload.push(...resultForMerchant);
    }

    return payload;
  };

  const handleSubmit = async () => {
    const payloadFinal = finalPayload(
      resultForAggregator,
      resultForScheme,
      resultForBank,
      resultForSwitch,
      resultForMerchant,
      formData
    );

    let hasInvalidFixAmount = false;
    let hasInvalidPercentage = false;

    payloadFinal?.forEach((item) => {
      if (!(parseInt(item.feeFix) >= 0)) {
        hasInvalidFixAmount = true;
        // console.log(hasInvalidFixAmount);
      }
    });

    payloadFinal?.forEach((item) => {
      if (
        !(
          parseInt(item.feePercentage) >= 0 &&
          parseInt(item.feePercentage) <= 100
        )
      ) {
        hasInvalidPercentage = true;
        // console.log(hasInvalidPercentage);
      }
    });

    console.log("fix---------", hasInvalidFixAmount);
    console.log("Percentage---------", hasInvalidPercentage);

    if (hasInvalidFixAmount) {
      swal({
        title: "Alert!",
        text: "Please Fill Positive Values in Fix Fees And Percentage Values Should Be In Range of 0 to 100.",
        icon: "error",
      });
      return;
    }

    if (hasInvalidPercentage) {
      swal({
        title: "Alert!",
        text: "Please Fill Positive Values in Fix Fees And Percentage Values Should Be In Range of 0 to 100.",
        icon: "error",
      });
      return;
    }

    console.log(payloadFinal);

    try {
      setShowLoader(true);
      const response = await SaveVehicleMasterPhase2Form(payloadFinal, token);

      if (response.statusCode === 200) {
        setShowLoader(false);
        swal({
          title: "Success",
          text: "Saved Successfully",
          icon: "success",
        });

        setFormData({
          aggregatorName: "NA",
          bankName: "NA",
          schemeName: "NA",
          switchName: "NA",
          masterMerchantName: "NA",
          remark: "",
        });

        setSelectedType({
          vehicleId: "",
        });
      } else {
        setShowLoader(false);

        swal({
          title: "Failed",
          text: "Vehicle Master Form Not Saved",
          icon: "error",
        });
      }
    } catch (error) {
      setShowLoader(false);

      console.log(error);
    }
  };

  return (
    <>
      <Card className="shadow border-0 mb-4">
        <Card.Body>
          <div className="merchant-report-view  ">
            <div className="report-table-view">
              <div className="row mt-4 ">
                <div className="col-md-6 offset mt-2">
                  <div className="col-sm-10 col-md-8  offset-md-3">
                    <DropDowns
                      fieldName={"Vehicle"}
                      Name={"vehicleId"}
                      HandleChange={handleTypeChange}
                      SelectedType={selectedType.vehicleId}
                      AllTypes={vehicleData}
                    />
                  </div>
                </div>

                <div className="col-md-6 offset mt-2">
                  <div className="col-sm-10 col-md-8  offset-md-3">
                    <ShowField
                      fieldName={"Aggregator"}
                      name="aggregatorId"
                      value={formData.aggregatorName}
                    />
                  </div>
                </div>

                <div className="col-md-6 offset mt-2">
                  <div className="col-sm-10 col-md-8  offset-md-3">
                    <ShowField
                      fieldName={"Scheme"}
                      name="schemeId"
                      value={formData.schemeName}
                    />
                  </div>
                </div>

                <div className="col-md-6 offset mt-2">
                  <div className="col-sm-10 col-md-8  offset-md-3">
                    <ShowField
                      fieldName={"Master Merchant"}
                      name="masterMerchantId"
                      value={formData.masterMerchantName}
                    />
                  </div>
                </div>

                <div className="col-md-6 offset mt-2">
                  <div className="col-sm-10 col-md-8  offset-md-3">
                    <ShowField
                      fieldName={"Bank"}
                      name="bankId"
                      value={formData.bankName}
                    />
                  </div>
                </div>
                <div className="col-md-6 offset mt-2">
                  <div className="col-sm-10 col-md-8  offset-md-3">
                    <ShowField
                      fieldName={"Remark"}
                      name="remark"
                      value={formData.remark}
                    />
                  </div>
                </div>

                <div className="col-md-6 offset">
                  <div className="col-sm-10 col-md-8 offset-md-3">
                    <ShowField
                      fieldName={"Switch"}
                      name="switchId"
                      value={formData.switchName}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {formData.schemeName !== "NA" ? (
            <div className=" merchant-report-view mt-4  px-5">
              <Row className="d-flex flex-column align-items-center px-3">
                {paymentSubType?.map((service) => (
                  <Col
                    className="w-100  mt-3"
                    xs={8}
                    lg={4}
                    md={3}
                    key={service.paymentSubTypeId}
                  >
                    <div className="form-group d-flex align-items-center justify-content-evenly  ">
                      <label className="w-25 form-label">
                        {`${formData.schemeName} (${service.type})`}
                        <sup className="text-danger">*</sup>
                      </label>
                      <div className="w-25 px-2">
                        <lable>Intercharge Fee Fix</lable>
                        <input
                          type="number"
                          placeholder="Fixed Amount"
                          className="form-control"
                          value={
                            SchemeMDRData?.findIndex(
                              (item) =>
                                item.serviceId === service.paymentSubTypeId
                            ) !== -1
                              ? SchemeMDRData?.find(
                                  (item) =>
                                    item.serviceId === service.paymentSubTypeId
                                ).fixAmount
                              : ""
                          }
                          onChange={(e) =>
                            handleMDRDataForScheme(
                              service.paymentSubservice,
                              "fixAmount",
                              e.target.value,
                              service.paymentSubTypeId
                            )
                          }
                        />
                      </div>
                      <div className="w-25 px-2">
                        <lable>Intercharge Fee Percentage</lable>
                        <input
                          type="number"
                          placeholder="Percentage"
                          className="form-control  "
                          value={
                            SchemeMDRData?.findIndex(
                              (item) =>
                                item.serviceId === service.paymentSubTypeId
                            ) !== -1
                              ? SchemeMDRData?.find(
                                  (item) =>
                                    item.serviceId === service.paymentSubTypeId
                                ).percentage
                              : ""
                          }
                          onChange={(e) =>
                            handleMDRDataForScheme(
                              service.paymentSubservice,
                              "percentage",
                              e.target.value,
                              service.paymentSubTypeId
                            )
                          }
                          required
                        />
                      </div>
                      {/* <div className="w-25 px-2">
                        <lable>Merchant Fee Fix</lable>
                        <input
                          type="number"
                          placeholder="Merchant Fee Fix"
                          className="form-control"
                          value={
                            SchemeMDRData?.findIndex(
                              (item) =>
                                item.serviceId === service.paymentSubTypeId
                            ) !== -1
                              ? SchemeMDRData?.find(
                                  (item) =>
                                    item.serviceId === service.paymentSubTypeId
                                ).MerchantFix
                              : ""
                          }
                          onChange={(e) =>
                            handleMDRDataForScheme(
                              service.paymentSubservice,
                              "MerchantFix",
                              e.target.value,
                              service.paymentSubTypeId
                            )
                          }
                          required
                        />
                      </div>
                      <div className="w-25 px-2">
                        <lable>Merchant Percentage</lable>
                        <input
                          type="number"
                          placeholder="Merchant Percentage"
                          className="form-control"
                          value={
                            SchemeMDRData?.findIndex(
                              (item) =>
                                item.serviceId === service.paymentSubTypeId
                            ) !== -1
                              ? SchemeMDRData?.find(
                                  (item) =>
                                    item.serviceId === service.paymentSubTypeId
                                ).MerchantPercentage
                              : ""
                          }
                          onChange={(e) =>
                            handleMDRDataForScheme(
                              service.paymentSubservice,
                              "MerchantPercentage",
                              e.target.value,
                              service.paymentSubTypeId
                            )
                          }
                          required
                        />
                      </div> */}
                      <span id="regAddErr" className="text-danger d-none">
                        Please Enter Registered Address*
                      </span>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          ) : null}
          {formData.bankName !== "NA" ? (
            <div className=" merchant-report-view mt-4  px-5">
              <Row className="d-flex flex-column align-items-center px-3">
                {paymentSubType?.map((service) => (
                  <Col
                    className="w-100   mt-3"
                    xs={8}
                    lg={4}
                    md={3}
                    key={service.paymentSubTypeId}
                  >
                    <div className="form-group d-flex align-items-center justify-content-evenly  ">
                      <label className="w-25 form-label">
                        {`${formData.bankName} (${service.type})`}
                        <sup className="text-danger">*</sup>
                      </label>
                      <div className="w-25 px-2">
                        <lable>Nodal Fee Fix</lable>
                        <input
                          type="number"
                          placeholder="Fixed Amount"
                          className="form-control"
                          value={
                            MDRData?.findIndex(
                              (item) =>
                                item.serviceId === service.paymentSubTypeId
                            ) !== -1
                              ? MDRData?.find(
                                  (item) =>
                                    item.serviceId === service.paymentSubTypeId
                                ).fixAmount
                              : ""
                          }
                          onChange={(e) =>
                            handleMDRDataForBank(
                              service.paymentSubservice,
                              "fixAmount",
                              e.target.value,
                              service.paymentSubTypeId
                            )
                          }
                          required
                        />
                      </div>
                      <div className="w-25 px-2">
                        <lable>Nodal Fee Percentage</lable>
                        <input
                          type="number"
                          placeholder="Percentage"
                          className="form-control  "
                          value={
                            MDRData?.findIndex(
                              (item) =>
                                item.serviceId === service.paymentSubTypeId
                            ) !== -1
                              ? MDRData?.find(
                                  (item) =>
                                    item.serviceId === service.paymentSubTypeId
                                ).percentage
                              : ""
                          }
                          onChange={(e) =>
                            handleMDRDataForBank(
                              service.paymentSubservice,
                              "percentage",
                              e.target.value,
                              service.paymentSubTypeId
                            )
                          }
                          required
                        />
                      </div>
                      {/* <div className="w-25 px-2">
                        <lable>Merchant Fee Fix</lable>
                        <input
                          type="number"
                          placeholder="Merchant Fee Fix"
                          className="form-control"
                          value={
                            MDRData?.findIndex(
                              (item) =>
                                item.serviceId === service.paymentSubTypeId
                            ) !== -1
                              ? MDRData?.find(
                                  (item) =>
                                    item.serviceId === service.paymentSubTypeId
                                ).MerchantFix
                              : ""
                          }
                          onChange={(e) =>
                            handleMDRDataForBank(
                              service.paymentSubservice,
                              "MerchantFix",
                              e.target.value,
                              service.paymentSubTypeId
                            )
                          }
                          required
                        />
                      </div>
                      <div className="w-25 px-2">
                        <lable>Merchant Fee Percentage</lable>
                        <input
                          type="number"
                          placeholder="Merchant Percentage"
                          className="form-control"
                          value={
                            MDRData?.findIndex(
                              (item) =>
                                item.serviceId === service.paymentSubTypeId
                            ) !== -1
                              ? MDRData?.find(
                                  (item) =>
                                    item.serviceId === service.paymentSubTypeId
                                ).MerchantPercentage
                              : ""
                          }
                          onChange={(e) =>
                            handleMDRDataForBank(
                              service.paymentSubservice,
                              "MerchantPercentage",
                              e.target.value,
                              service.paymentSubTypeId
                            )
                          }
                          required
                        />
                      </div> */}
                      <span id="regAddErr" className="text-danger d-none">
                        Please Enter Registered Address*
                      </span>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          ) : null}
          {formData.switchName !== "NA" ? (
            <div className=" merchant-report-view mt-4  px-5">
              <Row className="d-flex flex-column align-items-center px-3">
                {paymentSubType?.map((service) => (
                  <Col
                    className="w-100   mt-3"
                    xs={8}
                    lg={4}
                    md={3}
                    key={service.paymentSubTypeId}
                  >
                    <div className="form-group d-flex align-items-center justify-content-evenly  ">
                      <label className="w-25 form-label">
                        {`${formData.switchName} (${service.type})`}
                        <sup className="text-danger">*</sup>
                      </label>
                      <div className="w-25 px-2">
                        <lable>Processing Fee Fix</lable>
                        <input
                          type="number"
                          placeholder="Fixed Amount"
                          className="form-control"
                          value={
                            SwitchMDRData?.findIndex(
                              (item) =>
                                item.serviceId === service.paymentSubTypeId
                            ) !== -1
                              ? SwitchMDRData?.find(
                                  (item) =>
                                    item.serviceId === service.paymentSubTypeId
                                ).fixAmount
                              : ""
                          }
                          onChange={(e) =>
                            handleMDRDataSwitch(
                              service.paymentSubservice,
                              "fixAmount",
                              e.target.value,
                              service.paymentSubTypeId
                            )
                          }
                          required
                        />
                      </div>
                      <div className="w-25 px-2">
                        <lable>Processing Fee Percentage</lable>
                        <input
                          type="number"
                          placeholder="Percentage"
                          className="form-control  "
                          value={
                            SwitchMDRData?.findIndex(
                              (item) =>
                                item.serviceId === service.paymentSubTypeId
                            ) !== -1
                              ? SwitchMDRData?.find(
                                  (item) =>
                                    item.serviceId === service.paymentSubTypeId
                                ).percentage
                              : ""
                          }
                          onChange={(e) =>
                            handleMDRDataSwitch(
                              service.paymentSubservice,
                              "percentage",
                              e.target.value,
                              service.paymentSubTypeId
                            )
                          }
                          required
                        />
                      </div>
                      {/* <div className="w-25 px-2">
                        <lable>Merchant Fee Fix</lable>
                        <input
                          type="number"
                          placeholder="Merchant Fee Fix"
                          className="form-control"
                          value={
                            SwitchMDRData?.findIndex(
                              (item) =>
                                item.serviceId === service.paymentSubTypeId
                            ) !== -1
                              ? SwitchMDRData?.find(
                                  (item) =>
                                    item.serviceId === service.paymentSubTypeId
                                ).MerchantFix
                              : ""
                          }
                          onChange={(e) =>
                            handleMDRDataSwitch(
                              service.paymentSubservice,
                              "MerchantFix",
                              e.target.value,
                              service.paymentSubTypeId
                            )
                          }
                          required
                        />
                      </div>
                      <div className="w-25 px-2">
                        <lable>Merchant Percentage</lable>
                        <input
                          type="number"
                          placeholder="Merchant Percentage"
                          className="form-control"
                          value={
                            SwitchMDRData?.findIndex(
                              (item) =>
                                item.serviceId === service.paymentSubTypeId
                            ) !== -1
                              ? SwitchMDRData?.find(
                                  (item) =>
                                    item.serviceId === service.paymentSubTypeId
                                ).MerchantPercentage
                              : ""
                          }
                          onChange={(e) =>
                            handleMDRDataSwitch(
                              service.paymentSubservice,
                              "MerchantPercentage",
                              e.target.value,
                              service.paymentSubTypeId
                            )
                          }
                          required
                        />
                      </div> */}
                      <span id="regAddErr" className="text-danger d-none">
                        Please Enter Registered Address*
                      </span>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          ) : null}

          {formData.aggregatorName !== "NA" ? (
            <div className=" merchant-report-view mt-4  px-5">
              <Row className="d-flex flex-column align-items-center px-3">
                {paymentSubType?.map((service) => (
                  <Col
                    className="w-100   mt-3"
                    xs={8}
                    lg={4}
                    md={3}
                    key={service.paymentSubTypeId}
                  >
                    <div className="form-group d-flex align-items-center justify-content-evenly  ">
                      <label className="w-25 form-label">
                        {`${formData.aggregatorName} (${service.type})`}
                        <sup className="text-danger">*</sup>
                      </label>
                      <div className="w-25 px-2">
                        <lable>Platform Fee Fix</lable>
                        <input
                          type="number"
                          placeholder="Fixed Amount"
                          className="form-control"
                          value={
                            AggregatorMDRData?.findIndex(
                              (item) =>
                                item.serviceId === service.paymentSubTypeId
                            ) !== -1
                              ? AggregatorMDRData?.find(
                                  (item) =>
                                    item.serviceId === service.paymentSubTypeId
                                ).fixAmount
                              : ""
                          }
                          onChange={(e) =>
                            handleMDRDataForAggregator(
                              service.paymentSubservice,
                              "fixAmount",
                              e.target.value,
                              service.paymentSubTypeId
                            )
                          }
                          required
                        />
                      </div>
                      <div className="w-25 px-2">
                        <lable>Platform Fee Percentage</lable>
                        <input
                          type="number"
                          placeholder="Percentage"
                          className="form-control  "
                          value={
                            AggregatorMDRData?.findIndex(
                              (item) =>
                                item.serviceId === service.paymentSubTypeId
                            ) !== -1
                              ? AggregatorMDRData?.find(
                                  (item) =>
                                    item.serviceId === service.paymentSubTypeId
                                ).percentage
                              : ""
                          }
                          onChange={(e) =>
                            handleMDRDataForAggregator(
                              service.paymentSubservice,
                              "percentage",
                              e.target.value,
                              service.paymentSubTypeId
                            )
                          }
                          required
                        />
                      </div>
                      {/* <div className="w-25 px-2">
                        <lable>Merchant Fee Fix</lable>
                        <input
                          type="number"
                          placeholder="Merchant Fee Fix"
                          className="form-control"
                          value={
                            AggregatorMDRData?.findIndex(
                              (item) =>
                                item.serviceId === service.paymentSubTypeId
                            ) !== -1
                              ? AggregatorMDRData?.find(
                                  (item) =>
                                    item.serviceId === service.paymentSubTypeId
                                ).MerchantFix
                              : ""
                          }
                          onChange={(e) =>
                            handleMDRDataForAggregator(
                              service.paymentSubservice,
                              "MerchantFix",
                              e.target.value,
                              service.paymentSubTypeId
                            )
                          }
                          required
                        />
                      </div>
                      <div className="w-25 px-2">
                        <lable>Merchant Percentage</lable>
                        <input
                          type="number"
                          placeholder="Merchant Percentage"
                          className="form-control"
                          value={
                            AggregatorMDRData?.findIndex(
                              (item) =>
                                item.serviceId === service.paymentSubTypeId
                            ) !== -1
                              ? AggregatorMDRData?.find(
                                  (item) =>
                                    item.serviceId === service.paymentSubTypeId
                                ).MerchantPercentage
                              : ""
                          }
                          onChange={(e) =>
                            handleMDRDataForAggregator(
                              service.paymentSubservice,
                              "MerchantPercentage",
                              e.target.value,
                              service.paymentSubTypeId
                            )
                          }
                          required
                        />
                      </div> */}
                      <span id="regAddErr" className="text-danger d-none">
                        Please Enter Registered Address*
                      </span>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          ) : null}
          {formData.masterMerchantName !== "NA" ? (
            <div className=" merchant-report-view mt-4  px-5">
              <Row className="d-flex flex-column align-items-center px-3">
                {paymentSubType?.map((service) => (
                  <Col
                    className="w-100  mt-3"
                    xs={8}
                    lg={4}
                    md={3}
                    key={service.paymentSubTypeId}
                  >
                    <div className="form-group d-flex align-items-center justify-content-evenly  ">
                      <label className="w-25 form-label">
                        {`${formData.masterMerchantName} (${service.type})`}
                        <sup className="text-danger">*</sup>
                      </label>
                      <div className="w-25 px-2">
                        <lable>Merchant Fee Fix</lable>
                        <input
                          type="number"
                          placeholder="Fixed Amount"
                          className="form-control"
                          value={
                            MerchantMDRData?.findIndex(
                              (item) =>
                                item.serviceId === service.paymentSubTypeId
                            ) !== -1
                              ? MerchantMDRData?.find(
                                  (item) =>
                                    item.serviceId === service.paymentSubTypeId
                                ).fixAmount
                              : ""
                          }
                          onChange={(e) =>
                            handleMDRDataForMerchant(
                              service.paymentSubservice,
                              "fixAmount",
                              e.target.value,
                              service.paymentSubTypeId
                            )
                          }
                          required
                        />
                      </div>
                      <div className="w-25 px-2">
                        <lable>Merchant Fee Percentage</lable>
                        <input
                          type="number"
                          placeholder="Percentage"
                          className="form-control  "
                          value={
                            MerchantMDRData?.findIndex(
                              (item) =>
                                item.serviceId === service.paymentSubTypeId
                            ) !== -1
                              ? MerchantMDRData?.find(
                                  (item) =>
                                    item.serviceId === service.paymentSubTypeId
                                ).percentage
                              : ""
                          }
                          onChange={(e) =>
                            handleMDRDataForMerchant(
                              service.paymentSubservice,
                              "percentage",
                              e.target.value,
                              service.paymentSubTypeId
                            )
                          }
                          required
                        />
                      </div>
                      {/* <div className="w-25 px-2">
                        <lable>Merchant Fee Fix</lable>
                        <input
                          type="number"
                          placeholder="Merchant Fee Fix"
                          className="form-control"
                          value={
                            SchemeMDRData?.findIndex(
                              (item) =>
                                item.serviceId === service.paymentSubTypeId
                            ) !== -1
                              ? SchemeMDRData?.find(
                                  (item) =>
                                    item.serviceId === service.paymentSubTypeId
                                ).MerchantFix
                              : ""
                          }
                          onChange={(e) =>
                            handleMDRDataForScheme(
                              service.paymentSubservice,
                              "MerchantFix",
                              e.target.value,
                              service.paymentSubTypeId
                            )
                          }
                          required
                        />
                      </div>
                      <div className="w-25 px-2">
                        <lable>Merchant Percentage</lable>
                        <input
                          type="number"
                          placeholder="Merchant Percentage"
                          className="form-control"
                          value={
                            SchemeMDRData?.findIndex(
                              (item) =>
                                item.serviceId === service.paymentSubTypeId
                            ) !== -1
                              ? SchemeMDRData?.find(
                                  (item) =>
                                    item.serviceId === service.paymentSubTypeId
                                ).MerchantPercentage
                              : ""
                          }
                          onChange={(e) =>
                            handleMDRDataForScheme(
                              service.paymentSubservice,
                              "MerchantPercentage",
                              e.target.value,
                              service.paymentSubTypeId
                            )
                          }
                          required
                        />
                      </div> */}
                      <span id="regAddErr" className="text-danger d-none">
                        Please Enter Registered Address*
                      </span>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          ) : null}

          <div className="d-flex justify-content-center mt-4">
            <button className="btn btn-primary" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default VehicleMasterFormPhase2;