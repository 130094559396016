import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PieChart, Pie, LineChart, Cell, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import "./assets/css/style.css";
import { SWIPELINC_API } from './utils/constant';
import { useSelector } from 'react-redux';

const Dashboard = () => {
  const [rejectedMerchants, setRejectedMerchants] = useState([]);
  const [submittedMerchants, setSubmittedMerchants] = useState([]);
  const [allMerchants, setAllMerchants] = useState([]);
  // const [dashboardData, setDashboardData] = useState(null);
  const [showDashboardData, setShowDashboardData] = useState(false);
  const [dashboardPieData, setDashboardPieData] = useState(null);
  const [dashboardTrendData, setDashboardTrendData] = useState(null);
  const [showPieChart, setShowPieChart] = useState(true);
  const [showTrendChart, setShowTrendChart] = useState(true);
  const [totalTransaction, setTotalTransaction] = useState(null);
  const [totalTransactionAmount, setTotalTransactionAmount] = useState(null);
 

  const token = localStorage.getItem("token");
  const axiosInstance = axios.create({
    baseURL: `${SWIPELINC_API}MerchantAdminPanel/merchant/dashboard`,
    headers: {
      Authentication: `Bearer ${token}`
    }
  })

  const fetchRejectedMerchants = () => {
    axiosInstance
      .get("/allrejectedmerchant")
      .then((response) => {
        setRejectedMerchants(response.data);
      })
      .catch(error => {
        console.error('Error fetching rejected merchants:', error);
      });
  };
  const fetchSubmittedMerchants = () => {
    axiosInstance
      .get("/allsubmittedmerchant")
      .then((response) => {
        setSubmittedMerchants(response.data);
      })
      .catch(error => {
        console.error('Error fetching submitted merchants:', error);
      });
  };
  const fetchAllMerchants = () => {
    axiosInstance
      .get("/getallmerchant")
      .then((response) => {
        setAllMerchants(response.data);
      })
      .catch(error => {
        console.error('Error fetching all merchants:', error);
      });
  };
  const fetchshowDashboardData = () => {
    axiosInstance
      .get("/getdashboard")
      .then((response) => {
        setShowDashboardData(response.data.responseData);
      })
      .catch(error => {
        console.error('Error fetching dashboard data:', error);
      });
  };
  const fetchDashboardPieData = () => {
    axiosInstance
      .get("/getdashboardpiechart")
      .then((response) => {
        setDashboardPieData(response.data.responseData);
      
      })
      .catch(error => {
        console.error('Error fetching dashboard pie data:', error);
      });
  };

  const fetchDashboardTrendData = () => {
    axiosInstance
      .get("/getdashboardTrendchart")
      .then((response) => {
        setDashboardTrendData(response.data.responseData);
      })
      .catch(error => {
        console.error('Error fetching dashboard trend data:', error);
      });
  };

  useEffect(() => {
    fetchshowDashboardData();
    fetchDashboardPieData();
    fetchDashboardTrendData();
  }, []);

  const pieChartLabels = dashboardPieData ? dashboardPieData.map(item => item.category) : [];
  const pieChartData = dashboardPieData ? dashboardPieData.map(item => item.totalCount) : [];
  const COLORS = ['#82ca9d', '#FFc658'];
  const pieChartDataConfig = {
    labels: pieChartLabels,
    datasets: [
      {
        data: pieChartData,

        backgroundColor: [
          //'rgba(255, 99, 132, 0.6)', // Red
          // 'rgba(54, 162, 235, 0.6)', // Blue
          // 'rgba(255, 206, 86, 0.6)', // Yellow
          // 'rgba(75, 192, 192, 0.6)', // Green
          // 'rgba(153, 102, 255, 0.6)', // Purple
          // 'rgba(255, 159, 64, 0.6)' // Orange
          // Add more colors if needed
        ],
      }
    ]
  };

  const trendChartLabels = dashboardTrendData ? dashboardTrendData.map(item => item.date) : [];
  const trendChartSuccessData = dashboardTrendData ? dashboardTrendData.map(item => item.successCount) : [];
  const trendChartFailedData = dashboardTrendData ? dashboardTrendData.map(item => item.failedCount) : [];

  const trendChartDataConfig = {
    labels: trendChartLabels,
    datasets: [
      {
        label: 'Success Count',
        data: trendChartSuccessData,
        fill: false,
        borderColor: 'rgba(75, 192, 192, 1)', // Green
        tension: 0.1
      },
      {
        label: 'Failed Count',
        data: trendChartFailedData,
        fill: false,
        borderColor: 'rgba(255, 99, 132, 1)', // Red
        tension: 0.1
      }
    ]
  };

  const togglePieChart = () => {
    setShowPieChart(!showPieChart);
  };

  const toggleTrendChart = () => {
    setShowTrendChart(!showTrendChart);
  };

  const fetchTotalTransaction = () => {
    axiosInstance
      .get("/totaltransection")
      .then((response) => {
        setTotalTransaction(response.data.responseData);
      })
      .catch(error => {
        console.error('Error fetching total transaction:', error);
      });
  };
  const fetchTotalTransactionAmount = () => {
    axiosInstance
      .get("/totaltransectionamount")
      .then((response) => {
        setTotalTransactionAmount(response.data.responseData);
      })
      .catch(error => {
        console.error('Error fetching total transaction amount:', error);
      });
  };
  const toggleDashboardData = () => {
    setShowDashboardData(!showDashboardData);
  };
  return (
    <>

      {/* <div style={{ marginTop: '20px' }}> */}
      <main className="dashboardPage" style={{ marginLeft: "0%" }}>
        {/* 
        <div className="card mb-3">
          <div className="card-body d-flex flex-wrap justify-content-center">

            <button
              type="button"
              className="btn btn-dark btn-sm m-1 "
              onClick={fetchRejectedMerchants}
            >
              <h6>Show Rejected Merchants</h6>
            </button>

            <button type="button"
              className="btn btn-dark btn-sm m-1 "
              onClick={fetchSubmittedMerchants}>
              <h6>Show Submitted Merchants</h6>
            </button>

            <button type="button"
              className="btn btn-dark btn-sm m-1 "
              onClick={fetchAllMerchants}>
              <h6>Show All Merchants </h6>
            </button>

            <button type='button'
              className="btn btn-dark btn-sm m-1 "
              onClick={toggleDashboardData}>
              <h6>Dashboard Data</h6>
            </button>

            <button type='button'
              className="btn btn-dark btn-sm m-1  "
              onClick={fetchDashboardPieData}>
              <h6>Dashboard Pie Data</h6>
            </button>

            <button type='button'
              className="btn btn-dark btn-sm m-1 "
              onClick={fetchDashboardTrendData}>
              <h6>Dashboard Trend Chart</h6>
            </button>

            <button type='button'
              className="btn btn-dark btn-sm m-1 "
              onClick={fetchTotalTransaction}>
              <h6>Total Transactions</h6></button>

            <button type='button'
              className="btn btn-dark btn-sm m-1 "
              onClick={fetchTotalTransactionAmount}>
              <h6>Total Transaction Amount</h6></button>

          </div>
        </div> */}

        {/* designing part:    */}

        {/* {showDashboardData && showDashboardData.filterTxnList.length > 0 && (
          <div>
            <h2 className='heading'>Dashboard Data:</h2>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Total Count </th>
                    <th>Total Amount</th>
                    <th>Success Count</th>
                    <th>Failure Count</th>
                  </tr>
                </thead>
                <tbody>
                  {showDashboardData.filterTxnList.map((txn, index) => (
                    <tr key={index}>
                      <td>{txn.totalCount}</td>
                      <td>{txn.totalAmt}</td>
                      <td>{txn.successCount}</td>
                      <td>{txn.failureCount}</td>

                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )} */}



        {/* {showDashboardData && showDashboardData.filterTxnList.length > 0 && (
          <div>
            <h2 className='heading'>Filtered Transaction List:</h2>
            <div className="table-responsive">
              <table className="table table-striped">
            <thead>
              <tr>
                <th>Date</th>
                <th>Total Count</th>
                <th>Total Amount</th>
                <th>Success Count</th>
                <th>Failure Count</th>
              </tr>
            </thead>
            <tbody>
              {showDashboardData.filterTxnList.map((txn, index) => (
                <tr key={index}>
                  <td>{txn.date}</td>
                  <td>{txn.totalCount}</td>
                  <td>{txn.totalAmt}</td>
                  <td>{txn.successCount}</td>
                  <td>{txn.failureCount}</td>
                </tr>
              ))}
              </tbody>
          </table>
            </div>
          </div>
        )} */}

        {rejectedMerchants.length > 0 && (
          <div>
            <h2 className='heading'>Rejected Merchants:</h2>
            <table>
              <thead>
                <tr>
                  <th>Merchant Name</th>
                  <th>Status</th>
                  {/* Add more table headers as needed */}
                </tr>
              </thead>
              <tbody>
                {rejectedMerchants.map(merchant => (
                  <tr key={merchant.id}>
                    <td>{merchant.name}</td>
                    <td>{merchant.status}</td>
                    {/* Add more table cells as needed */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {submittedMerchants.length > 0 && (
          <div>
            <h2 className='heading'>Submitted Merchants:</h2>
            <table>
              <thead>
                <tr>
                  <th>Merchant Name</th>
                  <th>Status</th>
                  {/* Add more table headers as needed */}
                </tr>
              </thead>
              <tbody>
                {submittedMerchants.map(merchant => (
                  <tr key={merchant.id}>
                    <td>{merchant.name}</td>
                    <td>{merchant.status}</td>
                    {/* Add more table cells as needed */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {allMerchants.length > 0 && (
          <div>
            <h2 className='heading'>Total Merchant:</h2>
            <table>
              <thead>
                <tr>
                  <th>Merchant Name</th>
                  <th>Status</th>
                  {/* Add more table headers as needed */}
                </tr>
              </thead>
              <tbody>
                {allMerchants.map(merchant => (
                  <tr key={merchant.id}>
                    <td>{merchant.name}</td>
                    <td>{merchant.status}</td>
                    {/* Add more table cells as needed */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <div className='d-flex  mt-5'>
          {/* DISPLAY PIE CHART: */}
          {showPieChart && dashboardPieData && (
            <div className='w-45 mx-5 d-flex align-items-center flex-column'>
              <h2 className="heading"> Mode Of Payment:</h2>
              <PieChart width={400} height={400} className='mx-5 mb-5 align-self-center'>

                <Pie data={dashboardPieData} dataKey="totalCount" nameKey="category" cx="60%" cy="60%" outerRadius={120} label >
                  {dashboardPieData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
              <div className='d-flex flex-row '>
                <p className='mx-5' style={{ backgroundColor: '#82ca9d', width: '60px', height: '30px' }}></p><small className='fs-bolder'><b>QR</b></small>
              </div>
              <div className='d-flex flex-row '>
              <p className='mx-5' style={{ backgroundColor: '#ffc658', width: '60px', height: '30px' }}></p>
              <small className='fs-bolder'><b>UPI</b></small>
              </div>
            </div>
          )}

          {/* Display trend chart */}
          {showTrendChart && dashboardTrendData && (
            <div className='w-55 mx-5 d-flex align-items-center flex-column '>
              <h2 className="heading mb-5"> Transaction Graph:</h2>
              <LineChart width={700} height={400} className='mt-5 ' data={dashboardTrendData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="successCount" stroke="#01377d" />
                <Line type="monotone" dataKey="failedCount" stroke="#bf0000" />
              </LineChart>
            </div>
          )}
        </div>
        {totalTransaction !== null && (
          <div>
            <h2 className='heading'>Total Transaction:</h2>
            <p>Total Transactions: {totalTransaction}</p>
          </div>
        )}

        {totalTransactionAmount !== null && (
          <div>
            <h2 className='heading'>Total Transaction Amount:</h2>
            <p>Total Transaction Amount: {totalTransactionAmount}</p>
          </div>
        )}
        {rejectedMerchants.length === 0 &&
          submittedMerchants.length === 0 &&
          allMerchants.length === 0 &&
          (!showDashboardData || showDashboardData.filterTxnList.length === 0) &&
          (!dashboardPieData || dashboardPieData.length === 0) &&
          !  !totalTransaction &&
          (
            <p>No data to display.</p>
          )}
      </main>
    </>
  );
};

export default Dashboard;