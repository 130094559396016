import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import InactiveServices from '../components/InactiveServices';
import SIDMaster from './SIDMaster';
import SubmittedPayoutSIDApplications from './SubmittedPayoutSIDApplications';
import PayoutMaster from './PayoutMaster';
import Disabled from './Disabled';

const SubTabForPayoutMaster = ({setShowLoader}) => {
    const [activePhase, setActivePhase] = useState("Phase1");

    useEffect(() => {
      setActivePhase("Phase1");
    }, []);
  
    const handlePhaseClick = (phase) => {
      setActivePhase(phase);
    };
  

  return (
    <>
    <section className="dashboard-header-sec subtab-card">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-3  ds-inner-card">
                  <Card.Body>
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn submenu-tab-admin">
                        <li
                          className={activePhase === "Phase1" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase1")}
                        >
                          Payout Master Form {activePhase === "Phase1"}
                        </li>
                        <li
                          className={activePhase === "Phase2" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase2")}
                        >
                       Submitted {activePhase === "Phase2"}
                        </li>                       
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
                {/* ./card */}
              </Col>
            </Row>
            <div className="admin-tab-content">
              {activePhase === "Phase1" ? (
               
                <PayoutMaster
                setShowLoader = {setShowLoader} />
              ) : activePhase === "Phase2" ? (
                
                <SubmittedPayoutSIDApplications
                setShowLoader = {setShowLoader} />
              ) :activePhase === "Phase3" ? (
                
                <Disabled
                setShowLoader = {setShowLoader} />
              ) : (
                <InactiveServices location={"privils"}/>
              )}
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}


export default SubTabForPayoutMaster