import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import InactiveServices from "../../components/InactiveServices";
import Payoutacknowledgment from "./Payoutacknowledgment";
import Payinacknowledgment from "./Payinacknowledgment";


const AcknowledgmentTab = ({ setShowLoader }) => {
  const [activePhase, setActivePhase] = useState("Phase1");

 
 const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  return (
    <>
      <Card className="shadow border-0 mb-3  ds-inner-card">
        <Card.Body>
          <div className="merchant-tab-btn">
            <ul className="report-link nav-report-btn submenu-tab-admin">
              <li
                className={activePhase === "Phase1" ? "active" : ""}
                onClick={() => handlePhaseClick("Phase1")}
              >
                Payin {activePhase === "Phase1"}
              </li>
              <li
                className={activePhase === "Phase2" ? "active" : ""}
                onClick={() => handlePhaseClick("Phase2")}
              >
                Payout {activePhase === "Phase2"}
              </li>
           
            </ul>
          </div>
        </Card.Body>
      </Card>
      <Card className="shadow border-0 mb-3  ds-inner-card">
        <Card.Body>
          <div className="admin-tab-content mat-bt-table">
            {activePhase === "Phase1" ? (
              // <InactiveServices />
              <Payinacknowledgment  setShowLoader={setShowLoader} />
              ) : activePhase === "Phase2" ? (
              <Payoutacknowledgment  setShowLoader={setShowLoader} />
            ) : //  <InactiveServices />
            (
              <InactiveServices location={"privies"}/>
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default AcknowledgmentTab;