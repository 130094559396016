import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import { SWIPELINC_API } from "../../utils/constant";
import { Row } from "react-bootstrap";
import { getMechantForPayoutApiTest, getMechantForPayoutOnBoarding } from "../../utils/ApiServices";
import { useSelector } from "react-redux";

function PayoutRoutingOnBording({location, setShowLoader }) {
  const [merchantData, setMerchantData] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState("");
  const [selectedMid, setSelectedMid] = useState("");
  const [companyData, setCompanyData] = useState([]);
  const [route,setRoute] = useState ("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [returnUrl, setReturnUrl] = useState("");
  const [companyMid, setCompanyMid] = useState("");
  const [transferType, setTransferType] = useState("IMPS");
  const [callbackUrl, setCallbackUrl] = useState("");
  const [callbackUrlValue, setCallbackUrlValue] = useState("");
  const token = localStorage.getItem("token");

  const getAllMerchantForApiTest = async () => {
    setShowLoader(true);
    let res = await getMechantForPayoutApiTest(token);
    setShowLoader(false);
    setMerchantData(res.data);
  };

  const {privilagesData} = useSelector(state=>state?.privilages)

  const getAllMerchantForOnBoardingRoutingPayout= async () => {
    setShowLoader(true);
    let res = await getMechantForPayoutOnBoarding(token);
    setShowLoader(false);
    setMerchantData(res.data);
  };

  useState(() => {
   
      getAllMerchantForOnBoardingRoutingPayout()
    
  }, []);

  const getVirtualAccountData = (type) => {
    setShowLoader(true);
    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/merchantPayoutMaster/getAllMerchantPayoutMasterDistinctData/${type}`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          setCompanyData(response?.data?.data);
        } else setCompanyData();
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setShowLoader(false);
      });
  };

  useEffect(() => {
    getVirtualAccountData("IMPS");
  }, []);

  const handleSavePayoutWithdrawal = () => {
    if (
      selectedMerchant === "" ||
      callbackUrl === "" ||
      selectedCompany === "" ||
      transferType === ""
    ) {
      swal(
        "Error",
        "Please select a Merchant and fill in all required details",
        "error"
      );
      return;
    }
    if (callbackUrl === "Api" && callbackUrlValue.trim() === "") {
      swal("Error", "Please enter a Callback URL", "error");
      return;
    }

    const payload = {
      callBackUrl: callbackUrl === "Api" ? callbackUrlValue : "",
      mappedMid: companyMid,
      mid: selectedMid,
      transferType: transferType,
      usedFor: callbackUrl,
      requestRoute:route
    };
    if (selectedMerchant) {
      const selectedMerchantData = merchantData.find(
        (merchant) => merchant.merchantName === selectedMerchant
      );
      if (selectedMerchantData && selectedMerchantData.payoutCallBackUrl) {
        setReturnUrl(selectedMerchantData.payoutCallBackUrl);
      }
    }

    setShowLoader(true);
    axios
      .post(
        `${SWIPELINC_API}MerchantAdminPanel/payoutRouting/saveMerchantPayoutRouting`,
        payload,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          swal("Success", "Payout saved successfully!", "success");
          setCallbackUrlValue("");
          setSelectedCompany("");
          setTransferType("");
          setCallbackUrl("");
          setSelectedMerchant("");
          setRoute("");
        
            getAllMerchantForOnBoardingRoutingPayout()
        
        } else {
          swal("Error", response.data.message, "error");
        }
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error saving payout :", error);
        setShowLoader(false);
      });
  };

  const handleMerchantChange = (event) => {
    const selectedMerchant = event.target.value;
    const selectedMerchantData = merchantData?.find(
      (merchant) => merchant.merchantName === selectedMerchant
    );
    if (selectedMerchantData) {
      const { mid, payoutCallBackUrl } = selectedMerchantData;

      setSelectedMerchant(selectedMerchant);
      setSelectedMid(mid);

      if (payoutCallBackUrl) {
        setCallbackUrl("Api");
        setCallbackUrlValue(payoutCallBackUrl);
      } else {
        setCallbackUrl("");
        setCallbackUrlValue("");
      }
    }
  };

  const handleSelectChange = (event) => {
    setCallbackUrl(event.target.value);
  };
  const handleRouteChange = (event) => {
    setRoute(event.target.value);
  };
  const handleCompanyChange = (event) => {
    if (selectedMerchant === "" || callbackUrl === "") {
      swal(
        "Error",
        "Please select a Merchant and choose the purpose (Used For)",
        "error"
      );
      return;
    }

    const selectedCompany = event.target.value;
    const companyMid =
      companyData?.find((company) => company.companyName === selectedCompany)
        ?.mid || "";
    setSelectedCompany(selectedCompany);
    setCompanyMid(companyMid);
    console.log(companyMid);
  };
  return (
    <>
    {(location === "onboarding" && privilagesData.includes(2636)) ||  (location === "tools" && privilagesData.includes(2686)) ? (<section className="lincpay-dashboard-sec merchant-admin-info-form company-add-form card py-3">
        {/* {showLoader ? <MyLoader /> : ""} */}
        <div className="containerflag">
          <div className="row mt-4">
            <div className="col-md-4 offset">
              <div className="col-sm-12 col-md-6 offset-md-3">
                <label htmlFor="email">Select A Merchant</label>
                <select
                  id="companyDropdown"
                  className="form-control"
                  onChange={handleMerchantChange}
                  value={selectedMerchant}
                >
                  <option value="">Select a Merchant</option>
                  {merchantData?.map((merchant) => (
                    <option key={merchant.mid} value={merchant.merchantName}>
                      {merchant.merchantName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="row mt-2">
                <div className="col-sm-12 col-md-6 offset-md-3"></div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="col-sm-12 col-md-6 offset-md-3 mt-2">
                <label htmlFor="email">Access Type</label>
                <select
                  id="routingPatternSelect"
                  className="form-control"
                  value={callbackUrl}
                  onChange={handleSelectChange}
                  name="routingPattern"
                >
                  <option value="">Select</option>
                  <option value="Api">API</option>
                  <option value="Panel">Panel</option>
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div className="col-sm-12 col-md-6 offset-md-3 mt-2">
                <label htmlFor="email">Route With</label>
                <select
                  id="routingPatternSelect"
                  className="form-control"
                  value={route}
                  onChange={handleRouteChange}
                  name="routingPattern"
                >
                  <option value="">Select</option>
                  <option value="DECENTRO">DECENTRO</option>
                  <option value="FINZEN">FINZEN</option>
                  <option value="BRANCHX">BRANCHX</option>
                </select>
              </div>
            </div>
            <Row className="d-flex justify-content-center">
              <div className="w-50 d-flex justify-content-evenly gap-5 mt-5   ">
                <div
                  className={`${
                    transferType === "IMPS"
                      ? "rounded-5 text-light bg-dark "
                      : ""
                  }d-flex justify-content-evenly align-items-center gap-3 p-3 py-2`}
                >
                  <input
                    type="radio"
                    className="form-check-input mb-1 "
                    name="bankDetails"
                    checked={transferType === "IMPS" ? true : false}
                    onChange={() => {
                      setTransferType("IMPS");
                      getVirtualAccountData("IMPS");
                    }}
                  />
                  <label htmlFor="detailsRadio">
                    <span> &nbsp;IMPS </span>
                  </label>
                </div>
                <div
                  className={`${
                    transferType === "UPI"
                      ? "rounded-5 text-light bg-dark "
                      : ""
                  }d-flex justify-content-evenly align-items-center gap-3 p-3 py-2`}
                >
                  <input
                    type="radio"
                    className="form-check-input mb-1 "
                    name="bankDetails"
                    checked={transferType === "UPI" ? true : false}
                    onChange={() => {
                      setTransferType("UPI");
                      getVirtualAccountData("UPI");
                    }}
                  />
                  <label htmlFor="detailsRadio">
                    <span> &nbsp;UPI </span>
                  </label>
                </div>
              </div>
            </Row>

            <div className="row mt-2">
              <div className="col-sm-12 col-md-6 offset-md-3 my-2">
                <label htmlFor="holdAmount">Select Virtual Account</label>

                <select
                  className="form-control"
                  id="holdAmount"
                  value={selectedCompany}
                  onChange={handleCompanyChange}
                >
                  <option value="">Select a Company</option>
                  {companyData?.map((company) => (
                    <option key={company.mid} value={company.companyName}>
                      {company.companyName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-12 col-md-6 offset-md-3">
                {callbackUrl === "Api" && (
                  <div>
                    <label htmlFor="callbackUrl">Callback URL</label>
                    <input
                      type="text"
                      id="callbackUrlValue"
                      name="callbackUrlValue"
                      placeholder="Enter Callback URL"
                      className="form-control"
                      value={callbackUrlValue}
                      onChange={(e) => setCallbackUrlValue(e.target.value)}
                      disabled={returnUrl !== ""}
                    />
                  </div>
                )}
                {/* {returnUrl !== "" && (
            <div>
              <label>Auto-filled Callback URL:</label>
              <p>{returnUrl}</p>
            </div>
          )} */}
              </div>
            </div>

            <div className="d-flex justify-content-center mt-4">
              <button
                className="btn btn-success"
                onClick={handleSavePayoutWithdrawal}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </section>)  : null }
       
    </>
  );
}

export default PayoutRoutingOnBording;
