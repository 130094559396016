import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { SWIPELINC_API } from "../../../utils/constant";
import { Container, Row, Col, Card } from "react-bootstrap";
import "../../../stylesheet/ViewDetails.css";

import { HandleApprovePayout, fetchPayoutDetail } from "../../../utils/ApiServices";

function ViewDetails({ id,setActivePhase, setOpenView,setShowLoader}) {
  const [payoutDetails, setpayoutDetails] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));

useEffect(() => {
    fetchPayoutDetails();
  }, []);

  const fetchPayoutDetails = async () => {
    try {
      setShowLoader(true)
      const response = await fetchPayoutDetail(token,id)

      if (response?.statusCode === 200) {
        setpayoutDetails(response?.data);
        setShowLoader(false)
        console.log("response is:", response.data.data);
      } else {
        console.error("Api response error:", response.data.data);
      }
      setShowLoader(false)
    } catch (error) {
      console.error("Error fetching merchant details:", error);
    }
    setShowLoader(false)
  };

  // HANDLE APPROVE:

  const handleApproveConfirm = () => {
  swal({
      title: "Are you sure?",
      text: "Do you want to approve this application?",
      icon: "warning",
      buttons: ["Cancel", "Yes, approve it!"],
      dangerMode: true,
    }).then((isConfirmed) => {
      if (isConfirmed) {
     const payload = {
          payoutStatus: "Approved",
          id: id,
          remark: "NA",
          requestId: payoutDetails.requestId,
        
        };
        setShowLoader(true)
       const response = HandleApprovePayout(token,payload)
        .then((response) => {
            console.log(response)
            if(response?.statusCode === 200){
              swal("Success", "Application approved!", "success")
              setOpenView(false)
              setShowLoader(false)
            }else if(response?.statusCode === 306){
              swal("Failed", `${response?.data }`, "error")
            }else{
              console.error("Error fetching data:", response.data.message);
            }
            setShowLoader(false)
            
             
          })
          .catch((error) => {
            console.error("Error approving the application:", error.message);
          });
          setShowLoader(false)
      } 
      else {
         }
    });
  };

  // HANDLE REJECT:

  const handleReject = () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to reject this vendor?",
      icon: "warning",
      buttons: ["Cancel", "Yes, reject it!"],
      dangerMode: true,
      content: {
        element: "input",
        attributes: {
          type: "text",
          placeholder: "Enter rejection remark",
        },
      },
    }).then((remark) => {
      if (remark) {
      const payload = {
          payoutStatus: "Rejected",
          id: id,
          remark: remark,
          requestId: payoutDetails.requestId,
        };
        setShowLoader(true)
       const response = HandleApprovePayout(token,payload)
          .then((response) => {
            if(response?.statusCode === 200){
              swal("Success", "Application Rejected!", "success")
             setOpenView(false)
             setShowLoader(false)
            }else{
              console.error("Error fetching data:", response.data.message);
            }
            })
        .catch((error) => {
            console.error("Error rejecting the application:", error.message);
          });
      }
      else {
        
      }
    })
  }

  const handleBack = () => {
    setOpenView(false)
  }

  return (
    <>
   <section className="admin-view-details">
   <Container>
          <Card className="shadow border-0 mb-4 ds-header-card">
            <Card.Body>
              <Row>

                <Col xs={12} xl={12} lg={12} sm={12}>
                  <div className="two-column-layout">
                    <div className="vertical-table">

                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left"> ID</div>
                        <div className="vertical-table-cell-value text-left">
                          {payoutDetails.id}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Vendor Name
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {payoutDetails.fullName}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Merchant Name
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {payoutDetails.companyName}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left"> e-Mail</div>
                        <div className="vertical-table-cell-value text-left">
                          {payoutDetails.email}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          {" "}
                          Category
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {payoutDetails.category}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left"> Mobile</div>
                        <div className="vertical-table-cell-value text-left">
                          {payoutDetails.mobile}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          Bank Name
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {payoutDetails.bankName}
                        </div>
                      </div>

                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          {" "}
                          Account Number
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {payoutDetails.accountNumber}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          {" "}
                          IFSC Code
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {payoutDetails.ifscCode}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          {" "}
                          Branch Name
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {payoutDetails.branchName}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">UPI</div>
                        <div className="vertical-table-cell-value text-left">
                          {payoutDetails.upi}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left"> Amount</div>
                        <div className="vertical-table-cell-value text-left">
                          {payoutDetails.amount}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          {" "}
                          Merchant ID
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {payoutDetails.mid}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left"> Remark</div>
                        <div className="vertical-table-cell-value text-left">
                          {payoutDetails.remark}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          {" "}
                          Payout Status
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {payoutDetails.payoutStatus}
                        </div>
                      </div>
                      <div className="vertical-table-row">
                        <div className="vertical-table-cell  text-left">
                          {" "}
                          request Id
                        </div>
                        <div className="vertical-table-cell-value text-left">
                          {payoutDetails.requestId}
                        </div>
                      </div>

                      <div className="vertical-table-row">
                      <div className="d-flex gap-2">
           <button className="btn btn-success" onClick={() => handleApproveConfirm()} >Approve</button>
            <button className="btn btn-danger" onClick={() => handleReject()}>Reject</button>
                        </div>
                      </div>

                   
                      <div className="button-container">
                      <button className="btn btn-primary" onClick={handleBack}>
                          Go Back
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
}
export default ViewDetails;