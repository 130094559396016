import React from 'react'
 
import InactiveServices from './components/InactiveServices'
function Settings() {
  return (
    
    <>
      <InactiveServices location={"fdhjk"}/>
    </>
  )
}

export default Settings
