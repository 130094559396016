import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { FETCH_PAYMENT_SERVICES_PHASE1_API, SWIPELINC_API } from "../utils/constant";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import swal from "sweetalert";
import "../stylesheet/ViewDetails.css";
import MyLoader from "../common/MyLoader";
import {
  getKeyPressed,
  isIfscRegexIsValid,
  isWebsiteURLRegexIsValid,
  stopAlphabetInput,
  stopCharacterInput,
  stopNumberCharInput,
  stopSpecialCharacterInput,
} from "../utils/helper";
import useCheckPrivilages from "../utils/checkPrivilages";
import { useSelector } from "react-redux";

function OpenDetails({ mid, handleClose }) {
  // console.log("mid isssssssss", mid)
  const [merchantData, setmerchantData] = useState([]);
  const [getAgain, setGetAgain] = useState(false);
  const [again, setAgain] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [updatedDetails, setUpdatedDetails] = useState({ ...merchantData });
  const [showLoader, setShowLoader] = useState(false);
  const [isPhase2Editing, setIsPhase2Editing] = useState(false);
  const [showPaymentServicesList, setShowPaymentServicesList] = useState(false);
  const [pressedKeyName, setPressedKeyName] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [paymentservice, setServices] = useState([]);
  const [phase2Details, setPhase2Details] = useState({ ...merchantData });
  // const { mid } = useParams();
  // const navigate = useNavigate();
  useEffect(() => {
    fetchmerchantDetails(mid);
  }, [getAgain, again, mid]);

  const hasPrivilages2682 = useCheckPrivilages(2682)

  const  reduxData = useSelector((state) => state?.privilages);

  const handelPasteEvent = (event, name) => {
    let pastedText = (event.clipboardData || window.clipboardData).getData(
      "text"
    );

    const addressFieldLength = 200;
    const districtLength = 50;
    const StateLength = 50;
    const cityLength = 50;

    // console.log(updatedDetails.companyAddress.length + pastedText.length);

    if (
      (name === "companyAddress" &&
        updatedDetails &&
        updatedDetails.companyAddress &&
        updatedDetails.companyAddress.length + pastedText.length >
        addressFieldLength) ||
      (name === "registeredOfficeAddress" &&
        updatedDetails &&
        updatedDetails.registeredOfficeAddress &&
        updatedDetails.registeredOfficeAddress.length + pastedText.length >
        addressFieldLength) ||
      (name === "communicationAddress" &&
        updatedDetails &&
        updatedDetails.communicationAddress &&
        updatedDetails.communicationAddress.length + pastedText.length >
        addressFieldLength)
    ) {
      swal({
        title: "Alert!",
        text: `Address must be lesser than ${addressFieldLength} characters`,
        icon: "error",
      });
      return;
    }

    if (
      (name === "companyAddressState" &&
        updatedDetails &&
        updatedDetails.companyAddressState &&
        updatedDetails.companyAddressState.length + pastedText.length >
        StateLength) ||
      (name === "registeredOfficeAddressState" &&
        updatedDetails &&
        updatedDetails.registeredOfficeAddressState &&
        updatedDetails.registeredOfficeAddressState.length + pastedText.length >
        StateLength) ||
      (name === "communicationAddressState" &&
        updatedDetails &&
        updatedDetails.communicationAddressState &&
        updatedDetails.communicationAddressState.length + pastedText.length >
        StateLength)
    ) {
      swal({
        title: "Alert!",
        text: `State must be lesser than ${StateLength} characters`,
        icon: "error",
      });
      return;
    }

    if (
      (name === "companyAddressCity" &&
        updatedDetails &&
        updatedDetails.companyAddressCity &&
        updatedDetails.companyAddressCity.length + pastedText.length >
        cityLength) ||
      (name === "registeredOfficeAddressCity" &&
        updatedDetails &&
        updatedDetails.registeredOfficeAddressCity &&
        updatedDetails.registeredOfficeAddressCity.length + pastedText.length >
        cityLength) ||
      (name === "communicationAddressCity" &&
        updatedDetails &&
        updatedDetails.communicationAddressCity &&
        updatedDetails.communicationAddressCity.length + pastedText.length >
        cityLength)
    ) {
      swal({
        title: "Alert!",
        text: `City must be lesser than ${cityLength} characters`,
        icon: "error",
      });
      return;
    }
  };

  const fetchmerchantDetails = async (mid) => {
    try {
      setShowLoader(true);
      const response = await axios.get(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getByMid/${mid}`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      );

      if (response.data.statusCode === 200) {
        setmerchantData(response?.data?.data);
        const uniqueServices = Array.from(
          new Set(
            response?.data?.data?.paymentService?.map(
              (service) => service.paymentService
            )
          )
        );
        setSelectedServices(uniqueServices);
      } else if (response.data.statusCode === 304) {
        swal({
          title: "Failed",
          text: "Merchant not on-Boarded",
          icon: "error",
        });
        // navigate("/ToolsSection");
      } else {
        swal({
          title: "Failed",
          text: response.data.message,
          icon: "error",
        });
        // navigate("/ToolsSection");
      }
      setShowLoader(false);
    } catch (error) {
      console.error("Error fetching merchant details:", error);
      setShowLoader(false);
      // navigate("/ToolsSection");
    }
  };

  useEffect(() => {
    axios
      .get(
        FETCH_PAYMENT_SERVICES_PHASE1_API
      )
      .then((response) => {
        setServices(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching payment services:", error.message);
      });
  }, []);

  const handleServiceChange = (e) => {
    const found = selectedServices.find((item) => item === e.target.value);
    if (found) {
      setSelectedServices(selectedServices.filter((item) => item !== found));
    } else {
      setSelectedServices([...selectedServices, e.target.value]);
    }
  };
  const toggleEditing = () => {
    if (!isEditing) {
      const paymentTypeIds = selectedServices
        ?.map((service) => {
          const foundService = paymentservice.find(
            (item) => item.paymentService === service
          );
          return foundService ? foundService.paymentTypeId : null;
        })
        .filter((id) => id !== null);

      const updatedDetailsCopy = { ...updatedDetails };
      updatedDetailsCopy.paymentTypeId = paymentTypeIds;
      setUpdatedDetails({
        authorizedPersonName: merchantData.authorizedPersonName,
        aapUrl: merchantData.aapUrl,
        businessTypeName: merchantData.businessTypeName,
        businessAs: merchantData.businessAs,
        commissionPercentage: 0,
        communicationAddress: merchantData.communicationAddress,
        communicationAddressAreaName: merchantData.communicationAddressAreaName,
        communicationAddressCity: merchantData.communicationAddressCity,
        communicationAddressPinCode: merchantData.communicationAddressPinCode,
        communicationAddressState: merchantData.communicationAddressState,
        companyAddress: merchantData.companyAddress,
        companyAddressAreaName: merchantData.companyAddressAreaName,
        companyAddressCity: merchantData.companyAddressCity,
        companyAddressPinCode: merchantData.companyAddressPinCode,
        companyAddressState: merchantData.companyAddressState,
        email: merchantData.email,
        mobile: merchantData.mobile,
        newCompanyAddress: merchantData.newCompanyAddress,
        registeredOfficeAddress: merchantData.registeredOfficeAddress,
        registeredOfficeAddressAreaName:
          merchantData.registeredOfficeAddressAreaName,
        registeredOfficeAddressCity: merchantData.registeredOfficeAddressCity,
        registeredOfficeAddressPinCode:
          merchantData.registeredOfficeAddressPinCode,
        registeredOfficeAddressState: merchantData.registeredOfficeAddressState,
        registrationId: merchantData.registrationId,
        websiteUrl: merchantData.websiteUrl,
        paymentTypeId: paymentTypeIds,
      });
    }
    setIsEditing(!isEditing);
    setIsPhase2Editing(false);
  };

  const handleSave = async () => {
    if (
      updatedDetails.authorizedPersonName === "" ||
      phase2Details.authorizedPersonName === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter authorised person name !",
        icon: "error",
      });
      return;
    }
    if (updatedDetails.mobile === "" || phase2Details.mobile === null) {
      swal({
        title: "Alert!",
        text: "Please enter mobile number !",
        icon: "error",
      });
      return;
    }
    if (merchantData.businessType?.toLowerCase() === "others" && updatedDetails.businessTypeName === ""
    ) {
      swal({
        title: "Alert!",
        text: "Please enter other business category !",
        icon: "error",
      });
      return;
    }

    if (updatedDetails.email === "" || phase2Details.email === null) {
      swal({
        title: "Alert!",
        text: "Please enter email ID !",
        icon: "error",
      });
      return;
    }
    if (
      updatedDetails.companyAddress === "" ||
      phase2Details.companyAddress === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter company registered address !",
        icon: "error",
      });
      return;
    }
    if (
      updatedDetails.companyAddressPinCode === "" ||
      phase2Details.companyAddressPinCode === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter company registered pin-code !",
        icon: "error",
      });
      return;
    }
    if (
      updatedDetails.companyAddressCity === "" ||
      phase2Details.companyAddressCity === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter company registered city !",
        icon: "error",
      });
      return;
    }
    if (
      updatedDetails.companyAddressState === "" ||
      phase2Details.companyAddressState === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter company registered state !",
        icon: "error",
      });
      return;
    }
    if (
      updatedDetails.registeredOfficeAddress === "" ||
      phase2Details.registeredOfficeAddress === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter authorized signatory address !",
        icon: "error",
      });
      return;
    }
    if (
      updatedDetails.registeredOfficeAddressPinCode === "" ||
      phase2Details.registeredOfficeAddressPinCode === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter authorized signatory pincode !",
        icon: "error",
      });
      return;
    }
    if (
      updatedDetails.registeredOfficeAddressCity === "" ||
      phase2Details.registeredOfficeAddressCity === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter authorized signatory city !",
        icon: "error",
      });
      return;
    }
    if (
      updatedDetails.registeredOfficeAddressState === "" ||
      phase2Details.registeredOfficeAddressState === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter authorized signatory state !",
        icon: "error",
      });
      return;
    }
    if (
      updatedDetails.communicationAddress === "" ||
      phase2Details.communicationAddress === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter company communication address !",
        icon: "error",
      });
      return;
    }
    if (
      updatedDetails.communicationAddressPinCode === "" ||
      phase2Details.communicationAddressPinCode === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter company communication pin code !",
        icon: "error",
      });
      return;
    }
    if (
      updatedDetails.communicationAddressCity === "" ||
      phase2Details.communicationAddressCity === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter company communication city !",
        icon: "error",
      });
      return;
    }
    if (
      updatedDetails.communicationAddressState === "" ||
      phase2Details.communicationAddressState === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter company communication state !",
        icon: "error",
      });
      return;
    }

    try {
      setShowLoader(true);
      const paymentTypeIds = selectedServices?.map((service) => {
        const foundService = paymentservice.find(
          (item) => item.paymentService === service
        );
        return foundService.paymentTypeId;
      });

      const updatedDetailsCopy = { ...updatedDetails };
      updatedDetailsCopy.paymentTypeId = paymentTypeIds;

      const payload = {
        authorizedPersonName: updatedDetails.authorizedPersonName,
        aapUrl: updatedDetails.aapUrl,
        businessTypeName: updatedDetails.businessTypeName,
        businessAs: updatedDetails.businessAs,
        commissionPercentage: 0,
        communicationAddress: updatedDetails.communicationAddress,
        communicationAddressAreaName:
          updatedDetails.communicationAddressAreaName,
        communicationAddressCity: updatedDetails.communicationAddressCity,
        communicationAddressPinCode: updatedDetails.communicationAddressPinCode,
        communicationAddressState: updatedDetails.communicationAddressState,
        companyAddress: updatedDetails.companyAddress,
        companyAddressAreaName: updatedDetails.companyAddressAreaName,
        companyAddressCity: updatedDetails.companyAddressCity,
        companyAddressPinCode: updatedDetails.companyAddressPinCode,
        companyAddressState: updatedDetails.companyAddressState,
        email: updatedDetails.email,
        mobile: updatedDetails.mobile,
        newCompanyAddress: updatedDetails.newCompanyAddress,
        registeredOfficeAddress: updatedDetails.registeredOfficeAddress,
        registeredOfficeAddressAreaName:
          updatedDetails.registeredOfficeAddressAreaName,
        registeredOfficeAddressCity: updatedDetails.registeredOfficeAddressCity,
        registeredOfficeAddressPinCode:
          updatedDetails.registeredOfficeAddressPinCode,
        registeredOfficeAddressState:
          updatedDetails.registeredOfficeAddressState,
        registrationId: updatedDetails.registrationId,
        websiteUrl: updatedDetails.websiteUrl,
        paymentTypeId: paymentTypeIds,
      };

      const response = await axios.put(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/updateOnboard`,
        payload,

        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      );

      if (response.data.statusCode === 200) {
        setmerchantData(updatedDetails);
        toggleEditing();
        swal({
          title: "Success",
          text: "Profile updated successfully",
          icon: "success",
        });
        setGetAgain(!getAgain);
      } else {
        // console.error("API response error:", response.data);
        swal({
          title: "Failed",
          text: "Profile updation failed",
          icon: "error",
        });
      }
      setShowLoader(false);
    } catch (error) {
      console.error("Error updating merchant details:", error);
      setShowLoader(false);
    }
  };

  const togglePhase2Editing = () => {
    if (!isPhase2Editing) {
      setPhase2Details({
        websiteUrl: merchantData.websiteUrl,
        mid: merchantData.mid,
        merchantIfscCode: merchantData.merchantIfscCode,
        merchantBankName: merchantData.merchantBankName,
        merchantAccountNumber: merchantData.merchantAccountNumber,
        callBackUrl: merchantData.callBackUrl,
        bankCity: 0,
        bankBranchName: merchantData.bankBranchName,
        accoutBeneficiaryName: merchantData.companyName,
        chargebackCallbackUrl: merchantData.chargebackCallbackUrl,
        payoutCallbackUrl: merchantData.payoutCallbackUrl
      });
    }
    setIsPhase2Editing(!isPhase2Editing);
    setIsEditing(false);
  };

  const checkValidation = (name, e) => {
    if (
      pressedKeyName === "Backspace" &&
      (name === "companyAddress" ||
        name === "registeredOfficeAddress" ||
        name === "communicationAddress" ||
        name === "companyAddressPinCode" ||
        name === "registeredOfficeAddressPinCode" ||
        name === "communicationAddressPinCode" ||
        name === "companyAddressCity" ||
        name === "communicationAddressCity" ||
        name === "registeredOfficeAddressCity" ||
        name === "companyAddressState" ||
        name === "registeredOfficeAddressState" ||
        name === "communicationAddressState")
    ) {
      setUpdatedDetails({ ...updatedDetails, [name]: e.target.value });
    }

    // stop alphabets and special char input in pincode
    if (
      (stopAlphabetInput(pressedKeyName) ||
        stopSpecialCharacterInput(pressedKeyName)) &&
      (name === "companyAddressPinCode" ||
        name === "registeredOfficeAddressPinCode" ||
        name === "communicationAddressPinCode")
    ) {
      return;
    }

    // stop special char input and number input and allow alphabets INPUT in
    // LOCALITY - STATE - CITY
    if (
      (stopNumberCharInput(pressedKeyName) ||
        stopSpecialCharacterInput(pressedKeyName)) &&
      (name === "companyAddressCity" ||
        name === "registeredOfficeAddressCity" ||
        name === "communicationAddressCity" ||
        name === "companyAddressState" ||
        name === "registeredOfficeAddressState" ||
        name === "communicationAddressState")
    )
      return;

    const addressFieldLength = 200;
    const pincodeFieldLength = 6;
    const StateLength = 50;
    const cityLength = 50;

    if (
      (updatedDetails.companyAddress.length >= addressFieldLength &&
        name === "companyAddress") ||
      (updatedDetails.registeredOfficeAddress.length >= addressFieldLength &&
        name === "registeredOfficeAddress") ||
      (updatedDetails.communicationAddress.length >= addressFieldLength &&
        name === "communicationAddress") ||
      (updatedDetails.companyAddressPinCode.length >= pincodeFieldLength &&
        name === "companyAddressPinCode") ||
      (updatedDetails.registeredOfficeAddressPinCode.length >=
        pincodeFieldLength &&
        name === "registeredOfficeAddressPinCode") ||
      (updatedDetails.communicationAddressPinCode.length >=
        pincodeFieldLength &&
        name === "communicationAddressPinCode") ||
      (updatedDetails.companyAddressCity.length >= cityLength &&
        name === "companyAddressCity") ||
      (updatedDetails.communicationAddressCity.length >= cityLength &&
        name === "communicationAddressCity") ||
      (updatedDetails.registeredOfficeAddressCity.length >= cityLength &&
        name === "registeredOfficeAddressCity") ||
      (updatedDetails.companyAddressState.length >= StateLength &&
        name === "companyAddressState") ||
      (updatedDetails.registeredOfficeAddressState.length >= StateLength &&
        name === "registeredOfficeAddressState") ||
      (updatedDetails.communicationAddressState.length >= StateLength &&
        name === "communicationAddressState")
    ) {
      return;
    }

    setUpdatedDetails({
      ...updatedDetails,
      [name]: e.target.value,
    });
  };

  const checkPhase2Validation = (name, e) => {
    if (
      (stopNumberCharInput(pressedKeyName) ||
        stopSpecialCharacterInput(pressedKeyName)) &&
      (name === "accoutBeneficiaryName" ||
        name === "bankBranchName" ||
        name === "merchantBankName")
    )
      return;

    setPhase2Details({
      ...phase2Details,
      [name]: e.target.value,
    });
  };

  const handelValidations = (e) => {
    if (e.target.name === "merchantIfscCode" && e.target.value !== "") {
      if (!isIfscRegexIsValid(phase2Details.merchantIfscCode)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid IFSC Code !",
          icon: "error",
        }).then(() => {
          setPhase2Details({ ...phase2Details, merchantIfscCode: "" });
        });
        return;
      }
    }

    if (e.target.name === "websiteUrl" && e.target.value !== "") {
      if (!isWebsiteURLRegexIsValid(phase2Details.websiteUrl)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Website URL!",
          icon: "error",
        }).then(() => {
          setPhase2Details({ ...phase2Details, websiteUrl: "" });
        });
        return;
      }
    }
  };

  const ShowAlert = () => {
    swal({
      title: "Alert!",
      text: "Merchant not onboarded.",
      icon: "error",
    });
  };
  const handlePhase2Save = async () => {
    if (
      phase2Details.accoutBeneficiaryName === "" ||
      phase2Details.accoutBeneficiaryName === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter account beneficiary name !",
        icon: "error",
      });
      return;
    }

    if (
      phase2Details.bankBranchName === "" ||
      phase2Details.bankBranchName === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter bank branch name !",
        icon: "error",
      });
      return;
    }

    if (
      phase2Details.callBackUrl === "" ||
      phase2Details.callBackUrl === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter callBack URL !",
        icon: "error",
      });
      return;
    }
    if (
      phase2Details.payoutCallbackUrl === "" ||
      phase2Details.payoutCallbackUrl === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter Payout callBack URL !",
        icon: "error",
      });
      return;
    }
    if (
      phase2Details.chargebackCallbackUrl === "" ||
      phase2Details.chargebackCallbackUrl === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter Chargeback callBack URL !",
        icon: "error",
      });
      return;
    }
    if (
      phase2Details.merchantAccountNumber === "" ||
      phase2Details.merchantAccountNumber === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter merchant account number !",
        icon: "error",
      });
      return;
    }

    if (
      phase2Details.merchantBankName === "" ||
      phase2Details.merchantBankName === null
    ) {
      swal({
        title: "Alert!",
        text: "Please Enter Merchant Bank Name !",
        icon: "error",
      });
      return;
    }

    if (
      phase2Details.merchantIfscCode === "" ||
      phase2Details.merchantIfscCode === null
    ) {
      swal({
        title: "Alert!",
        text: "Please enter IFSC code !",
        icon: "error",
      });
      return;
    }

    if (!isIfscRegexIsValid(phase2Details.merchantIfscCode)) {
      swal({
        title: "Alert!",
        text: "Please enter valid IFSC code !",
        icon: "error",
      }).then(() => {
        setPhase2Details({ ...phase2Details, merchantIfscCode: "" });
      });
      return;
    }

    try {
      setShowLoader(true);
      const response = await axios.put(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/updateOnboard/BankDetailStage2`,
        phase2Details,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      );

      if (response.data.statusCode === 200) {
        setmerchantData(phase2Details);

        togglePhase2Editing();
        swal({
          title: "Success",
          text: "Profile updated successfully",
          icon: "success",
        });
        setAgain(!again);
      } else {
        console.error("API response error:", response.data);
      }
      setShowLoader(false);
    } catch (error) {
      console.error("Error updating merchant details:", error);
      setShowLoader(false);
    }
  };

  console.log("dfghjk=-----------------",  reduxData)

  return (
    <>
      {showLoader ? <MyLoader /> : ""}
      {Object.keys(merchantData).length > 0 && (
        <section className="admin-view-details">
          <Container>
          <Card.Header className="d-flex justify-content-between">
            <Card.Title>Merchant Details</Card.Title>
            <Button onClick={handleClose}>Close</Button>
          </Card.Header>
            <Card className="shadow border-0 mb-4 ds-header-card">
            
              <Card.Body>
                <Row>

                  <Col xs={12} xl={12} lg={12} sm={12}>
                    <div className="two-column-layout">
                       
                      {isEditing || isPhase2Editing ? (
                        ""
                      ) : (
                        <div className="vertical-table mb-4">
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              User Name
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {  merchantData.userName }
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Merchant Master
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData.masterMerchant}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Mcc Code
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData.mccCode}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Password
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                            {reduxData.prefix === "ALL" ?  merchantData.password : "XXXXXXXXX"}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Payin Encryption Key
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData.encKey}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Payout API Key
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData.apiKey}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Payout Secret Key
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData.secretKey}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Mid
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData.mid}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Company Name
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData.companyName}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Mobile Number
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData.mobile}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Business Type
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData.companyType}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Payment Services
                            </div>
                            <div>
                              <div className="vertical-table-cell-value text-left">
                                {Array.from(
                                  new Set(
                                    merchantData?.paymentService?.map(
                                      (service) => service.paymentService
                                    )
                                  )
                                ).map((uniqueService, index) => (
                                  <li key={index}>{uniqueService}</li>
                                ))}
                              </div>
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Company PAN Number
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData.companyPanNumber}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              GST Number
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData.gstNumber}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Authorized Person PAN
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData.authorizedPersonPan}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Aadhaar Number
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData.aadharNumber}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Mode of Registration
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData.modeOfRegistration}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Modification Date
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData.modificationDate}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Registration Type
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData.registrationType}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Business As
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData.businessAs}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Authorized Person Name
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData.authorizedPersonName}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              e-Mail ID
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData.email}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Website URL
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData.websiteUrl}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Application URL
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData.aapUrl}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Company Registered Address
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData.companyAddress}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Pin Code
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData.companyAddressPinCode}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              City
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData.companyAddressCity}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              State
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData.companyAddressState}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Authorized Signatory Address
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData.registeredOfficeAddress}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Pin Code
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData.registeredOfficeAddressPinCode}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              City
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData.registeredOfficeAddressCity}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              State
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData.registeredOfficeAddressState}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Company Communication Address
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData.communicationAddress}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Pin Code
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData.communicationAddressPinCode}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              City
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData.communicationAddressCity}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              State
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData.communicationAddressState}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Account Beneficiary Name
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData.companyName}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Bank Branch Name
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData.bankBranchName}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Merchant Account Number
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData.merchantAccountNumber}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Merchant Bank Name
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData.merchantBankName}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Merchant IFSC Code
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData.merchantIfscCode}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Payin Callback URL
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData.callBackUrl}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Payout Callback URL
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData.payoutCallbackUrl}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Chargeback Callback URL
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData.chargebackCallbackUrl}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Submission Date
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData.submitDate}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Submission Time
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData.submitTime}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Referred By
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData.referredBy}
                            </div>
                          </div>

                        </div>
                      )}

                      {/* editable column for phase 1:                */}

                      {isEditing && !isPhase2Editing && (
                        <>
                          <div className="vertical-table mb-4">
                            <div className="vertical-table-row">
                              <div className="vertical-table-cell text-left">
                                Business Category
                              </div>
                              <div className="vertical-table-cell-value text-left">
                                {merchantData.businessType}
                              </div>
                            </div>
                            {merchantData.businessType === "OTHERS" && (
                              <div className="vertical-table-row">
                                <div className="vertical-table-cell text-left">
                                  Other Business Category
                                </div>
                                <div className="vertical-table-cell-value text-left">
                                  {isEditing ? (
                                    <input
                                      type="text"
                                      value={updatedDetails.businessTypeName}
                                      onChange={(e) =>
                                        setUpdatedDetails({
                                          ...updatedDetails,
                                          businessTypeName: e.target.value,
                                        })
                                      }
                                    />
                                  ) : (
                                    merchantData.businessTypeName
                                  )}
                                </div>
                              </div>
                            )}

                            <div className="vertical-table-row">
                              <div className="vertical-table-cell text-left">
                                Business As
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {isEditing ? (
                                  <input
                                    type="text"
                                    value={updatedDetails.businessAs}
                                    onKeyDown={(e) => {
                                      setPressedKeyName(getKeyPressed(e));
                                    }}
                                    onChange={(e) => {
                                      if (
                                        stopSpecialCharacterInput(
                                          pressedKeyName
                                        )
                                      )
                                        return;

                                      setUpdatedDetails({
                                        ...updatedDetails,
                                        businessAs: e.target.value,
                                      });
                                    }}
                                  />
                                ) : (
                                  merchantData.businessAs
                                )}
                              </div>
                            </div>

                            <div className="vertical-table-row">
                              <div className="vertical-table-cell text-left">
                                Authorized Person Name
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {isEditing ? (
                                  <input
                                    type="text"
                                    value={updatedDetails.authorizedPersonName}
                                    onKeyDown={(e) => {
                                      setPressedKeyName(getKeyPressed(e));
                                    }}
                                    onChange={(e) => {
                                      if (
                                        stopSpecialCharacterInput(
                                          pressedKeyName
                                        ) ||
                                        stopNumberCharInput(pressedKeyName)
                                      )
                                        return;

                                      setUpdatedDetails({
                                        ...updatedDetails,
                                        authorizedPersonName: e.target.value,
                                      });
                                    }}
                                  />
                                ) : (
                                  merchantData.authorizedPersonName
                                )}
                              </div>
                            </div>

                            <div className="vertical-table-row">
                              <div className="vertical-table-cell text-left">
                                Payment Services
                              </div>
                              <div className="vertical-table-cell-value">
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    setShowPaymentServicesList(
                                      !showPaymentServicesList
                                    )
                                  }
                                >
                                  View Services
                                </button>
                                {showPaymentServicesList && (
                                  <div className="payment-services-list">
                                    {paymentservice.map((service) => (
                                      <label key={service.paymentTypeId}>
                                        <input
                                          type="checkbox"
                                          value={service.paymentService}
                                          checked={
                                            selectedServices.includes(
                                              service.paymentService
                                            )
                                              ? true
                                              : false
                                          }
                                          onChange={handleServiceChange}
                                        />
                                        <span
                                          className={
                                            selectedServices.includes(
                                              service.paymentService
                                            )
                                              ? "selected-service"
                                              : ""
                                          }
                                        >
                                          {service.paymentService}
                                        </span>
                                      </label>
                                    ))}
                                    {/* <button
                                  className="btn btn-success"
                                  onClick={handlepSave}
                                >
                                  Save
                                </button> */}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="vertical-table-row">
                              <div className="vertical-table-cell text-left">
                                e-Mail ID
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {isEditing ? (
                                  <input
                                    type="text"
                                    value={updatedDetails.email}
                                    onChange={(e) =>
                                      setUpdatedDetails({
                                        ...updatedDetails,
                                        email: e.target.value,
                                      })
                                    }
                                  />
                                ) : (
                                  merchantData.email
                                )}
                              </div>
                            </div>
                            <div className="vertical-table-row">
                              <div className="vertical-table-cell text-left">
                                Mobile Number
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {isEditing ? (
                                  <input
                                    type="text"
                                    value={updatedDetails.mobile}
                                    onKeyDown={(e) => {
                                      setPressedKeyName(getKeyPressed(e));
                                    }}
                                    onChange={(e) => {
                                      if (
                                        stopCharacterInput(pressedKeyName) ||
                                        stopSpecialCharacterInput(
                                          pressedKeyName
                                        ) ||
                                        e.target.value.length > 10
                                      )
                                        return;

                                      setUpdatedDetails({
                                        ...updatedDetails,
                                        mobile: e.target.value,
                                      });
                                    }}
                                  />
                                ) : (
                                  merchantData.mobile
                                )}
                              </div>
                            </div>

                            <div className="vertical-table-row">
                              <div className="vertical-table-cell text-left">
                                Website URL
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {isEditing ? (
                                  <input
                                    type="text"
                                    value={updatedDetails.websiteUrl}
                                    onKeyDown={(e) => {
                                      if (e.key === " ") {
                                        e.preventDefault();
                                      }
                                    }}
                                    onChange={(e) =>
                                      setUpdatedDetails({
                                        ...updatedDetails,
                                        websiteUrl: e.target.value,
                                      })
                                    }
                                  />
                                ) : (
                                  merchantData.websiteUrl
                                )}
                              </div>
                            </div>
                            <div className="vertical-table-row">
                              <div className="vertical-table-cell text-left">
                                Application URL
                              </div>
                              <div className="vertical-table-cell-value text-left">
                                {isEditing ? (
                                  <input
                                    type="text"
                                    value={updatedDetails.aapUrl}
                                    onKeyDown={(e) => {
                                      if (e.key === " ") {
                                        e.preventDefault();
                                      }
                                    }}
                                    onChange={(e) =>
                                      setUpdatedDetails({
                                        ...updatedDetails,
                                        aapUrl: e.target.value,
                                      })
                                    }
                                  />
                                ) : (
                                  merchantData.aapUrl
                                )}
                              </div>
                            </div>
                            <div className="vertical-table-row">
                              <div className="vertical-table-cell text-left">
                                Company Registered Address
                              </div>
                              <div className="vertical-table-cell-value   text-left">
                                {isEditing ? (
                                  <div>
                                    <input
                                      type="text"
                                      value={updatedDetails.companyAddress}
                                      onKeyDown={(e) =>
                                        setPressedKeyName(getKeyPressed(e))
                                      }
                                      onChange={(e) =>
                                        checkValidation("companyAddress", e)
                                      }
                                      onPaste={(e) =>
                                        handelPasteEvent(e, "companyAddress")
                                      }
                                    />
                                    <div className="d-flex justify-content-end">
                                      <span className="text-danger">
                                        {`Remaining Characters ${200 -
                                          updatedDetails.companyAddress.length
                                          } / 200`}{" "}
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  merchantData.companyAddress
                                )}
                              </div>
                            </div>
                            <div className="vertical-table-row">
                              <div className="vertical-table-cell text-left">
                                {" "}
                                Pin Code
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {isEditing ? (
                                  <input
                                    type="text"
                                    value={updatedDetails.companyAddressPinCode}
                                    onKeyDown={(e) => {
                                      setPressedKeyName(getKeyPressed(e));
                                    }}
                                    onChange={(e) => {
                                      checkValidation(
                                        "companyAddressPinCode",
                                        e
                                      );
                                    }}
                                  />
                                ) : (
                                  merchantData.companyAddressPinCode
                                )}
                              </div>
                            </div>
                            <div className="vertical-table-row">
                              <div className="vertical-table-cell text-left">
                                City
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {isEditing ? (
                                  <input
                                    type="text"
                                    value={updatedDetails.companyAddressCity}
                                    onKeyDown={(e) => {
                                      setPressedKeyName(getKeyPressed(e));
                                    }}
                                    onChange={(e) => {
                                      checkValidation("companyAddressCity", e);
                                    }}
                                    onPaste={(e) =>
                                      handelPasteEvent(e, "companyAddressCity")
                                    }
                                  />
                                ) : (
                                  merchantData.companyAddressCity
                                )}
                              </div>
                            </div>
                            <div className="vertical-table-row">
                              <div className="vertical-table-cell text-left">
                                State
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {isEditing ? (
                                  <input
                                    type="text"
                                    value={updatedDetails.companyAddressState}
                                    onKeyDown={(e) => {
                                      setPressedKeyName(getKeyPressed(e));
                                    }}
                                    onChange={(e) => {
                                      checkValidation("companyAddressState", e);
                                    }}
                                    onPaste={(e) =>
                                      handelPasteEvent(e, "companyAddressState")
                                    }
                                  />
                                ) : (
                                  merchantData.companyAddressState
                                )}
                              </div>
                            </div>

                            {/* Second half of the table */}

                            <div className="vertical-table-row">
                              <div className="vertical-table-cell text-left">
                                Authorized Signatory Address
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {isEditing ? (
                                  <div>
                                    <input
                                      type="text"
                                      value={
                                        updatedDetails.registeredOfficeAddress
                                      }
                                      onKeyDown={(e) =>
                                        setPressedKeyName(getKeyPressed(e))
                                      }
                                      onChange={(e) =>
                                        checkValidation(
                                          "registeredOfficeAddress",
                                          e
                                        )
                                      }
                                      onPaste={(e) =>
                                        handelPasteEvent(
                                          e,
                                          "registeredOfficeAddress"
                                        )
                                      }
                                    />
                                    <div className="d-flex justify-content-end">
                                      <span className="text-danger">
                                        {`Remaining Characters ${200 -
                                          updatedDetails.registeredOfficeAddress
                                            .length
                                          } / 200`}{" "}
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  merchantData.registeredOfficeAddress
                                )}
                              </div>
                            </div>
                            <div className="vertical-table-row">
                              <div className="vertical-table-cell text-left">
                                Pin Code
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {isEditing ? (
                                  <input
                                    type="text"
                                    value={
                                      updatedDetails.registeredOfficeAddressPinCode
                                    }
                                    onKeyDown={(e) => {
                                      setPressedKeyName(getKeyPressed(e));
                                    }}
                                    onChange={(e) => {
                                      checkValidation(
                                        "registeredOfficeAddressPinCode",
                                        e
                                      );
                                    }}
                                  />
                                ) : (
                                  merchantData.registeredOfficeAddressPinCode
                                )}
                              </div>
                            </div>
                            <div className="vertical-table-row">
                              <div className="vertical-table-cell text-left">
                                City
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {isEditing ? (
                                  <input
                                    type="text"
                                    value={
                                      updatedDetails.registeredOfficeAddressCity
                                    }
                                    onKeyDown={(e) => {
                                      setPressedKeyName(getKeyPressed(e));
                                    }}
                                    onChange={(e) => {
                                      checkValidation(
                                        "registeredOfficeAddressCity",
                                        e
                                      );
                                    }}
                                    onPaste={(e) =>
                                      handelPasteEvent(
                                        e,
                                        "registeredOfficeAddressCity"
                                      )
                                    }
                                  />
                                ) : (
                                  merchantData.registeredOfficeAddressCity
                                )}
                              </div>
                            </div>
                            <div className="vertical-table-row">
                              <div className="vertical-table-cell text-left">
                                State
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {isEditing ? (
                                  <input
                                    type="text"
                                    value={
                                      updatedDetails.registeredOfficeAddressState
                                    }
                                    onKeyDown={(e) => {
                                      setPressedKeyName(getKeyPressed(e));
                                    }}
                                    onChange={(e) => {
                                      checkValidation(
                                        "registeredOfficeAddressState",
                                        e
                                      );
                                    }}
                                    onPaste={(e) =>
                                      handelPasteEvent(
                                        e,
                                        "registeredOfficeAddressState"
                                      )
                                    }
                                  />
                                ) : (
                                  merchantData.registeredOfficeAddressState
                                )}
                              </div>
                            </div>
                            <div className="vertical-table-row">
                              <div className="vertical-table-cell text-left">
                                Company Communication Address
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {isEditing ? (
                                  <div>
                                    <input
                                      type="text"
                                      value={
                                        updatedDetails.communicationAddress
                                      }
                                      onKeyDown={(e) =>
                                        setPressedKeyName(getKeyPressed(e))
                                      }
                                      onChange={(e) =>
                                        checkValidation(
                                          "communicationAddress",
                                          e
                                        )
                                      }
                                      onPaste={(e) =>
                                        handelPasteEvent(
                                          e,
                                          "communicationAddress"
                                        )
                                      }
                                    />
                                    <div className="d-flex justify-content-end">
                                      <span className="text-danger">
                                        {`Remaining Characters ${200 -
                                          updatedDetails.communicationAddress
                                            .length
                                          } / 200`}{" "}
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  merchantData.communicationAddress
                                )}
                              </div>
                            </div>
                            <div className="vertical-table-row">
                              <div className="vertical-table-cell text-left">
                                Pin Code
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {isEditing ? (
                                  <input
                                    type="text"
                                    value={
                                      updatedDetails.communicationAddressPinCode
                                    }
                                    onKeyDown={(e) => {
                                      setPressedKeyName(getKeyPressed(e));
                                    }}
                                    onChange={(e) => {
                                      checkValidation(
                                        "communicationAddressPinCode",
                                        e
                                      );
                                    }}
                                  />
                                ) : (
                                  merchantData.communicationAddressPinCode
                                )}
                              </div>
                            </div>
                            <div className="vertical-table-row">
                              <div className="vertical-table-cell text-left">
                                City
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {isEditing ? (
                                  <input
                                    type="text"
                                    value={
                                      updatedDetails.communicationAddressCity
                                    }
                                    onKeyDown={(e) => {
                                      setPressedKeyName(getKeyPressed(e));
                                    }}
                                    onChange={(e) => {
                                      checkValidation(
                                        "communicationAddressCity",
                                        e
                                      );
                                    }}
                                    onPaste={(e) =>
                                      handelPasteEvent(
                                        e,
                                        "communicationAddressCity"
                                      )
                                    }
                                  />
                                ) : (
                                  merchantData.communicationAddressCity
                                )}
                              </div>
                            </div>
                            <div className="vertical-table-row">
                              <div className="vertical-table-cell text-left">
                                State
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                {isEditing ? (
                                  <input
                                    type="text"
                                    value={
                                      updatedDetails.communicationAddressState
                                    }
                                    onKeyDown={(e) => {
                                      setPressedKeyName(getKeyPressed(e));
                                    }}
                                    onChange={(e) => {
                                      checkValidation(
                                        "communicationAddressState",
                                        e
                                      );
                                    }}
                                    onPaste={(e) =>
                                      handelPasteEvent(
                                        e,
                                        "communicationAddressState"
                                      )
                                    }
                                  />
                                ) : (
                                  merchantData.communicationAddressState
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {isPhase2Editing && (
                        <div className="vertical-table mb-4">
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Account Beneficiary Name
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {isPhase2Editing ? (
                                <input
                                  type="text"
                                  value={phase2Details.accoutBeneficiaryName}
                                  onKeyDown={(e) => {
                                    setPressedKeyName(getKeyPressed(e));
                                  }}
                                  onChange={(e) =>
                                    checkPhase2Validation(
                                      "accoutBeneficiaryName",
                                      e
                                    )
                                  }
                                />
                              ) : (
                                merchantData.accoutBeneficiaryName
                              )}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Bank Branch Name
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {isPhase2Editing ? (
                                <input
                                  type="text"
                                  value={phase2Details.bankBranchName}
                                  onKeyDown={(e) => {
                                    setPressedKeyName(getKeyPressed(e));
                                  }}
                                  onChange={(e) =>
                                    checkPhase2Validation("bankBranchName", e)
                                  }
                                />
                              ) : (
                                merchantData.bankBranchName
                              )}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Payin Callback URL
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {isPhase2Editing ? (
                                <input
                                  type="text"
                                  value={phase2Details.callBackUrl}
                                  onChange={(e) =>
                                    setPhase2Details({
                                      ...phase2Details,
                                      callBackUrl: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                merchantData.callBackUrl
                              )}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Payout Callback URL
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {isPhase2Editing ? (
                                <input
                                  type="text"
                                  value={phase2Details.payoutCallbackUrl}
                                  onChange={(e) =>
                                    setPhase2Details({
                                      ...phase2Details,
                                      payoutCallbackUrl: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                merchantData.payoutCallbackUrl
                              )}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Chargeback Callback URL
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {isPhase2Editing ? (
                                <input
                                  type="text"
                                  value={phase2Details.chargebackCallbackUrl}
                                  onChange={(e) =>
                                    setPhase2Details({
                                      ...phase2Details,
                                      chargebackCallbackUrl: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                merchantData.chargebackCallbackUrl
                              )}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Merchant Account Number
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {isPhase2Editing ? (
                                <input
                                  type="number"
                                  value={phase2Details.merchantAccountNumber}
                                  onChange={(e) =>
                                    setPhase2Details({
                                      ...phase2Details,
                                      merchantAccountNumber: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                merchantData.merchantAccountNumber
                              )}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Merchant Bank Name
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {isPhase2Editing ? (
                                <input
                                  type="text"
                                  value={phase2Details.merchantBankName}
                                  onKeyDown={(e) => {
                                    setPressedKeyName(getKeyPressed(e));
                                  }}
                                  onChange={(e) =>
                                    checkPhase2Validation("merchantBankName", e)
                                  }
                                />
                              ) : (
                                merchantData.merchantBankName
                              )}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Merchant IFSC Code
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {isPhase2Editing ? (
                                <input
                                  type="text"
                                  value={phase2Details.merchantIfscCode}
                                  onKeyDown={(e) => {
                                    if (e.key === " ") {
                                      e.preventDefault();
                                    }
                                  }}
                                  name="merchantIfscCode"
                                  onChange={(e) =>
                                    setPhase2Details({
                                      ...phase2Details,
                                      merchantIfscCode: e.target.value,
                                    })
                                  }
                                  onBlur={handelValidations}
                                />
                              ) : (
                                merchantData.merchantIfscCode
                              )}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Website URL
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {isPhase2Editing ? (
                                <input
                                  type="text"
                                  value={phase2Details.websiteUrl}
                                  name="websiteUrl"
                                  onChange={(e) =>
                                    setPhase2Details({
                                      ...phase2Details,
                                      websiteUrl: e.target.value,
                                    })
                                  }
                                  onBlur={handelValidations}
                                />
                              ) : (
                                merchantData.websiteUrl
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {hasPrivilages2682 ? (<div className="button-toolbar groups-btn text-end">
                        {isEditing && !isPhase2Editing ? (
                          <button
                            className="btn btn-success"
                            onClick={handleSave}
                          >
                            Update
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary"
                            onClick={
                              merchantData.applicationStatus === null
                                ? ShowAlert
                                : toggleEditing
                            }
                          >
                            Edit (Phase 1)
                          </button>
                        )}

                        {isPhase2Editing ? (
                          <button
                            className="btn btn-success"
                            onClick={handlePhase2Save}
                          >
                            Update
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary"
                            onClick={
                              merchantData.applicationStatus === null
                                ? ShowAlert
                                : togglePhase2Editing
                            }
                          >
                            Edit (Phase 2)
                          </button>
                        )}
                      </div>) : null}

                      {/* <button
                          className="btn btn-primary"
                          onClick={() => navigate(-1)}
                        >
                          Go Back
                        </button> */}

                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
        </section>
      )}
    </>
  );
}

export default OpenDetails;