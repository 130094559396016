import React, { useEffect, useState } from "react";
import axios from "axios";
import { MDBDataTable } from 'mdbreact';
import { SWIPELINC_API } from "../utils/constant";
import MoaDetails1 from "./MoaDetails1";

const Stage2Rejected = () => {
  const [applications, setApplications] = useState([]);
  const [openView,setOpenView] = useState(false);
  const [id,setId] = useState("")
  
  const token = localStorage.getItem("token");
  useEffect(() => {
    axios.get(
    // setShowLoader(true)
   `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/findbystage2/rejected`,
     { headers: {
        Authentication: `Bearer ${token}`,
      },
    },
     { 
      params:{
        applicationStatus:"rejected"
      }
    })
    .then((response) => {
      if (response.data.statusCode===200){
        console.log("Approved applications:", response.data.data);
        setApplications(response?.data?.data);
        }
        else if (response.data.statusCode === 304){
          setApplications (response?.data?.data);
        }
        else{
          console.error("NO APPLICATIONS FOUND:", response.data.data);
        }
        // setShowLoader(false)
   })
    .catch((error) => {
      console.error("Error fetching applications:", error.message);
      // setShowLoader(false)
    });
}, []);

  
  const handleViewDetails = (registrationId) => {
    setId(registrationId);
    setOpenView(true);
  }
  const handleClose = () => {
    setId("");
    setOpenView(false);
  };
   const columns = [
    { label: "Company Name", field: "companyName", width: "auto" },
    { label: "Authorized Person Name", field: "authorizedPersonName", width: "auto" },
    { label: "e-Mail ID", field: "email", width: "auto" },
    { label: "Mobile Number", field: "mobile", width: "auto" },
    { label: "Rejection Remark", field: "rejectionRemark", width: "auto" },
    { label: "Company PAN", field: "companyPanNumber", width: "auto" },
    { label: "GST Number", field: "gstNumber", width: "auto" },
    { label: "Action", field: "action", width: "150px" },
  ];

  const mappedData = applications?.map((application) => {
      const mappedItem = { ...application };
      mappedItem.action = (
        <button className="btn btn-primary btn-sm"
          type="button"
          onClick={() => handleViewDetails(application.registrationId)}
        >
          View Details
        </button>
      );
      return mappedItem;
    })
  

  return (
    <>
      <div className="report-grid-view">
      {! openView ? (
        <MDBDataTable
          className='dataTable'
          striped
          small
          bordered
          hover
          responsive
          data={{
            columns: columns,
            rows: mappedData,
          }}
          searching={true}
          entries={50}
          exportToCSV
          theadColor="#333"
          paginationLabel={['Previous', 'Next']}
          sortable={true}
          footer="none"
        />
      ): openView ? (
      <MoaDetails1
       id={id}
       handleClose={handleClose}
      //  setShowLoader={setShowLoader}
       />
      ): null }
      </div>
    </>
  );
};

export default Stage2Rejected;