import { useNavigate, NavLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
// import MainLogo from "../assets/images/Logo.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import { Badge } from " @mui/material";
import SwipelincLogo from "../assets/images/SwipelincLogo.png";
import LincpayLogo from "../assets/images/LincpayLogo.jpeg";
import user from "../assets/images/user.png";
import "../stylesheet/Header.css";
import { Badge } from "@mui/material";
import Notification from "../components/Notification/Notification";
import axios from "axios";
import { GET_ALL_PRIVILAGES, SWIPELINC_API } from "../utils/constant";
import {
  config,
  convertStringDateToDateType,
  getCompleteDomain,
  getServerEnvironment,
  isExpired,
} from "../utils/helper";
import { jwtDecode } from "jwt-decode";
import swal from "sweetalert";
import { setAllPrivilagesData } from "../store/slices/privilagesSlice";
import { getAllFunctionForApiCall } from "../utils/ApiServices";
import { useDispatch, useSelector } from "react-redux";
import useCheckPrivilages from "../utils/checkPrivilages";

const Header = () => {
  let navigate = useNavigate();

  const [isShowNotify, setIsShowNotify] = useState(false);
  const [count, setCount] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [getNotify, setGetNotify] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));

  const handleClearLocalStorage = () => {
    const token = localStorage.getItem("token");
    localStorage.removeItem("token");
    if (!token) {
      navigate("/");
    }
  };

  const dispatch = useDispatch();

  const { logoPath } = config(getServerEnvironment(getCompleteDomain()));

  const   hasPrivilages179774= useCheckPrivilages(179774)
  const   hasPrivilages179773= useCheckPrivilages(179773)

  useEffect(() => {
    // Fetch notifications from your API here
    axios
      .get(`${SWIPELINC_API}MerchantAdminPanel/merchant/notification/findAll`, {
        headers: {
          Authentication: "Bearer " +token,
        },
      })
      .then((response) => {
        setNotifications(response.data.data);
        setCount(response.data.size);
      })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
      });
  }, [getNotify]);

  const { prefix } = useSelector((state) => state.privilages);
  console.log(prefix);

  const getAllPrivilages = async () => {
    try {
      const response = await getAllFunctionForApiCall(
        GET_ALL_PRIVILAGES,
        token
      );

      if (response.statusCode === 200) {
        const finalArray = response?.data.map((item) => {
          const final = item.subTypes.map((item2) => {
            return {
              ...item2,
            };
          });

          return final;
        });
        dispatch(setAllPrivilagesData(finalArray.flat()));
      } else {
        dispatch(setAllPrivilagesData(response?.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPrivilages();
  }, []);

  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   // console.log("token", token);
  //   const { expiryTime } = jwtDecode(token);
  //   const expDate = convertStringDateToDateType(expiryTime);
  //   // const currentDate = new Date();
  //   // let isTokenExpired = isExpired(currentDate, expDate)

  //   // if(isTokenExpired) {
  //   //   swal({
  //   //     title: "Oops!",
  //   //     text: "Your Session has been expired ! Please LogIn Again !",
  //   //     icon: "error",
  //   //   }).then(function () {
  //   //     localStorage.removeItem("token")
  //   //     navigate("/")
  //   //   });
  //   // }

  //   setInterval(() => {
  //     const currentDate = new Date();
  //     let isTokenExpired = isExpired(currentDate, expDate);

  //     if (isTokenExpired) {
  //       swal({
  //         title: "Oops!",
  //         text: "Your Session has been expired ! Please LogIn Again !",
  //         icon: "error",
  //       }).then(function () {
  //         localStorage.removeItem("token");
  //         navigate("/");
  //       });
  //     }
  //   }, 50000);
  // }, []);

  return (
    <>
      <Navbar
        expand="xxl"
        className="bg-dark lincpay-header-sec"
        style={{ zIndex: "1000" }}
      >
        <Container fluid>
          <Navbar.Brand href="/Dashboard">
            <img src={logoPath} className="img-fluid" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="ms-auto my-2 my-lg-0">
            {prefix === "ALL" ? (
                <NavLink
                  className="nav-link"
                  to="/MasterMerchant"
                  activeClassName="active-link"
                >
                  <i className="icon-dashboard"></i>Master Merchant
                </NavLink>
              ) : null}
              {prefix === "ALL" ? (
                <NavLink
                  className="nav-link"
                  to="/VehicleMaster"
                  activeClassName="active-link"
                >
                  <i className="icon-dashboard"></i>External Master
                </NavLink>
              ) : null}
             

              {hasPrivilages179774 || hasPrivilages179773 ? (
                <NavLink
                  className="nav-link"
                  to="/Masters"
                  activeClassName="active-link"
                >
                  <i className="icon-dashboard"></i>Masters
                </NavLink>
              ) : null}

              <NavLink
                className="nav-link"
                to="/OnBoarding"
                activeClassName="active-link"
              >
                <i className="bi bi-cloud-upload"></i>On-Boarding
              </NavLink>
              <NavLink
                className="nav-link"
                to="/ToolsSection"
                activeClassName="active-link"
              >
                <i className="icon-tools"></i>Tools
              </NavLink>

              <NavLink
                className="nav-link"
                to="/Reports"
                activeClassName="active-link"
              >
                <i className="icon-reports"></i>Reports
              </NavLink>
              <NavLink
                className="nav-link"
                to="/Support"
                activeClassName="active-link"
              >
                <i className="icon-tools"></i>Customer Support
              </NavLink>

              {/* <NavLink className="nav-link" to="/Merchant" activeClassName="active-link">
                                <i className="bi bi-bag-check"></i>Merchants
                            </NavLink> */}
              <NavLink
                className="nav-link"
                to="/settings"
                activeClassName="active-link"
              >
                <i className="icon-settings"></i>Settings
              </NavLink>
              {/* <NavLink className="nav-link" to="/Payment" activeClassName="active-link">
                                <i className="icon-paymen"></i>Payment
                            </NavLink>
                            <NavLink className="nav-link" to="/Earnings" activeClassName="active-link">
                                <i className="icon-earnings"></i>Earnings
                            </NavLink> */}
              {/* <NavLink
                // to="/Notify"
                activeClassName="active-link"
                className="nav-link bg-transparent"
                onClick={() => setIsShowNotify(true)}
              >
                <Badge
                  color="error"
                  badgeContent={count ? count : 0}
                  showZero
                  size="small"
                >
                  <i class="bi bi-bell fs-6  text-light"></i>
                </Badge>
              </NavLink> */}

              <NavLink
                className="nav-link"
                to="/"
                onClick={handleClearLocalStorage}
              >
                Sign-Out{" "}
                <span className="sign-out">
                  <i className="bi bi-box-arrow-in-left"></i>
                </span>
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* {isShowNotify ? (
        <Notification
          setIsShowNotify={setIsShowNotify}
          setGetNotify={setGetNotify}
          getNotify={getNotify}
          notifications={notifications}
        />
      ) : (
        ""
      )} */}

      {/* Header End Here */}
    </>
  );
};

export default Header;