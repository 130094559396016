import React, { useState } from "react";
import Ledger from "./Ledger";
import EKyc from "./EKyc";
import MerchantTransaction from "../Merchant/MerchantTransaction";
import { Container, Card, Row, Col } from "react-bootstrap";
import MyLoader from "../common/MyLoader";
import InactiveServices from "../components/InactiveServices";
import LiveCummulativeSubTab from "./LiveCummulativeSubTab";

import TemporaryAutoRequest from "./TemporaryAutoWithdrawlRequest";
import MerchantTxnReport from "./MerchantTxnReport";
import AcknowledgmentTab from "./Acknowledgment/AcknowledgmentTab";
import { useSelector } from "react-redux";
import useCheckPrivilages from "../utils/checkPrivilages";
import DownloadExcel from "../components/Tools/ALL PAYOUT REPORTS/DownloadExcel";

function Reports() {
  const [reportType, setReportType] = useState("");
  const [activeButton, setActiveButton] = useState(0);
  const [showLoader, setShowLoader] = useState(false);

  const { privilagesData } = useSelector((state) => state.privilages);

  const hasPrivilages2713 = useCheckPrivilages(2713);
  const hasPrivilages2714 = useCheckPrivilages(2714);

  const HandleClick = (reportType, btn) => {
    setReportType(reportType);
    setActiveButton(btn);
  };

  return (
    <>
      {showLoader ? <MyLoader /> : ""}
      <section className="dashboard-header-sec">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4 ds-header-card">
                  <Card.Body>
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn">
                        {hasPrivilages2713 ? (
                          <li
                            className={activeButton === 1 ? "active" : ""}
                            onClick={() =>
                              HandleClick("MerchantTransaction", 1)
                            }
                          >
                            Merchant Transaction
                          </li>
                        ) : null}

                        {hasPrivilages2714 ? (
                          <li
                            className={activeButton === 2 ? "active" : ""}
                            onClick={() =>
                              HandleClick("LiveCummulaticeSubTab", 2)
                            }
                          >
                            Live Cummulative Report
                          </li>
                        ) : null}

                        {/* <li
                          className={activeButton === 3 ? "active" : ""}
                          onClick={() => HandleClick("TemporaryAutoRequest", 3)}
                        >
                          Auto-Withdrawl Report
                        </li>
                        <li
                          className={activeButton === 4 ? "active" : ""}
                          onClick={() => HandleClick("merchantTxnReport", 4)}
                        >
                          Merchant Transaction Report
                        </li> */}
                        <li
                          className={activeButton === 5 ? "active" : ""}
                          onClick={() => HandleClick("EkycReport", 5)}
                        >
                          E-KYC
                        </li>
                        <li
                          className={activeButton === 6 ? "active" : ""}
                          onClick={() => HandleClick("LedgerReport", 6)}
                        >
                          Ledger
                        </li>
                        <li
                          className={activeButton === 7 ? "active" : ""}
                          onClick={() => HandleClick("acknowlesgment", 7)}
                        >
                          Acknowledgment
                        </li>
                        <li
                            className={activeButton === 8 ? "active" : ""}
                            onClick={() => HandleClick("DownloadCSV", 8)}
                          >
                            Download CSV  
                          </li>
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
                {/* ./card */}
              </Col>
              {activeButton ? <Col xs={12} lg={12} md={12}>
                <div className="merchant-report-view">
                  <div className="report-table-view">
                    {reportType === "EkycReport" ? (
                      <EKyc setShowLoader={setShowLoader} />
                    ) : reportType === "LedgerReport" ? (
                      <Ledger setShowLoader={setShowLoader} />
                    ) : reportType === "MerchantTransaction" ? (
                      <MerchantTransaction setShowLoader={setShowLoader} />
                    ) : reportType === "LiveCummulaticeSubTab" ? (
                      <LiveCummulativeSubTab setShowLoader={setShowLoader} />
                    ) : reportType === "TemporaryAutoRequest" ? (
                      <TemporaryAutoRequest setShowLoader={setShowLoader} />
                    ) : reportType === "merchantTxnReport" ? (
                      <MerchantTxnReport setShowLoader={setShowLoader} />
                    ) : reportType === "acknowlesgment" ? (
                      <AcknowledgmentTab setShowLoader={setShowLoader} />
                    ) : reportType === "DownloadCSV" ? (
                      <DownloadExcel setShowLoader={setShowLoader} />
                    ) : null}
                  </div>
                </div>
              </Col>: null}
               
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Reports;
