import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import { SWIPELINC_API } from "../../utils/constant";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import {
  stopCharacterInput,
  stopSpecialCharacterInput,
} from "../../utils/helper";
import NumberInWords from "../../masters/NumberInWords";
import {
  getMechantForPayinOnBoarding,
  getMechantForPayoutApiTest,
} from "../../utils/ApiServices";
import { useSelector } from "react-redux";
import InactiveServices from "../../components/InactiveServices";
import useCheckPrivilages from "../../utils/checkPrivilages";

const PayInRouting = ({ location, setShowLoader }) => {
  const [merchantData, setMerchantData] = useState([]);
  const [SidData, setSidData] = useState([]);

  const token = localStorage.getItem("token");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sidDetailsData, setSidDetailsData] = useState([]);
  const [keyName, setKeyName] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [mappedMerchant, setMappedMerchant] = useState([]);
  const [prorityNumber, setProrityNumber] = useState([]);
  const [routingPattern, setRoutingPattern] = useState("");
  const [priority, setPriority] = useState([]);

  const [data, setData] = useState({
    sid: "",
    txnAmt: "",
    merchantSelect: "",
  });

  

  const getPrevMappedDetails = (mid) => {
    setShowLoader(true)
    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/admin/MerchantRouting/get-mapped-sid/byMid/${mid}`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setShowLoader(false)
        if (response.data.statusCode === 200) {
          setMappedMerchant(response?.data?.data);
          
          setPriority(response?.data?.data);
          
          setRoutingPattern(response?.data?.data[0].routingType);
          
        } else {
          setRoutingPattern("");
        }

        
      })
      .catch((error) => {
        
        setShowLoader(false);
      });
  };

  const getAllMerchantForApiTest = async () => {
    setShowLoader(true);
    let res = await getMechantForPayoutApiTest(token);
    setShowLoader(false);
    setMerchantData(res.data);
  };

  const getAllMerchantForOnBoardingRoutingPayin = async () => {
    setShowLoader(true);
    let res = await getMechantForPayinOnBoarding(token);
    setShowLoader(false);
    setMerchantData(res.data);
  };

  useState(() => {
    
    getAllMerchantForApiTest();
  
}, []);

  const getAllInitialSID = () => {
    setShowLoader(true);
    axios
      .get(`${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/getAllSid`, {
        headers: {
          Authentication: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setShowLoader(false);
        if (response.data.statusCode === 200) {
          setSidData(response?.data?.data);
          let res = response?.data?.data?.map((elem) => {
            return { ...elem, maxTxnAmtInputVal: "", priority: 0 };
          });
          setSidData(res);
        } else {
          console.error("Error fetching data:", response.data.message);
        }

        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handelDataChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    if (e.target.name === "merchantSelect") {
      getAllInitialSID();
      getPrevMappedDetails(e.target.value);
      setMappedMerchant([]);
      setSelectedData([]);
      setProrityNumber([]);
    }

    if (e.target.name === "sid") {
      const selectedOption = e.target.options[e.target.selectedIndex];
      const prifix = selectedOption.getAttribute("data-prifix");

      let val = e.target.value;
      let select = SidData.filter((data) => {
        if (data.sid === val) return { ...data, prifix: prifix };
      });

      const remaining = selectedData.filter((elem) => {
        return elem.sid !== select[0].sid;
      });

      setSelectedData([...remaining, ...select]);

      let allPeriority = [...priority, ...select];
      setPriority(allPeriority);

      setProrityNumber([...prorityNumber, 0]);

      setSidData(
        SidData.filter((elem) => {
          return elem.sid !== val;
        })
      );
    }
  };

  const getKeyName = (e) => {
    setKeyName(e.key);
  };

  const sidDetailsColumn = [
    { label: "Merchant ID", field: "mid", width: "auto" },
    { label: "Merchant Name", field: "companyName", width: "auto" },
    { label: "SID", field: "sid", width: "auto" },
    { label: "SID Limit", field: "sidLimit", width: "auto" },
  ];

  const handelSearchLimit = (e, sid) => {
    setShowLoader(true)
    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/admin/MerchantRouting/getall-mapped-sid/${sid}`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setShowLoader(false)
        if (response.data.statusCode === 200) {
          setSidDetailsData(response?.data?.data);
        } else {
          setSidDetailsData([]);
          console.error("Error fetching data:", response.data.message);
        }
        
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    setIsModalOpen(true);
  };

  const handelTxnInput = (e, sid) => {
    if (stopSpecialCharacterInput(keyName) || stopCharacterInput(keyName)) {
      return;
    }

    let data = SidData?.map((elem) => {
      if (elem.sid === sid) {
        return { ...elem, maxTxnAmtInputVal: e.target.value };
      }
      return elem;
    });
    setSidData(data);

    let data1 = selectedData?.map((elem) => {
      if (elem.sid === sid) {
        return { ...elem, maxTxnAmtInputVal: e.target.value };
      }
      return elem;
    });
    setSelectedData(data1);
  };

  const handelProrityInput = (e, sid) => {
    let data = SidData?.map((elem) => {
      if (elem.sid === sid) {
        return {
          ...elem,
          priority: e.target.value === "Select" ? 0 : e.target.value,
        };
      }
      return elem;
    });
    setSidData(data);

    let data1 = selectedData?.map((elem) => {
      if (elem.sid === sid) {
        return {
          ...elem,
          priority: e.target.value === "Select" ? 0 : e.target.value,
        };
      }
      return elem;
    });
    setSelectedData(data1);
  };

  const { privilagesData } = useSelector((state) => state?.privilages);

  const checkMaxTxnAmtLimit = (payload) => {
    let flag = true;

    for (var i = 0; i < payload.length; i++) {
      let sidLimit = parseInt(payload[i].sidLimit);
      let remainingLimit = parseInt(selectedData[i].remainingLimit);
      let company = selectedData[i].company;
      if (sidLimit > remainingLimit) {
        swal({
          title: "Alert!",
          text:
            "Maximum transaction amount can not be greater than remaining limit of " +
            company,
          icon: "error",
        });
        flag = false;
      }
    }

    return flag;
  };

  function areArrayValuesUnique(selectedData) {
    const PriorityArr = selectedData?.map((item) => {
      return item?.priority;
    });
    //  console.log(selectedData,PriorityArr)
    // Use a Set to track unique values
    const uniqueSet = new Set(PriorityArr);

    // If the size of the Set is equal to the length of the array, all values are unique
    return uniqueSet.size === PriorityArr?.length;
  }

  function IsAllPrioritiesSelect(selectedData) {
    const PriorityArr = selectedData?.map((item) => {
      return item?.priority;
    });

    
    // If the size of the Set is equal to the length of the array, all values are unique
    return PriorityArr.includes(0);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const IsPrioritiesUnique = areArrayValuesUnique(selectedData);
    const IsPrioritiesSelected = IsAllPrioritiesSelect(selectedData);

    if (routingPattern !== "RR") {
      if (IsPrioritiesSelected) {
        swal({
          title: "ALERT!",
          text: "Please Select Priorities.",
          icon: "error",
        });
        return;
      }

      if (!IsPrioritiesUnique) {
        swal({
          title: "ALERT!",
          text: "Priorities Cannot Be Same.",
          icon: "error",
        });
        return;
      }
    }

    if (data.merchantSelect === "") {
      swal({
        title: "ALERT!",
        text: "Please select a merchant",
        icon: "error",
      });
      return;
    }

    if (routingPattern === "") {
      swal({
        title: "ALERT!",
        text: "Please select a routing pattern",
        icon: "error",
      });
      return;
    }

    if (selectedData.length === 0) {
      swal({
        title: "ALERT!",
        text: "Please select SID",
        icon: "error",
      });
      return;
    }

    const payload = selectedData?.map((elem) => {
      if (elem.maxTxnAmtInputVal === "") {
        swal({
          title: "ALERT!",
          text: "Please enter maximum transaction amount",
          icon: "error",
        });
        return [];
      }

      return {
        domain: elem.prifix,
        mid: data.merchantSelect,
        routingType: routingPattern,
        sid: elem.sid,
        sidLimit: elem.maxTxnAmtInputVal,
        prority: parseInt(elem.priority),
      };
    })

    const save = checkMaxTxnAmtLimit(payload);

    if (save) {
      setShowLoader(true)
      axios
        .post(
          `${SWIPELINC_API}MerchantAdminPanel/admin/MerchantRouting/save`,
          payload,
          {
            headers: {
              Authentication: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setShowLoader(false)
          if (response.data.statusCode === 200) {
            swal({
              title: "ALERT!",
              text: response.data.message,
              icon: "success",
            });
            getAllInitialSID();
            setSelectedData([]);
            setMappedMerchant([]);
            setData({
              sid: "",
              txnAmt: "",
              merchantSelect: "",
              // routingPattern: "",
            });
            
              getAllMerchantForApiTest();
            
          } else {
            swal({
              title: "ALERT!",
              text: response.data.message,
              icon: "error",
            });
          }
    
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const removeSelectedSid = (sid, elem) => {
    setSelectedData(
      selectedData?.filter((elem) => {
        return elem.sid !== sid;
      })
    );

    let allPeriority = priority.filter((elem) => elem.sid !== sid);

    prorityNumber.splice(0, 1);

    setPriority(allPeriority);

    setSidData([...SidData, elem]);
    setData({
      ...data,
      sid: "",
    });
  };

  const handleCancelAction = () => {
    setData({
      sid: "",
      txnAmt: "",
      merchantSelect: "",
      // routingPattern: "",
    });
    setSelectedData([]);
    setMappedMerchant([]);
    setRoutingPattern("");
  };

  return (
    <>
       
        <section className="w-100 lincpay-dashboard-sec merchant-admin-info-form company-add-form card py-3 container">
          {/* {showLoader ? <MyLoader /> : ""} */}
          <div className="containerflag">
            <div className="row mt-4">
              <div className="col-md-6 offset  ">
                <div className="col-sm-12 col-md-6 offset-md-3">
                  <label htmlFor="email">Select A Merchant</label>
                  <select
                    id="companyDropdown"
                    className="form-control"
                    onChange={handelDataChange}
                    value={data.merchantSelect}
                    name="merchantSelect"
                  >
                    <option value="">Select a Merchant</option>
                    {merchantData?.map((merchant) => (
                      <option key={merchant.mid} value={merchant.mid}>
                        {merchant.merchantName}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-sm-12 col-md-6 offset-md-3 mt-2">
                  <label htmlFor="email">Routing Pattern</label>
                  <select
                    id=""
                    className="form-control"
                    onChange={(e) => setRoutingPattern(e.target.value)}
                    value={routingPattern}
                    name="routingPattern"
                  >
                    <option value="">Select a Pattern</option>
                    <option value="RR">Round Robin</option>
                    <option value="SQ">Sequential</option>
                  </select>
                </div>

                <div className="row mt-2">
                  <div className="col-sm-12 col-md-6 offset-md-3"></div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="col-sm-12 col-md-6 offset-md-3 mt-2">
                  <label htmlFor="email">Select SID's</label>
                  <select
                    id=""
                    className="form-control"
                    onChange={handelDataChange}
                    value={data.sid}
                    name="sid"
                  >
                    <option value="">Select SID</option>
                    {SidData?.map((elem) => {
                      return (
                        <option
                          key={elem.sid}
                          data-prifix={elem.prifix}
                          value={elem.sid}
                        >
                          {elem.prifix} :- {elem.company}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="d-flex justify-content-center  ">
                <div className=" ">
                  {mappedMerchant?.map((elem) => {
                    // console.log("elem---", elem);
                    return (
                      <div
                        key={elem.sid}
                        className="w-100 d-flex mt-3  py-2   "
                      >
                        <div className="form-group d-flex flex-column col-sm-12 col-md-5  mt-2">
                          <div className=" ">
                            {elem.domain} :- {elem.sidCompanyName}
                          </div>

                          <span className=" text-danger">
                            Remaining Limit : {`${elem.sidRemainigLimit}`}
                          </span>
                        </div>
                        <div className="form-group  col-sm-12 col-md-4 mt-2 ">
                          <label htmlFor="email">
                            Enter Maximum Txn Amount
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="txnAmt"
                            name="txnAmt"
                            placeholder="Enter Maximum Txn Amount"
                            value={elem.sidLimit}
                            onKeyDown={getKeyName}
                            onChange={(e) => handelTxnInput(e, elem.sid)}
                          />
                          <span>
                            <NumberInWords number={elem.sidLimit} />
                          </span>
                        </div>
                        <div className="col-sm-12 col-md-2 mx-3 d-flex align-items-center ">
                          <Button
                            className="bg-success"
                            size="sm"
                            onClick={(e) => handelSearchLimit(e, elem.sid)}
                          >
                            View Mapped SID's
                          </Button>
                        </div>

                        {routingPattern === "RR" ? (
                          ""
                        ) : (
                          <div className="col-sm-12 col-md-2 my-2 ">
                            <label htmlFor="email">Priority</label>

                            <input
                              type="text"
                              value={elem.prority}
                              readOnly
                              className="form-control"
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}

                  {selectedData?.map((elem, index) => {
                    console.log("elem---", elem);
                    console.log("index---<<<<<<", index);
                    return (
                      <div
                        key={elem.sid}
                        className="w-100 d-flex mt-3  py-2   "
                      >
                        <div className="form-group d-flex flex-column col-sm-12 col-md-5  mt-2">
                          <div className=" ">
                            {elem.prifix} :- {elem.company}
                          </div>
                          <span className="  text-danger">
                            Maximum Transaction Amount :{" "}
                            {`${elem.maxTxnAmount}`}
                          </span>
                          <span className=" text-danger">
                            Remaining Limit : {`${elem.remainingLimit}`}
                          </span>
                        </div>
                        <div className="form-group  col-sm-12 col-md-4 mt-2 ">
                          <label htmlFor="email">
                            Enter Maximum Txn Amount
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="txnAmt"
                            name="txnAmt"
                            placeholder="Enter Maximum Txn Amount"
                            value={elem.maxTxnAmtInputVal}
                            onKeyDown={getKeyName}
                            onChange={(e) => handelTxnInput(e, elem.sid)}
                          />
                          <span>
                            <NumberInWords number={elem.maxTxnAmtInputVal} />
                          </span>
                        </div>
                        <div className="col-sm-12 col-md-2 mx-3 d-flex align-items-center ">
                          <Button
                            className="bg-success"
                            size="sm"
                            onClick={(e) => handelSearchLimit(e, elem.sid)}
                          >
                            View Mapped SID's
                          </Button>
                        </div>
                        <div className="col-sm-12 col-md-1 d-flex align-items-center mx-2">
                          <span
                            onClick={() => {
                              removeSelectedSid(elem.sid, elem);
                            }}
                            style={{ cursor: "pointer" }}
                            className="text-danger fs-4"
                          >
                            <i className="bi bi-trash-fill"></i>
                          </span>
                        </div>
                        {routingPattern === "RR" ? (
                          ""
                        ) : (
                          <div className="col-sm-12 col-md-2 my-2 ">
                            <label htmlFor="email">Priority</label>
                            <select
                              className="form-control  "
                              // id="selectMerchant"
                              value={elem.priority}
                              onChange={(e) => {
                                handelProrityInput(e, elem.sid);
                              }}
                            >
                              <option>Select</option>

                              {prorityNumber?.map((item, index) => {
                                return (
                                  <option
                                    key={mappedMerchant.length + index + 1}
                                    value={mappedMerchant.length + index + 1}
                                  >
                                    {mappedMerchant.length + index + 1}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="d-flex justify-content-center mt-4">
                <button className="btn btn-success" onClick={handleSubmit}>
                  Submit
                </button>

                <button
                  className="btn btn-danger mx-2"
                  onClick={handleCancelAction}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          {/* // EDIT FORM MODEL STARTS */}
          <Modal show={isModalOpen} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>SID Details:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Card className="shadow border-0 mb-4">
                <Card.Body>
                  <div className="merchant-report-view">
                    <div className="report-table-view">
                      <div className="containerflag ">
                        <div className="row mt-4 ">
                          <MDBDataTable
                            className="dataTable"
                            striped
                            small
                            bordered
                            hover
                            responsive
                            data={{
                              columns: sidDetailsColumn,
                              rows: sidDetailsData,
                            }}
                            searching={true}
                            entries={50}
                            exportToCSV
                            theadColor="#333"
                            paginationLabel={["Previous", "Next"]}
                            sortable={true}
                            footer="none"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Modal.Body>
          </Modal>
        </section>
      
    </>
  );
};

export default PayInRouting;
