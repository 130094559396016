import React, { useEffect, useState } from 'react'
import InactiveServices from '../../InactiveServices';
import UserManagement from './UserManagement';
import { Card, Col, Container, Row } from 'react-bootstrap';
import SubmittedUserforms from './SubmittedUserforms';

const UserManangementSubTab = ({setShowLoader}) => {
    const [activePhase, setActivePhase] = useState("Phase1");
    // const [showLoader, setShowLoader] = useState(false);
  
    useEffect(() => {
      setActivePhase("Phase1");
      //   setActiveSubtab("MerchantManagement");
    }, []);
  
    const handlePhaseClick = (phase) => {
      setActivePhase(phase);
    };
  
    return (
      <>
       {/* {showLoader ? <MyLoader /> : ""} */}
        <div className="subtab-sec-admin">
          <Container>
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4">
                  <div className="merchant-tab-btn">
                    <ul className="report-link nav-report-btn">
                      <li
                        className={activePhase === "Phase1" ? "active" : ""}
                        onClick={() => handlePhaseClick("Phase1")}
                      >
                        Create User
                      </li>
                      <li
                        className={activePhase === "Phase2" ? "active" : ""}
                        onClick={() => handlePhaseClick("Phase2")}
                      >
                        Submitted
                      </li>
                   
                    </ul>
                  </div>
                  <Card.Body>
                    <div className="admin-tab-content">
                      {
                        activePhase === "Phase1" ? (
                          <UserManagement setShowLoader={setShowLoader}/>
                      ) :
                       activePhase === "Phase2" ? (
                            <SubmittedUserforms setShowLoader={setShowLoader}/>
                      ) 
                  
                      : (
                        <InactiveServices location={"privilages"}/>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  };
  

export default UserManangementSubTab