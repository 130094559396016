import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import SIDMaster from './SIDMaster';
import InactiveServices from '../components/InactiveServices';
import SubmittedMasterSIDApplications from './SubmittedMasterSIDApplications';
import SIDDetails from './SIDDetails';
import Sid from './Sid';
import { useSelector } from "react-redux";



const SubTabForSIDMaster = ({setShowLoader}) => {

    const [activePhase, setActivePhase] = useState("Phase1");

    const { prefix } = useSelector((state) => state.privilages);
    
    useEffect(() => {
      setActivePhase("Phase1");
    }, []);
  
    const handlePhaseClick = (phase) => {
      setActivePhase(phase);
    };
  

  return (
    <>
    <section className="dashboard-header-sec subtab-card">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-3  ds-inner-card">
                  <Card.Body>
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn submenu-tab-admin">
                        <li
                          className={activePhase === "Phase1" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase1")}
                        >
                          SID MASTER FORM {activePhase === "Phase1"}
                        </li>
                        <li
                          className={activePhase === "Phase2" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase2")}
                        >
                       SUBMITTED {activePhase === "Phase2"}
                        </li>
                        <li
                          className={activePhase === "Phase3" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase3")}
                        >
                       SID Details {activePhase === "Phase3"}
                        </li>
                       { prefix === "ALL" ? (              
                         <li
                          className={activePhase === "Phase4" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase4")}
                        >
                         Merchant On-Boarding With Bank  {activePhase === "Phase4"}
                        </li>
                       ):null }
                      
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
                {/* ./card */}
              </Col>
            </Row>
            <div className="admin-tab-content">
              {activePhase === "Phase1" ? (
                <SIDMaster
                setShowLoader = {setShowLoader} />
              ) : activePhase === "Phase2" ? (
                <SubmittedMasterSIDApplications
                setShowLoader = {setShowLoader} />
              ) : activePhase === "Phase3" ?(
                <SIDDetails 
                setShowLoader = {setShowLoader} />
              ): activePhase === "Phase4" ? (
              <Sid 
              setShowLoader = {setShowLoader}/>
              ):
             
              (
                <InactiveServices location={"privages"}/>
              )}
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}

export default SubTabForSIDMaster