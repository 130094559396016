import React, { useEffect, useState } from "react";
import SubTabManage from "./SubTabManage";
import InactiveServices from "../components/InactiveServices";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import "../stylesheet/Reports.css";
import SubTabForAccounts from "./SubTabForaccounts";
import SubTabForbank from "./SubTabForbank";
import SubTabForFRM from "./SubTabForFRM";
import SubTabForRouting from "./SubTabForRouting";
import MyLoader from "../common/MyLoader";
import SubTabForCreateMerchant from "./SubTabForCreateMerchant";
import RoutingSubtab1 from "./RoutingSubtab1";
import { useSelector } from "react-redux";
import useCheckPrivilages from "../utils/checkPrivilages";

const Applications = () => {
  const [activePhase, setActivePhase] = useState("");
  const [activeSubtab, setActiveSubtab] = useState("Applications");
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setActivePhase("");
    setActiveSubtab("Applications");
  }, []);

  const hasPrivilages2693 = useCheckPrivilages(2693);
  const hasPrivilages2694 = useCheckPrivilages(2694);
  const hasPrivilages2695 = useCheckPrivilages(2695);
  const hasPrivilages2630 = useCheckPrivilages(2630);
  const hasPrivilages2142 = useCheckPrivilages(2142);
  const hasPrivilages2641 = useCheckPrivilages(2641);
  const hasPrivilages2677 = useCheckPrivilages(2677);
  const hasPrivilages2633 = useCheckPrivilages(2633);
  const hasPrivilages2634 = useCheckPrivilages(2634);
  const hasPrivilages2635 = useCheckPrivilages(2635);
  const hasPrivilages2636 = useCheckPrivilages(2636);
  const hasPrivilages2637 = useCheckPrivilages(2637);
  const hasPrivilages2638 = useCheckPrivilages(2638);
  const hasPrivilages2639 = useCheckPrivilages(2639);
  const hasPrivilages2640 = useCheckPrivilages(2640);

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  return (
    <>
      {showLoader ? <MyLoader /> : ""}
      <section className="dashboard-header-sec">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4 ds-header-card">
                  <Card.Body>
                    {/* <Card.Title className="h3">Reports</Card.Title>  */}
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn">
                        {hasPrivilages2693 ||
                        hasPrivilages2694 ||
                        hasPrivilages2695 ||
                        hasPrivilages2630 ||
                        hasPrivilages2142 ? (
                          <li
                            className={activePhase === "Phase1" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase1")}
                          >
                            Applications
                          </li>
                        ) : null}

                        {hasPrivilages2633 ||
                        hasPrivilages2634 ||
                        hasPrivilages2635 ||
                        hasPrivilages2636 ||
                        hasPrivilages2637 ||
                        hasPrivilages2638 ? (
                          <li
                            className={activePhase === "Phase2" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase2")}
                          >
                            Routing
                          </li>
                        ) : null}

                        {hasPrivilages2639 || hasPrivilages2640 ? (
                          <li
                            className={activePhase === "Phase3" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase3")}
                          >
                            MDR
                          </li>
                        ) : null}
                        {hasPrivilages2641 || hasPrivilages2677 ? (
                          <li
                            className={activePhase === "Phase4" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase4")}
                          >
                            Fraud And Risk Management
                          </li>
                        ) : null}

                        {/* <li
                          className={activePhase === "Phase4" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase4")}
                        >
                          Bank
                        </li> */}

                        {/* <li
                          className={activePhase === "Phase5" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase5")}
                        >
                          Routing
                        </li> */}
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
                {/* ./card */}
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <div className="admin-tab-content">
        {activePhase === "Phase1" ? (
          <SubTabManage setShowLoader={setShowLoader} />
        ) : activePhase === "Phase2" ? (
          <RoutingSubtab1 setShowLoader={setShowLoader} />
        ) : activePhase === "Phase3" ? (
          <SubTabForAccounts setShowLoader={setShowLoader} />
        ) : activePhase === "Phase4" ? (
          <SubTabForFRM setShowLoader={setShowLoader} />
        ) : (
          null
        )}
      </div>
    </>
  );
};
export default Applications;
