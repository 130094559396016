import axios from "axios";
import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import swal from "sweetalert";
import { SWIPELINC_API } from "../utils/constant";
import {
  fetchAllMerchantMaster,
  fetchAllVehicleMasterForm,
  getAllmerchantPayoutmasters,
} from "../utils/ApiServices";

const SubmittedPayoutSIDApplications = ({ setShowLoader }) => {
  const [applications, setApplications] = useState([]);
  const [updateId, setUpdateId] = useState("");
  const [updateMId, setUpdateMId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [isIfscError, setIsIfscError] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [callAgain, setCallAgain] = useState(false);
  const [formDataInputs, setFormDataInputs] = useState({});
  const [data, setData] = useState({
     vehicle:"",
    merchantMasterName: "",
    merchantMasterId: "",
  });
  const [vehicleData, setVehicleData] = useState([]);
  const [merchantMasterData, setMerchantMasterData] = useState([]);

  const columns = [
    { label: "Merchant ID", field: "mid", width: "auto" },
    { label: "Merchant Name", field: "companyName", width: "auto" },
    { label: "User Name", field: "userName", width: "auto" },
    { label: "Vehicle Master Name", field: "vehichleMasterName", width: "auto" },
    { label: "Merchant Master Name", field: "masterMerchantName", width: "auto" },
    { label: "Client ID", field: "clientIds", width: "auto" },
    { label: "Client Secret", field: "clientSecrete", width: "auto" },
    {
      label: "Account Module Secret",
      field: "accountsModuleSecrete",
      width: "auto",
    },
    {
      label: "Payment Module Secret",
      field: "paymentModuleSecrete",
      width: "auto",
    },
    { label: "Provider Secret", field: "providerSecrete", width: "auto" },
    {
      label: "master Virtual Account",
      field: "masterVirtualAccount",
      width: "auto",
    },
    { label: "Transfer Type", field: "transferType", width: "auto" },
    { label: "MVA Customer Id", field: "mvaCustomerId", width: "auto" },
    { label: "MVA Mobile Number", field: "mvaMobileNumber", width: "auto" },
    { label: "Master Alius Id", field: "masterAliusId", width: "auto" },
    { label: "IFSC Code", field: "ifsc", width: "auto" },
    { label: "Password", field: "password", width: "auto" },

    { label: "Action", field: "action", width: "300px" },
  ];

  const mappedData = applications.length
    ? applications?.map((item) => {
        const mappedItem = { ...item };
        mappedItem.maxTxnAmount = (
          <sapan>
            {" "}
            <b>&#8377;</b> {`${mappedItem.maxTxnAmount}`}
          </sapan>
        );

        if (mappedItem.active === true) {
          mappedItem.action = (
            <div className="d-flex gap-2 justify-content-evenly">
              <button
                className="btn btn-warning btn-sm"
                onClick={() => openDetails({ ...item })}
              >
                Edit
              </button>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => disablePayout(item.id)}
              >
                Disable
              </button>
            </div>
          );
        }

        if (mappedItem.active === false) {
          mappedItem.action = (
            <div className="d-flex gap-2 justify-content-evenly">
              <button
                className="btn btn-warning btn-sm"
                onClick={() => openDetails({ ...item })}
              >
                Edit
              </button>
              <button
                className="btn btn-success btn-sm"
                onClick={() => enablePayout(item.id)}
              >
                Enable
              </button>
            </div>
          );
        }

        return mappedItem;
      })
    : [];

  const openDetails = ({
    id,
    mid,
    accountsModuleSecrete,
    companyName,
    clientIds,
    clientSecrete,
    ifsc,

    masterAliusId,
    masterVirtualAccount,
    transferType,
    mvaCustomerId,
    mvaMobileNumber,
    password,
    paymentModuleSecrete,

    userName,
    providerSecrete,
    vehichleMasterId,
    masterMerchantId,
    masterMerchantName
  }) => {
     
    setFormDataInputs({
      accountsModuleSecrete,

      clientIds,
      clientSecrete,
      ifsc,
      mid,
      masterAliusId,
      masterVirtualAccount,
      transferType,
      mvaCustomerId,
      mvaMobileNumber,
      password,
      paymentModuleSecrete,

      userName,
      providerSecrete,
    });
    setData({
      vehicle:vehichleMasterId,
      merchantMasterName: masterMerchantName,
      merchantMasterId: masterMerchantId,
    });
    setCompanyName(companyName);
    setUpdateId(id);
    setUpdateMId(mid);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getAllVehicle = async () => {
    try {
      const response = await fetchAllVehicleMasterForm(token);

      if (response.statusCode === 200) {
        setVehicleData(response?.data);
      } else {
        setVehicleData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  

  const getAllMerchantMaster = async () => {
    try {
      const response = await fetchAllMerchantMaster(token); // Add the await keyword here
      if (response?.statusCode === 200) {
        setMerchantMasterData(response?.data);
      } else {
        setMerchantMasterData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllVehicle();
    getAllMerchantMaster();
  }, []);

  const enablePayout = (id) => {
    swal({
      title: "Alert",
      text: "Are you sure, do you want to enable?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDisable) => {
      if (willDisable) {
        setShowLoader(true);
        axios
          .put(
            `${SWIPELINC_API}MerchantAdminPanel/merchant/merchantPayoutMaster/enable/${id}`,
            "",
            {
              headers: {
                Authentication: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            setShowLoader(false)
           if (response.data.statusCode === 200) {
              swal({
                title: "Success!",
                text: "Merchant Payout Master Enable Successfully",
                icon: "success",
              });

              FetchData(token);
            } else {
              swal({
                title: "Alert!",
                text: response.data.message,
                icon: "error",
              });
            }
            setCallAgain(!callAgain);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            setShowLoader(false);
          });
      }
    });
  };

  const disablePayout = (id) => {
    swal({
      title: "Alert",
      text: "Are you sure, do you want to disable?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDisable) => {
      if (willDisable) {
        setShowLoader(true);
        axios
          .put(
            `${SWIPELINC_API}MerchantAdminPanel/merchant/merchantPayoutMaster/disable/${id}`,
            "",
            {
              headers: {
                Authentication: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            setShowLoader(false)
            

            if (response.data.statusCode === 200) {
              swal({
                title: "Success!",
                text: "Merchant Payout Master Disable Successfully",
                icon: "success",
              });

              FetchData(token);
            } else {
              swal({
                title: "Alert!",
                text: response.data.message,
                icon: "error",
              });
            }
            setCallAgain(!callAgain);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            setShowLoader(false);
          });
      }
    });
  };

  function stopSpecialCharacterInput(input) {
    // Define a regular expression for alphanumeric characters
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;

    // Test the input against the regular expression
    return alphanumericRegex.test(input);
  }

  function setNumericInput(input) {
    // Define a regular expression for numeric characters
    const numericRegex = /^[0-9]+$/;

    // Test the input against the regular expression
    return numericRegex.test(input) ? input : "";
  }

  const HandleIFSC = (e) => {
    if (e.target.name === "ifsc") {
      const ifscRegex = new RegExp(/^[a-zA-Z]{4}0[A-Z0-9]{6}$/);
      const isValidIfsc = ifscRegex.test(e.target.value);
      if (!isValidIfsc) {
        setIsIfscError(true);
        document.getElementById("ifscErr").classList.remove("d-none");

        return;
      } else {
        setIsIfscError(false);
        document.getElementById("ifscErr").classList.add("d-none");
      }
      setIsIfscError(false);
    }
  };
  const handelDataChange = (e) => {
    console.log(e.target.name, e.target.value);

    if (!e.target.value.length) {
      setFormDataInputs({
        ...formDataInputs,
        [e.target.name]: e.target.value,
      });
    }

    if (
      e.target.name === "accountsModuleSecrete" ||
      e.target.name === "paymentModuleSecrete" ||
      e.target.name === "providerSecrete" ||
      e.target.name === "ifsc" ||
      e.target.name === "clientSecrete"
    ) {
      if (stopSpecialCharacterInput(e.target.value)) {
        console.log(stopSpecialCharacterInput(""));
        setFormDataInputs({
          ...formDataInputs,
          [e.target.name]: e.target.value,
        });
      } else {
        return;
      }
    } else if (
      e.target.name === "mvaMobileNumber" ||
      e.target.name === "masterVirtualAccount" ||
      e.target.name === "mvaCustomerId"
    ) {
      if (setNumericInput(e.target.value)) {
        setFormDataInputs({
          ...formDataInputs,
          [e.target.name]: e.target.value === "" ? "" : e.target.value,
        });
      } else {
        return;
      }
    } else {
      setFormDataInputs({
        ...formDataInputs,
        [e.target.name]: e.target.value === "" ? "" : e.target.value,
      });
    }
  };

  const handelVehicleDataChange = (e) => {
    if (e.target.name === "vehicle") {
      const selectedOption = e.target.options[e.target.selectedIndex];
      const master = selectedOption.getAttribute("data-master");
      const masterId = selectedOption.getAttribute("data-masterId");
      console.log(masterId,master,e.target.value);
      if (e.target.value === "") {
        setData({
          ...data,
          [e.target.name]: "",
          merchantMasterName: "",
          merchantMasterId: "",
        });
        return;
      }
      setData({
        ...data,
        [e.target.name]: e.target.value,
        merchantMasterName: master,
        merchantMasterId: masterId,
      });
      
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }

    console.log(data);
  };

 

  const handleUpdateSubmit = (e) => {
    // console.log("edit form data", formDataInputs);
    e.preventDefault();

    if (formDataInputs.accountsModuleSecrete === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Account Module Secret",
        icon: "error",
      });
      return;
    }

    if (formDataInputs.clientIds === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Client ID",
        icon: "error",
      });
      return;
    }

    if (formDataInputs.clientSecrete === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Client Secrete",
        icon: "error",
      });
      return;
    }

    if (formDataInputs.ifsc === "") {
      swal({
        title: "Alert!",
        text: "Please Enter IFSC ",
        icon: "error",
      });
      return;
    }

    if (formDataInputs.mvaCustomerId === "") {
      swal({
        title: "Alert!",
        text: "Please Enter MVA Customer ID ",
        icon: "error",
      });
      return;
    }

    if (formDataInputs.masterAliusId === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Master Alius ID ",
        icon: "error",
      });
      return;
    }

    if (formDataInputs.masterVirtualAccount === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Master Virtual Account ",
        icon: "error",
      });
      return;
    }

    if (formDataInputs.userName === "") {
      swal({
        title: "Alert!",
        text: "Please Enter UserName ",
        icon: "error",
      });
      return;
    }

    if (formDataInputs.providerSecrete === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Provider Secrete ",
        icon: "error",
      });
      return;
    }

    if (formDataInputs.paymentModuleSecrete === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Payment Module Secrete ",
        icon: "error",
      });
      return;
    }

    if (formDataInputs.password === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Password ",
        icon: "error",
      });
      return;
    }

    if (formDataInputs.mvaMobileNumber === "") {
      swal({
        title: "Alert!",
        text: "Please Enter MVA Mobile Number ",
        icon: "error",
      });
      return;
    }

    const ifscRegex = new RegExp(/^[a-zA-Z]{4}0[A-Z0-9]{6}$/);
    const isValidIfsc = ifscRegex.test(formDataInputs.ifsc);
    if (!isValidIfsc) {
      swal({
        title: "Alert!",
        text: "Please Enter Valid IFSC Code ",
        icon: "error",
      });
      return;
    }

    if (formDataInputs.mvaMobileNumber.length !== 10) {
      swal({
        title: "Alert!",
        text: "Please Enter Valid MVA Mobile Number ",
        icon: "error",
      });
      return;
    }

    const payload={
      ...formDataInputs,
      masterMerchantId:data.merchantMasterId,
      vehichleMasterId:data.vehicle
    }

    console.log(payload)
    // console.log(data.masterMerchantId)

    setShowLoader(true);
    axios
      .put(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/merchantPayoutMaster/update/${updateId}`,
        payload,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);

        if (response.data.statusCode === 200) {
          swal({
            title: "Success!",
            text: response.data.message,
            icon: "success",
          });
          setFormDataInputs({
            sid: "",
            txnAmt: "",
            mid: "",
            merchantName: "",
            id: "",
          });
          handleCloseModal();
          setCallAgain(!callAgain);
        } else {
          swal({
            title: "Alert!",
            text: response.data.message,
            icon: "error",
          });
        }
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setShowLoader(false);
      });
  };

  const FetchData = async (token) => {
    setShowLoader(true);
    try {
      const apiResponse = await getAllmerchantPayoutmasters(token);
      if (apiResponse.statusCode === 200) {
        setApplications(apiResponse?.data);
        console.log(apiResponse.data);
      } else {
        setApplications([]);
      }
      setShowLoader(false);
    } catch (error) {
      // console.error(error);
      console.log(error);
      setShowLoader(false);
    }
  };

  useEffect(() => {
    FetchData(token);
  }, [callAgain]);

  return (
    <>
      <Row>
        <Col xs={12} lg={12} md={12}>
          <Card className="shadow border-0 mb-4">
            <Card.Body>
              <div className="report-grid-view">
                <div className="material-table-records mb-3">
                  <MDBDataTable
                    className="dataTable"
                    striped
                    small
                    bordered
                    hover
                    responsive
                    data={{
                      columns: columns,
                      rows: mappedData,
                    }}
                    searching={true}
                    entries={50}
                    exportToCSV
                    theadColor="#333"
                    paginationLabel={["Previous", "Next"]}
                    sortable={true}
                    footer="none"
                  />
                </div>

                {/* EDIT FORM MODEL STARTS */}
                <Modal show={isModalOpen} onHide={handleCloseModal} size="xl">
                  <Modal.Header closeButton>
                    <Modal.Title>Edit Payout Master:</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Card className="shadow border-0 mb-4">
                      <Card.Body>
                        <div className="merchant-report-view">
                          <div className="report-table-view">
                            <div className="containerflag ">
                              <div className="row">
                                <div className="col-sm-12 col-md-8  offset-md-2 ">
                                  <label htmlFor="email">Merchant Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    placeholder="Merchant Name"
                                    value={companyName}
                                    readOnly
                                  />
                                </div>

                                <div className="col-sm-12 col-md-8 offset-md-2">
                                  <div className="form-group mt-3">
                                    <label htmlFor="email">Merchant MID</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="email"
                                      placeholder="Company Name"
                                      value={updateMId}
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </div>
                              <Row className="mt-2">
                                <Col xs={12} lg={4} md={6} className="mt-3">
                                  <div className="form-group">
                                    <label htmlFor="email">
                                      Select A Vehicle
                                    </label>
                                    <select
                                      id="companyDropdown"
                                      className="form-control"
                                      onChange={handelVehicleDataChange}
                                      name="vehicle"
                                      value={data.vehicle}
                                    >
                                      <option value="">Select a Vehicle</option>
                                      {vehicleData?.map((vehicle) => (
                                        <option
                                          key={vehicle.id}
                                          data-master={
                                            vehicle.masterMerchantName
                                          }
                                          data-masterId={
                                            vehicle.masterMerchantId
                                          }
                                          value={vehicle.id}
                                        >
                                          {vehicle.vehicleName}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </Col>
                                <Col xs={12} lg={4} md={6} className="mt-3">
                                  <div className="form-group">
                                    <label htmlFor="email">
                                      Select Master Merchant
                                    </label>
                                    <input
                                      id="companyDropdown"
                                      className="form-control"
                                      // onChange={handelVehicleDataChange}
                                      readOnly
                                      name="merchantMaster"
                                      value={data.merchantMasterName}
                                    />
                                  </div>
                                </Col>
                                <Col xs={12} lg={4} md={6} className="mt-3">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Account Module Secret
                                      <sup className="text-danger">*</sup>
                                    </label>
                                    <div className="d-flex gap-2">
                                      <input
                                        className="form-control"
                                        name="accountsModuleSecrete"
                                        value={
                                          formDataInputs.accountsModuleSecrete
                                        }
                                        onChange={handelDataChange}
                                      />
                                    </div>
                                  </div>
                                  {/* ./form-group */}
                                </Col>

                                <Col xs={12} lg={4} md={6} className="mt-3">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Client Id
                                      <sup className="text-danger">*</sup>
                                    </label>
                                    <div className="d-flex gap-2">
                                      <input
                                        className="form-control"
                                        name="clientIds"
                                        value={formDataInputs.clientIds}
                                        onChange={handelDataChange}
                                      />
                                    </div>
                                  </div>
                                  {/* ./form-group */}
                                </Col>
                                <Col xs={12} lg={4} md={6} className="mt-3">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Client Secret
                                      <sup className="text-danger">*</sup>
                                    </label>
                                    <div className="d-flex gap-2">
                                      <input
                                        className="form-control"
                                        name="clientSecrete"
                                        value={formDataInputs.clientSecrete}
                                        onChange={handelDataChange}
                                      />
                                    </div>
                                  </div>
                                  {/* ./form-group */}
                                </Col>
                                <Col xs={12} lg={4} md={6} className="mt-3">
                                  <div className="form-group">
                                    <label className="form-label">
                                      IFSC
                                      <sup className="text-danger">*</sup>
                                    </label>
                                    <div className="d-flex gap-2">
                                      <input
                                        className="form-control"
                                        name="ifsc"
                                        value={formDataInputs.ifsc}
                                        onChange={handelDataChange}
                                      />
                                    </div>
                                  </div>

                                  {/* ./form-group */}
                                </Col>

                                <Col xs={12} lg={4} md={6} className="mt-3">
                                  <div className="form-group">
                                    <label className="form-label">
                                      MVA Customer Id
                                      <sup className="text-danger">*</sup>
                                    </label>
                                    <div className="d-flex gap-2">
                                      <input
                                        className="form-control"
                                        name="mvaCustomerId"
                                        value={formDataInputs.mvaCustomerId}
                                        onChange={handelDataChange}
                                      />
                                    </div>
                                  </div>
                                  {/* ./form-group */}
                                </Col>
                                <Col xs={12} lg={4} md={6} className="mt-3">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Master Alius Id
                                      <sup className="text-danger">*</sup>
                                    </label>
                                    <div className="d-flex gap-2">
                                      <input
                                        className="form-control"
                                        name="masterAliusId"
                                        value={formDataInputs.masterAliusId}
                                        onChange={handelDataChange}
                                      />
                                    </div>
                                  </div>
                                  {/* ./form-group */}
                                </Col>
                                <Col xs={12} lg={4} md={6} className="mt-3">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Master Virtual Account
                                      <sup className="text-danger">*</sup>
                                    </label>
                                    <div className="d-flex gap-2">
                                      <input
                                        className="form-control"
                                        name="masterVirtualAccount"
                                        value={
                                          formDataInputs.masterVirtualAccount
                                        }
                                        onChange={handelDataChange}
                                      />
                                    </div>
                                  </div>
                                  {/* ./form-group */}
                                </Col>

                                <Col xs={12} lg={4} md={6} className="mt-3">
                                  <div className="form-group">
                                    <label className="form-label">
                                      User Name
                                      <sup className="text-danger">*</sup>
                                    </label>
                                    <div className="d-flex gap-2">
                                      <input
                                        className="form-control"
                                        name="userName"
                                        value={formDataInputs.userName}
                                        onChange={handelDataChange}
                                      />
                                    </div>
                                  </div>
                                  {/* ./form-group */}
                                </Col>
                                <Col xs={12} lg={4} md={6} className="mt-3">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Provider Secret
                                      <sup className="text-danger">*</sup>
                                    </label>
                                    <div className="d-flex gap-2">
                                      <input
                                        className="form-control"
                                        name="providerSecrete"
                                        value={formDataInputs.providerSecrete}
                                        onChange={handelDataChange}
                                      />
                                    </div>
                                  </div>
                                  {/* ./form-group */}
                                </Col>
                                <Col xs={12} lg={4} md={6} className="mt-3">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Payment Module Secret
                                      <sup className="text-danger">*</sup>
                                    </label>
                                    <div className="d-flex gap-2">
                                      <input
                                        className="form-control"
                                        name="paymentModuleSecrete"
                                        value={
                                          formDataInputs.paymentModuleSecrete
                                        }
                                        onChange={handelDataChange}
                                      />
                                    </div>
                                  </div>
                                  {/* ./form-group */}
                                </Col>
                                <Col xs={12} lg={4} md={6} className="mt-3">
                                  <div className="form-group">
                                    <label className="form-label">
                                      Password
                                      <sup className="text-danger">*</sup>
                                    </label>
                                    <div className="d-flex gap-2">
                                      <input
                                        className="form-control"
                                        name="password"
                                        value={formDataInputs.password}
                                        onChange={handelDataChange}
                                      />
                                    </div>
                                  </div>
                                  {/* ./form-group */}
                                </Col>
                                <Col xs={12} lg={4} md={6} className="mt-3">
                                  <div className="form-group">
                                    <label className="form-label">
                                      MVA Mobile Number
                                      <sup className="text-danger">*</sup>
                                    </label>
                                    <div className="d-flex gap-2">
                                      <input
                                        className="form-control"
                                        name="mvaMobileNumber"
                                        value={formDataInputs.mvaMobileNumber}
                                        onChange={handelDataChange}
                                      />
                                    </div>
                                  </div>

                                  {/* ./form-group */}
                                </Col>
                              </Row>
                              <div>
                                <div className="d-flex justify-content-center mt-3">
                                  <Button
                                    id="UpdateFormBtn"
                                    onClick={handleUpdateSubmit}
                                  >
                                    Update
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Modal.Body>
                </Modal>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SubmittedPayoutSIDApplications;
