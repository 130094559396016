import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import CreateMerchant from "./CreateMerchantPhase1";
import InactiveServices from "../components/InactiveServices";
import CreateMerchantPhase2 from "./CreateMerchantPhase2";
import useCheckPrivilages from "../utils/checkPrivilages";

const SubTabForCreateMerchant = ({ setShowLoader }) => {
  const [activePhase, setActivePhase] = useState("");
  // const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setActivePhase("");
  }, []);
  const hasPrivilage = useCheckPrivilages(2630);

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  return (
    <>
      <section className="dashboard-header-sec subtab-card">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-3  ds-inner-card">
                  <Card.Body>
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn submenu-tab-admin">
                        <li
                          className={activePhase === "Phase1" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase1")}
                        >
                          Phase 1 {activePhase === "Phase1"}
                        </li>

                        <li
                          className={activePhase === "Phase2" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase2")}
                        >
                          Phase 2 {activePhase === "Phase2"}
                        </li>
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <div className="admin-tab-content">
              {activePhase === "Phase1" ? (
                <CreateMerchant setShowLoader={setShowLoader} />
              ) : activePhase === "Phase2" ? (
                <CreateMerchantPhase2 setShowLoader={setShowLoader} />
              ) : null}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default SubTabForCreateMerchant;
