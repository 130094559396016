import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { SWIPELINC_API } from "../utils/constant";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Card, Row, Button, Col } from "react-bootstrap";

import DownloadCsv from "../common/DownloadCsv";
import { headerLabelsForReconciliation } from "../utils/DownloadHeaders";
import { getAllDailyTransactionDataReconciliation } from "../utils/ApiServices";
import MidFiter from "./MidFiter";
import ReconMultiFilter from "../components/Tools/ALL PAYOUT REPORTS/ReconMultiFilter";
import ReconAdvanceSearch from "../components/Tools/ALL PAYOUT REPORTS/ReconAdvanceSearch";

function LiveReconciliationReport({ reportType, setShowLoader ,dropDownData}) {
  const [data, setData] = useState();

  const token = localStorage.getItem("token");
  const [showTable, setShowTable] = useState(false);
  const [isMultipleFilterOpen, setIsMultipleFilterOpen] = useState(false);
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
  const [currentPage,setCurrentPage] = useState(0)
  const [reset, setReset] = useState(false);
  const [show2, setShow2] = useState(false);
  const { mid } = useParams();
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const entriesPerPageOptions = [5,10, 20, 30,40,50]; 
  const navigate = useNavigate();

  const getDailyTransactionData = (token,page=0,size=entriesPerPage) => {
    setShowLoader(true)
    const response = getAllDailyTransactionDataReconciliation(token,page,size)
      .then((response) => {
        if (response.statusCode === 200) {
          setData(response?.data);
          setShowLoader(false)
        } else {
          setData([]);
          console.error("Error fetching data:", response.data.message);
          setShowLoader(false)
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setShowLoader(false)
      });
  };
  const handlePageChange = (newPage) => {
    console.log("New page:", newPage);
    setCurrentPage(newPage);
    getDailyTransactionData(token,newPage)
  };
  const handleEntriesChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setEntriesPerPage(value);
      setCurrentPage(0);
      getDailyTransactionData(token, 0, value);
    }
  };
  const columns = [
    { label: "Merchant ID", field: "mid", width: "auto" },
    { label: "Company Name", field: "companyName", width: "auto" },
    { label: "Transaction ID", field: "extId", width: "auto" },
    { label: "Date", field: "date", width: "auto" },
    { label: "UTR Number", field: "rrn", width: "auto" },
    { label: "Amount", field: "amount", width: "auto" },
    { label: "Transaction Status", field: "transactionStatus", width: "auto" },
    {label: "Consolidated Status",field: "consolidatedStatus",width: "auto",},
    { label: "Payin Status", field: "payinStatus", width: "auto" },
  ];
  const mappedData = data?.map((item) => {
    const mappedItem = {};
    columns.forEach((column) => {
      if (column.field === "date") {
        const dateValue = item["date"] ? item["date"].split(" ")[0] : "";
        mappedItem[column.field] = dateValue;
      } else if (column.field === "extId") {
        const extId = item["extId"] ? item["extId"]  : "";
        mappedItem[column.field] = extId;
      }else{
        mappedItem[column.field] = item[column.field];
      }
    });
    return mappedItem;
  });
  // const handleBackButtonClick = () => {
  //   navigate(-1);
  // };

  const handleFilter = () => {
    setIsMultipleFilterOpen(!isMultipleFilterOpen);
    setIsAdvancedSearchOpen(false);
    // setIsDateFilterOpen(false);
    // setShow2(!show2);
  };

  const handleMidFilter = () => {
    setShow2(true);
  };
  
  const HandleMultipleFilter = () => {
    setIsMultipleFilterOpen(true);
  };

  const HandleAdvanceFilter = () => {
    setIsAdvancedSearchOpen(true);
  };

  const handleAdvanceSearch = () => {
    setIsAdvancedSearchOpen(!isAdvancedSearchOpen);
    // setIsDateFilterOpen(false);
    setIsMultipleFilterOpen(false);
    // setShow(!show);
  };

  const HandleReset = () => {
    setReset(!reset);
  };
  useEffect(() => {
    getDailyTransactionData(token,currentPage);
  }, [reset,currentPage,entriesPerPage]);

  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    handlePageChange(nextPage);
  };

  const handlePreviousPage = () => {
    const previousPage = currentPage - 1;
    handlePageChange(previousPage);
  };
  return (
    <>
      <section className="lincpay-dashboard-sec">
      <div className="d-flex align-items-center">
          <label htmlFor="entriesPerPage">Entries per page:</label>
          <select
            id="entriesPerPage"
            value={entriesPerPage}
            onChange={handleEntriesChange}
            className="form-select form-select-sm" 
            style={{ maxWidth: '100px' }}
          >
            {entriesPerPageOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <Col className="d-flex justify-content-end gap-2">
          <Button onClick={handleMidFilter}>Merchant</Button>
          <Button onClick={HandleMultipleFilter}>
                        More Filter
                      </Button>
                      <Button onClick={HandleAdvanceFilter}>
                        Advance Filter
                      </Button>
          <Button
            className="d-flex align-items-center justify-content-center gap-2"
            onClick={HandleReset}
          >
            Reset<i class="bi bi-arrow-clockwise"></i>
          </Button>
        </Col>
        <div className="dasboard-view-count-boxes">
          <Card.Body>
            <div className="report-grid-view">
              <div className="material-table-records mb-3">
                <MDBDataTable
                  className="dataTable"
                  striped
                  bordered
                  small
                  data={{
                    columns: columns,
                    rows: mappedData,
                  }}
                  searching={true}
                  responsive
                  sorting={true}
                  hover
                  footer="none"
                  page={currentPage}
                  onPageChange={handlePageChange}
                  pagination={false}
                  paging={false}
                />
              </div>
              <DownloadCsv DownLoadData={data} />
              <div className="pagination-buttons d-flex justify-content-end gap-2">
        <Button onClick={handlePreviousPage} disabled={currentPage === 0}>
          Previous
        </Button>
        <Button onClick={handleNextPage}>Next</Button>
      </div>
            </div>
          </Card.Body>
          <MidFiter
            reportType={reportType}
            setShow2={setShow2}
            show2={show2}
            setLiveData={setData}
          />

          {isMultipleFilterOpen ? (
            <ReconMultiFilter
              setReconReportData={setData}
              setIsMultiFilterOpen={setIsMultipleFilterOpen}
              setShowLoader={setShowLoader}
              
            />
          ) : isAdvancedSearchOpen ? (
            <ReconAdvanceSearch
              setReconReportData={setData}
              setIsAdvanceFilterOpen={setIsAdvancedSearchOpen}
              setShowLoader={setShowLoader}
            />
          ) : (
            ""
          )}
        </div>
        {/* </Container> */}
      </section>
    </>
  );
}

export default LiveReconciliationReport;
