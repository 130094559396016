import React, { useEffect, useState } from "react";
import InactiveServices from "./InactiveServices";
import EkycRouting from "./Tools/EkycRouting";
import { Card, Col, Row } from "react-bootstrap";
import SubmittedRoutingEkyc from "./Tools/ALL PAYOUT REPORTS/SubmittedRoutingEkyc";
import { useSelector } from "react-redux";
import useCheckPrivilages from "../utils/checkPrivilages";

const RoutingSubTabForEkyc = ({ location, setShowLoader }) => {
  const [activePhase, setActivePhase] = useState("");

  const { privilagesData } = useSelector((state) => state?.privilages);

  const hasPrivilage2688 = useCheckPrivilages(2688);
  const hasPrivilage2689 = useCheckPrivilages(2689);

  useEffect(() => {
    setActivePhase("");
    //   setActiveSubtab("MerchantManagement");
  }, []);

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  return (
    <>
      <>
        <section className="dashboard-header-sec">
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4 ds-header-card">
                  <Card.Body>
                    {/* <Card.Title className="h3">Reports</Card.Title>  */}
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn">
                        {!hasPrivilage2689 ? null : (
                          <li
                            className={activePhase === "Phase2" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase2")}
                          >
                            Routing
                          </li>
                        )}
                        {!hasPrivilage2688 ? null : (
                          <li
                            className={activePhase === "Phase1" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase1")}
                          >
                            Submitted
                          </li>
                        )}
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </section>
{ activePhase === "" ? null :  <div>
          {activePhase === "Phase2" ? (
            <EkycRouting location={location} setShowLoader={setShowLoader} />
          ) : activePhase === "Phase1" ? (
            <SubmittedRoutingEkyc
              location={location}
              setShowLoader={setShowLoader}
            />
          ) : (
            <InactiveServices location={"kjhgfdsdfg"} />
          )}
        </div>}
        
      </>
    </>
  );
};

export default RoutingSubTabForEkyc;
