import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { SWIPELINC_API } from "../utils/constant";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Card, Row,Button, Col } from "react-bootstrap";
import { getAllDailyTransactionDataChargeback } from "../utils/ApiServices";
import DownloadCsv from "../common/DownloadCsv";
import { headerLabelsForChargeback } from "../utils/DownloadHeaders";
import LiveChargebackAdvanceFilter from "./LiveChargebackAdvanceFilter";
import LiveChargebackMultipleFilter from "./LiveChargebackMultipleFilter";
import MidFiter from "./MidFiter";


function LiveChargebackReport({reportType,setShowLoader,dropDownData}) {
  const [data, setData] = useState();

  const token = localStorage.getItem("token");
  const [showTable, setShowTable] = useState(false);
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);
  const [isMultipleFilterOpen, setIsMultipleFilterOpen] = useState(false);
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
  const [show2, setShow2] = useState(false);
 const [currentPage,setCurrentPage] = useState(0)
 const [entriesPerPage, setEntriesPerPage] = useState(10);
 const entriesPerPageOptions = [10, 20, 30,40,50];
  const [reset, setReset] = useState(false);
  const { mid } = useParams();
  const navigate = useNavigate();
 
    const getDailyTransactionData = (token,page=0,size=entriesPerPage) =>{
      setShowLoader(true)
    const response= getAllDailyTransactionDataChargeback(token,page,size)
        .then((response) => {
          if (response.statusCode === 200){
          setData(response?.data);
          setShowLoader(false)
       }
       else{
        setData([])
        setShowLoader(false)
       }
       })
       .catch((error) => {
        console.error("Error saving data:", error);
        setShowLoader(false)
      });
    };
      
    const handlePageChange = (newPage) => {
      console.log("New page:", newPage);
      setCurrentPage(newPage);
      getDailyTransactionData(token,newPage)
    };
    const handleEntriesChange = (e) => {
      const value = parseInt(e.target.value, 10);
      if (!isNaN(value) && value > 0) {
        setEntriesPerPage(value);
        // Reset to first page when changing entries per page
        setCurrentPage(0);
        getDailyTransactionData(token, 0, value);
      }
    };
  const columns = [
     { label: "Merchant ID", field: "mid", width: "auto" },
     { label: "Company Name", field: "fullName", width: "auto" },
     { label: "Merchant Calllback Acknowledged", field: "merchantCallbackResponse", width: "auto" },
     { label: "Merchant Callback Received", field: "callBackRecive", width: "auto" },
     { label: "Callback Send", field: "isCallbackSand", width: "auto" },
     { label: "ChargeBack Adjustment Date", field: "chargeBackAdjDate", width: "auto" },
     { label: "Create Date", field: "createDate", width: "auto" },
     { label: "ChargeBack Amount", field: "chargeBackAmount", width: "auto" },
    { label: "UTR Number", field: "rrn", width: "auto" },
    { label: "Compensation Penalty", field: "compensationPenalty", width: "auto" },
    { label: "Transaction Date", field: "transactionDate", width: "auto" },
    { label: "IFSC Code", field: "ifscCode", width: "auto" },
    { label: "Payer Vpa", field: "payerVpa", width: "auto" },
    { label: "Payee Bank", field: "payeeBank", width: "auto" },
    // {label: "Transaction UID",field: "transactionUid",width: "auto"},
    { label: "Mobile Number", field: "mobileNumber", width: "auto" },
    { label: "e-Mail ID", field: "emailId", width: "auto" },
    // { label: "IFSC Code", field: "ifscCode", width: "auto" },
    { label: "Bank Location", field: "bankLocation", width: "auto" },
   
    // { label: "Time", field: "createTime", width: "auto" },
    { label: "Payee Location", field: "payeeLocation", width: "auto" },
  ];
  const mappedData = data?.map((item) => {
    const mappedItem = {};
    columns.forEach((column) => {
 if (column.field === "createDate" || column.field ==="chargeBackAdjDate" || column.field=== "transactionDate" ){
      const dateValue = item[column.field] ? item[column.field].split(' ')[0] : '';
      mappedItem[column.field] = dateValue;
      }else if(column.field === "isCallbackSand"){
        const lkjhValue = item[column.field] === true ? "Yes" : 'No';
      mappedItem[column.field] = lkjhValue;
      }else if(column.field === "callBackRecive"){
        const iuyfddfgh = item[column.field] === "yes" ? "Yes" : 'No';
      mappedItem[column.field] = iuyfddfgh;
      }
        else
      {
        mappedItem[column.field] = item[column.field];
      }
        });
    return mappedItem;
  });
  const handleBackButtonClick = () => {
    navigate(-1);
  };

  
  const handleFilter = () => {
    // setIsMultipleFilterOpen(!isMultipleFilterOpen);
    // setIsAdvancedSearchOpen(false);
    // // setIsDateFilterOpen(false);
    setShow2(!show2);
  };

  const handleAdvanceSearch = () => {
    setIsAdvancedSearchOpen(!isAdvancedSearchOpen);
    // setIsDateFilterOpen(false);
    setIsMultipleFilterOpen(false);
    // setShow(!show);
  };

  const handleAdvanceFilter = () => {
    setShowAdvanceFilter(true);
  };
  const handleMultipleFilter = () => {
    setIsMultipleFilterOpen(true);
  };

  const HandleReset = () => {
    setReset(!reset);
  };
  useEffect(() => {
    getDailyTransactionData(token,currentPage);
    }, [reset,currentPage,entriesPerPage])

  const handleNextPage = () => {
    const nextPage = currentPage + 1;  
    handlePageChange(nextPage);
  };

  const handlePreviousPage = () => {
    const previousPage = currentPage - 1;
    handlePageChange(previousPage);
  };
  return (
    <>
      <section className="lincpay-dashboard-sec">
      <div className="d-flex align-items-center">
          <label htmlFor="entriesPerPage">Entries per page:</label>
          <select
            id="entriesPerPage"
            value={entriesPerPage}
            onChange={handleEntriesChange}
            className="form-select form-select-sm" 
            style={{ maxWidth: '100px' }}
          >
            {entriesPerPageOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
          <Col className="d-flex justify-content-end gap-2">
            <Button onClick={handleFilter}>Merchant</Button>
            <Button onClick={handleMultipleFilter}>More  Filter</Button>
            <Button onClick={handleAdvanceFilter}>Advance  Filter</Button>

            <Button
              className="d-flex align-items-center justify-content-center gap-2"
              onClick={HandleReset}
            >
              Reset<i class="bi bi-arrow-clockwise"></i>
            </Button>
          </Col>
          <div className="dasboard-view-count-boxes">
            <Card.Body>
              <div className="report-grid-view">
                <div className="material-table-records mb-3">
                    <MDBDataTable
                      className="dataTable"
                      striped
                      bordered
                      small
                      data={{
                        columns: columns,
                        rows: mappedData,
                      }}
                      searching={true}
                      responsive
                      sorting={true}
                      hover
                      footer="none"
                      page={currentPage}
                      onPageChange={handlePageChange}
                      pagination={false}
                      paging={false}
                    />

                  </div>
                  <DownloadCsv DownLoadData={data} 
                  Name={`Live Chargeback Report`} />
                   <div className="pagination-buttons d-flex justify-content-end gap-2">
        <Button onClick={handlePreviousPage} disabled={currentPage === 0}>
          Previous
        </Button>
        <Button onClick={handleNextPage}>Next</Button>
      </div>
                </div>
              </Card.Body>
              <MidFiter reportType={reportType} setShow2={setShow2} show2={show2} setLiveData={setData}  />
             
              { showAdvanceFilter ? (
            <LiveChargebackAdvanceFilter
              setShowLoader={setShowLoader}
              setChargebackReportData={setData}
              setIsAdvanceFilterOpen={setShowAdvanceFilter}
            />
          ) : isMultipleFilterOpen ? (
            <LiveChargebackMultipleFilter
              setShowLoader={setShowLoader}
              setChargebackReportData={setData}
              setIsMultipleFilterOpen={setIsMultipleFilterOpen}
              dropDownData={dropDownData}
            />
          ) :""}
          </div>
        {/* </Container> */}
      </section>
    </>
  );
}

export default LiveChargebackReport;