import React, { useEffect, useState } from 'react'
import InactiveServices from '../../InactiveServices';
import { Card, Col, Container, Row } from 'react-bootstrap';
import CreateAggregator from '../AggregatorManage/CreateAggregator';
import SubmittedAggregator from '../AggregatorManage/SubmittedAggregator';
import MyLoader from '../../../common/MyLoader';

function AggregatorManagement( ) {
  const [activePhase, setActivePhase] = useState("Phase1");
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setActivePhase("Phase1");
    //   setActiveSubtab("MerchantManagement");
  }, []);

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  return (


    <>
      {showLoader ? <MyLoader /> : ""}
      <section className="dashboard-header-sec">
        <Container>
          <div className="ds-header-sec">
              
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4 ds-header-card">
                  <Card.Body>
                    {/* <Card.Title className="h3">Reports</Card.Title>  */}
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn">
                        <li
                          className={activePhase === "Phase1" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase1")}
                        >
                          Create Master Merchant
                        </li>
                        <li
                          className={activePhase === "Phase2" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase2")}
                        >
                          Submitted
                        </li>

                        {/* <li
                    className={activePhase === "Phase5" ? "active" : ""}
                    onClick={() => handlePhaseClick("Phase5")}
                  >
                    INSTRUMENT MANAGEMENT
                  </li> */}




                      </ul>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <Container> 
        <Card className='p-4'> 
      {activePhase === "" ? null : (
        <div>
          {activePhase === "Phase1" ? (
            <CreateAggregator setShowLoader={setShowLoader} />


          ) : activePhase === "Phase2" ? (
            <SubmittedAggregator setShowLoader={setShowLoader} />
          ) : (
            <InactiveServices location={"SubTabTools"} />
          )}
        </div>
      )}
      </Card>
      </Container>
    </>
  );
};

export default AggregatorManagement
