import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllSubmittedEkycRouting } from "../../../utils/ApiServices";
import EditEkycRouting from "./EditEkycRouting";
import useCheckPrivilages from "../../../utils/checkPrivilages";

const SubmittedRoutingEkyc = ({ location, setShowLoader }) => {
  const [applications, setApplications] = useState([]);
  const [applicationData, setApplicationData] = useState([]);
  const [openView, setOpenView] = useState(false);
  const [mid, setMid] = useState("");
  const [name, setName] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const navigate = useNavigate();

  const hasPrivilages2638 = useCheckPrivilages(2638);

  useEffect(() => {
    FetchData(token);
  }, [openView]);

  const FetchData = async (token) => {
    
    try {
      setShowLoader(true)
      const apiResponse = await getAllSubmittedEkycRouting(token);
      setShowLoader(false)
      if (apiResponse.statusCode === 200) {
        setApplications(apiResponse?.data);
        console.log(apiResponse.data);
      } else {
      }
      
    } catch (error) {
      // console.error(error);
      console.log(error);
      setShowLoader(false);
    }
  };

  const columns = [
    { label: "Merchant Name", field: "company", width: "auto" },
    { label: "Merchant ID", field: "mid", width: "auto" },
    { label: "Vehicle Master Name", field: "vehicleMaster", width: "auto" },
    { label: "Master Merchant Name", field: "merchantMaster", width: "auto" },
    { label: "Action", field: "action", width: "auto" },
  ];

  const mappedData = applications?.map((item) => {
    const mappedItem = { ...item };
    if (location === "tools") {
      mappedItem.action = (
        <div>
          {hasPrivilages2638 ? (
            <button
              className="btn btn-primary"
              onClick={() => ViewDetails({ ...item })}
            >
              Edit
            </button>
          ) : null}
        </div>
      );
    } else {
    }

    return mappedItem;
  });

  const ViewDetails = (data) => {
    setApplicationData(data);
    setOpenView(true);
  };

  return (
    <>
      <div className="card p-4">
        {openView ? (
          <EditEkycRouting
            setShowLoader={setShowLoader}
            ApplicationData={applicationData}
            setOpenView={setOpenView}
            openView={openView}
          />
        ) : (
          <MDBDataTable
            className="dataTable"
            striped
            small
            bordered
            hover
            responsive
            data={{
              columns: columns,
              rows: mappedData,
            }}
            searching={true}
            entries={50}
            exportToCSV
            theadColor="#333"
            paginationLabel={["Previous", "Next"]}
            sortable={true}
            footer="none"
          />
        )}
      </div>
    </>
  );
};

export default SubmittedRoutingEkyc;
