import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import InactiveServices from "../../components/InactiveServices";
import ViewMappedSID from "../../components/Tools/ALL PAYOUT REPORTS/ViewMappedSID ";
import SubmittedRoutingPayin from "../../components/Tools/ALL PAYOUT REPORTS/SubmittedRoutingPayin";
import PayInRoutingOnBoarding from "./PayInRoutingOnBording";
import useCheckPrivilages from "../../utils/checkPrivilages";

const RoutingSubTabForPayinOnBorading = ({ location, setShowLoader }) => {
  const [activePhase, setActivePhase] = useState("");
  // const [activeSubtab, setActiveSubtab] = useState("MerchantManagement");
  // const [showLoader, setShowLoader] = useState(false);

  const { privilagesData } = useSelector((state) => state?.privilages);

  const hasPrivilages2634=useCheckPrivilages(2634)
  const hasPrivilages2633=useCheckPrivilages(2633)

  console.log(location);

  useEffect(() => {
    setActivePhase("");
    //   setActiveSubtab("MerchantManagement");
  }, []);

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  return (
    <>
      {/* {showLoader ? <MyLoader/> : ""} */}

      <>
        <section className="dashboard-header-sec">
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4 ds-header-card">
                  <Card.Body>
                    {/* <Card.Title className="h3">Reports</Card.Title>  */}
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn">
                        {hasPrivilages2634 ? (
                          <li
                            className={activePhase === "Phase1" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase1")}
                          >
                            Routing
                          </li>
                        ) : null}
                        {hasPrivilages2633 ? (
                          <li
                            className={activePhase === "Phase2" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase2")}
                          >
                            Submitted
                          </li>
                        ) : null}
                        {hasPrivilages2633 ? (
                          <li
                            className={activePhase === "Phase3" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase3")}
                          >
                            View Mapped SID
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </section>

        <div>
          {activePhase === "Phase1" ? (
            <PayInRoutingOnBoarding
              location={location}
              setShowLoader={setShowLoader}
            />
          ) : activePhase === "Phase2" ? (
            <SubmittedRoutingPayin
            showEditButton={false}
            setShowLoader={setShowLoader} />
          ) : activePhase === "Phase3" ? (
          
            <ViewMappedSID setShowLoader={setShowLoader} />
          ) : (
            null
          )}
        </div>
      </>
    </>
  );
};

export default RoutingSubTabForPayinOnBorading;
