import React, { useEffect, useState } from "react";
import SubTabManage from "./SubTabManage";
import { Card, Row, Container, Col } from "react-bootstrap";
import InactiveServices from "../../InactiveServices";
import MyLoader from "../../../common/MyLoader";
import PayoutApiTest from "../PayoutApiTest/PayoutApiTest";
import CallbackSubTab from "./CallbackSubTab";
import AggregatorManagement from "./AggregatorManagement";
import { useSelector } from "react-redux";
import UserManagement from "../UserManage/UserManagement";
import UserManangementSubTab from "../UserManage/UserManangementSubTab";
import useCheckPrivilages from "../../../utils/checkPrivilages";

import CheckExportConst from "../CheckExportConst/CheckExportConst";
import FraudManagement from "../FRM/FraudManagement";
import FraudSubTab from "../FRM/FraudSubTab";

const PayoutRequest = () => {
  const [activePhase, setActivePhase] = useState("");
  const [activeSubtab, setActiveSubtab] = useState("MerchantManagement");
  const [showLoader, setShowLoader] = useState(false);

  const { prefix, type } = useSelector((state) => state.privilages);
  console.log(prefix);

  const {
    hasPrivilages2681,
    hasPrivilages2682,
    hasPrivilages2683,
    hasPrivilages2684,
    hasPrivilages2685,
    hasPrivilages2686,
    hasPrivilages2688,
    hasPrivilages2689,
    hasPrivilages2691,
    hasPrivilages2692,
    hasPrivilages2693,
    hasPrivilages2694,
    hasPrivilages2696,
    hasPrivilages2697,
    hasPrivilages2698,
    hasPrivilages2699,
    hasPrivilages2700,
    hasPrivilages2701,
    hasPrivilages2702,
    hasPrivilages2703,
    hasPrivilages2706,
    hasPrivilages2708,
    hasPrivilages2709,
    HasPrivilagesMerchantManagement,
  } = CheckExportConst();

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  console.log(HasPrivilagesMerchantManagement);

  return (
    <>
      {showLoader ? <MyLoader /> : ""}
      <section className="dashboard-header-sec">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4 ds-header-card">
                  <Card.Body>
                    {/* <Card.Title className="h3">Reports</Card.Title>  */}
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn">
                        {HasPrivilagesMerchantManagement ? (
                          <li
                            className={activePhase === "Phase1" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase1")}
                          >
                            MERCHANT MANAGEMENT
                          </li>
                        ) : null}

                        {/* <li
                          className={activePhase === "Phase3" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase3")}
                        >
                          RESELLER MANAGEMENT
                        </li> */}
                        

                        {type === "user" ? (
                          <li
                            className={activePhase === "Phase8" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase8")}
                          >
                            USER MANAGEMENT
                          </li>
                        ): null}

                        {/* <li
                          className={activePhase === "Phase5" ? "active" : ""}
                          onClick={() => handlePhaseClick("Phase5")}
                        >
                          INSTRUMENT MANAGEMENT
                        </li> */}

                        {hasPrivilages2702 ? (
                          <li
                            className={activePhase === "Phase6" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase6")}
                          >
                            PAYOUT API TEST
                          </li>
                        ) : null}

                        {hasPrivilages2703 ? (
                          <li
                            className={activePhase === "Phase7" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase7")}
                          >
                            CALLBACK TEST
                          </li>
                        ) : null} {hasPrivilages2703 ? (
                          <li
                            className={activePhase === "Phase9" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase9")}
                          >
                            FRAUD MANAGEMENT
                          </li>
                        ) : null}


                      </ul>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      {activePhase === "" ? null : (
        <div>
          {activePhase === "Phase1" ? (
            <SubTabManage setShowLoader={setShowLoader} />
          ) : activePhase === "Phase2" ? (
            <InactiveServices />
          ) : activePhase === "Phase3" ? (
            <InactiveServices />
          // ) : activePhase === "Phase4" ? (
          //   <AggregatorManagement setShowLoader={setShowLoader} />
          ) : activePhase === "Phase5" ? (
            <InactiveServices />
          ) : activePhase === "Phase6" ? (
            <PayoutApiTest setShowLoader={setShowLoader} />
          ) : activePhase === "Phase7" ? (
            <CallbackSubTab setShowLoader={setShowLoader} />
          ) : activePhase === "Phase8" ? (
            <UserManangementSubTab setShowLoader={setShowLoader} />
          ) :activePhase === "Phase9" ? (
            <FraudSubTab setShowLoader = {setShowLoader} />
          ):
          
          (
            <InactiveServices location={"SubTabTools"} />
          )}
        </div>
      )}
    </>
  );
};

export default PayoutRequest;

/* ) : activePhase === "Phase4" ? (
         <AggregatorManagement setShowLoader={setShowLoader}  /> */
