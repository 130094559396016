import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { isVpaUpiRegexIsValid } from "../../utils/helper";
import { DateTime } from "luxon";
import {
  USE_LIVE_FOR_CSV_DOWNLOAD,
  USE_LIVE_PAYOUT_MULTIPLE_FILTER_API,
} from "../../utils/ApiServices";

const DownloadExcleFilter = ({
  setShowLoader,
  setTransactionReportData,
  setIsMultipleFilterOpen,
  dropDownData,
}) => {
  console.log(dropDownData);
  const token = localStorage.getItem("token");
  const [startAmount, setStartAmount] = useState(Number);
  const [range, setRange] = useState("");
  const [transferType, setTransferType] = useState("");
  const [endAmount, setEndAmount] = useState(Number);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchOption, setSearchOption] = useState("");
  const [searchValue, setSearchValue] = useState("test");
  const [transectionStatus, setTransectionStatus] = useState("");
  const [searchOption2, setSearchOption2] = useState("");
  const [searchValue2, setSearchValue2] = useState("");
  const [show2, setShow2] = useState(true);
  const [formData, setFormData] = useState({
    mid: "",
  });
  const [selectedCompany, setSelectedCompany] = useState("");

  const exampleValues = {
    amount: "Ex- 5500",
    amountInBetween: "Ex- 100-500",
    VPA: "Ex- abcde@xyz / 98XXXXXXX1@xyz",
    paymentType: "Ex-  QR / SQR / DQR / UPI",
    custLocation: "Ex- Madhya Pradesh",
    bankLocation: "Ex- Bhopal",
    // Add more options and example values as needed
  };

  const [searchValueError, setSearchValueError] = useState("");
  const [isSearchValueError, setIsSearchValueError] = useState(false);

  const handleAmountRange = (e) => {
    setRange(e.target.value);
    function splitString(inputString) {
      const parts = inputString.split("-").map((part) => part.trim());
      return parts;
    }

    const [startAmount, endAmount] = splitString(e.target.value);
    setStartAmount(startAmount);
    setEndAmount(endAmount);

    console.log(startAmount, endAmount);
  };

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    if (selectedCompany === "") {
      setFormData({
        mid: "",
      });

      setSelectedCompany("");
      return;
    }

    setSelectedCompany(selectedCompany);

    const selectedMerchant = dropDownData.find(
      (merchant) =>
        `${merchant.mid} :- ${merchant.merchantName}` === selectedCompany
    );
    console.log(selectedMerchant);

    if (selectedMerchant) {
      setFormData({
        mid: selectedMerchant.mid || "",
      });
    }
  };

  useEffect(() => {
    setSearchValue("");
  }, [searchOption]);
  useEffect(() => {
    setSearchValue2("");
  }, [searchOption2]);

  const withOrWithoutMid = () => {
    if (formData.mid === "") {
      return true;
    } else {
      return false;
    }
  };

  const submitHandler = () => {
    // console.log(searchOption2, searchValue2);
    USE_LIVE_FOR_CSV_DOWNLOAD(
      withOrWithoutMid(formData.mid),
      searchOption === "amount" ? searchValue : 0,
      searchOption === "bankLocation" ? searchValue : "",
      searchOption === "custLocation" ? searchValue : "",
      searchOption2 === "date" ? searchValue2 : "",
      searchOption === "amountInBetween" ? endAmount : 0,
      searchOption2 === "dateInBetween" ? endDate : "",
      "",
      "",
      "",
      formData.mid,
      searchOption === "VPA" ? searchValue : "",
      searchOption === "paymentType" ? searchValue.toUpperCase() : "",
      formData.sid,
      searchOption === "amountInBetween" ? startAmount : 0,
      searchOption2 === "dateInBetween" ? startDate : "",
      token,
      transectionStatus,
      setTransactionReportData,
      setShowLoader
    );
    handleModalClose();
  };

  const handleModalClose = () => {
    setShow2(false);
    setIsMultipleFilterOpen(false);
  };
  const handleDateInputChange = (event) => {
    const newDate = event.target.value;
    // Check if the input date is greater than today's date
    const inputDateTime = DateTime.fromISO(newDate, { zone: "UTC" });
    const today = DateTime.now().startOf("day");

    if (inputDateTime.toISODate() > today.toISODate()) {
      return true;
    }
    return false;
  };

  const handelSearchData = (e) => {
    setSearchValue(e.target.value);
    setIsSearchValueError(false);
    setSearchValueError("");
  };

  const handelValidations = (e) => {
    if (searchOption === "VPA") {
      if (!isVpaUpiRegexIsValid(searchValue)) {
        setIsSearchValueError(true);
        setSearchValueError("Invalid Enter Valid VPA");
      }
      return;
    }
  };

  const handleFromToDateInputChange = (from, to) => {
    console.log(from, ":", to);

    const inputFromDateTime = DateTime.fromISO(from, { zone: "utc" });
    const inputToDateTime = DateTime.fromISO(to, { zone: "utc" });
    if (inputToDateTime >= inputFromDateTime) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <div className="filter-field-area">
        <fieldset className="rounded-0 mb-3">
          <legend className="fs-5 fw-bold">Select Date</legend>
          <div className="field-set-form">
            <Row className="g-2">
              <Col lg={6} sm={12}>
                <div>
                  <select
                    className="form-control"
                    value={searchOption2}
                    onChange={(e) => setSearchOption2(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="date">Date</option>
                    <option value="dateInBetween">Date In Between</option>
                    <i class="bi bi-caret-down-fill"></i>
                  </select>
                </div>
              </Col>

              {searchOption2 !== "dateInBetween" ? (
                <Col lg={6} sm={12}>
                  <div className="form-group">
                    <input
                      className="advanced-input-box form-control"
                      type="date"
                      value={searchValue2}
                      onChange={(e) => {
                        if (handleDateInputChange(e)) {
                          setSearchValue2("");
                          console.log("true..........");
                          document
                            .getElementById("dateErr")
                            .classList.remove("d-none");
                        } else {
                          document
                            .getElementById("dateErr")
                            .classList.add("d-none");
                          setSearchValue2(e.target.value);
                          console.log("false..........");
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === " ") {
                          e.preventDefault();
                        }
                      }}
                      placeholder={searchOption === "Date" ? "Enter Date" : ""}
                    />
                    <span id="dateErr" className=" text-danger d-none">
                      *Date must be lesser than current date.
                    </span>
                  </div>
                </Col>
              ) : (
                <>
                  <Col lg={6} sm={12}>
                    <div className="form-group">
                      <input
                        className="advanced-input-box form-control"
                        type="date"
                        value={startDate}
                        onChange={(e) => {
                          if (handleDateInputChange(e)) {
                            setStartDate("");
                            document
                              .getElementById("startDateErr")
                              .classList.remove("d-none");
                          } else {
                            document
                              .getElementById("startDateErr")
                              .classList.add("d-none");
                            setStartDate(e.target.value);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                        placeholder="Enter Start Date"
                      />
                      <span id="startDateErr" className=" text-danger d-none">
                        *Please Enter Valid Date!
                      </span>
                    </div>
                    <div className="form-group">
                      <input
                        className="advanced-input-box form-control"
                        type="date"
                        value={endDate}
                        onChange={(e) => {
                          if (handleDateInputChange(e)) {
                            setEndDate("");
                            document
                              .getElementById("endDateErr")
                              .classList.remove("d-none");
                          } else {
                            if (
                              handleFromToDateInputChange(
                                startDate,
                                e.target.value
                              )
                            ) {
                              document
                                .getElementById("endDateErr")
                                .classList.add("d-none");
                              document
                                .getElementById("fromToDateErr")
                                .classList.add("d-none");
                              setEndDate(e.target.value);
                            } else {
                              document
                                .getElementById("fromToDateErr")
                                .classList.remove("d-none");
                            }
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === " ") {
                            e.preventDefault();
                          }
                        }}
                        placeholder="End Date"
                      />
                      <span id="endDateErr" className=" text-danger d-none">
                        *Please Enter Valid Date!
                      </span>
                      <span id="fromToDateErr" className=" text-danger d-none">
                        *TO Date can't be less than FROM Date!
                      </span>
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </div>
        </fieldset>

        {/* <fieldset className="border-2 rounded-0 mt-3">
                <legend className="fs-5 fw-bold">Transfer Type</legend>
                <div className="field-set-form">
                  <Row className="g-2">
                    <Col lg={12} sm={12}>
                      <div className="form-group">
                        <select
                          className="form-control"
                          value={transferType}
                          onChange={(e) => setTransferType(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="UPI">UPI</option>
                          <option value="IMPS">IMPS</option>
                          <option value="RTGS">RTGS</option>
                          <option value="NEFT">NEFT</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                </div>
              </fieldset>

              <fieldset className="border-2 rounded-0 mt-3">
                <legend className="fs-5 fw-bold">Select Parameter</legend>
                <div className="field-set-form">
                  <Row className="g-2">
                    <Col lg={6}>
                      <div className="form-group">
                        <select
                          className="form-control"
                          value={searchOption}
                          onChange={(e) => setSearchOption(e.target.value)}
                        >
                          <option type="number" value="">
                            Select
                          </option>
                          <option type="number" value="amount">
                            Amount
                          </option>
                          <option type="number" value="amountInBetween">
                            Amount Range
                          </option>
                        </select>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="form-group">
                        {searchOption === "amountInBetween" ? (
                          <>
                            <input
                              className="advanced-input-box form-control"
                              type="text"
                              min={0}
                              value={range}
                              onChange={(e) => handleAmountRange(e)}
                            />
                            <span className="ml-2">
                              {searchOption && exampleValues[searchOption]}
                            </span>
                          </>
                        ) : searchOption === "amount" ? (
                          <div>
                            <input
                              className="advanced-input-box form-control"
                              type="number"
                              value={searchValue}
                              onChange={(e) => setSearchValue(e.target.value)}
                            />
                            <span className="ml-2 mt-1">
                              {searchOption && exampleValues[searchOption]}
                            </span>
                          </div>
                        ) : (
                          <div>
                            <input
                              className="advanced-input-box form-control"
                              type="text"
                              value={searchValue}
                              onChange={handelSearchData}
                              onBlur={handelValidations}
                            />
                            {isSearchValueError ? (
                              <span id="searchValErr" className=" text-danger">
                                {`${searchValueError}`}
                              </span>
                            ) : (
                              ""
                            )}
                            <div>
                              <span className="ml-2 mt-1">
                                {searchOption && exampleValues[searchOption]}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </fieldset> */}

        <fieldset className="border-2 rounded-0 mt-3">
          <legend className="fs-5 fw-bold">Select Merchant</legend>
          <div className="field-set-form">
            <Row className="g-2">
              <Col lg={6}>
                <div className="form-group">
                  <div className=" ">
                    <select
                      id="companyDropdown"
                      className="form-control"
                      onChange={handleCompanyChange}
                      value={selectedCompany}
                    >
                      <option value="">Select a Company</option>
                      {dropDownData.map((merchant) => (
                        <option
                          key={merchant.mid}
                          value={`${merchant.mid} :- ${merchant.merchantName}`}
                        >
                          {merchant.mid} :- {merchant.merchantName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="">
                  <input
                    id="companyDropdown"
                    className="form-control"
                    value={formData.mid}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </fieldset>
        {/* 
              <fieldset className="border-2 rounded-0 mt-3">
                <legend className="fs-5 fw-bold">Transaction Status</legend>
                <div className="field-set-form">
                  <Row className="g-2">
                    <Col lg={12} sm={12}>
                      <div className="form-group">
                        <select
                          className="form-control"
                          value={transectionStatus}
                          onChange={(e) => setTransectionStatus(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="pending">Pending</option>
                          <option value="success">Success</option>
                          <option value="failure">Failure</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                </div>
              </fieldset> */}
      </div>

      <div className="w-100 d-flex justify-content-end">
        <Button
          className="text-capitlize"
          size="sm"
          variant="primary"
          onClick={submitHandler}
        >
          <i class="bi bi-search mx-1"></i> Search
        </Button>
      </div>
    </div>
  );
};
export default DownloadExcleFilter;
