import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { SWIPELINC_API } from '../utils/constant';
import { MDBDataTable } from 'mdbreact';
import { getAllFunctionForApiCalls } from '../utils/ApiServices';
import { FETCH_MERCHANT_FOR_SID } from '../utils/constant';
import { Card, Modal } from "react-bootstrap";

const Sid = () => {
  const [merchants, setMerchants] = useState([]);
  const [selectedSidDetails, setSelectedSidDetails] = useState([]);
  const [selectedMerchantId, setSelectedMerchantId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const token = localStorage.getItem("token");

  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  // };

  useEffect(() => {
    const fetchData = async (FETCH_MERCHANT_FOR_SID, token) => {
      try {
        const response = await getAllFunctionForApiCalls(
          FETCH_MERCHANT_FOR_SID,
          token
        );
        if (response.statusCode === 200) {
          setMerchants(response?.data);
        } else {
          setMerchants([]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(FETCH_MERCHANT_FOR_SID, token);
  }, []);

  const handleSidClick = async (id) => {
    try {
      const response = await axios.get(`${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/getSidByid/${id}?id=${id}`,
        {
          headers: {
            Authentication: "Bearer " + token
          }
        }
      );
      if (response.data.statusCode === 200) {
        setSelectedSidDetails(response.data.data);
        setSelectedMerchantId(id);
        setIsModalOpen(true);
        
      }
    } catch (error) {
      console.error('Error fetching SID details:', error);
    }
  };
  const columns =
  [
    { label: 'Merchant Name', field: 'merchantName', width: "auto" },
    { label: 'LINCPAY', field: 'LINCPAY', width: "auto" },
    { label: 'ICEPE', field: 'ICEPE', width: "auto" },
    { label: 'SILVERPAY', field: 'SILVERPAY', width: "auto" },
    { label: 'SWIPELINC', field: 'SWIPELINC', width: "auto" }
  ]

  const renderButton = (status, id) => (
    <div className="d-inline-flex align-items-center" onClick={() => handleSidClick(id)}>
      <div className={`rounded-circle border border-2 ${status ? 'border-success' : 'border-danger'} p-2 mr-2`}>
        {status ? <FaCheck style={{ color: 'green', fontSize: '20px' }} /> : <FaTimes style={{ color: 'red', fontSize: '20px' }} />}
      </div>
    </div>
  );

  const mappedData = merchants?.map((item, index) => {
    return {
      ...item,
      LINCPAY: renderButton(item.data.LINCPAY, item.ids.LINCPAY),
      ICEPE: renderButton(item.data.ICEPE, item.ids.ICEPE),
      SILVERPAY: renderButton(item.data.SILVERPAY, item.ids.SILVERPAY),
      SWIPELINC: renderButton(item.data.SWIPELINC, item.ids.SWIPELINC)
    };
  });

  const column = [
    { label: 'Company', field: 'company' },
    { label: 'Master Marchant Name', field: 'masterMarchantName' },
    { label: 'Vehicle Name', field: 'vehicleName' },
    { label: 'SID', field: 'sid' },
    { label: 'Max Transaction Amount', field: 'maxTxnAmount' },
    { label: 'Remaining Limit', field: 'remainingLimit' },
    { label: 'Create DateTime', field: 'createDateTime' },
    { label: 'Updated DateTime', field: 'updatedDateTime' },
  ];

  return (
    <>
    <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} size="xl">
      <Modal.Header closeButton>
      <Modal.Title>SID Details:</Modal.Title>
      </Modal.Header>
        {/* <button className="close-button" onClick={() => setIsModalOpen(false)}>Close</button> */}
         <Modal.Body>
         <Card className="shadow border-0 mb-4">
         <Card.Body>
          <div className="merchant-report-view">
            <div className="report-table-view">
              <div className="containerflag ">
                <div className="row mt-4 ">
                  <MDBDataTable
                    className="dataTable"
                    hover
                    striped
                    small
                    bordered
                    responsive
                    theadColor="#333"
                    data={{
                      columns: column,
                      rows: [selectedSidDetails]
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          </Card.Body>
         </Card>
        </Modal.Body>
      </Modal>
      <div className="card p-4">
        <MDBDataTable
          className='dataTable'
          hover
          striped
          small
          bordered
          responsive
          entriesOptions={[5, 10, 20]}
          entries={10}
          data={{
            columns: columns,
            rows: mappedData,
          }}
          searching={true}
          exportToCSV
          theadColor="#333"
          paginationLabel={['Previous', 'Next']}
          sortable={true}
          footer="none"
        />
      </div>
      
    </>
  );
};

export default Sid;