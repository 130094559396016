import React, { useEffect, useState } from "react";
import axios from "axios"; // assuming you're using axios for API requests
import {
  FETCH_ALL_MERCHANT_VALIDATIONS,
  GET_DOWNLOAD_EXCEL,
} from "../../../utils/constant";
import { Card, Col, Container, Row } from "react-bootstrap";
import MidFiter from "../../../reports/MidFiter";
import DownloadExcleFilter from "../../../Merchant/MultipleFilter/DownloadExcelFilter";
import DownloadCsv from "../../../common/DownloadCsv";

const DownloadExcel = ({ setShowLoader }) => {
  const [data, setData] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [dropDownData, setDropDownData] = useState([]);
  const token = localStorage.getItem("token");

  const fetchData = async () => {
    const payload = {
      date: "",
      endDate: "",
      mid: "IPCOS000100THA",
      startDate: "",
    };
    try {
      const response = await axios.post(GET_DOWNLOAD_EXCEL, payload, {
        headers: {
          Authentication: "Bearer " + token,
        },
      });
      setData(response); // Assuming the API response contains the data for the Excel file
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchMerchantData = async () => {
    try {
      const response = await axios.get(FETCH_ALL_MERCHANT_VALIDATIONS, {
        headers: {
          Authentication: "Bearer " + token,
        },
      });
      setDropDownData(response?.data?.data); // Assuming the API response contains the data for the Excel file
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    // fetchData();
    fetchMerchantData();
  }, []);

  return (
    <div>
      <div className="subtab-sec-admin">
        <Container>
          <Row>
            <Col xs={12} lg={12} md={12}>
              <Card className="shadow border-0 mb-4">
                <Card.Body className=" d-flex flex-column align-items-center">
                  <DownloadExcleFilter
                    setShowLoader={setShowLoader}
                    setTransactionReportData={setData}
                    setIsMultipleFilterOpen={setIsFilterOpen}
                    dropDownData={dropDownData}
                  />
                  {data.length ? (
                    <div className="w-100 d-flex justify-content-center align-items-center mt-4">
                      <DownloadCsv DownLoadData={data} Name={`Payin-`} />
                    </div>
                  ) : null}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default DownloadExcel;
