import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { SWIPELINC_API } from "../utils/constant";
import { MDBDataTable } from 'mdbreact';
import ViewFile1 from "./ViewFile1";

const ViewApprove = ({setShowLoader}) => {
  const [applications, setApplications] = useState([]);
  const [openView,setOpenView] = useState(false)
  const [openServices, setOpenServices] = useState({});
  const [id, setId] = useState("");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    setShowLoader(true)
    axios
      .get(`${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getallmerchant/Approved`,
      {
        headers: {
          Authentication: "Bearer " + token,
        }
      },
      {
        params:{
          applicationStatus:"Approved"
        }
      })
      .then((response) => {
        if (response.data.statusCode===200){
          setApplications(response?.data?.data);
        }
        else if (response.data.statusCode === 304)
        setApplications(response?.data?.data);
        setShowLoader(false)
      })
   
      .catch((error) => {
        console.error("Error fetching applications:", error.message);
        setShowLoader(false)
      });
  }, []);
  const handleViewDetails = (registrationId) => {
    setId(registrationId);
    setOpenView(true);
  };
const handleClose = () => {
  setId("")
  setOpenView(false)
}
 
  const togglePaymentServices = (registrationId) => {
    setOpenServices((prevOpenServices) => ({
      ...prevOpenServices,
      [registrationId]: !prevOpenServices[registrationId],
    }));
  };

  const columns = [
    { label: "Company Name", field: "companyName", width: "auto" },
    { label: "Authorized Person Name", field: "authorizedPersonName", width: "auto" },
    { label: "e-Mail ID", field: "email", width: "auto" },
    { label: "Mobile Number", field: "mobile", width: "auto" },
    { label: "Company PAN Number", field: "companyPanNumber", width: "auto" },
    { label: "GST Number", field: "gstNumber", width: "auto" },
    { label: "Services", field: "services", width: "auto" },
    { label: "Action", field: "action", width: "150px" },
  ];

  const mappedData = applications && applications.length > 0
    ? applications?.map((application) => {
      const mappedItem = { ...application };
      mappedItem.services = (
        <div>
          <button
            className="btn btn-secondary btn-sm"
            onClick={() => togglePaymentServices(application.registrationId)}
          >
            {openServices[application.registrationId]
              ? "Hide Services"
              : "View Services"}
          </button>
          {openServices[application.registrationId] && (
            <div>
              {application.paymentService?.map((service) => (
                <div key={service.paymentTypeId}>
                  {service.paymentService}
                </div>
              ))}
            </div>
          )}
        </div>
      );
      mappedItem.action = (
        <button className="btn btn-primary btn-sm"
          type="button"
          onClick={() => handleViewDetails(application.registrationId)}
        >
          View Details
        </button>
      );
      return mappedItem;
    })
    : [];

  return (
    <>
      {!openView ?(
      <MDBDataTable
        className='dataTable'
        striped
        small
        bordered
        hover
        responsive
        data={{
          columns: columns,
          rows: mappedData,
        }}
        searching={true}
        entries={50}
        exportToCSV
        theadColor="#333"
        paginationLabel={['Previous', 'Next']}
        sortable={true}
        footer="none"
      />
     ):openView ? (
    <ViewFile1
    id={id}
    handleClose={handleClose}
    setShowLoader = {setShowLoader}
    />
     ):null}
    </>
  );
};

export default ViewApprove;