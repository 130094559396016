import React, { useState } from "react";
// import Onboarding from "./Onboarding";
// import TechSupport from "./TechSupport";
// import RiskManagement from "./RiskManagement";
// import Accounting from "./Accounting";
import { Container, Card, Row, Col } from "react-bootstrap";
import InactiveServices from "../InactiveServices";

function Support() {
  const [reportType, setReportType] = useState("Onboarding");
  const [activeButton, setActiveButton] = useState(1);

  const HandleClick = (reportType, btn) => {
    setReportType(reportType);
    setActiveButton(btn);
  };

  return (
    <>
      {/* <Header/> */}

      <section className="dashboard-header-sec">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4 ds-header-card">
                  <Card.Body>
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn">
                        <li
                          className={activeButton === 1 ? "active" : ""}
                          onClick={() => HandleClick("Onboarding", 1)}
                        >
                          On-Boarding
                        </li>
                        <li
                          className={activeButton === 2 ? "active" : ""}
                          onClick={() => HandleClick("TechSupport", 2)}
                        >
                          Technical Support
                        </li>
                        <li
                          className={activeButton === 3 ? "active" : ""}
                          onClick={() => HandleClick("RiskManagement", 3)}
                        >
                          Risk Management Support
                        </li>
                        <li
                          className={activeButton === 4 ? "active" : ""}
                          onClick={() => HandleClick("Accounting", 4)}
                        >
                          Accounting
                        </li>
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
                {/* ./card */}
              </Col>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4">
                  <Card.Body>
                    <div className="merchant-report-view">
                      <div className="report-table-view">
                        {reportType === "Onboarding" ? (
                          <InactiveServices />
                        ) : reportType === "TechSupport" ? (
                          <InactiveServices />
                        ) : reportType === "RiskManagement" ? (
                          <InactiveServices />
                        ) : reportType === "Accounting" ? (
                          <InactiveServices location={"otrsdf"} />
                        ) : null}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Support;
