import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { SWIPELINC_API, YES_BANK_ONBOARD_WITH_MID } from "../utils/constant";
import { MDBDataTable } from 'mdbreact';
import ViewFile1 from "./ViewFile1";
import ViewFile1Yesbank from "./ViewFile1YesBank";
import swal from "sweetalert";

const ViewApproveYesBank = ({setShowLoader}) => {
  const [applications, setApplications] = useState([]);
  const [openView,setOpenView] = useState(false)
  const [data,setData] = useState({})
  const [openServices, setOpenServices] = useState({});
  const [id, setId] = useState("");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    setShowLoader(true)
    axios
      .get(`${SWIPELINC_API}/MerchantAdminPanel/yesBank/getAllApproved`,
      {
        headers: {
          Authentication: "Bearer " + token,
        }
      },
      {
        params:{
          applicationStatus:"Approved"
        }
      })
      .then((response) => {
        if (response.data.statusCode===200){
          setApplications(response?.data?.data);
        }
        else if (response.data.statusCode === 304)
        setApplications(response?.data?.data);
        setShowLoader(false)
      })
   
      .catch((error) => {
        console.error("Error fetching applications:", error.message);
        setShowLoader(false)
      });
  }, []);

  const handleOnBoard=(mid)=>{ 
    setShowLoader(true);
    axios
      .post(YES_BANK_ONBOARD_WITH_MID + mid,"",{
        headers: {
          Authentication: `Bearer ${token}`
        }, 
      })
      .then((response) => {
        if (response?.data?.statusCode === 200 ) {
           
            swal({
              title: "SUCCESS",
              text: "Merchant On-Boarded Successfully.",
              icon: "success",
            })
        }else{
          swal({
            title: "Failed",
            text: "Merchant On-Boarding Failed.",
            icon: "error",
          })
        }
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching applications:", error.message);
        setShowLoader(false);
      });
  }

  const handleViewDetails = (data) => {
    setId(data.registrationId);
    console.log(data)
    setData(data);
    setOpenView(true);
  };
const handleClose = () => {
  setId("")
  setOpenView(false)
}
 
  const togglePaymentServices = (registrationId) => {
    setOpenServices((prevOpenServices) => ({
      ...prevOpenServices,
      [registrationId]: !prevOpenServices[registrationId],
    }));
  };

  const columns = [
    { label: "Company Name", field: "companyName", width: "auto" },
    { label: "Authorized Person Name", field: "authorizedPersonName", width: "auto" },
    { label: "e-Mail ID", field: "email", width: "auto" },
    { label: "Mobile Number", field: "mobile", width: "auto" },
    { label: "Company PAN Number", field: "companyPanNumber", width: "auto" },
    { label: "GST Number", field: "gstNumber", width: "auto" },
    { label: "Services", field: "services", width: "auto" },
    { label: "Action", field: "action", width: "150px" },
    { label: "On-Board", field: "onboard", width: "150px" },
  ];

  const mappedData = applications && applications.length > 0
    ? applications?.map((application) => {
      const mappedItem = { ...application };
      mappedItem.services = (
        <div>
          <button
            className="btn btn-secondary btn-sm"
            onClick={() => togglePaymentServices(application.registrationId)}
          >
            {openServices[application.registrationId]
              ? "Hide Services"
              : "View Services"}
          </button>
          {openServices[application.registrationId] && (
            <div>
              {application.paymentService?.map((service) => (
                <div key={service.paymentTypeId}>
                  {service.paymentService}
                </div>
              ))}
            </div>
          )}
        </div>
      );
      mappedItem.action = (
        <button className="btn btn-primary btn-sm"
          type="button"
          onClick={() => handleViewDetails(application)}
        >
          View Details
        </button>
      );
      mappedItem.onboard = (
        <button className="btn btn-primary btn-sm"
          type="button"
          onClick={() => handleOnBoard(application.mid)}
        >
         On-Board
        </button>
      );
      return mappedItem;
    })
    : [];

  return (
    <>
      {!openView ?(
      <MDBDataTable
        className='dataTable'
        striped
        small
        bordered
        hover
        responsive
        data={{
          columns: columns,
          rows: mappedData,
        }}
        searching={true}
        entries={50}
        exportToCSV
        theadColor="#333"
        paginationLabel={['Previous', 'Next']}
        sortable={true}
        footer="none"
      />
     ):openView ? (
    <ViewFile1Yesbank
    id={id}
    data={data}
    handleClose={handleClose}
    setShowLoader = {setShowLoader}
    />
     ):null}
    </>
  );
};

export default ViewApproveYesBank;