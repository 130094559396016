import axios from "axios";
import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import swal from "sweetalert";
import { SWIPELINC_API } from "../../../utils/constant";
import { getAllSubmittedPayoutRouting } from "../../../utils/ApiServices";
import useCheckPrivilages from "../../../utils/checkPrivilages";

const SubmittedRoutingPayout = ({
  setShowLoader,
  showEditButton,
  showEnaDisaButton,
}) => {
  const [applications, setApplications] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [accessType, setAccessType] = useState("");
  const [routing, setRoutingWith] = useState("");
  const [mid, setmid] = useState("");
  const [modalData, setModalData] = useState("");
  const [companyMid, setCompanyMid] = useState("");
  const [callbackUrl, setCallbackUrl] = useState("");
  const [viewMid, setViewMid] = useState(""); // State to store the mid for the view modal
  const [editCallback, setEditCallback] = useState({
    id: "",
    callbackUrl: "",
    transferType: "",
    mappedMid: "",
    mappedWithCompanyName: "",
    usedFor: "",
    mid: "",
    requestRoute: "",
  });

  const hasPrivilages2636 = useCheckPrivilages(2636);

  const columns = [
    { label: "Merchant ID", field: "mid", width: "auto" },
    { label: "Merchant Name", field: "companyName", width: "auto" },
    // { label: "SID", field: "sid", width: "auto" },
    { label: "Mapped With", field: "mappedWithCompanyName", width: "auto" },
    { label: "Callback URL", field: "callbackUrl", width: "auto" },
    { label: "Transfer Type", field: "transferType", width: "auto" },
    { label: "Access Type", field: "usedFor", width: "auto" },
    { label: "Route With", field: "requestRoute", width: "auto" },
    { label: "Action", field: "action", width: "auto" },
    { label: "Virtual A/C Balance", field: "view", width: "auto" },
  ];

  const handleViewbalance = (mid, type) => {
    setViewMid(type); // Set the mid in the state
    getVirtualAccountBalance(mid, type);
    setIsViewModalOpen(true); // Open the view modal
  };

  const mappedData = applications?.map((item) => {
    const mappedItem = { ...item };
    mappedItem.maxTxnAmount = (
      <sapan>
        {" "}
        <b>&#8377;</b> {`${mappedItem.maxTxnAmount}`}
      </sapan>
    );
    mappedItem.view = (
      <button
        className="btn btn-sm btn-primary"
        onClick={() =>
          handleViewbalance(mappedItem.mid, mappedItem.transferType)
        }
      >
        View
      </button>
    );

    if (mappedItem.active === true) {
      mappedItem.action = hasPrivilages2636 ? (
        <div className="d-flex gap-2 justify-content-evenly">
          {showEditButton && (
            <button
              className="btn btn-warning btn-sm"
              onClick={() =>
                openDetails(
                  item.id,
                  item.callbackUrl,
                  item.transferType,
                  item.mappedMid,
                  item.mappedWithCompanyName,
                  item.usedFor,
                  item.mid,
                  item.requestRoute
                )
              }
            >
              Edit
            </button>
          )}
          {showEnaDisaButton && (
            <button
              className="btn btn-danger btn-sm"
              onClick={() => disableSID(item.id)}
            >
              Disable
            </button>
          )}
        </div>
      ) : null;
    }

    if (mappedItem.active === false) {
      mappedItem.action = hasPrivilages2636 ? (
        <div className="d-flex gap-2 justify-content-evenly">
          {showEditButton && (
            <button
              className="btn btn-warning btn-sm"
              onClick={() =>
                openDetails(
                  item.id,
                  item.callbackUrl,
                  item.transferType,
                  item.mappedMid,
                  item.mappedWithCompanyName,
                  item.usedFor,
                  item.mid,
                  item.requestRoute
                )
              }
            >
              Edit
            </button>
          )}
          {showEnaDisaButton && (
            <button
              className="btn btn-success btn-sm"
              onClick={() => enableSID(item.id)}
            >
              Enable
            </button>
          )}
        </div>
      ) : null;
    }

    return mappedItem;
  });

  const getVirtualAccountData = (type) => {
    setShowLoader(true);
    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/merchantPayoutMaster/getAllMerchantPayoutMasterDistinctData/${type}`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setShowLoader(false);
        if (response.data.statusCode === 200) {
          setCompanyData(response?.data?.data);
        } else setCompanyData();
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setShowLoader(false);
      });
  };

  const getVirtualAccountBalance = (mid, type) => {
    setShowLoader(true);
    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/api/alert/fetchDecentroBalance/${mid}/${type}`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response?.status === 500) {
          setModalData("");
          return;
        }
        setModalData(response?.data);

        setShowLoader(false);
      })
      .catch((error) => {
        setModalData("");
        console.error("Error fetching data:", error);
        setShowLoader(false);
      });
  };

  const openDetails = (
    id,
    callbackUrl,
    transferType,
    mappedMid,
    mappedWithCompanyName,
    usedFor,
    mid,
    requestRoute
  ) => {
    setEditCallback({
      id,
      callbackUrl,
      transferType,
      mappedMid,
      mappedWithCompanyName,
      usedFor,
      mid,
      requestRoute,
    });

    setIsModalOpen(true);
    getVirtualAccountData(transferType);
    setSelectedCompany(mappedWithCompanyName);
    setCompanyMid(mappedMid);
    setmid(mid);
    setAccessType(usedFor);
    setCallbackUrl("");
    setRoutingWith(requestRoute);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditCallback({
      id: "",
      callbackUrl: "",
      transferType: "",
      mappedMid: "",
      mid: "",
    });
    setAccessType("");
    setRoutingWith("");
  };

  const disableSID = (id) => {
    swal({
      title: "Alert",
      text: "Are you sure, do you want to disable?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDisable) => {
      if (willDisable) {
        const formData = new FormData();
        formData.append("id", id);
        setShowLoader(true);

        axios
          .put(
            `${SWIPELINC_API}MerchantAdminPanel/payoutRouting/disable/${id}`,
            formData,
            {
              headers: {
                Authentication: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            setShowLoader(false);

            if (response.data.statusCode === 200) {
              swal({
                title: "Success!",
                text: response.data.message,
                icon: "success",
              });

              handleCloseModal();
              FetchData(token);
            } else {
              swal({
                title: "Alert!",
                text: response.data.message,
                icon: "error",
              });
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            setShowLoader(false);
          });
      } else {
        // User clicked cancel in the swal confirmation
        setShowLoader(false);
      }
    });
  };

  const enableSID = (id) => {
    // Show swal confirmation before proceeding
    swal({
      title: "Alert",
      text: "Are you sure, do you want to enable?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDisable) => {
      if (willDisable) {
        const formData = new FormData();
        formData.append("id", id);
        setShowLoader(true);

        axios
          .put(
            `${SWIPELINC_API}MerchantAdminPanel/payoutRouting/enable/${id}`,
            formData,
            {
              headers: {
                Authentication: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            setShowLoader(false);

            if (response.data.statusCode === 200) {
              swal({
                title: "Success!",
                text: response.data.message,
                icon: "success",
              });

              handleCloseModal();
              FetchData(token);
            } else {
              swal({
                title: "Alert!",
                text: response.data.message,
                icon: "error",
              });
            }
          })
          .catch((error) => {
            setShowLoader(false);
          });
      } else {
        // User clicked cancel in the swal confirmation
        setShowLoader(false);
      }
    });
  };

  const handelDataChange = (e) => {
    const { name, value } = e.target;
    setEditCallback({ ...editCallback, [name]: value });
  };

  const handleUpdateSubmit = (e) => {
    e.preventDefault();

    if (editCallback.usedFor === "Api") {
      if (editCallback.callbackUrl === "") {
        swal({
          title: "Alert!",
          text: "Please Enter Callback URL",
          icon: "error",
        });
        return;
      }
    }

    const payload = {
      callbackUrl: editCallback.callbackUrl,
      id: editCallback.id,
      mappedMid: companyMid,
      mid: mid,
      transferType: editCallback.transferType,
      usedFor: accessType,
      requestRoute: routing,
    };
    handleCloseModal();
    setShowLoader(true);

    axios
      .put(
        `${SWIPELINC_API}MerchantAdminPanel/payoutRouting/updateMerchantPayoutMaster`,
        payload,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setShowLoader(false);
        if (response.data.statusCode === 200) {
          swal({
            title: "Success!",
            text: "Details Updated Successfully.",
            icon: "success",
          });
          handleCloseModal();
          FetchData(token);
        } else {
          swal({
            title: "Alert!",
            text: response.data.message,
            icon: "error",
          });
        }
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  const FetchData = async (token) => {
    setShowLoader(true);
    try {
      const apiResponse = await getAllSubmittedPayoutRouting(token);
      setShowLoader(false);
      if (apiResponse.statusCode === 200) {
        setApplications(apiResponse?.data);
      } else {
        setApplications(apiResponse?.data);
      }
    } catch (error) {
      // console.error(error);

      setShowLoader(false);
    }
  };

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    const companyMid =
      companyData?.find((company) => company.companyName === selectedCompany)
        ?.mid || "";
    setSelectedCompany(selectedCompany);
    setCompanyMid(companyMid);
  };

  useEffect(() => {
    FetchData(token);
  }, []);

  return (
    <>
      <div className="card p-4">
        <MDBDataTable
          className="dataTable"
          striped
          small
          bordered
          hover
          responsive
          data={{
            columns: columns,
            rows: mappedData,
          }}
          searching={true}
          entries={50}
          exportToCSV
          theadColor="#333"
          paginationLabel={["Previous", "Next"]}
          sortable={true}
          footer="none"
        />
      </div>

      {/* EDIT FORM MODEL STARTS */}
      <Modal show={isModalOpen} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Callback URL:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="shadow border-0 mb-4">
            <Card.Body>
              <div className="merchant-report-view">
                <div className="report-table-view">
                  <div className="containerflag ">
                    <div className="row mt-4 ">
                      <div className="col-sm-12 col-md-8 offset-md-2">
                        {accessType?.toUpperCase() === "API" && (
                          <div className="form-group mt-3">
                            <label htmlFor="callbackUrl">
                              Enter Callback URL
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="callbackUrl"
                              name="callbackUrl"
                              placeholder="Enter Callback URL"
                              value={editCallback.callbackUrl}
                              onChange={handelDataChange}
                            />
                          </div>
                        )}

                        <div className="row mt-2">
                          <div className=" my-2">
                            <label htmlFor="holdAmount">
                              Select Virtual Account
                            </label>

                            <select
                              className="form-control"
                              id="holdAmount"
                              value={selectedCompany}
                              onChange={handleCompanyChange}
                            >
                              <option value="">Select a Company</option>
                              {companyData?.map((company) => (
                                <option
                                  key={company.mid}
                                  value={company.companyName}
                                >
                                  {company.companyName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="form-group mt-3">
                          <label htmlFor="accessType">Access Type</label>
                          <select
                            className="form-control"
                            id="accessType"
                            name="accessType"
                            value={accessType}
                            onChange={(e) => setAccessType(e.target.value)}
                          >
                            <option value="API">API</option>
                            <option value="Panel">Panel</option>
                          </select>
                        </div>
                        <div className="form-group mt-3">
                          <label htmlFor="RouteWith">Route With</label>
                          <select
                            className="form-control"
                            id="routing"
                            name="routing"
                            value={routing}
                            onChange={(e) => setRoutingWith(e.target.value)}
                          >
                            {/* <option value="">select</option> */}
                            <option value="DECENTRO">DECENTRO</option>
                            <option value="FINZEN">FINZEN</option>
                            <option value="BRANCHX">BRANCHX</option>
                          </select>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center mt-4">
                        <button
                          className="btn btn-primary"
                          onClick={handleUpdateSubmit}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>

      {/* VIEW MID MODAL */}
      <Modal show={isViewModalOpen} onHide={() => setIsViewModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>View Virtual A/C Balance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>{viewMid} :</strong> {modalData}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setIsViewModalOpen(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SubmittedRoutingPayout;
