import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { SWIPELINC_API } from "../utils/constant";
import axios from "axios";
import swal from "sweetalert";
import NumberInWords from "./NumberInWords";
import {
  fetchAllMerchantMaster,
  fetchAllVehicleMasterForm,
  getMechantForPayoutApiTest,
  getVehicleForMastersForms,
} from "../utils/ApiServices";

const SIDMaster = ({ setShowLoader }) => {
  const [merchantData, setMerchantData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  // const [masterName, setMasterName] = useState("");
  const [vehicleData, setVehicleData] = useState([]);
  const [merchantMasterData, setMerchantMasterData] = useState([]);
  const [data, setData] = useState({
    sid: "",
    txnAmt: "",
    vehicle: "",
    merchantMasterId: "",
    merchantMasterName: "",
    MerchantVPA: "",
  });

  const token = localStorage.getItem("token");

  const [formData, setFormData] = useState({
    mid: "",
    merchantName: "",
  });

  const handelDataChange = (e) => {
    if (e.target.name === "vehicle") {
      const selectedOption = e.target.options[e.target.selectedIndex];
      const master = selectedOption.getAttribute("data-master");
      const masterId = selectedOption.getAttribute("data-masterId");
      console.log(masterId);
      setData({
        ...data,
        [e.target.name]: e.target.value,
        merchantMasterName: master,
        merchantMasterId: masterId,
      });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }

    console.log(data);
  };

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    setSelectedCompany(selectedCompany);

    const selectedMerchant = merchantData.find(
      (merchant) => merchant.merchantName === selectedCompany
    );

    if (selectedMerchant) {
      setFormData({
        mid: selectedMerchant.mid || "",
        merchantName: selectedMerchant.merchantName || "",
      });
    } else {
      setFormData({
        mid: "",
        merchantName: "",
      });
    }
  };

  const handleVPAValidation = () => {
    const VPAREGEX= /^[a-zA-Z0-9.\-_]{2,49}@[a-zA-Z._]{2,49}$/;
    const VPA_REGEX = /^([a-zA-Z0-9_\.-]+)@([a-zA-Z0-9_\.-]+)\.([a-zA-Z]{2,5})$/;
    return VPAREGEX.test(data.MerchantVPA.trim());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const showConfirmationAlert = () => {
      swal({
        title: "Are you sure?",
        text: "Do you want to submit this application ?",
        icon: "warning",
        buttons: true,
      }).then((willSubmit) => {
        if (willSubmit) {
          if (formData.merchantName === "") {
            swal({
              title: "Alert!",
              text: "Please Select Merchant",
              icon: "error",
            });
            return;
          }

          if (data.vehicle === "") {
            swal({
              title: "Alert!",
              text: "Please Select Vehicle",
              icon: "error",
            });
            return;
          }

          if (data.sid === "") {
            swal({
              title: "Alert!",
              text: "Please Enter SID",
              icon: "error",
            });
            return;
          }

          if (data.txnAmt === "") {
            swal({
              title: "Alert!",
              text: "Please Enter Maximum Transaction Amount",
              icon: "error",
            });
            return;
          }
          if (data.merchantMasterName === "") {
            swal({
              title: "Alert!",
              text: "Please Select Merchant Master",
              icon: "error",
            });
            return;
          }
          if (data.MerchantVPA === "") {
            swal({
              title: "Alert!",
              text: "Please Enter Merchant VPA",
              icon: "error",
            });
            return;
          }

          if (!handleVPAValidation()) {
            swal({
              title: "Alert!",
              text: "Please Enter a valid Merchant VPA",
              icon: "error",
            });
            return;
          }

          const payload = {
            company: formData.merchantName.trim(),
            maxTxnAmount: data.txnAmt.trim(),
            mid: formData.mid.trim(),
            sid: data.sid.trim(),
            vehicleMasterId: data.vehicle.trim(),
            masterMerchantId: data.merchantMasterId.trim(),
            merchantVpa: data.MerchantVPA.trim(),
          };

          
          setShowLoader(true);
          axios
            .post(
             ` ${SWIPELINC_API}MerchantAdminPanel/admin/sidMaster/save`,
              payload,
              {
                headers: {
                  Authentication:"Bearer " + token,
                },
              }
            )
            .then((response) => {
              setShowLoader(false)
            

              if (response.data.statusCode === 200) {
                swal({
                  title: "Success!",
                  text: response.data.message,
                  icon: "success",
                });
                setFormData({
                  mid: "",
                  merchantName: "",
                });
                setData({
                  sid: "",
                  txnAmt: "",
                  vehicle: "",
                  merchantMasterId: "",
                  merchantMasterName: "",
                });
                setSelectedCompany("");
              } else {
                swal({
                  title: "Alert!",
                  text: response.data.message,
                  icon: "error",
                });
              }
              
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
              setShowLoader(false);
            });
        } else {
          swal("Submission Cancelled!", {
            icon: "info",
          });
        }
      });
    };

    showConfirmationAlert();
  };

  const getAllMerchantForApiTest = async () => {
    setShowLoader(true);
    let res = await getMechantForPayoutApiTest(token);
    setShowLoader(false);
    setMerchantData(res?.data);
  };

  useState(() => {
    getAllMerchantForApiTest();
  }, []);

  const getAllVehicle = async () => {
    try {
      const payload={
        serviceType:"UPI"
      }
      const response = await getVehicleForMastersForms(payload,token); // Add the await keyword here

      if (response.statusCode === 200) {
        setVehicleData(response?.data);
      } else {
        setVehicleData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllMerchantMaster = async () => {
    try {
      const response = await fetchAllMerchantMaster(token); // Add the await keyword here
  if (response?.statusCode === 200) {
        setMerchantMasterData(response?.data);
      } else {
        setMerchantMasterData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllVehicle();
    getAllMerchantMaster();
  }, []);

  return (
    <Card className="shadow border-0 mb-4">
      <Card.Body>
        <div className="merchant-report-view">
          <div className="report-table-view">
            <div className="containerflag">
              <div className="row mt-4">
                <div className="col-md-6 offset">
                  <div className="col-sm-12 col-md-6  offset-md-3">
                    <label htmlFor="email">Select A Merchant</label>
                    <select
                      id="companyDropdown"
                      className="form-control"
                      onChange={handleCompanyChange}
                      value={selectedCompany}
                    >
                      <option value="">Select a Merchant</option>
                      {merchantData?.map((merchant) => (
                        <option
                          key={merchant.mid}
                          value={merchant.merchantName}
                        >
                          {merchant.merchantName}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="row mt-2">
                    <div className="col-sm-12 col-md-6 offset-md-3">
                      <div className="form-group mt-2">
                        <label htmlFor="email">Merchant MID</label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          placeholder="Company Name"
                          value={formData.mid}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 offset-md-3 mt-3">
                    <div className="form-group mt-2">
                      <label htmlFor="email">
                        Enter Maximum Transaction Amount
                      </label>
                      <input
                        type="number"
                        className="form-control rounded-0"
                        id="txnAmt"
                        name="txnAmt"
                        placeholder="Enter Maximum Txn Amount "
                        value={data.txnAmt}
                        onChange={handelDataChange}
                      />
                      <span>
                        <NumberInWords number={data.txnAmt} />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="col-sm-12 col-md-6  offset-md-3 mt-3">
                    <label htmlFor="email">Select A Vehicle</label>
                    <select
                      id="companyDropdown"
                      className="form-control"
                      onChange={handelDataChange}
                      name="vehicle"
                      value={data.vehicle}
                    >
                      <option value="">Select a Vehicle</option>
                      {vehicleData?.map((vehicle) => (
                        <option
                          key={vehicle.id}
                          data-master={vehicle.masterMerchantName}
                          data-masterId={vehicle.masterMerchantId}
                          value={vehicle.id}
                        >
                          {vehicle.vehicleName}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-sm-12 col-md-6  offset-md-3 mt-3">
                    <label htmlFor="email">Select Master Merchant</label>
                    <input
                      id="companyDropdown"
                      className="form-control"
                      // onChange={handelDataChange}
                      readOnly
                      name="merchantMaster"
                      value={data.merchantMasterName}
                    />
                  </div>

                  <div className="col-sm-12 col-md-6 offset-md-3">
                    <div className="form-group mt-2">
                      <label htmlFor="email">Enter SID</label>
                      <input
                        type="text"
                        className="form-control"
                        id="sid"
                        name="sid"
                        placeholder="Enter SID"
                        value={data.sid}
                        onChange={handelDataChange}
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-6 offset-md-3">
                    <div className="form-group mt-2">
                      <label htmlFor="email">Enter Merchant VPA</label>
                      <input
                        type="text"
                        className="form-control"
                        id="MerchantVPA"
                        name="MerchantVPA"
                        placeholder="Enter Merchant VPA"
                        value={data.MerchantVPA}
                        onChange={handelDataChange}
                      />
                    </div>
                  </div>

                  <div className="row mt-2"></div>
                </div>
                <div className="d-flex justify-content-center mt-4">
                  <button className="btn btn-primary" onClick={handleSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default SIDMaster;