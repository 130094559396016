import React, { useEffect, useState } from "react";
import ViewSubmittedApplications from "./SubmittedApplications";
import ViewApprove from "./ViewApprove";
import ViewRejected from "./ViewRejected";
import ViewPending from "./ViewPending";
import InactiveServices from "../components/InactiveServices";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { useSelector } from "react-redux";
import useCheckPrivilages from "../utils/checkPrivilages";
import ViewSubmittedApplicationsYesBank from "./ViewSubmittedApplicationsYesBank";
import ViewApproveYesBank from "./ViewApproveYesBank";

const ApplicationSectionYesBank = ({ setShowLoader }) => {
  const [activePhase, setActivePhase] = useState("");
  const [privilages, setPrivilages] = useState("Phase1");

  useEffect(() => {
    setActivePhase("");
  }, []);

  const hasPrivilage2142 = useCheckPrivilages(2142);
  const { privilagesData } = useSelector((state) => state.privilages);
 

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  return (
    <>
      <div className="subtab-sec-admin">
        <Row>
          <Col xs={12} lg={12} md={12}>
            <Card className="shadow border-0 mb-4">
              <div className="merchant-tab-btn">
                  <ul className="report-link nav-report-btn">
                    <li
                      className={activePhase === "Phase1" ? "active" : ""}
                      onClick={() => handlePhaseClick("Phase1")}
                    >
                      Submitted
                    </li>
                   <li
                      className={activePhase === "Phase2" ? "active" : ""}
                      onClick={() => handlePhaseClick("Phase2")}
                    >
                      Approved
                    </li>
                      {/* <li
                      className={activePhase === "Phase3" ? "active" : ""}
                      onClick={() => handlePhaseClick("Phase3")}
                    >
                      Rejected
                    </li>
                    <li
                      className={activePhase === "Phase4" ? "active" : ""}
                      onClick={() => handlePhaseClick("Phase4")}
                    >
                      Pending
                    </li> */}
                  </ul>
                
 
              </div>
              <Card.Body>
                <div className="admin-tab-content">
                  {activePhase === "Phase1" ? (
                    <ViewSubmittedApplicationsYesBank
                      setActivePhase={setActivePhase}
                      setShowLoader={setShowLoader}
                    />
                  ) : activePhase === "Phase2" ? (
                    <ViewApproveYesBank
                      setActivePhase={setActivePhase}
                      setShowLoader={setShowLoader}
                    />
                  ) : activePhase === "Phase3"  ? (
                    <ViewRejected
                      setActivePhase={setActivePhase}
                      setShowLoader={setShowLoader}
                    />
                  ) : activePhase === "Phase4"   ? (
                    <ViewPending
                      setActivePhase={setActivePhase}
                      setShowLoader={setShowLoader}
                    />
                  ) : (
                    null
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      
    </>
  );
};

export default ApplicationSectionYesBank;

// && privilagesData.includes("Application-phase-1-view")
