import React, { useEffect, useState } from "react";
import {
  FETCH_PAYIN_FOR_ACKNOWLEDGMENT,
  GET_ALL_AGGREGATOR,
  GET_ALL_PRIVILAGES,
  GET_ALL_PRIVILAGES_BY_ID,
  GET_USER_MANAGEMENT_DATA_BY_ID,
} from "../../../utils/constant";
import { getAllFunctionForApiCall } from "../../../utils/ApiServices";
import { Card, Col, Row } from "react-bootstrap";
import { getTokenFromLocalStorage } from "../../../utils/tokenService";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";

const ViewUser = ({ handleClose, UserId }) => {
  const [aggregatorData, setAggregatorData] = useState({});
  const [loading, setLoading] = useState(false);
  const [privilagesData, setPrivilagesData] = useState([]);
  const [privilages, setPrivilages] = useState([]);
  const [privilagesWithSubTypeData, setPrivilagesWithSubTypeData] = useState(
    []
  );
  const token = getTokenFromLocalStorage();

  const decodedToken = jwtDecode(token);
  const { masterId } = decodedToken;
  const getAggregatorData = async () => {
    try {
      const response = await getAllFunctionForApiCall(
        GET_USER_MANAGEMENT_DATA_BY_ID + UserId,
        token
      );
      if (response.statusCode === 200) {
        setAggregatorData(response?.data);

        const res = response?.data?.privilege?.map((item) => {
          console.log(item.adminPrivilegeSubTypeId);
          return item.adminPrivilegeSubTypeId;
        });

        setPrivilagesData(res);
      } else {
        setAggregatorData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const  privilagesSlice = useSelector(state=>state.privilages )

  const getAllPrivilages = async () => {
    try {
      const response = await getAllFunctionForApiCall(
        GET_ALL_PRIVILAGES_BY_ID + masterId,
        token
      );
      if (response.statusCode === 200) {
        const res = response?.data?.map((item) => {
          console.log(item.adminPrivilegeSubTypeId);
          return item.adminPrivilegeSubTypeId;
        });

        await setPrivilagesData(res);
        console.log("fhgf--------", privilagesData);
      } else {
        setPrivilagesData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    privilagesData?.map((item, i) => {
      console.log(item.adminPrivilegeSubTypeId);
      setPrivilages((prevPrivilages) => {
        return [...prevPrivilages, item.adminPrivilegeSubTypeId];
      });
    });
    console.log(privilages);
  }, [privilagesWithSubTypeData, privilagesData]);

  const getAllPrivilagesWithSubType = async () => {
    try {
      const response = await getAllFunctionForApiCall(
        GET_ALL_PRIVILAGES,
        token
      );

      if (response.statusCode === 200) {
        setPrivilagesWithSubTypeData(response?.data);
        console.log(privilagesData);
      } else {
        setPrivilagesWithSubTypeData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // API calls
        await getAllPrivilagesWithSubType();
        // await getAllPrivilages();
        await getAggregatorData();

        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    privilagesWithSubTypeData.map((item) => {
      privilagesData.includes(item.adminPrivilegeSubTypeId)
        ? console.log("true")
        : console.log("true");
    });
  }, [privilagesData]);

  return (
    <>
      <Row className="px-4">
        <Col xs={12} lg={4} md={6} className="mt-5">
          <div className="form-group">
            <label className="form-label">
              User Name
              <sup className="text-danger">*</sup>
            </label>
            <div className="d-flex gap-2">
              <input
                className="form-control"
                name="AggregatorName"
                value={aggregatorData?.name}
                readOnly
              />
            </div>
          </div>
          {/* ./form-group */}
        </Col>

        <Col xs={12} lg={4} md={6} className="mt-5">
          <div className="form-group">
            <label className="form-label">
              e-Mail Id
              <sup className="text-danger">*</sup>
            </label>
            <div className="d-flex gap-2">
              <input
                className="form-control"
                name="emailId"
                value={aggregatorData?.email}
                readOnly
              />
            </div>
          </div>
          {/* ./form-group */}
        </Col>
        {/* <Col xs={12} lg={4} md={6} className="mt-5">
                <div className="form-group">
                    <label className="form-label">
                    Autherize Person Name
                    <sup className="text-danger">*</sup>
                    </label>
                    <div className="d-flex gap-2">
                    <input
                        className="form-control"
                        name="AuthPersonName"
                        value={formDataInputs.AuthPersonName}
                        onChange={handleformDataInputs}
                    />
                    </div>
                </div>
                
                </Col> */}

        <Col xs={12} lg={4} md={6} className="mt-5">
          <div className="form-group">
            <label className="form-label">
              Mobile Number
              <sup className="text-danger">*</sup>
            </label>
            <div className="d-flex gap-2">
              <input
                className="form-control"
                name="MobileNumber"
                value={aggregatorData?.mobile}
                readOnly
              />
            </div>
          </div>
          {/* ./form-group */}
        </Col>

        <Col xs={12} lg={4} md={6} className="mt-5">
          <div className="form-group">
            <label className="form-label">
              Password
              <sup className="text-danger">*</sup>
            </label>
            <div className="d-flex gap-2">
              <input
                className="form-control"
                name="password"
                value={aggregatorData?.password}
                readOnly
              />
            </div>
          </div>
          {/* ./form-group */}
        </Col>
      </Row>
      <Row className="mt-3">
        <lable className="fs-3 fw-bold mx-3 mt-2 mb-3">Privilages</lable>
        <div className="d-flex flex-column gap-4">
          {privilagesData
            ? privilagesWithSubTypeData.map((item, i) => (
                <div key={i} className="d-flex flex-column">
                  <Card.Header className="form-label mt-4 mb-3 fs-4 mx-1">
                    {item.privilegeName}
                  </Card.Header>
                  <div className="d-flex flex-wrap">
                    {item.subTypes.map(
                      (item, i) =>
                      privilagesSlice?.privilagesData?.includes(
                          parseInt(item.adminPrivilegeSubTypeId)
                        ) && (
                          <>
                            <div
                              key={i}
                              className="bg-light d-flex justify-content-center align-items-center gap-5 px-4 py-1 rounded rounded-5 mt-2 mx-2   form-group"
                            >
                              <label className="form-label mt-1 fs-4 mx-1">
                                {item.subTypePrivilegeName}
                              </label>
                              <div className="s-lg form-check form-switch ">
                                <input
                                  id="flexSwitchCheckDefault"
                                  className="form-check-input fs-4"
                                  type="checkbox"
                                  checked={privilagesData.includes(
                                    item.adminPrivilegeSubTypeId
                                  )}
                                  // name={item.adminPrivilegeSubTypeId}
                                  // value={
                                  //   privilagesData.includes(
                                  //     item.adminPrivilegeSubTypeId
                                  //   )
                                  //     ? "true"
                                  //     : "false"
                                  // }
                                  // onChange={handlePrivilages}
                                />
                              </div>
                            </div>
                          </>
                        )
                    )}
                  </div>
                </div>
              ))
            : null}
        </div>
      </Row>

      <Row>
        <div className="d-flex justify-content-end">
          <button type="button" class="btn btn-primary" onClick={handleClose}>
            Close
          </button>
        </div>
      </Row>
    </>
  );
};

export default ViewUser;
