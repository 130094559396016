import React, { useState, useEffect } from 'react';
import { MDBDataTable } from 'mdbreact';
import ViewDetails from './ViewDetails';
import { Container, Card, Row, Col } from "react-bootstrap";
import { fetchSubmittedPayout } from '../../../utils/ApiServices';


function SubmittedPayout({setActivePhase,setShowLoader}) {
 const [payout, setSubmittedPayout] = useState([]);
 const [id, setId] = useState('');
 const [openView, setOpenView] = useState(false);
 const [token , setToken] = useState (localStorage.getItem("token"));
    
  useEffect(() => {
    setShowLoader(true);
    fetchSubmittedPayout(token)
        .then((response) => {
         
          if (response?.statusCode === 200) {
            setSubmittedPayout(response?.data);
      
          }else{
            setSubmittedPayout([]);
             }
             setShowLoader(false)
        })
        .catch((error) => {
          setShowLoader(false)
          console.error('Error fetching vendor:', error.message);
        });
    }, [openView]);
  
    const ViewDetail = (id) => {
      setOpenView(true)
       setId(id)
      //  setRequestId(requestId)
    };
  
    const columns = [
      // { label: ' ID', field: 'id', width: 'auto' },
      { label: 'Vendor Name', field: 'fullName', width: 'auto' },
      { label: 'e-Mail', field: 'email', width: 'auto' },
      { label: 'Category', field: 'category', width: 'auto' },
      { label: 'Mobile', field: 'mobile', width: 'auto' },
      { label: 'Merchant ID', field: 'mid', width: 'auto' },
      { label: 'Merchant Name', field: 'companyName', width: 'auto' },
      { label: ' Created Date', field: 'createDate', width: 'auto' },
      { label: ' Created Time', field: 'createTime', width: 'auto' },
      { label: ' Amount', field: 'amount', width: 'auto' },
      { label: 'Action', field: 'action', width: '150px' },
    ];
   

    const mappedData = payout? payout?.map((item) => {
      const mappedItem = { ...item }; 
      mappedItem.action = (
        <div>
          <button
          className="btn btn-primary"
            onClick={() => ViewDetail(item.id )}
          >
            View Details
          </button>
          </div>
      );
      return mappedItem;
    }):[];
  
    return (
      <> 
    <Container>
    <Row>
      <Col xs={12} lg={12} md={12}>
      <Card className="shadow border-0 mb-4">
        <Card.Body>
        <div className="report-grid-view">
      <div className="material-table-records mb-3">
              {!openView?(  <MDBDataTable
                  className='dataTable'
                striped
                small
                bordered
                hover
                responsive
                data={{
                  columns:columns,
                  rows: mappedData,
                }}
                searching={true}
                entries={50}
                exportToCSV
                theadColor="#333"
                paginationLabel={['Previous', 'Next']}
                sortable={true}
                footer="none"
              />):(<ViewDetails id={id} setActivePhase={setActivePhase} setOpenView={setOpenView} setShowLoader={setShowLoader}/>)}
                 </div>
            </div>
            </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container> 
  </>
    );
  }
  
export default SubmittedPayout