import React, { useEffect, useState } from "react";
//  import NodalFile from '../../assets/templateFiles'
import { MDBDataTable, MDBDataTableV5 } from "mdbreact";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  ModalFooter,
  Row,
} from "react-bootstrap";
import Papa from "papaparse";

import {
  FetchAllReconcialiation,
  getUploadedPdfFiles,
  uploadNodalFile,
  uploadNodalTrueOrFalse,
  uploadSwitchFile,
} from "../../../utils/ApiServices";
import swal from "sweetalert";
import { FileOpen } from "@mui/icons-material";
import axios from "axios";
import NodalTemplate from "../../../assets/templateFiles/NodalTemplate.csv";
import SwitchTemplate from "../../../assets/templateFiles/SwitchTemplate.csv";
import { SWIPELINC_API } from "../../../utils/constant";
import ViewDetailsForReconcialiation from "./ViewDetailsForReconcialiation";
import {
  convertStringDateToDateType,
  getMonthName,
} from "../../../utils/helper";
import { DateTime } from "luxon";
import ReconMultiFilter from "./ReconMultiFilter";
import ReconAdvanceSearch from "./ReconAdvanceSearch";
import DownloadCsv from "../../../common/DownloadCsv";
import useCheckPrivilages from "../../../utils/checkPrivilages";
import InactiveServices from "../../InactiveServices";

const Reconciliation = ({ setShowLoader }) => {
  const [routingFile, setRoutingFile] = useState(null);
  const [nodalFile, setNodalFile] = useState(null);
  const [reconcialiAtion, setReconcialiAtionData] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filesOpen, setFilesOpen] = useState(false);
  const [isMultiFilterOpen, setIsMultiFilterOpen] = useState(false);
  const [isAdvanceFilterOpen, setIsAdvanceFilterOpen] = useState(false);
  const [getApi, setGetApi] = useState(false);
  const [pdfFilenames, setPdfFilenames] = useState([]);
  const [isSubmitDisabledByApi, setIsSubmitDisabledByApi] = useState(true);
  const [id, setId] = useState("");
  const [dataTableData, setDataTableData] = useState([]); // Data for MDBDataTable
  const token = localStorage.getItem("token");
  const hasPrivilage = useCheckPrivilages(2700);
  const [lastReconcillationDate, setLastReconcillationDate] = useState(null);

  const [isSwitchFileDateModalOpen, setIsSwitchFileDateModalOpen] =
    useState(false);
  const [switchFileUploadDate, setSwitchFileUploadDate] = useState({
    startDate: "",
    endDate: "",
  });

  const [isNodalFileDateModalOpen, setIsNodalFileDateModalOpen] =
    useState(false);
  const [nodalFileUploadDate, setNodalFileUploadDate] = useState({
    startDate: "",
    endDate: "",
  });

  const handleRoutingFileChange = (e) => {
    const file = e.target.files[0];
    setRoutingFile(file);
  };

  const getTrueOrFalse = async (token) => {
    const resApi = await uploadNodalTrueOrFalse(token);

    if (resApi.statusCode === 200) {
      setIsSubmitDisabled(false);
    }
  };
  getTrueOrFalse(token);

  const handleViewPDF = (filename) => {
    const encodedFilename = encodeURIComponent(filename);
    const headers = {
      Authentication: ` Bearer ${token}`,
    };
    console.log(filename);
    axios
      .get(
        `${SWIPELINC_API}/MerchantAdminPanel/reconciliationReport/view-pdf/${encodedFilename}`,
        {
          responseType: "arraybuffer",
          headers,
        }
      )
      .then((response) => {
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");
        // setSelectedFile(pdfUrl);
      })
      .catch((error) => {
        console.error("Error fetching PDF:", error.message);
      });
  };

  const getReconcialiationData = async (token) => {
    const resApi = await FetchAllReconcialiation(token);
    if (resApi.statusCode === 200) {
      const modifiedData = resApi.data.map((item) => ({
        ...item,
        ExtId: item.extId ? item.extId : "",
        switchMsg: item.switchMsg.toLowerCase(),
      }));
      setReconcialiAtionData(modifiedData);
    }
  };

  const ViewDetails = (id) => {
    console.log("id____", id);
    setIsModalOpen(true);
    setId(id);
  };
  const mappedData = !reconcialiAtion?.length
    ? []
    : reconcialiAtion?.map((item) => {
        const mappedItem = { ...item };
        mappedItem.action = (
          <div>
            <button
              className="btn btn-primary"
              onClick={() => ViewDetails(item.primeryKey)}
            >
              View Details
            </button>
          </div>
        );
        mappedItem.ExtId = item.extId ? item.extId : "";

        return mappedItem;
      });
  const getUploadedPdfFilesNames = async (token) => {
    const resApi = await getUploadedPdfFiles(token);
    if (resApi.statusCode === 200) {
      setPdfFilenames(resApi?.data);
    }
  };

  const ViewPdfs = () => {
    setFilesOpen(!filesOpen);
  };
  useEffect(() => {
    getReconcialiationData(token);
  }, [getApi]);
  useEffect(() => {
    console.log(reconcialiAtion);
  }, [reconcialiAtion]);
  useEffect(() => {
    // getReconcialiationData(token);
    getUploadedPdfFilesNames(token);
  }, [filesOpen]);

  const handleNodalFileChange = (e) => {
    const file = e.target.files[0];
    setNodalFile(file);
  };
  const handleCheckBox = () => {
    setIsSubmitDisabled(!isSubmitDisabled);
  };

  const handleRoutingUpload = async () => {
    setIsSwitchFileDateModalOpen(true);
  };

  function handleDownloadExcel(reconcialiAtion) {
    try {
      // Check if FinalDownloadData is defined and not null
      if (!reconcialiAtion) {
        console.error("Error: FinalDownloadData is undefined or null");
        return;
      }

      // Assuming you have access to the originalData
      const apiData = reconcialiAtion;
      console.log(reconcialiAtion);

      // Check if apiData is defined and not null, and if it has at least one element
      if (!apiData || apiData.length === 0) {
        console.error("Error: apiData is undefined, null, or empty");
        return;
      }

      if (!Array.isArray(apiData)) {
        console.error("Error: apiData is not an array");
        return;
      }

      // Check if apiData is not empty
      if (apiData.length === 0) {
        console.error("Error: apiData is an empty array");
        return;
      }

      const headerLabels = Object.keys(apiData[0]);

      // Extract the data from the originalData
      const apiRows = apiData.map((item) => Object?.values(item));

      // Combine header with data
      const dataWithHeader = [headerLabels, ...apiRows];

      // Convert the data with header to CSV format using PapaParse
      const csv = Papa.unparse(dataWithHeader);

      // Create a Blob object from the CSV data
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "Report.csv"; // Change the file name as needed
      link.style.display = "none";

      document.body.appendChild(link);
      link.click();

      // Clean up by removing the temporary element
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const UploadSwitchFileToServer = async () => {
    if (routingFile) {
      setShowLoader(true);
      let SwitchDocsRes = await uploadSwitchFile(routingFile, token);
      setShowLoader(false);
      console.log(SwitchDocsRes);

      if (
        SwitchDocsRes.statusCode !== 600 &&
        SwitchDocsRes.statusCode !== 101
      ) {
        // update Date
        sendDatesAndFileType(
          switchFileUploadDate.startDate,
          switchFileUploadDate.endDate,
          "switch"
        );
        fetchUploadHistory();
        setIsSwitchFileDateModalOpen(false);
        setSwitchFileUploadDate({
          startDate: "",
          endDate: "",
        });
      }

      if (SwitchDocsRes.statusCode === 200) {
        const errorData = SwitchDocsRes.size;
        let errorMessage = ` ${errorData} Records has been Uploaded.`;
        swal({
          title: "Success",
          text: errorMessage,
          icon: "success",
        }).then(() => {
          fetchUploadHistory();
          setIsSwitchFileDateModalOpen(false);
          setSwitchFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
        setGetApi(!getApi);
        getTrueOrFalse(token);
      } else if (SwitchDocsRes.statusCode === 1001) {
        const errorData = SwitchDocsRes.size;
        let errorMessage = `${errorData} Records has been Uploaded.`;
        swal({
          title: "Success",
          text: errorMessage,
          icon: "success",
        }).then(() => {
          fetchUploadHistory();
          setIsSwitchFileDateModalOpen(false);
          setSwitchFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
        setGetApi(!getApi);
        getTrueOrFalse(token);
      } else if (SwitchDocsRes.statusCode === 422) {
        let errorMessage = "No Matching Record Found.";
        fetchUploadHistory();
        swal({
          title: "Error",
          text: errorMessage,
          icon: "error",
        }).then(() => {
          fetchUploadHistory();
          setIsSwitchFileDateModalOpen(false);
          setSwitchFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
      } else if (SwitchDocsRes?.statusCode === 600) {
        const errorMessage =
          SwitchDocsRes.message || "Error occurred while reading the file.";
        fetchUploadHistory();
        swal({
          title: `${SwitchDocsRes.status}`,
          text: errorMessage,
          icon: "error",
        }).then(() => {
          setIsSwitchFileDateModalOpen(false);
          fetchUploadHistory();
          setSwitchFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
      } else if (SwitchDocsRes?.statusCode === 101) {
        const errorMessage = "Please upload a valid file.";

        swal({
          title: "Failed",
          text: errorMessage,
          icon: "error",
        }).then(() => {
          fetchUploadHistory();
          setIsSwitchFileDateModalOpen(false);
          setSwitchFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
      } else if (SwitchDocsRes?.statusCode === 500) {
        swal({
          title: "Failed",
          text: "Please Upload CSV File",
          icon: "error",
        }).then(() => {
          fetchUploadHistory();
          setIsSwitchFileDateModalOpen(false);
          setSwitchFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
      } else if (SwitchDocsRes?.statusCode === 304) {
        swal({
          title: "Failed",
          text: "Something Went Wrong.",
          icon: "error",
        }).then(() => {
          fetchUploadHistory();
          setIsSwitchFileDateModalOpen(false);
          setSwitchFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
      } else {
        swal({
          title: "Alert!",
          text: SwitchDocsRes?.message,
          icon: "error",
        }).then(() => {
          fetchUploadHistory();
          setIsSwitchFileDateModalOpen(false);
          setSwitchFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
      }

      setRoutingFile(null);
      document.getElementById("FileName").value = "";
    } else {
      swal({
        title: "Alert!",
        text: "Select File First",
        icon: "warning",
      }).then(() => {
        setIsSwitchFileDateModalOpen(false);
        setSwitchFileUploadDate({
          startDate: "",
          endDate: "",
        });
      });
    }
  };

  const UploadNodalFileToServer = async () => {
    if (nodalFile) {
      setShowLoader(true);
      let NodalDocsRes = await uploadNodalFile(nodalFile, token);

      if (NodalDocsRes.statusCode !== 600 && NodalDocsRes.statusCode !== 101) {
        // update Date
        sendDatesAndFileType(
          nodalFileUploadDate.startDate,
          nodalFileUploadDate.endDate,
          "nodel"
        );
        setIsNodalFileDateModalOpen(false);
        fetchUploadHistory();
        setNodalFileUploadDate({
          startDate: "",
          endDate: "",
        });
      }

      setShowLoader(false);
      console.log(NodalDocsRes);
      if (NodalDocsRes?.statusCode === 200) {
        const errorData = NodalDocsRes?.size;
        let errorMessage = `${NodalDocsRes?.size} Records has been Matched.`;
        swal({
          title: "Alert!",
          text: errorMessage,
          icon: "success",
        }).then(() => {
          fetchUploadHistory();
          setIsNodalFileDateModalOpen(false);
          setNodalFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
        setGetApi(!getApi);
      } else if (NodalDocsRes?.statusCode === 1001) {
        const errorData = NodalDocsRes?.size;
        let errorMessage = `${NodalDocsRes?.size} Records has been Matched.`;
        fetchUploadHistory();
        swal({
          title: "success",
          text: errorMessage,
          icon: "success",
        }).then(() => {
          setIsNodalFileDateModalOpen(false);
          setNodalFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
        setGetApi(!getApi);
      } else if (NodalDocsRes?.statusCode === 422) {
        let errorMessage = "No Matching Record Found.";
        fetchUploadHistory();
        swal({
          title: "Error",
          text: errorMessage,
          icon: "error",
        }).then(() => {
          setIsNodalFileDateModalOpen(false);
          setNodalFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
      } else if (NodalDocsRes?.statusCode === 600) {
        const errorMessage =
          NodalDocsRes?.message || "Error occurred while reading the file.";

        swal({
          title: `${NodalDocsRes?.status}`,
          text: errorMessage,
          icon: "error",
        }).then(() => {
          fetchUploadHistory();
          setIsNodalFileDateModalOpen(false);
          setNodalFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
      } else if (NodalDocsRes?.statusCode === 101) {
        const errorMessage = "Please upload a valid file.";
        swal({
          title: "Failed",
          text: errorMessage,
          icon: "error",
        }).then(() => {
          fetchUploadHistory();
          setIsNodalFileDateModalOpen(false);
          setNodalFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
      } else if (NodalDocsRes?.statusCode === 500) {
        swal({
          title: "Failed",
          text: "Please Upload CSV File",
          icon: "error",
        }).then(() => {
          fetchUploadHistory();
          setIsNodalFileDateModalOpen(false);
          setNodalFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
      } else {
        swal({
          title: "Alert!",
          text: NodalDocsRes?.message,
          icon: "error",
        }).then(() => {
          fetchUploadHistory();
          setIsNodalFileDateModalOpen(false);
          setNodalFileUploadDate({
            startDate: "",
            endDate: "",
          });
        });
      }

      setNodalFile(null);
      document.getElementById("FileNameNodal").value = "";
    } else {
      swal({
        title: "Alert!",
        text: "Select File First",
        icon: "warning",
      }).then(() => {
        setIsNodalFileDateModalOpen(false);
        setNodalFileUploadDate({
          startDate: "",
          endDate: "",
        });
      });
    }
  };

  const handleNodalUpload = async () => {
    setIsNodalFileDateModalOpen(true);
  };

  const handleCloseSwitchFileDateModal = () => {
    setIsSwitchFileDateModalOpen(false);
  };

  const handleCloseNodalFileDateModal = () => {
    setIsNodalFileDateModalOpen(false);
  };

  const columns = [
    {
      label: "Merchant Id",
      field: "mid",
      sort: "asc",
    },

    {
      label: "RRN / UTR Number",
      field: "switchRrn",
      sort: "asc",
    },

    {
      label: "EXT Transaction ID",
      field: "ExtId",
      sort: "asc",
    },
    {
      label: "Amount",
      field: "amount",
      sort: "asc",
    },
    {
      label: "Payin Status",
      field: "payinStatus",
      sort: "asc",
    },
    {
      label: "Consolidated Status",
      field: "consolidatedStatus",
      sort: "asc",
    },
    {
      label: "Switch Message",
      field: "switchMsg",
      sort: "asc",
    },
    {
      label: "Date",
      field: "date",
      sort: "asc",
      // width:"300px"
    },
    {
      label: "Action",
      field: "action",
      sort: "asc",
      // width:"300px"
    },
  ];

  const handelSwithDateAndUploadFileToServer = () => {
    if (routingFile === null) {
      swal({
        title: "Alert!",
        text: "Please Select Switch File",
        icon: "error",
      }).then(() => {
        setIsSwitchFileDateModalOpen(false);
        setSwitchFileUploadDate({
          startDate: "",
          endDate: "",
        });
      });
      return;
    }

    if (switchFileUploadDate.startDate === "") {
      swal({
        title: "Alert!",
        text: "Please Select Switch File Start Date",
        icon: "error",
      });
      return;
    }

    if (switchFileUploadDate.endDate === "") {
      swal({
        title: "Alert!",
        text: "Please Select Switch File End Date",
        icon: "error",
      });
      return;
    }

    UploadSwitchFileToServer();
  };

  const handelNodalDateAndUploadFileToServer = () => {
    if (nodalFile === null) {
      swal({
        title: "Alert!",
        text: "Please Select Nodal File",
        icon: "error",
      }).then(() => {
        setIsNodalFileDateModalOpen(false);
        setNodalFileUploadDate({
          startDate: "",
          endDate: "",
        });
      });
      return;
    }

    if (nodalFileUploadDate.startDate === "") {
      swal({
        title: "Alert!",
        text: "Please Select Nodal File Start Date",
        icon: "error",
      });
      return;
    }

    if (nodalFileUploadDate.endDate === "") {
      swal({
        title: "Alert!",
        text: "Please Select Nodal File End Date",
        icon: "error",
      });
      return;
    }

    UploadNodalFileToServer();
  };

  const sendDatesAndFileType = async (startDate, endDate, fileType) => {
    try {
      const params = {
        startDate: startDate,
        endDate: endDate,
        file: fileType,
      };

      const response = await axios.post(
        `${SWIPELINC_API}MerchantAdminPanel/reconciliationReport/save/date`,
        params,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error("Error sending dates and file type:", error);
      return null;
    }
  };

  const handleDateInputChange = (event) => {
    const newDate = event.target.value;
    // Check if the input date is greater than today's date
    const inputDateTime = DateTime.fromISO(newDate, { zone: "UTC" });
    const today = DateTime.now().startOf("day");

    if (inputDateTime.toISODate() > today.toISODate()) {
      return true;
    }
    return false;
  };

  const handleFromToDateInputChange = (from, to) => {
    console.log(from, ":", to);

    const inputFromDateTime = DateTime.fromISO(from, { zone: "utc" });
    const inputToDateTime = DateTime.fromISO(to, { zone: "utc" });
    if (inputToDateTime >= inputFromDateTime) {
      return true;
    } else {
      return false;
    }
  };

  const fetchUploadHistory = async () => {
    try {
      const response = await axios.get(
        `${SWIPELINC_API}MerchantAdminPanel/reconciliationReport/getDate`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      );

      if (response.data.statusCode === 200) {
        setLastReconcillationDate(response.data.data);
      } else {
      }
    } catch (error) {
      console.error("Error fetching upload history:", error);
    }
  };

  const HandleMultipleFilter = () => {
    setIsMultiFilterOpen(!isMultiFilterOpen);
  };

  const HandleAdvanceFilter = () => {
    setIsAdvanceFilterOpen(!isAdvanceFilterOpen);
  };

  useEffect(() => {
    fetchUploadHistory();
  }, []);

  return (
    <>
      {/* {hasPrivilage ? ( */}
        <>
          {" "}
          <section className="lincpay-dashboard-sec chargeback-reports-sec">
            <Container>
              <div className="cart-view-sec mt-2">
                <Row>
                  <Col xs={12} lg={12} md={12}>
                    {/* Tab content start here */}

                    <Card className="shadow-cst border-0 mb-3 chart-boxes">
                      <Card.Body className="px-5 mt-2">
                        <div className="d-flex border border-2 w-25 p-2 rounded-2">
                          <b>
                            Last Reconciliation Date : {lastReconcillationDate}{" "}
                          </b>
                        </div>
                      </Card.Body>
                      <Card.Body className="">
                        <div className="w-100 d-flex justify-content-center align-items-between   ">
                          <div className="w-50 mx-5">
                            <Card.Title className="h3 mb-3 card-header-btn">
                              Switch File Upload
                            </Card.Title>
                            <div className="card-chart-box">
                              <div className="excel-uploader">
                                <div className="card-chart-box">
                                  <div className="excel-uploader">
                                    <div className="form-group upload-field">
                                      <div className="input-upload-file">
                                        <input
                                          type="file"
                                          className="form-control"
                                          id="FileName"
                                          // accept=".csv"
                                          onChange={handleRoutingFileChange}
                                        />
                                      </div>
                                      <div className="toolbar-btn">
                                        <Button
                                          variant="primary"
                                          onClick={handleRoutingUpload}
                                        >
                                          Upload
                                        </Button>
                                      </div>
                                    </div>
                                    <Card.Title className="w-100 h3 mb-3 mt-3 card-header-btn  d-flex  justify-content-start gap-5">
                                      <div className="button-toolbar">
                                        <a
                                          className="btn btn-success"
                                          title="Click here to download Chargeback file template"
                                          href={SwitchTemplate}
                                          download
                                        >
                                          Download Template
                                        </a>
                                      </div>
                                    </Card.Title>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="w-50">
                            <Card.Title className="h3 mb-3 card-header-btn">
                              Nodal / Bank File Upload
                            </Card.Title>

                            <div className=" d-flex justify-content-start gap-5 align-items-center mb-2">
                              <input
                                type="checkbox"
                                className="fw-bold"
                                onChange={handleCheckBox}
                              />
                              <span>
                                Do You still want to Upload the nodal file
                              </span>
                            </div>

                            <div className="card-chart-box">
                              <div className="excel-uploader">
                                <div className="card-chart-box">
                                  <div className="excel-uploader">
                                    <div className="form-group upload-field">
                                      <div className="input-upload-file">
                                        <input
                                          type="file"
                                          className="form-control"
                                          id="FileNameNodal"
                                          onChange={handleNodalFileChange}
                                        />
                                      </div>
                                      <div className="toolbar-btn">
                                        <Button
                                          disabled={isSubmitDisabled}
                                          variant="primary"
                                          onClick={handleNodalUpload}
                                        >
                                          Upload
                                        </Button>
                                      </div>
                                    </div>
                                    <span className="text-danger mt-2 ">
                                      * Note - Kindly upload Switch file first.
                                    </span>
                                    <Card.Title className="w-100 h3 mb-3 mt-3 card-header-btn  d-flex  justify-content-start gap-5">
                                      <div className="button-toolbar">
                                        <a
                                          className="btn btn-success"
                                          title="Click here to download Chargeback file template"
                                          href={NodalTemplate}
                                          download
                                        >
                                          Download Template
                                        </a>
                                      </div>
                                    </Card.Title>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <canvas id="income" width="auto" height="auto"></canvas> */}
                      </Card.Body>
                    </Card>
                    <Button onClick={ViewPdfs}>
                      {filesOpen ? "Close Files" : "View Uploaded Files"}
                    </Button>
                    {filesOpen && (
                      <div className="pdf-list-container report-grid-view mt-3 p-5 card">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Switch File Name</th>
                              <th>consolidated File Name</th>
                              {/* <th>Action</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {pdfFilenames.map((fileObject, index) => (
                              <tr key={index}>
                                {/* <td>{fileObject["Orignal name"]}</td> */}
                                <td>{fileObject["switchFilePath"]}</td>
                                <td>{fileObject["consolidatedFilePath"]}</td>
                                {/* <td>
                              <button
                                className="btn btn-secondary"
                                onClick={() =>
                                  handleViewPDF(fileObject["switchFilePath"])
                                }
                              >
                                View PDF
                              </button>
                            </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </Col>
                  {/* ./col */}
                </Row>
              </div>
            </Container>
          </section>
          <section className="lincpay-dashboard-sec chargeback-reports-sec mt-3">
            <Container>
              <div className="cart-view-sec mt-2">
                <Row>
                  <Col xs={12} lg={12} md={12}>
                    {/* Tab content start here */}
                    <Card className="shadow-cst border-0 mb-3 chart-boxes">
                      <Card.Body>
                        <Col className="d-flex justify-content-end gap-3">
                          <Button onClick={HandleMultipleFilter}>
                            <i className="bi bi-funnel-fill mx-1"></i>More
                            Filter
                          </Button>
                          <Button onClick={HandleAdvanceFilter}>
                            <i className="bi bi-funnel-fill mx-1"></i>Advance
                            Filter
                          </Button>
                          <Button
                            onClick={() => {
                              setGetApi(!getApi);
                            }}
                          >
                            {" "}
                            <i class="bi bi-arrow-clockwise mx-1"> </i>Reset
                          </Button>
                        </Col>
                        <MDBDataTable
                          className="dataTable"
                          striped
                          small
                          bordered
                          hover
                          responsive
                          data={{
                            columns: columns,
                            rows: mappedData,
                          }}
                          searching={true}
                          entries={50}
                          exportToCSV
                          theadColor="#333"
                          paginationLabel={["Previous", "Next"]}
                          sortable={true}
                          footer="none"
                        />
                      </Card.Body>

                      {isMultiFilterOpen ? (
                        <ReconMultiFilter
                          setReconReportData={setReconcialiAtionData}
                          setIsMultiFilterOpen={setIsMultiFilterOpen}
                          setShowLoader={setShowLoader}
                          dropDownData=""
                        />
                      ) : (
                        ""
                      )}
                      {isAdvanceFilterOpen ? (
                        <ReconAdvanceSearch
                          setReconReportData={setReconcialiAtionData}
                          setIsAdvanceFilterOpen={setIsAdvanceFilterOpen}
                          setShowLoader={setShowLoader}
                        />
                      ) : (
                        ""
                      )}
                      <div className="p-2 px-2">
                        <DownloadCsv
                          DownLoadData={reconcialiAtion}
                          Name={`Reconciliation`}
                        />
                      </div>
                    </Card>
                    {/* Tab content end here */}
                  </Col>
                  {/* ./col */}
                </Row>
              </div>
            </Container>
          </section>
          {isModalOpen ? (
            <section className="lincpay-dashboard-sec chargeback-reports-sec mt-3">
              <Container>
                <ViewDetailsForReconcialiation
                  id={id}
                  setIsModalOpen={setIsModalOpen}
                  isModalOpen={isModalOpen}
                />
              </Container>
            </section>
          ) : (
            ""
          )}
          <Modal
            show={isSwitchFileDateModalOpen}
            onHide={handleCloseSwitchFileDateModal}
            centered="true"
          >
            <Modal.Header closeButton>
              <Modal.Title>Switch File:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId="switchStartDate">
                <Form.Label>Switch Start Date</Form.Label>
                <Form.Control
                  type="date"
                  value={switchFileUploadDate.startDate}
                  onChange={(e) => {
                    if (handleDateInputChange(e)) {
                      setSwitchFileUploadDate({
                        ...switchFileUploadDate,
                        startDate: "",
                      });
                      document
                        .getElementById("startDateErr")
                        .classList.remove("d-none");
                      document.getElementById(
                        "applySwitchDateBtn"
                      ).disabled = true;
                    } else {
                      document
                        .getElementById("startDateErr")
                        .classList.add("d-none");
                      document.getElementById(
                        "applySwitchDateBtn"
                      ).disabled = false;
                      setSwitchFileUploadDate({
                        ...switchFileUploadDate,
                        startDate: e.target.value,
                      });
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                  // onChange={(e) =>
                  //   setSwitchFileUploadDate({
                  //     ...switchFileUploadDate,
                  //     startDate: e.target.value,
                  //   })
                  // }
                />
                <span id="startDateErr" className=" text-danger d-none">
                  *Please Enter Valid Date!
                </span>
              </Form.Group>
              <Form.Group controlId="switchEndDate" className="mt-3">
                <Form.Label>Switch End Date</Form.Label>
                <Form.Control
                  type="date"
                  value={switchFileUploadDate.endDate}
                  onChange={(e) => {
                    if (handleDateInputChange(e)) {
                      setSwitchFileUploadDate({
                        ...switchFileUploadDate,
                        endDate: "",
                      });
                      document
                        .getElementById("endDateErr")
                        .classList.remove("d-none");
                      document.getElementById(
                        "applySwitchDateBtn"
                      ).disabled = true;
                    } else {
                      if (
                        handleFromToDateInputChange(
                          switchFileUploadDate.startDate,
                          e.target.value
                        )
                      ) {
                        document
                          .getElementById("endDateErr")
                          .classList.add("d-none");
                        document
                          .getElementById("fromToDateErr")
                          .classList.add("d-none");
                        document.getElementById(
                          "applySwitchDateBtn"
                        ).disabled = false;
                        setSwitchFileUploadDate({
                          ...switchFileUploadDate,
                          endDate: e.target.value,
                        });
                      } else {
                        document
                          .getElementById("fromToDateErr")
                          .classList.remove("d-none");
                        document.getElementById(
                          "applySwitchDateBtn"
                        ).disabled = true;
                        setSwitchFileUploadDate({
                          ...switchFileUploadDate,
                          endDate: "",
                        });
                      }
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}

                  // onChange={(e) =>
                  //   setSwitchFileUploadDate({
                  //     ...switchFileUploadDate,
                  //     endDate: e.target.value,
                  //   })
                  // }
                />
                <span id="endDateErr" className=" text-danger d-none">
                  *Please Enter Valid Date!
                </span>
                <span id="fromToDateErr" className=" text-danger d-none">
                  *TO Date can't be less than FROM Date!
                </span>
              </Form.Group>
              <Form.Group
                className="mt-2 d-flex justify-content-center"
                controlId="switchEndDate"
              >
                <Button
                  variant="primary"
                  id="applySwitchDateBtn"
                  onClick={handelSwithDateAndUploadFileToServer}
                >
                  Apply Switch Dates
                </Button>
              </Form.Group>
            </Modal.Body>
            <ModalFooter></ModalFooter>
          </Modal>
          <Modal
            show={isNodalFileDateModalOpen}
            onHide={handleCloseNodalFileDateModal}
            centered="true"
          >
            <Modal.Header closeButton>
              <Modal.Title>Nodal File:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId="switchStartDate">
                <Form.Label>Switch Start Date</Form.Label>
                <Form.Control
                  type="date"
                  value={nodalFileUploadDate.startDate}
                  onChange={(e) => {
                    if (handleDateInputChange(e)) {
                      setNodalFileUploadDate({
                        ...nodalFileUploadDate,
                        startDate: "",
                      });
                      document
                        .getElementById("startDateErr")
                        .classList.remove("d-none");
                      document.getElementById(
                        "applyNodalDateBtn"
                      ).disabled = true;
                    } else {
                      document
                        .getElementById("startDateErr")
                        .classList.add("d-none");
                      document.getElementById(
                        "applyNodalDateBtn"
                      ).disabled = false;
                      setNodalFileUploadDate({
                        ...nodalFileUploadDate,
                        startDate: e.target.value,
                      });
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                />
                <span id="startDateErr" className=" text-danger d-none">
                  *Please Enter Valid Date!
                </span>
              </Form.Group>
              <Form.Group controlId="switchEndDate" className="mt-3">
                <Form.Label>Switch End Date</Form.Label>
                <Form.Control
                  type="date"
                  value={nodalFileUploadDate.endDate}
                  onChange={(e) => {
                    if (handleDateInputChange(e)) {
                      setNodalFileUploadDate({
                        ...nodalFileUploadDate,
                        endDate: "",
                      });
                      document
                        .getElementById("endDateErr")
                        .classList.remove("d-none");
                      document.getElementById(
                        "applyNodalDateBtn"
                      ).disabled = true;
                    } else {
                      if (
                        handleFromToDateInputChange(
                          nodalFileUploadDate.startDate,
                          e.target.value
                        )
                      ) {
                        document
                          .getElementById("endDateErr")
                          .classList.add("d-none");
                        document
                          .getElementById("fromToDateErr")
                          .classList.add("d-none");
                        document.getElementById(
                          "applyNodalDateBtn"
                        ).disabled = false;
                        setNodalFileUploadDate({
                          ...nodalFileUploadDate,
                          endDate: e.target.value,
                        });
                      } else {
                        document
                          .getElementById("fromToDateErr")
                          .classList.remove("d-none");
                        document.getElementById(
                          "applyNodalDateBtn"
                        ).disabled = true;
                        setNodalFileUploadDate({
                          ...nodalFileUploadDate,
                          endDate: "",
                        });
                      }
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                />
                <span id="endDateErr" className=" text-danger d-none">
                  *Please Enter Valid Date!
                </span>
                <span id="fromToDateErr" className=" text-danger d-none">
                  *TO Date can't be less than FROM Date!
                </span>
              </Form.Group>
              <Form.Group
                className="mt-2 d-flex justify-content-center"
                controlId="switchEndDate"
              >
                <Button
                  variant="primary"
                  id="applyNodalDateBtn"
                  onClick={handelNodalDateAndUploadFileToServer}
                >
                  Apply Nodal Dates
                </Button>
              </Form.Group>
            </Modal.Body>
            <ModalFooter></ModalFooter>
          </Modal>
        </>
      {/* ) : (
        <InactiveServices location={"privilages"} />
      )} */}
    </>
  );
};

export default Reconciliation;
