import React, { useEffect, useState } from "react";
// import PayoutRouting from "../../../Merchant/MerchantManagement/PayoutRouting";
import InactiveServices from "../components/InactiveServices";
import { Row, Col, Card, Container } from "react-bootstrap";
// import PayInRouting from "../../../Merchant/MerchantManagement/PayInRouting";
import RoutingSubTabForPayin from "../components/Tools/ALL PAYOUT REPORTS/RoutingSubTabForPayin";
import RoutingSubTabForPayout from "../components/Tools/ALL PAYOUT REPORTS/RoutingSubTabForPayout";
import RoutingSubTabForEkyc from "../components/RoutingSubTabForEkyc";
import useCheckPrivilages from "../utils/checkPrivilages";
import RoutingSubTabForPayoutOnBording from "./RoutingOnBoarding/routinSubTabForPayoutOnBording";
import RoutingSubTabForPayinOnBorading from "./RoutingOnBoarding/RoutingSubTabForPayinOnBorading";
import RoutingSubTabForEkycOnBording from "./RoutingOnBoarding/RoutingSubTabForEkycOnBording";

const RoutingSubtab1 = ({ setShowLoader }) => {
  const [activePhase, setActivePhase] = useState("Phase1");

  const hasPrivilages2633 = useCheckPrivilages(2633);
  const hasPrivilages2634 = useCheckPrivilages(2634);
  const hasPrivilages2635 = useCheckPrivilages(2635);
  const hasPrivilages2636 = useCheckPrivilages(2636);
  const hasPrivilages2637 = useCheckPrivilages(2637);
  const hasPrivilages2638 = useCheckPrivilages(2638);

  useEffect(() => {
    setActivePhase("Phase1");
  }, []);

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };
  return (
    <>
      <div className="subtab-sec-admin">
        <Container>
          <Row>
            <Col xs={12} lg={12} md={12}>
              <Card className="shadow border-0 mb-4">
                <div className="merchant-tab-btn">
                  <ul className="report-link nav-report-btn">
                  {hasPrivilages2633 || hasPrivilages2634 ? ( <li
                      className={activePhase === "Phase1" ? "active" : ""}
                      onClick={() => handlePhaseClick("Phase1")}
                    >
                      Payin Routing
                    </li>) : null}
                  {hasPrivilages2635 || hasPrivilages2636 ? ( <li
                      className={activePhase === "Phase2" ? "active" : ""}
                      onClick={() => handlePhaseClick("Phase2")}
                    >
                      Payout Routing
                    </li>) : null}
                  {hasPrivilages2637 || hasPrivilages2638 ? ( <li
                      className={activePhase === "Phase3" ? "active" : ""}
                      onClick={() => handlePhaseClick("Phase3")}
                    >
                      E-kyc Routing
                    </li>) : null}
                    
                  </ul>
                </div>
                <Card.Body>
                  <div className="admin-tab-content">
                    {activePhase === "Phase1" ? (
                      <RoutingSubTabForPayinOnBorading
                        location={"onboarding"}
                        setShowLoader={setShowLoader}
                      />
                    ) : //  <InactiveServices />
                    activePhase === "Phase2" ? (
                      // <InactiveServices />
                      <RoutingSubTabForPayoutOnBording
                        location={"onboarding"}
                        setShowLoader={setShowLoader}
                      />
                    ) : // <InactiveServices />
                    activePhase === "Phase3" ? (
                      // <InactiveServices />
                      <RoutingSubTabForEkycOnBording
                        location={"onboarding"}
                        setShowLoader={setShowLoader}
                      />
                    ) : (
                      // <InactiveServices />

                      <InactiveServices location={"onboarding"} />
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default RoutingSubtab1;
