import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import {
  GET_ALL_MCC_CODE,
  GET_CHANNEL_AND_TXN_SET,
  GET_DEVICE_MODAL,
  SAVE_BANK_ONBOARD,
} from "../utils/constant";
import swal from "sweetalert";
import MyLoader from "../common/MyLoader";

const BankOnboardModal = ({
  id,
  showModal,
  setShowModal,
  mid,
}) => {
  console.log(id, mid);

  const [bankOption, setBankOption] = useState("");
  const [MCCOption, setMCCOption] = useState("");
  const [deviceImeiNumber, setDeviceImeiNumber] = useState("");
  const [deviceModel, setDeviceModel] = useState("");
  const [deviceSerialNumber, setDeviceSerialNumber] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [upiVpa, setUpiVpa] = useState("");
  const [terminalName, setTerminalName] = useState("");
  const [upiOfflineType, setUpiOfflineType] = useState("");
  const [upiTerminalType, setUpiTerminalType] = useState("");
  const [radioOption, setRadioOption] = useState(null);
  const [mccCode, setMccCode] = useState([]);
  const [channelAndTxnSet, setChannelAndTxnSet] = useState([]);
  const [channel, setChannel] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [bussinesType, setBussinessType] = useState("");
  const [device, setDeviceModal] = useState([]);
  const [deviceModelOption, setDeviceModelOption] = useState("");
  const [welcomeKitSerialNo, setWelcomeKitSerialNo] = useState("");
  const token = localStorage.getItem("token");
  console.log(token);

  const resetForm = () => {
    setBankOption("");
    setMCCOption("");
    setDeviceImeiNumber("");
    setDeviceModel("");
    setDeviceSerialNumber("");
    setDeviceType("");
    setUpiVpa("");
    setTerminalName("");
    setUpiOfflineType("");
    setUpiTerminalType("");
    setRadioOption(null);
    setMccCode([]);
    setChannelAndTxnSet([]);
    setChannel("");
    setDeviceModal([]);
    setDeviceModelOption("");
    setWelcomeKitSerialNo("");
  };

  useEffect(() => {
    setShowLoader(true);
    FetchAllMccCode(token);
    FetchChannelAndTxnSet(token);
    FetchDeviceModal(token);
    setShowLoader(false);
  }, [radioOption]);

  const FetchAllMccCode = async (token) => {
    try {
      const response = await axios.get(GET_ALL_MCC_CODE, {
        headers: {
          Authentication: `Bearer ${token}`,
        },
      });

      if (response?.data?.statusCode) {
        setMccCode(response?.data?.data);
      } else {
        setMccCode([]);
      }
    } catch (error) {}
  };

  const FetchDeviceModal = async (token) => {
    try {
      const response = await axios.get(GET_DEVICE_MODAL, {
        headers: {
          Authentication: `Bearer ${token}`,
        },
      });

      if (response?.data?.statusCode) {
        setDeviceModal(response?.data?.data);
      } else {
        setDeviceModal([]);
      }
    } catch (error) {}
  };

  const FetchChannelAndTxnSet = async (token) => {
    try {
      const response = await axios.get(GET_CHANNEL_AND_TXN_SET, {
        headers: {
          Authentication: `Bearer ${token}`,
        },
      });

      if (response?.data?.statusCode) {
        setChannelAndTxnSet(response?.data?.data);
      } else {
        setChannelAndTxnSet([]);
      }
    } catch (error) {}
  };

  const HandleSubmit = async (token) => {
    if (MCCOption === "") {
      swal({
        title: "ALERT",
        text: "Please Select MCC Code",
        icon: "error",
      });
      return;
    }

    if (
      channel === "" &&
      radioOption === "radio1" &&
      bankOption === "CUB"
    ) {
      swal({
        title: "ALERT",
        text: "Please Select Channel",
        icon: "error",
      });
      return;
    }

    if (
      upiVpa === "" &&
      radioOption === "radio1" &&
      bankOption === "CUB"
    ) {
      swal({
        title: "ALERT",
        text: "Please Select Channel",
        icon: "error",
      });
      return;
    }

    // if (
    //   deviceImeiNumber === "" &&
    //   radioOption === "radio1" &&
    //   bankOption === "CUB"
    // ) {
    //   swal({
    //     title: "ALERT",
    //     text: "Please Enter Device IMEI Number",
    //     icon: "error",
    //   });
    //   return;
    // }

    // if (
    //   deviceModelOption === "" &&
    //   radioOption === "radio1" &&
    //   bankOption === "CUB"
    // ) {
    //   swal({
    //     title: "ALERT",
    //     text: "Please Select Device Model",
    //     icon: "error",
    //   });
    //   return;
    // }

    // if (
    //   deviceSerialNumber === "" &&
    //   radioOption === "radio1" &&
    //   bankOption === "CUB"
    // ) {
    //   swal({
    //     title: "ALERT",
    //     text: "Please Enter Device Seriel Number",
    //     icon: "error",
    //   });
    //   return;
    // }

    // if (
    //   deviceSerialNumber === "" &&
    //   radioOption === "radio1" &&
    //   bankOption === "CUB"
    // ) {
    //   swal({
    //     title: "ALERT",
    //     text: "Please Enter Device Seriel Number",
    //     icon: "error",
    //   });
    //   return;
    // }

    // if (
    //   deviceType === "" &&
    //   radioOption === "radio1" &&
    //   bankOption === "CUB"
    // ) {
    //   swal({
    //     title: "ALERT",
    //     text: "Please Enter Device Type",
    //     icon: "error",
    //   });
    //   return;
    // }

    // if (
    //   upiVpa === "" &&
    //   radioOption === "radio1" &&
    //   bankOption === "CUB"
    // ) {
    //   swal({
    //     title: "ALERT",
    //     text: "Please Enter Merchant UPI / VPA",
    //     icon: "error",
    //   });
    //   return;
    // }

    // if (
    //   terminalName === "" &&
    //   radioOption === "radio1" &&
    //   bankOption === "CUB"
    // ) {
    //   swal({
    //     title: "ALERT",
    //     text: "Please Enter Terminal Name",
    //     icon: "error",
    //   });
    //   return;
    // }

    // if (
    //   upiOfflineType === "" &&
    //   radioOption === "radio1" &&
    //   bankOption === "CUB"
    // ) {
    //   swal({
    //     title: "ALERT",
    //     text: "Please Enter UPI Offline Type",
    //     icon: "error",
    //   });
    //   return;
    // }

    // if (
    //   upiTerminalType === "" &&
    //   radioOption === "radio1" &&
    //   bankOption === "CUB"
    // ) {
    //   swal({
    //     title: "ALERT",
    //     text: "Please Enter UPI Terminal Type",
    //     icon: "error",
    //   });
    //   return;
    // }

    // if (
    //   welcomeKitSerialNo === "" &&
    //   radioOption === "radio1" &&
    //   bankOption === "CUB"
    // ) {
    //   swal({
    //     title: "ALERT",
    //     text: "Please Enter Welcome Kit Serial Number",
    //     icon: "error",
    //   });
    //   return;
    // }

    const payload = {
      bank: bankOption,
      mccCode: MCCOption,
      mid: mid,
      isTerminal: radioOption === "radio1" ? true : false,
      bussinesType: bankOption === "CUB" ? bussinesType : "",
      terminalInfoRequestDto: {
        channel: [channel],
        deviceImeiNumber: deviceImeiNumber,
        deviceModel: deviceModelOption,
        deviceSerialNumber: deviceSerialNumber,
        deviceType: deviceType,
        merchantCpid: "",
        upiVpa: upiVpa,
        terminalName: terminalName,
        upiOfflineType: upiOfflineType,
        upiTerminalType: upiTerminalType,
        welcomeKitSerialNo: welcomeKitSerialNo,
      },
    };

    const payload2 = {
      bank: bankOption,
      mccCode: MCCOption,
      mid: mid,
      isTerminal: radioOption === "radio1" ? true : false,
      bussinesType: bankOption === "CUB" ? bussinesType : "",
      terminalInfoRequestDto: {
        channel: [],
        deviceImeiNumber: "",
        deviceModel: "",
        deviceSerialNumber: "",
        merchantCpid: "",
        upiVpa: "",
        terminalName: "",
        upiOfflineType: "",
        upiTerminalType: "",
        welcomeKitSerialNo: "",
      },
    };
    setShowLoader(true);
    try {
      const response = await axios.post(
        SAVE_BANK_ONBOARD,
        radioOption === "radio1" ? payload : payload2,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      );

      if (response?.data?.statusCode === 200) {
        resetForm();
        swal({
          title: "Success",
          text: "Bank On-boarding Done Successfully",
          icon: "success",
        });
        setShowModal(false);
        setShowLoader(false);
      } else {
        swal({
          title: "Failed!",
          text: "Bank On-boarding Failed.",
          icon: "error",
        });
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      console.log(error.message);
    }
  };

  const handelDeviceModelSelection = (e) => {
    const { value } = e.target;
    const deviceType =
      e.target.selectedOptions[0].getAttribute("data-deviceType");
    console.log(value, deviceType);
    setDeviceModelOption(value);
    setDeviceType(deviceType);
  };

  const arrayBussinesstype = [
    "INDIVIDUAL",
    "PROPRIETORSHIP",
    "PARTNERSHIP",
    "PRIVATE LIMITED",
    "PUBLIC LIMITED",
    "TRUST",
    "OTHER",
  ];

  useEffect(() => {
    console.log(MCCOption);
  }, [MCCOption]);

  return (
    <Modal show={showModal} size="lg" onHide={() => setShowModal(false)}>
      {showLoader ? <MyLoader /> : null}
      <Modal.Header closeButton={() => setShowModal(false)}>
        <Modal.Title>Bank On-Board</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="w-100 p-4">
          <Form.Group>
            <Row className="form-group">
              <Col xm={3} xl={6} lg={4} className="">
                <label htmlFor="dropdown1">Select Bank</label>
                <select
                  className="form-control"
                  name="dropdown1"
                  value={bankOption}
                  onChange={(e) => setBankOption(e.target.value)}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="CUB">CENTRAL UNION BANK</option>
                  <option value="IDFC">IDFC Bank</option>
                  <option value="YES">YES Bank</option>
                </select>
              </Col>
              <Col xm={3} xl={6} lg={4} className="">
                <label htmlFor="inputField">Merchant Id</label>
                <input
                  className="form-control"
                  type="text"
                  id="inputField"
                  value={mid}
                  readOnly
                  name="inputField"
                />
              </Col>
              <Col xm={3} xl={6} lg={4} className="mt-2">
                <label htmlFor="dropdown2">MCC Code</label>
                <select
                  className="form-control"
                  value={MCCOption}
                  onChange={(e) => setMCCOption(e.target.value)}
                  name="dropdown2"
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {mccCode?.map((item, i) => {
                    return (
                      <option key={i + 1} value={item?.mccCode}>
                        {item?.natureOfBusiness}
                      </option>
                    );
                  })}
                </select>
              </Col>

              {bankOption === "CUB" && (
                <>
                  <Col xm={3} xl={6} lg={4} className="mt-2">
                    <label htmlFor="dropdown2">Bussines Type</label>
                    <select
                      className="form-control"
                      value={bussinesType}
                      onChange={(e) => setBussinessType(e.target.value)}
                      name="dropdown2"
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      {arrayBussinesstype.map((item, i) => {
                        return <option value={item}>{item}</option>;
                      })}
                    </select>
                  </Col>
                  <div className="d-flex gap-5 mt-3  ">
                    <div
                      className={`d-flex gap-3 border border-2 border-primary p-3 rounded rounded-5 ${
                        radioOption === "radio1" ? "bg-dark text-white" : ""
                      }`}
                    >
                      <input
                        style={{ width: "20px", height: "20px" }}
                        className="mt-1"
                        type="radio"
                        id="radio1"
                        name="radioGroup"
                        value="radio1"
                        onClick={(e) => setRadioOption(e.target.value)}
                      />
                      <label htmlFor="radio1">With Terminal</label>
                    </div>
                    <div
                      className={`d-flex gap-3 border border-2 border-primary p-3 rounded rounded-5 ${
                        radioOption === "radio2" ? "bg-dark text-white" : ""
                      }`}
                    >
                      <input
                        style={{ width: "20px", height: "20px" }}
                        className="mt-1"
                        size="lg"
                        type="radio"
                        id="radio2"
                        name="radioGroup"
                        value="radio2"
                        onClick={(e) => setRadioOption(e.target.value)}
                      />
                      <label htmlFor="radio2">Without Terminal</label>
                    </div>
                  </div>
                  {radioOption === "radio1" ? (
                    <Row className="mt-4">
                      <Col xm={3} xl={6} lg={4} className="mb-3">
                        <label htmlFor="deviceImeiNumber">
                          Device IMEI Number
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="deviceImeiNumber"
                          value={deviceImeiNumber}
                          onChange={(e) => setDeviceImeiNumber(e.target.value)}
                          name="deviceImeiNumber"
                        />
                      </Col>

                      <Col xm={3} xl={6} lg={4} className="mb-3">
                        <label htmlFor="deviceImeiNumber">Channel</label>
                        <select
                          className="form-control"
                          value={channel}
                          onChange={(e) => setChannel(e.target.value)}
                          name="dropdown2"
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          {channelAndTxnSet?.map((item, i) => {
                            return (
                              <option key={i + 1} value={item?.name}>
                                {item?.name}
                              </option>
                            );
                          })}
                        </select>
                      </Col>

                      <Col xm={3} xl={6} lg={4} className="mb-3">
                        <label htmlFor="deviceModel">Device Model</label>
                        <select
                          className="form-control"
                          value={deviceModelOption}
                          onChange={(e) => handelDeviceModelSelection(e)}
                          name="dropdown2"
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          {device?.map((item, i) => {
                            return (
                              <option
                                key={i + 1}
                                data-deviceType={item?.deviceTtype}
                                value={item?.name}
                              >
                                {item?.name}
                              </option>
                            );
                          })}
                        </select>
                      </Col>

                      <Col xm={3} xl={6} lg={4} className="mb-3">
                        <label htmlFor="deviceSerialNumber">
                          Device Serial Number
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="deviceSerialNumber"
                          value={deviceSerialNumber}
                          onChange={(e) =>
                            setDeviceSerialNumber(e.target.value)
                          }
                          name="deviceSerialNumber"
                        />
                      </Col>

                      <Col xm={3} xl={6} lg={4} className="mb-3">
                        <label htmlFor="deviceType">Device Type</label>
                        <input
                          className="form-control"
                          type="text"
                          id="deviceType"
                          value={deviceType}
                          // onChange={(e) => setDeviceType(e.target.value)}
                          name="deviceType"
                          readOnly
                        />
                      </Col>

                      <Col xm={3} xl={6} lg={4} className="mb-3">
                        <label htmlFor="upiVpa">User VPA</label>
                        <input
                          className="form-control"
                          type="text"
                          id="upiVpa"
                          value={upiVpa}
                          onChange={(e) => setUpiVpa(e.target.value)}
                          name="upiVpa"
                        />
                      </Col>

                      <Col xm={3} xl={6} lg={4} className="mb-3">
                        <label htmlFor="terminalName">Terminal Name</label>
                        <input
                          className="form-control"
                          type="text"
                          id="terminalName"
                          value={terminalName}
                          onChange={(e) => setTerminalName(e.target.value)}
                          name="terminalName"
                        />
                      </Col>

                      <Col xm={3} xl={6} lg={4} className="mb-3">
                        <label htmlFor="upiOfflineType">UPI Offline Type</label>
                        <input
                          className="form-control"
                          type="text"
                          id="upiOfflineType"
                          value={upiOfflineType}
                          onChange={(e) => setUpiOfflineType(e.target.value)}
                          name="upiOfflineType"
                        />
                      </Col>

                      <Col xm={3} xl={6} lg={4} className="mb-3">
                        <label htmlFor="upiTerminalType">
                          UPI Terminal Type
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="upiTerminalType"
                          value={upiTerminalType}
                          onChange={(e) => setUpiTerminalType(e.target.value)}
                          name="upiTerminalType"
                        />
                      </Col>

                      <Col xm={3} lg={4} xl={6} className="mb-3">
                        <label htmlFor="welcomeKitSerialNo">
                          Welcome Kit Serial No
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="welcomeKitSerialNo"
                          value={welcomeKitSerialNo}
                          onChange={(e) =>
                            setWelcomeKitSerialNo(e.target.value)
                          }
                          name="welcomeKitSerialNo"
                        />
                      </Col>
                    </Row>
                  ) : null}
                </>
              )}
            </Row>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => HandleSubmit(token)}>
          Submit
        </Button>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BankOnboardModal;
