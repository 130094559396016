import React from "react";

const ShowField = ({fieldName, name, value }) => {

  console.log(value)
  return (
    <>
      <label htmlFor="email">{fieldName}</label>
      <input
        name={name}
        id={name}
        className="form-control"
        readOnly
        value={value}
        // onChange={handleTypeChange}
      />
    </>
  );
};

export default ShowField;
