import React, { useEffect, useState } from "react";
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import { useNavigate } from "react-router-dom";
import { SWIPELINC_API } from "../utils/constant";
import MoaDetails1 from "./MoaDetails1";
import swal from "sweetalert";
import BankOnboardModal from "./BankOnboardModal";

const Stage2Approved = ({ setShowLoader }) => {
  const [applications, setApplications] = useState([]);
  const [openView, setOpenView] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState("");
  const [mid, setMid] = useState("");
  const token = localStorage.getItem("token");
  useEffect(() => {
    setShowLoader(true);
    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/findbystage2/Approved`,
        {
          headers: {
            Authentication: "Bearer " + token,
          },
        },
        {
          params: {
            applicationStatus: "Approved",
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          console.log("Approved applications:", response.data.data);
          setApplications(response?.data?.data);
        } else if (response.data.statusCode === 304) {
          setApplications(response?.data?.data);
        } else {
          console.error("NO APPLICATIONS FOUND:", response.data.data);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching applications:", error.message);
        setShowLoader(false);
      });
  }, []);

  const handleViewDetails = (registrationId) => {
    setId(registrationId);
    setOpenView(true);
  };
  const handleClose = () => {
    setId("");
    setOpenView(false);
  };
  const columns = [
    { label: "Company Name", field: "companyName", width: "auto" },
    {
      label: "Authorized Person Name",
      field: "authorizedPersonName",
      width: "auto",
    },
    { label: "e-Mail ID", field: "email", width: "auto" },
    { label: "Mobile Number", field: "mobile", width: "auto" },
    { label: "Company PAN", field: "companyPanNumber", width: "auto" },
    { label: "GST Number", field: "gstNumber", width: "auto" },
    { label: "Action", field: "action", width: "150px" },
    { label: "OnBoardAction", field: "OnBoardBtn", width: "150px" },
  ];
  const handleBankOnBoard = (id, mid) => {
    console.log(id,mid)
    setShowModal(true)
    setId(id)
    setMid(mid)
    // swal({
    //   title: "Select an option",
    //   content: {
    //     element: "select",
    //     attributes: {
    //       innerHTML: `
    //         <option value="" disabled selected>Select an option</option>
    //         <option value="COSMOS">IDFC BANK</option>
    //         <option value="UCO">UCO BANK</option>
    //         <option value="SBI">YES BANK</option>
    //       `,
    //     },
    //   },
    //   buttons: {
    //     cancel: true,
    //     confirm: {
    //       text: "Select",
    //       closeModal: false,
    //     },
    //   },
    // }).then((value) => {
    //   if (value) {
    //     // Continue with the selected value
    //     const selectedOption = document.querySelector(
    //       ".swal-content__select"
    //     ).value;
    //     if (selectedOption) {
    //       swal.close();
    //       console.log("Selected:", selectedOption);
    //       // Perform any action with the selected value here
    //     } else {
    //       swal.close();
    //       handleBankOnBoard(id, mid); // Reopen the alert if validation fails
    //     }
    //   }
    // });
  };

  const mappedData =
    applications && applications.length > 0
      ? applications.map((application) => {
          const mappedItem = { ...application };
          mappedItem.action = (
            <button
              className="btn btn-primary btn-sm"
              type="button"
              onClick={() => handleViewDetails(application.registrationId)}
            >
              View Details
            </button>
          );
          mappedItem.OnBoardBtn = (
            <>
            <button
            className="btn btn-sm btn-primary"
              onClick={() =>
                handleBankOnBoard(application.registrationId, application.mid)
              }
            >
              Bank On-Board
            </button>
            </>
          );
          return mappedItem;
        })
        : [];
        
        return (
          <>
    <BankOnboardModal id={id} setShowLoader={setShowLoader} showModal={showModal} setShowModal={setShowModal} mid={mid}/>
      <div className="report-grid-view">
        {!openView ? (
          <MDBDataTable
            className="dataTable"
            striped
            small
            bordered
            hover
            responsive
            data={{
              columns: columns,
              rows: mappedData,
            }}
            searching={true}
            entries={50}
            exportToCSV
            theadColor="#333"
            paginationLabel={["Previous", "Next"]}
            sortable={true}
            footer="none"
          />
        ) : openView ? (
          <MoaDetails1
            id={id}
            handleClose={handleClose}
            setShowLoader={setShowLoader}
          />
        ) : null}
      </div>
    </>
  );
};

export default Stage2Approved;
