import React, { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import InactiveServices from "../components/InactiveServices";
import SIDMaster from "./SIDMaster";
import SubTabForSIDMaster from "./SubTabForSIDMaster";
import MyLoader from "../common/MyLoader";
import SubTabForPayoutMaster from "./SubTabForPayoutMaster";
import { useSelector } from "react-redux";
import useCheckPrivilages from "../utils/checkPrivilages";

const Masters = () => {
  const [reportType, setReportType] = useState("SIDMaster");
  const [activeButton, setActiveButton] = useState(1);
  const [showLoader, setShowLoader] = useState(false);

  const { prefix } = useSelector((state) => state.privilages);
  console.log(prefix);

  const hasPrivilages179774 = useCheckPrivilages(179774);
  const hasPrivilages179773 = useCheckPrivilages(179773);

  const HandleClick = (reportType, btn) => {
    setReportType(reportType);
    setActiveButton(btn);
  };

  return (
    <>
      {showLoader ? <MyLoader /> : ""}
      <section className="dashboard-header-sec">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4 ds-header-card">
                  <Card.Body>
                    {/* <Card.Title className="h3">Reports</Card.Title>  */}
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn">
                        {hasPrivilages179773 ? (
                          <li
                            className={activeButton === 1 ? "active" : ""}
                            onClick={() => HandleClick("SIDMaster", 1)}
                          >
                            SID Master
                          </li>
                        ) : null}

                        {hasPrivilages179774 ? (
                          <li
                            className={activeButton === 2 ? "active" : ""}
                            onClick={() => HandleClick("PayoutMaster", 2)}
                          >
                            Payout Master
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
                {/* ./card */}
              </Col>
            </Row>
          </div>
        </Container>
        <Col xs={12} lg={12} md={12}>
          {reportType === "SIDMaster" ? (
            <SubTabForSIDMaster setShowLoader={setShowLoader} />
          ) : reportType === "PayoutMaster" ? (
            <SubTabForPayoutMaster setShowLoader={setShowLoader} />
          ) : (
            //  <InactiveServices/>
            <InactiveServices location={"prilages"} />
          )}
        </Col>
      </section>
    </>
  );
};

export default Masters;