import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import { SWIPELINC_API } from "../../utils/constant";
import MyLoader from "../../common/MyLoader";
import useCheckPrivilages from "../../utils/checkPrivilages";
import InactiveServices from "../../components/InactiveServices";

function ManageAmount({ setShowLoader, data }) {
  console.log(data);
  const [merchantData, setMerchantData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [formData, setFormData] = useState({
    mid: "",
  });
  const hasPrivilage = useCheckPrivilages(2691);
  const [securityAmountPer, setSecurityAmountPer] = useState("");
  const [securityAmount, setSecurityAmount] = useState("");
  const [holdAmountError, setHoldAmountError] = useState("");
  const [securityAmountError, setSecurityAmountError] = useState("");
  const [changedAmount, setChangedAmount] = useState("");

  const Fetch_Hold_Amount = (mid) => {
    setShowLoader(true);
    axios
      .get(
        `${SWIPELINC_API}/MerchantAdminPanel/merchant/depositpercentage/getDepositPercentageByMid/${mid}`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          console.log(response.data);
          setSecurityAmountPer(
            response.data.data[0].depositPercentage === "null"
              ? "0"
              : response.data.data[0].depositPercentage
          );
        } else {
          console.error("Error fetching data:", response.data.message);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const Fetch_Deposit_Amount = (mid) => {
    setShowLoader(true);
    axios
      .get(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/securitydeposit/getsecuirtydata/${mid}`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setSecurityAmount(
            response.data.data === "null" ? "0" : response.data.data
          );
        } else {
          console.error("Error fetching data:", response.data.message);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    setMerchantData(data);
    console.log(merchantData);
  }, [data]);

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    if (selectedCompany === "") {
      setFormData({
        mid: "",
      });
      setSecurityAmountPer("");
      setSecurityAmount("");
      setSelectedCompany("");
      return;
    }

    setSelectedCompany(selectedCompany);

    const selectedMerchant = merchantData.find(
      (merchant) =>
        `${merchant.mid} :- ${merchant.merchantName}` === selectedCompany
    );
    console.log(selectedMerchant);

    if (selectedMerchant) {
      setFormData({
        mid: selectedMerchant.mid || "",
      });
      Fetch_Hold_Amount(selectedMerchant.mid);
      Fetch_Deposit_Amount(selectedMerchant.mid);
      setChangedAmount("");
    }
  };

  const handleHoldAmountChange = (e) => {
    const sanitizedValue = e.target.value.replace(/[^0-9.]/g, "");
    setSecurityAmountPer(sanitizedValue);
  };
  const handleSecurityAmountChange = (e) => {
    // Check if the input is a leading zero (e.g., "01" or "001")
    const sanitizedValue = e.target.value.replace(/^-|[^0-9]/g, "");
    if (/^0[0-9]*$/.test(sanitizedValue)) {
      setSecurityAmount(sanitizedValue.slice(1));
    } else {
      setSecurityAmount(sanitizedValue);
    }
  };

  useEffect(() => {
    console.log(changedAmount);
  }, [changedAmount]);

  const handleSubmit = (changedAmount) => {
    if (holdAmountError || securityAmountError) {
      return;
    }

    const ApiPayload = (changedAmount) => {
      console.log(changedAmount);
      if (changedAmount === "depositpercentage") {
        return {
          mid: formData.mid,
          depositPercentage: securityAmountPer,
        };
      } else if (changedAmount === "securitydeposit") {
        return {
          mid: formData.mid,
          amount: securityAmount,
        };
      } else {
        console.log("not chnage");
      }
    };

    const newData = ApiPayload(changedAmount);
    console.log(newData);

    if (!formData.mid.length) {
      swal({
        title: "Warning",
        text: "Please Fill the Mandatory fields.",
        icon: "warning",
      });
      return;
    }
    setShowLoader(true);
    axios
      .post(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/${changedAmount}/save`,
        newData,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          swal({
            title: "Success",
            text: "Data Saved Successfully.",
            icon: "success",
          });
          setChangedAmount("");
          setFormData({
            mid: "",
          });
          setSecurityAmountPer("");
          setSecurityAmount("");
          setSelectedCompany("");
        } else if (response.data.statusCode === 202) {
          swal({
            title: "Failed",
            text: "Amount must be lesser than Wallet Balance.",
            icon: "warning",
          });
        } else {
          console.log(response.data.message);
          swal({
            title: "Failed",
            text: "Something went wrong.",
            icon: "error",
          });
        }
        setShowLoader(false);
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };
  return (
    <>
      {/* {hasPrivilage ? ( */}
      <div className="containerflag">
        <div className="row mt-4">
          <div className="col-sm-12 col-md-6 offset-md-3">
            <select
              id="companyDropdown"
              className="form-control"
              onChange={handleCompanyChange}
              value={selectedCompany}
            >
              <option value="">Select a Company</option>
              {merchantData.map((merchant) => (
                <option
                  key={merchant.mid}
                  value={`${merchant.mid} :- ${merchant.merchantName}`}
                >
                  {merchant.mid} :- {merchant.merchantName}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-sm-12 col-md-6 offset-md-3">
            <div className="form-group">
              <label htmlFor="email">Merchant ID</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Merchant ID"
                value={formData.mid}
                readOnly
              />
            </div>

            {/* <div className="form-group">
            <label htmlFor="fullName">Full Name</label>
            <input
              type="text"
              className="form-control"
              id="fullName"
              placeholder="Full Name"
              value={formData.fullName}
              readOnly
            />
          </div> */}
            <div className="form-group">
              <label htmlFor="holdAmount">Deposit (in Percentage)</label>
              <div className="d-flex gap-4">
                <input
                  type="text"
                  className="form-control"
                  id="depositpercentage"
                  placeholder="Amount Percentage"
                  value={securityAmountPer}
                  onChange={handleHoldAmountChange}
                  readOnly={
                    changedAmount === "depositpercentage" ? false : true
                  }
                />
                {changedAmount === "depositpercentage" ? (
                  <button
                    className="toolbutton"
                    onClick={() => handleSubmit(changedAmount)}
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    className="bg-secondary"
                    onClick={() => setChangedAmount("depositpercentage")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                    </svg>
                  </button>
                )}
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="securityAmount"> Deposit (in Rupees)</label>
              <div className="d-flex gap-4">
                <input
                  type="text"
                  className="form-control"
                  id="securityAmount"
                  placeholder="Amount"
                  value={securityAmount}
                  onChange={handleSecurityAmountChange}
                  readOnly={changedAmount === "securitydeposit" ? false : true}
                />
                {changedAmount === "securitydeposit" ? (
                  <button
                    className="toolbutton"
                    onClick={() => handleSubmit(changedAmount)}
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    className="bg-secondary"
                    onClick={() => setChangedAmount("securitydeposit")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ) : (<InactiveServices location={"privilages"}/>)} */}
    </>
  );
}

export default ManageAmount;
