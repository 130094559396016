import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import ShowField from "./ShowField";
import DropDowns from "./DropDowns";
import swal from "sweetalert";
import { getPhase2VehicleMasterSubmittedByID } from "../utils/ApiServices";
import { Title } from "@mui/icons-material";

const VehicleMasterFormDetails = ({Id,setShowLoader,setShowDetails}) => {
    console.log(Id)
  const [selectedType, setSelectedType] = useState({
    vehicleId: Number,
  });

  const [paymentSubType, setPaymentSubType] = useState([]);
  const [MDRData, setMDRData] = useState([]);
//   const [Id, setId] = useState("");
  const [MerchantMDRData, setMerchantMDRData] = useState([]);
  const [SchemeMDRData, setMDRDataForScheme] = useState([]);
  const [AggregatorMDRData, setMDRDataForAggreGator] = useState([]);
  const [SwitchMDRData, setMDRDataForSwitch] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [formData, setFormData] = useState({});
  const [vehicleData, setVehicleData] = useState([]);
//   const [paymentSubType, setPaymentSubType] = useState([]);
  // const [vehicleData, setVehicleData] = useState([])

  useEffect(() => {
    GetVehicalMasterData(token);
  }, []);

  const GetVehicalMasterData = async (token) => {
    setShowLoader(true);
    try {
      const response = await  getPhase2VehicleMasterSubmittedByID(Id,token);
   console.log(response)
      if (response.statusCode === 200) {
        
        setVehicleData(response.data[0]);
        setPaymentSubType(response.data[0].paymentSubTypes);
        console.log(vehicleData)
      } else {
        setVehicleData([]);
        setPaymentSubType([]);

      }
    } catch (error) {
      console.log(error);
    }
    setShowLoader(false);
  };

  const handleClose=()=>{
    setShowDetails(false)
  }

  const handleTypeChange = (event) => {
    const selectedVehicleValue = event.target.value;
    const name = event.target.name;

    // console.log(selectedVehicleValue, name);

    setSelectedType((prevSelectedType) => ({
      ...prevSelectedType,
      vehicleId: selectedVehicleValue,
    }));

    const selectedVehicle = vehicleData.find(
      (vehicle) => vehicle.Id === parseInt(selectedVehicleValue)
    );

    console.log(selectedVehicle);

    if (selectedVehicle) {
      setFormData({
        aggregatorName: selectedVehicle.aggregatorName,
        bankName: selectedVehicle.bankName,
        schemeName: selectedVehicle.schemeName,
        switchName: selectedVehicle.switchName, // Fix: Use 'switchName' instead of 'merchantName'
        masterMerchantName: selectedVehicle.masterMerchantName,
        remark: selectedVehicle.remark, // Assuming 'remark' is a property in the vehicle object
      });

      setPaymentSubType(selectedVehicle.paymentSubTypes);

      console.log(formData);
    } else {
    }

    setMDRData(
      selectedVehicle?.paymentSubTypes?.map((service) => {
        return {
          vehicleMasterSubPaymentTypeServiceId:
            service?.vehicleMasterSubPaymentTypeServiceId,
          serviceId: service?.paymentSubTypeId,
          fixAmount: "",
          percentage: "",
          // MerchantFix: "",
          // MerchantPercentage: "",
        };
      })
    );
    setMerchantMDRData(
      selectedVehicle?.paymentSubTypes?.map((service) => {
        return {
          vehicleMasterSubPaymentTypeServiceId:
            service?.vehicleMasterSubPaymentTypeServiceId,
          serviceId: service?.paymentSubTypeId,
          fixAmount: "",
          percentage: "",
          // MerchantFix: "",
          // MerchantPercentage: "",
        };
      })
    );
    setMDRDataForAggreGator(
      selectedVehicle?.paymentSubTypes?.map((service) => {
        return {
          vehicleMasterSubPaymentTypeServiceId:
            service?.vehicleMasterSubPaymentTypeServiceId,
          serviceId: service?.paymentSubTypeId,
          fixAmount: "",
          percentage: "",
          // MerchantFix: "",
          // MerchantPercentage: "",
        };
      })
    );
    setMDRDataForScheme(
      selectedVehicle?.paymentSubTypes?.map((service) => {
        return {
          vehicleMasterSubPaymentTypeServiceId:
            service?.vehicleMasterSubPaymentTypeServiceId,
          serviceId: service?.paymentSubTypeId,
          fixAmount: "",
          percentage: "",
          // MerchantFix: "",
          // MerchantPercentage: "",
        };
      })
    );
    setMDRDataForSwitch(
      selectedVehicle?.paymentSubTypes?.map((service) => {
        return {
          vehicleMasterSubPaymentTypeServiceId:
            service?.vehicleMasterSubPaymentTypeServiceId,
          serviceId: service?.paymentSubTypeId,
          fixAmount: "",
          percentage: "",
          // MerchantFix: "",
          // MerchantPercentage: "",
        };
      })
    );
  };

  const handleMDRDataForBank = (
    paymentSubservice,
    dataType,
    value,
    paymentSubTypeId
  ) => {
    // Find the item in MDRData array
    console.log(dataType, value);
    const foundItem = MDRData.find(
      (item) => item.serviceId === paymentSubTypeId
    );

    // console.log(foundItem);
    // If found, update the value for the corresponding paymentSubTypeId
    if (foundItem) {
      const updatedMDRData = MDRData?.map((item) =>
        item.serviceId === paymentSubTypeId
          ? { ...item, [dataType]: value }
          : item
      );
      //   console.log(updatedMDRData);
      setMDRData(updatedMDRData);
    }
  };

  const handleMDRDataForMerchant = (
    paymentSubservice,
    dataType,
    value,
    paymentSubTypeId
  ) => {
    // Find the item in MDRData array
    console.log("Master----------------in", dataType, value);
    const foundItem = MerchantMDRData.find(
      (item) => item.serviceId === paymentSubTypeId
    );

    console.log(foundItem);
    // If found, update the value for the corresponding paymentSubTypeId
    if (foundItem) {
      const updatedMDRData = MerchantMDRData?.map((item) =>
        item.serviceId === paymentSubTypeId
          ? { ...item, [dataType]: value }
          : item
      );
      //   console.log(updatedMDRData);
      setMerchantMDRData(updatedMDRData);
    }
  };

  const handleMDRDataSwitch = (
    paymentSubservice,
    dataType,
    value,
    paymentSubTypeId
  ) => {
    // Find the item in MDRData array
    // console.log(dataType);
    const foundItem = SwitchMDRData.find(
      (item) => item.serviceId === paymentSubTypeId
    );

    // console.log(foundItem);
    // If found, update the value for the corresponding paymentSubTypeId
    if (foundItem) {
      const updatedMDRData = SwitchMDRData?.map((item) =>
        item.serviceId === paymentSubTypeId
          ? { ...item, [dataType]: value }
          : item
      );
      //   console.log(updatedMDRData);
      setMDRDataForSwitch(updatedMDRData);
    }
  };

  const handleMDRDataForAggregator = (
    paymentSubservice,
    dataType,
    value,
    paymentSubTypeId
  ) => {
    // Find the item in MDRData array
    console.log(dataType, value);
    const foundItem = AggregatorMDRData.find(
      (item) => item.serviceId === paymentSubTypeId
    );

    // console.log(foundItem);
    // If found, update the value for the corresponding paymentSubTypeId
    if (foundItem) {
      const updatedMDRData = AggregatorMDRData?.map((item) =>
        item.serviceId === paymentSubTypeId
          ? { ...item, [dataType]: value }
          : item
      );
      console.log(updatedMDRData);
      setMDRDataForAggreGator(updatedMDRData);
    }
  };

 

  console.log(vehicleData.vehicleName)
 

  return (
    <>

        <Card.Header className="fs-2 mb-3 d-flex justify-content-between">
            Vehicle Details 
           <Button onClick={handleClose}>Close</Button>
              
        </Card.Header>
      <Card className="shadow border-0 mb-4">
        <Card.Body>
          <div className="merchant-report-view  ">
            <div className="report-table-view">
              <div className="row mt-4 ">
                
                <div className="col-md-6 offset mt-2">
                  <div className="col-sm-10 col-md-8  offset-md-3">
                    <ShowField
                      fieldName={"Vehicle"}
                      name="vehicleName"
                      value={vehicleData.vehicleName}
                    />
                  </div>
                </div>
                <div className="col-md-6 offset mt-2">
                  <div className="col-sm-10 col-md-8  offset-md-3">
                    <ShowField
                      fieldName={"Aggregator"}
                      name="aggregatorId"
                      value={vehicleData.aggregatorName}
                    />
                  </div>
                </div>

                <div className="col-md-6 offset mt-2">
                  <div className="col-sm-10 col-md-8  offset-md-3">
                    <ShowField
                      fieldName={"Scheme"}
                      name="schemeId"
                      value={vehicleData.schemeName}
                    />
                  </div>
                </div>

                <div className="col-md-6 offset mt-2">
                  <div className="col-sm-10 col-md-8  offset-md-3">
                    <ShowField
                      fieldName={"Master Merchant"}
                      name="masterMerchantId"
                      value={vehicleData.masterMerchantName}
                    />
                  </div>
                </div>

                <div className="col-md-6 offset mt-2">
                  <div className="col-sm-10 col-md-8  offset-md-3">
                    <ShowField
                      fieldName={"Bank"}
                      name="bankId"
                      value={vehicleData.bankName}
                    />
                  </div>
                </div>
                <div className="col-md-6 offset mt-2">
                  <div className="col-sm-10 col-md-8  offset-md-3">
                    <ShowField
                      fieldName={"Remark"}
                      name="remark"
                      value={vehicleData.remark}
                    />
                  </div>
                </div>

                <div className="col-md-6 offset">
                  <div className="col-sm-10 col-md-8 offset-md-3">
                    <ShowField
                      fieldName={"Switch"}
                      name="switchId"
                      value={vehicleData.switchName}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {vehicleData.schemeName !== "NA" ? (
            <div className=" merchant-report-view mt-4  px-5">
              <Row className="d-flex flex-column align-items-center px-3">
                {paymentSubType?.map((service) => (
                  <Col
                    className="w-100  mt-3"
                    xs={8}
                    lg={4}
                    md={3}
                    key={service.paymentSubTypeId}
                  >
                    <div className="form-group d-flex align-items-center justify-content-evenly  ">
                      <label className="w-25 form-label">
                        {`${vehicleData.schemeName} (${service.type})`}
                        <sup className="text-danger">*</sup>
                      </label>
                      <div className="w-25 px-2">
                        <lable>Intercharge Fee Fix</lable>
                        <input
                          type="number"
                          placeholder="Fixed Amount"
                          className="form-control"
                          value={service.interchangeFeeFix}
                        />
                      </div>
                      <div className="w-25 px-2">
                        <lable>Intercharge Fee Percentage</lable>
                        <input
                          type="number"
                          placeholder="Percentage"
                          className="form-control  "
                          value={service.interchangeFeePercentage}
                        //   onChange={(e) =>
                        //     handleMDRDataForScheme(
                        //       service.paymentSubservice,
                        //       "percentage",
                        //       e.target.value,
                        //       service.paymentSubTypeId
                        //     )
                        //   }
                          required
                        />
                      </div>
                   
                      <span id="regAddErr" className="text-danger d-none">
                        Please Enter Registered Address*
                      </span>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          ) : null}
          {vehicleData.bankName !== "NA" ? (
            <div className=" merchant-report-view mt-4  px-5">
              <Row className="d-flex flex-column align-items-center px-3">
                {paymentSubType?.map((service) => (
                  <Col
                    className="w-100   mt-3"
                    xs={8}
                    lg={4}
                    md={3}
                    key={service.paymentSubTypeId}
                  >
                    <div className="form-group d-flex align-items-center justify-content-evenly  ">
                      <label className="w-25 form-label">
                        {`${vehicleData.bankName} (${service.type})`}
                        <sup className="text-danger">*</sup>
                      </label>
                      <div className="w-25 px-2">
                        <lable>Nodal Fee Fix</lable>
                        <input
                          type="number"
                          placeholder="Fixed Amount"
                          className="form-control"
                          value={
                            service.nodelFeeFix
                          }
                        //   onChange={(e) =>
                        //     handleMDRDataForBank(
                        //       service.paymentSubservice,
                        //       "fixAmount",
                        //       e.target.value,
                        //       service.paymentSubTypeId
                        //     )
                        //   }
                          required
                        />
                      </div>
                      <div className="w-25 px-2">
                        <lable>Nodal Fee Percentage</lable>
                        <input
                          type="number"
                          placeholder="Percentage"
                          className="form-control  "
                          value={
                            service.nodelFeePercentage
                          }
                        //   onChange={(e) =>
                        //     handleMDRDataForBank(
                        //       service.paymentSubservice,
                        //       "percentage",
                        //       e.target.value,
                        //       service.paymentSubTypeId
                        //     )
                        //   }
                          required
                        />
                      </div>
                   
                      <span id="regAddErr" className="text-danger d-none">
                        Please Enter Registered Address*
                      </span>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          ) : null}
          {vehicleData.switchName !== "NA" ? (
            <div className=" merchant-report-view mt-4  px-5">
              <Row className="d-flex flex-column align-items-center px-3">
                {paymentSubType?.map((service) => (
                  <Col
                    className="w-100   mt-3"
                    xs={8}
                    lg={4}
                    md={3}
                    key={service.paymentSubTypeId}
                  >
                    <div className="form-group d-flex align-items-center justify-content-evenly  ">
                      <label className="w-25 form-label">
                        {`${vehicleData.switchName} (${service.type})`}
                        <sup className="text-danger">*</sup>
                      </label>
                      <div className="w-25 px-2">
                        <lable>Processing Fee Fix</lable>
                        <input
                          type="number"
                          placeholder="Fixed Amount"
                          className="form-control"
                          value={
                            service.processingFeeFix
                          }
                        //   onChange={(e) =>
                        //     handleMDRDataSwitch(
                        //       service.paymentSubservice,
                        //       "fixAmount",
                        //       e.target.value,
                        //       service.paymentSubTypeId
                        //     )
                        //   }
                          required
                        />
                      </div>
                      <div className="w-25 px-2">
                        <lable>Processing Fee Percentage</lable>
                        <input
                          type="number"
                          placeholder="Percentage"
                          className="form-control  "
                          value={
                            service.processingFeePercentage
                          }
                        //   onChange={(e) =>
                        //     handleMDRDataSwitch(
                        //       service.paymentSubservice,
                        //       "percentage",
                        //       e.target.value,
                        //       service.paymentSubTypeId
                        //     )
                        //   }
                          required
                        />
                      </div>
                     
                      <span id="regAddErr" className="text-danger d-none">
                        Please Enter Registered Address*
                      </span>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          ) : null}

          {vehicleData.aggregatorName !== "NA" ? (
            <div className=" merchant-report-view mt-4  px-5">
              <Row className="d-flex flex-column align-items-center px-3">
                {paymentSubType?.map((service) => (
                  <Col
                    className="w-100   mt-3"
                    xs={8}
                    lg={4}
                    md={3}
                    key={service.paymentSubTypeId}
                  >
                    <div className="form-group d-flex align-items-center justify-content-evenly  ">
                      <label className="w-25 form-label">
                        {`${vehicleData.aggregatorName} (${service.type})`}
                        <sup className="text-danger">*</sup>
                      </label>
                      <div className="w-25 px-2">
                        <lable>Platform Fee Fix</lable>
                        <input
                          type="number"
                          placeholder="Fixed Amount"
                          className="form-control"
                        value={service.platformFeeFix}
                          required
                        />
                      </div>
                      <div className="w-25 px-2">
                        <lable>Platform Fee Percentage</lable>
                        <input
                          type="number"
                          placeholder="Percentage"
                          className="form-control  "
                          value={service.platformFeePercentage}
                        //   onChange={(e) =>
                        //     handleMDRDataForAggregator(
                        //       service.paymentSubservice,
                        //       "percentage",
                        //       e.target.value,
                        //       service.paymentSubTypeId
                        //     )
                        //   }
                          required
                        />
                      </div>
                       
                      <span id="regAddErr" className="text-danger d-none">
                        Please Enter Registered Address*
                      </span>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          ) : null}
          {vehicleData.masterMerchantName !== "NA" ? (
            <div className=" merchant-report-view mt-4  px-5">
              <Row className="d-flex flex-column align-items-center px-3">
                {paymentSubType?.map((service) => (
                  <Col
                    className="w-100  mt-3"
                    xs={8}
                    lg={4}
                    md={3}
                    key={service.paymentSubTypeId}
                  >
                    <div className="form-group d-flex align-items-center justify-content-evenly  ">
                      <label className="w-25 form-label">
                        {`${vehicleData.masterMerchantName} (${service.type})`}
                        <sup className="text-danger">*</sup>
                      </label>
                      <div className="w-25 px-2">
                        <lable>Merchant Fee Fix</lable>
                        <input
                          type="number"
                          placeholder="Fixed Amount"
                          className="form-control"
                          value={
                            service.masterMerchantFess
                          }
                        //   onChange={(e) =>
                        //     handleMDRDataForMerchant(
                        //       service.paymentSubservice,
                        //       "fixAmount",
                        //       e.target.value,
                        //       service.paymentSubTypeId
                        //     )
                        //   }
                          required
                        />
                      </div>
                      <div className="w-25 px-2">
                        <lable>Merchant Fee Percentage</lable>
                        <input
                          type="number"
                          placeholder="Percentage"
                          className="form-control  "
                          value={
                            service.masterMerchantPercentage
                          }
                        //   onChange={(e) =>
                        //     handleMDRDataForMerchant(
                        //       service.paymentSubservice,
                        //       "percentage",
                        //       e.target.value,
                        //       service.paymentSubTypeId
                        //     )
                        //   }
                          required
                        />
                      </div>
                     
                      <span id="regAddErr" className="text-danger d-none">
                        Please Enter Registered Address*
                      </span>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          ) : null}

          {/* <div className="d-flex justify-content-center mt-4">
            <button className="btn btn-primary" onClick={handleSubmit}>
              Submit
            </button>
          </div> */}
        </Card.Body>
      </Card>
    </>
  );
};

export default VehicleMasterFormDetails;
