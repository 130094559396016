import React from "react";

const DropDowns = ({
  fieldName,
  Name,
  HandleChange,
  SelectedType,
  AllTypes,
}) => {
 
  return (
    <>
      <div className="">
        <div className="">
          <label htmlFor="email">
            {fieldName}
            <sup className="text-danger">*</sup>
          </label>
          <select
            id={Name}
            name={Name}
             
            className="form-control"
            onChange={HandleChange}
            value={SelectedType}
          >
            <option value="">Select</option>
            {AllTypes.map((item, i) => (
              <option key={i} value={ item.Id }>
                {item.type}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

export default DropDowns;
