
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import MyLoader from "../../../common/MyLoader";
import InactiveServices from "../../InactiveServices";
import FraudManagement from "./FraudManagement";
import SubmittedForm from "./SubmittedForm";



const FraudSubTab = () => {
  const [activePhase, setActivePhase] = useState("Phase1");
  
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setActivePhase("Phase1");
  }, []);

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  return (
    <>
      
        
          <section className="dashboard-header-sec subtab-card">
            <Container>
              <div className="ds-header-sec">
                <Row>
                  <Col xs={12} lg={12} md={12}>
                    <Card className="shadow border-0 mb-3  ds-inner-card">
                      <Card.Body>
                        <div className="merchant-tab-btn">
                          <ul className="report-link nav-report-btn submenu-tab-admin">
                            <li
                              className={
                                activePhase === "Phase1" ? "active" : ""
                              }
                              onClick={() => handlePhaseClick("Phase1")}
                            >
                              Fraud Management{activePhase === "Phase1"}
                            </li>
                            <li
                              className={
                                activePhase === "Phase2" ? "active" : ""
                              }
                              onClick={() => handlePhaseClick("Phase2")}
                            >
                              Submitted Form{activePhase === "Phase2"}
                            </li>
                          </ul>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>

          <div className="admin-tab-content mat-bt-table">
            {activePhase === "Phase1" ? (
              <FraudManagement setShowLoader={setShowLoader} />
            ) : 

            activePhase === "Phase2" ? (
              <SubmittedForm setShowLoader={setShowLoader} />
            ) : (
               
              <InactiveServices />
            )}
          </div>
        
       
        
    
    </>
  );
};

export default FraudSubTab;
