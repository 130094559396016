import React, { useEffect, useState } from "react";
import AllVendor from "../ALL VENDOR REGISTRATION REQUEST/AllVendor";
import SubmittedVendor from "../ALL VENDOR REGISTRATION REQUEST/SubmittedVendor";
import ApprovedVendor from "../ALL VENDOR REGISTRATION REQUEST/ApprovedVendor";
import RejectedVendor from "../ALL VENDOR REGISTRATION REQUEST/RejectedVendor";
import InactiveServices from "../../InactiveServices";
import { Card, Col, Container, Row } from "react-bootstrap";
import useCheckPrivilages from "../../../utils/checkPrivilages";
// import MyLoader from "../../../common/MyLoader";

const VendorSection = ({setShowLoader}) => {
  const [activePhase, setActivePhase] = useState("Phase2");
  // const [showLoader, setShowLoader] = useState(false);
const hasPrivilage=useCheckPrivilages(2698)

  useEffect(() => {
    setActivePhase("Phase2");
    //   setActiveSubtab("MerchantManagement");
  }, []);

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  return (
    <>
     {/* {showLoader ? <MyLoader /> : ""} */}
      {/* {hasPrivilage ? (  */}
        <div className="subtab-sec-admin">
        <Container>
          <Row>
            <Col xs={12} lg={12} md={12}>
              <Card className="shadow border-0 mb-4">
                <div className="merchant-tab-btn">
                  <ul className="report-link nav-report-btn">
                    {/* <li
                      className={activePhase === "Phase1" ? "active" : ""}
                      onClick={() => handlePhaseClick("Phase1")}
                    >
                      All
                    </li> */}
                    <li
                      className={activePhase === "Phase2" ? "active" : ""}
                      onClick={() => handlePhaseClick("Phase2")}
                    >
                      Submitted
                    </li>
                    {/* <li
                      className={activePhase === "Phase3" ? "active" : ""}
                      onClick={() => handlePhaseClick("Phase3")}
                    >
                      Approved
                    </li>
                    <li
                      className={activePhase === "Phase4" ? "active" : ""}
                      onClick={() => handlePhaseClick("Phase4")}
                    >
                      Rejectced
                    </li> */}
                  </ul>
                </div>
                <Card.Body>
                  <div className="admin-tab-content">
                    {
                    // activePhase === "Phase1" ? (
                    //   <AllVendor />
                    // ) :
                     activePhase === "Phase2" ? (
                      <SubmittedVendor
                      setShowLoader={setShowLoader}
                       />
                    ) 
                    // : activePhase === "Phase3" ? (
                    //   <ApprovedVendor />
                    // ) : activePhase === "Phase4" ? (
                    //   <RejectedVendor />
                    // ) 
                    : (
                      <InactiveServices location={"prilages"}/>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* ) : (<InactiveServices location={"privilages"}/>)} */}
      
    </>
  );
};

export default VendorSection;
