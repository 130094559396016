import { combineReducers, configureStore } from "@reduxjs/toolkit";
import reportSlice from "./slices/reportSlice";
import privilagesSlice from "./slices/privilagesSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import FilterSlice from "./slices/FilterSlice";

// Define slices
const reportReducer = reportSlice ;
const privilagesReducer = privilagesSlice ;
const filterReducer = FilterSlice ;

// Combine reducers
const rootReducer = combineReducers({
  report: reportReducer,
  privilages: privilagesReducer,
  filterData: filterReducer,
});
// Create persistConfig
const persistConfig = {
  key: "root", // key is the name of the key in storage
  storage, // storage is the storage engine to use
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store
const store = configureStore({
  reducer: persistedReducer,
});

// Create the persistor
const persistor = persistStore(store);

export { store, persistor };
