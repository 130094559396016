import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { GET_DOCUMENT_FILE, GET_DOCUMENT_UPLOADED_BY_ID } from '../utils/constant';
import { Card } from 'react-bootstrap';

const DocumentList = ({id,setShowLoader}) => {

  const [documentsNames, setDocumentsNames] = useState([]);


    const token = localStorage.getItem("token");

    const handleViewsingleDocuments = async (name) => {
      const headers = {
        Authentication: `Bearer ${token}`,
      };
      setShowLoader(true);
      
      await axios
        .get(GET_DOCUMENT_FILE + name, {
          responseType: "arraybuffer",
          headers,
        })
        .then((response) => {
          setShowLoader(false);
          
          // Determine the file extension and MIME type
          const fileExtension = name.split('.').pop().toLowerCase();
          let mimeType;
          
          switch (fileExtension) {
            case 'pdf':
              mimeType = 'application/pdf';
              break;
            case 'jpg':
            case 'jpeg':
              mimeType = 'image/jpeg';
              break;
            case 'png':
              mimeType = 'image/png';
              break;
            case 'gif':
              mimeType = 'image/gif';
              break;
            // Add more cases as needed for different file types
            default:
              console.error('Unsupported file type:', fileExtension);
              return;
          }
          
          // Create a blob with the correct MIME type
          const blob = new Blob([response.data], { type: mimeType });
          const fileUrl = URL.createObjectURL(blob);
          
          // Open the file in a new tab
          window.open(fileUrl, "_blank");
        })
        .catch((error) => {
          setShowLoader(false);
          console.error("Error fetching file:", error.message);
        });
    };
    

      
  const fileNameCheck = (name, response) => {
    if (name.split(".")[1] === "jpg") {
      const pdfBlob = new Blob([response.data], { type: "image/jpg" });
      return pdfBlob;
    } else if (name.split(".")[1] === "pdf") {
      const pdfBlob = new Blob([response.data], {
        type: "application/pdf",
      });
      return pdfBlob;
    }
  };

  const handleViewDocuments = async () => {
    try {
      const response = await axios.get(
        GET_DOCUMENT_UPLOADED_BY_ID + id,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      );

      if (response?.data?.statusCode === 200) {
        setDocumentsNames(response?.data?.data);
      } else {
        setDocumentsNames([]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleViewDocuments()
  }, [ ])
  


  return (
    <Card className="p-4 mt-1 border border-0 ">
    <Card.Header>Documents list</Card.Header>
    <div className="pdf-list-container report-grid-view">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Document Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {documentsNames?.map((fileObject, index) => (
            <tr key={index}>
              {/* <td>{fileObject["Orignal name"]}</td> */}
              <td>{fileObject["convertedName"]}</td>
              <td>
                <button
                  className="btn btn-secondary"
                  onClick={() =>
                    handleViewsingleDocuments(
                      fileObject["convertedName"]
                    )
                  }
                >
                  View 
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    {/* <div className="button-toolbar groups-btn text-end">
      <button
        className="btn btn-success"
        onClick={() => handleViewDocuments()}
      >
        View Documents
      </button>
    </div> */}
  </Card>
  )
}

export default DocumentList