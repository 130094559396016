import React, { useEffect, useState } from "react";
import DailyTransactionBox from "./DailyTransactionBox";
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import {
  USE_LIVE_FOR_CSV_DOWNLOAD,
  USE_LIVE_PAYIN_MORE_FILTER_API,
  getAllDailyTransactionData,
  getAllDailyTransactionDataByMid,
  getAllDailyTransactionDataTable,
  getAllDailyTransactionDataTableByMid,
} from "../utils/ApiServices";
import DownloadCsv from "../common/DownloadCsv";
import LivePayinAdvanceFilter from "./LivePayinAdvanceFilter";
import LivePayinMutipleFilter from "./LivePayinMutipleFilter";
import MidFiter from "./MidFiter";
import { useDispatch, useSelector } from "react-redux";
import {
  setamount,
  setbankLocation,
  setcustomerLocation,
  setdate,
  setendAmount,
  setendDate,
  setfilterDataNull,
  setipAddress,
  setisFilterUsed,
  setmid,
  setpayerVPA,
  setpaymentType,
  setsid,
  setstartAmount,
  setstartDate,
  settransactionStatus,
} from "../store/slices/FilterSlice";

const LivePayinReport = ({
  reportType,
  setShowLoader,
  dropDownData,
  SIDData,
}) => {
  const [data, setData] = useState([]);
  const [downloadCSVData, setDownloadCSVData] = useState([]);
  const [show2, setShow2] = useState(false);
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);
  const [showMultipleFilter, setShowMultipleFilter] = useState(false);
  const [reset, setReset] = useState(false);
  const [merchantID, setMerchantID] = useState("");
  const [tableData, setTableData] = useState([]);
  const [tableData2, setTableData2] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [showServerCallBack, setShowServerCallBack] = useState([]);
  const [showCallBack, setShowCallBack] = useState([]);
  const [entriesPerPage, setEntriesPerPage] = useState(100);
  const entriesPerPageOptions = [50, 100, 200, 300, 400, 500];
  const token = localStorage.getItem("token");

  const FiltersNowlkjhgfd = (withOrWithoutMid, filterData, page, size) => {
    console.log(filterData);
    USE_LIVE_PAYIN_MORE_FILTER_API(
      withOrWithoutMid(filterData.mid),
      filterData.amount,
      filterData.bankLocation,
      filterData.customerLocation,
      filterData.date,
      filterData.endAmount,
      filterData.endDate,

      "",
      "",
      "",
      filterData.mid,
      filterData.payerVPA,
      filterData.paymentType,
      filterData.sid,
      filterData.startAmount,
      filterData.startDate,
      token,
      filterData.transactionStatus,
      setTableData,
      setShowLoader,
      page,
      size
    );
  };

  useEffect(() => {
    console.log(
      "csvData-------------------------------------------------",
      downloadCSVData
    );
  }, [downloadCSVData]);

  const ApiForCSVDownload = (withOrWithoutMid, filterData) => {
    console.log(filterData);
    const data = USE_LIVE_FOR_CSV_DOWNLOAD(
      withOrWithoutMid(filterData.mid),
      filterData.amount,
      filterData.bankLocation,
      filterData.customerLocation,
      filterData.date,
      filterData.endAmount,
      filterData.endDate,
      "",
      "",
      "",
      filterData.mid,
      filterData.payerVPA,
      filterData.paymentType,
      filterData.sid,
      filterData.startAmount,
      filterData.startDate,
      token,
      filterData.transactionStatus,

      setShowLoader
    );

    return data;
  };

  const dispatch = useDispatch();

  const getDailyTransactionData = (token) => {
    setShowLoader(true);
    const response = getAllDailyTransactionData(token)
      .then((response) => {
        if (response.statusCode === 200) {
          setData(response?.data);
          setShowLoader(false);
        } else {
          setData([]);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setShowLoader(false);
      });
  };

  const { filterData } = useSelector((state) => state);
  console.log(filterData);

  const withOrWithoutMid = (mid) => {
    if (mid === "") {
      return true;
    } else {
      return false;
    }
  };

  const handlePageChange = (newPage) => {
    // console.log("New page:", newPage);
    setCurrentPage(newPage);
    getDailyTransactionData(token);
    if (!filterData.isFilterUsed) {
      getDailyTransactionDataTable(token, newPage);
    } else {
      FiltersNowlkjhgfd(withOrWithoutMid, filterData, newPage, entriesPerPage);
      ApiForCSVDownload(withOrWithoutMid, filterData);
    }
  };

  const handleEntriesChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value) && value > 0) {
      setEntriesPerPage(value);
      // Reset to first page when changing entries per page
      setCurrentPage(0);
      if (!filterData.isFilterUsed) {
        // getDailyTransactionDataTable(token, newPage);
        getDailyTransactionDataTable(token, 0, value);
      } else {
        FiltersNowlkjhgfd(withOrWithoutMid, filterData, 0, value);
        ApiForCSVDownload(withOrWithoutMid, filterData);
      }
    }
  };

  const getDailyTransactionDataTable = (
    token,
    page = 0,
    size = entriesPerPage
  ) => {
    setShowLoader(true);
    const response = getAllDailyTransactionDataTable(token, page, size)
      .then((response) => {
        if (response.statusCode === 200) {
          setTableData(response?.data);
          setShowLoader(false);
        } else {
          setTableData([]);
          // console.error("Error fetching data:", response.data.message);
          setShowLoader(false);
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setShowLoader(false);
      });
  };

  // filtered Data Call
  const getDailyTransactionDataByMId = (merchantID, token) => {
    const response = getAllDailyTransactionDataByMid(merchantID, token)
      .then((response) => {
        if (response.statusCode === 200) {
          setData(response?.data);
          setMerchantID("");
        } else {
          setData([]);
          // console.error("Error fetching data:", response.data.message);
        }
        setMerchantID("");
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  const getDataForCsvDownload = async (withOrWithoutMid, filterData) => {
    const response = await USE_LIVE_FOR_CSV_DOWNLOAD(
      withOrWithoutMid(filterData.mid),
      filterData.amount,
      filterData.bankLocation,
      filterData.customerLocation,
      filterData.date,
      filterData.endAmount,
      filterData.endDate,

      "",
      "",
      "",
      filterData.mid,
      filterData.payerVPA,
      filterData.paymentType,
      filterData.sid,
      filterData.startAmount,
      filterData.startDate,
      token,
      filterData.transactionStatus,

      setShowLoader
    )
      .then((response) => {
        if (response.statusCode === 200) {
          console.log(response?.data);
          // setMerchantID("");
        } else {
          // setData([]);
          // console.error("Error fetching data:", response.data.message);
        }
        // setMerchantID("");
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  const getDailyTransactionDataTableByMId = (merchantID, token) => {
    const response = getAllDailyTransactionDataTableByMid(merchantID, token)
      .then((response) => {
        if (response.statusCode === 200) {
          setTableData(response?.data);
          setMerchantID("");
        } else {
          setTableData([]);
          // console.error("Error fetching data:", response.data.message);
        }
        setMerchantID("");
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  const handleFilter = () => {
    setShow2(true);
  };

  const handleAdvanceFilter = () => {
    setShowAdvanceFilter(true);
  };

  const handleMultipleFilter = () => {
    setShowMultipleFilter(true);
  };

  const DispatcherFunction = () => {
    dispatch(setamount(0));
    dispatch(setbankLocation(""));
    dispatch(setcustomerLocation(""));
    dispatch(setdate(""));
    dispatch(setendAmount(0));
    dispatch(setendDate(""));
    dispatch(setipAddress(""));
    dispatch(setmid(""));
    dispatch(setpayerVPA(""));
    dispatch(setpaymentType(""));
    dispatch(setstartAmount(0));
    dispatch(setstartDate(""));
    dispatch(setsid(""));
    dispatch(settransactionStatus(""));
    dispatch(setisFilterUsed(false));
  };

  const HandleReset = () => {
    DispatcherFunction();
    setReset(!reset);
    getDailyTransactionDataTable(token, currentPage);
  };

  useEffect(() => {
    getDailyTransactionData(token);
    // getDailyTransactionDataTable(token, currentPage);
    // setTableData2([]);
  }, [reset, currentPage, entriesPerPage]);

  useEffect(() => {
    // getDailyTransactionData(token);
    getDailyTransactionDataTable(token, currentPage);
    // setTableData2([]);
  }, []);

  const handleShowOrNot = (index) => {
    setShowCallBack((prevShowCallBack) => {
      const newShowCallBack = [...prevShowCallBack];
      newShowCallBack[index] = !newShowCallBack[index];
      return newShowCallBack;
    });
  };

  const handleServerShowOrNot = (index) => {
    setShowServerCallBack((prevShowCallBack) => {
      const newShowCallBack = [...prevShowCallBack];
      newShowCallBack[index] = !newShowCallBack[index];
      return newShowCallBack;
    });
  };

  const columns = [
    { label: "Company Name", field: "companyName", width: "auto" },
    { label: "MID", field: "mid", width: "auto" },
    { label: "SID", field: "sid", width: "auto" },
    { label: "Transaction Status", field: "txnStatus", width: "auto" },
    {
      label: "Transaction Response Status",
      field: "txnRespCode",
      width: "auto",
    },
    { label: "Bank Response Status", field: "evokTxnCode", width: "auto" },
    { label: "Payment Type", field: "paymentType", width: "auto" },
    { label: "Order Number", field: "orderNo", width: "auto" },
    { label: "Transaction ID", field: "txn_id", width: "auto" },
    { label: "Transaction Date", field: "txnDate", width: "auto" },
    { label: "Request Time", field: "txnTime", width: "auto" },
    { label: "Sent to Bank", field: "sentToBankDate", width: "auto" },
    { label: "Response from Bank", field: "ackFromBankDate", width: "auto" },
    {
      label: "QR Sent to Merchant",
      field: "qrSentToMerchantDate",
      width: "auto",
    },
    {
      label: "Callback Received from Bank",
      field: "responseDate",
      width: "auto",
    },
    {
      label: "Callback Sent to Merchant",
      field: "callbackSentToMerchantDate",
      width: "auto",
    },
    {
      label: "Ack from Merchant",
      field: "ackReceivedFromMerchantDate",
      width: "auto",
    },
    {
      label: "Merchant Response",
      field: "responseCode",
      width: "auto",
    },
    { label: "Terminal ID", field: "terminalId", width: "auto" },
    { label: "Amount", field: "amount", width: "auto" },
    { label: "Currency", field: "currency", width: "auto" },
    { label: "Response Date", field: "responseDate", width: "auto" },
    { label: "UTR Number", field: "custRefNo", width: "auto" },
    { label: "Payer VPA", field: "userVpa", width: "auto" },
    { label: "Callback Response", field: "callbackRespJson", width: "auto" },
    { label: "Server Response Json", field: "serverRespJson", width: "auto" },
    { label: "Mobile Number", field: "mobileNo", width: "auto" },
    { label: "e-Mail", field: "email", width: "auto" },
    { label: "Customer IP Address", field: "customerIp", width: "auto" },
    { label: "Customer Location", field: "customerLocation", width: "auto" },
    { label: "Remark", field: "remark", width: "auto" },
    { label: "Customer IFSC", field: "customerIfsc", width: "auto" },
    { label: "Bank Location", field: "bankLocation", width: "auto" },
  ];

  const mappingFunction = (tableData) => {
    const returnMap = tableData?.map((item, index) => {
      return {
        ...item,
        evokTxnCode: item.txnStatus === "success" ? "0" : item.evokTxnCode,
        sentToBankDate: item?.sentToBankDate?.split(" ")[1],
        ackFromBankDate: item?.ackFromBankDate?.split(" ")[1],
        qrSentToMerchantDate: item?.qrSentToMerchantDate?.split(" ")[1],
        callbackSentToMerchantDate:
          item?.callbackSentToMerchantDate?.split(" ")[1],
        ackReceivedFromMerchantDate:
          item?.ackReceivedFromMerchantDate?.split(" ")[1],

        responseDate: item?.responseDate?.split(" ")[1],
        serverRespJson: item?.serverRespJson ? (
          <>
            <Button
              className="mb-2"
              onClick={() => handleServerShowOrNot(index)}
            >
              {showServerCallBack[index] ? "Hide" : "Show"}
            </Button>
            <div>{showServerCallBack[index] ? item.serverRespJson : null}</div>
          </>
        ) : null,
        callbackRespJson: item.callbackRespJson ? (
          <>
            <Button className="mb-2" onClick={() => handleShowOrNot(index)}>
              {showCallBack[index] ? "Hide" : "Show"}
            </Button>
            <div>{showCallBack[index] ? item.callbackRespJson : null}</div>
          </>
        ) : null,
      };
    });
    return returnMap;
  };

  const MappedData = mappingFunction(tableData);

  const calculateTotalAmountForSuccess = (data) => {
    // Filter the data to include only "success" transactions
    const successTransactions = data.filter(
      (item) => item.txnStatus.toUpperCase() === "SUCCESS"
    );
    return successTransactions.reduce(
      (total, item) => total + parseFloat(item.amount),
      0
    );
    // Calculate the total sum of amounts for success transactions
  };

  const totalAmountForSuccess = calculateTotalAmountForSuccess(tableData);

  const boxComponentsUp = data?.map((entry, index) => (
    <Col xs={2} lg={1} md={1} key={index}>
      <DailyTransactionBox data={entry} />
    </Col>
  ));

  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    handlePageChange(nextPage);
  };

  const handlePreviousPage = () => {
    const previousPage = currentPage - 1;
    handlePageChange(previousPage);
  };

  const funDownLoadCsv = async (downloadCSVData, Name) => {
    //  const blob1 = await ApiForCSVDownload(withOrWithoutMid, filterData)

    const blob = new Blob([downloadCSVData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${Name}data.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <section className="lincpay-dashboard-sec">
        <div className="d-flex align-items-center">
          <label htmlFor="entriesPerPage">Entries per page:</label>
          <select
            id="entriesPerPage"
            value={entriesPerPage}
            onChange={handleEntriesChange}
            className="form-select form-select-sm"
            style={{ maxWidth: "100px" }}
          >
            {entriesPerPageOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <Col className="d-flex justify-content-end gap-2">
          <Button onClick={handleFilter}>Merchant</Button>
          <Button onClick={handleAdvanceFilter}>Advance Filter</Button>
          <Button onClick={handleMultipleFilter}>More Filter</Button>

          <Button
            className="d-flex align-items-center justify-content-center gap-2"
            onClick={HandleReset}
          >
            Reset<i class="bi bi-arrow-clockwise"></i>
          </Button>
        </Col>
        <div className="dasboard-view-count-boxes">
          <Container>
            <Card.Body>
              <Row>{boxComponentsUp}</Row>
            </Card.Body>
          </Container>
        </div>
        <div className="dasboard-view-count-boxes">
          <Card.Body>
            <div className="report-grid-view">
              <div className="material-table-records mb-3">
                <MDBDataTable
                  className="dataTable"
                  striped
                  small
                  bordered
                  hover
                  responsive
                  data={{
                    columns: columns,
                    rows: MappedData,
                  }}
                  searching={true}
                  entries={10}
                  entriesOptions={[10, 20, 50, 100]}
                  sortable={true}
                  footer="none"
                  // pagesAmount={10}
                  page={currentPage}
                  onPageChange={handlePageChange}
                  // pagination={false}
                  paging={false}
                />
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <DownloadCsv DownLoadData={downloadCSVData} Name={`Payin-`} />

                <div className="pagination-buttons d-flex align-items-center justify-content-end gap-2">
                  <Button
                    onClick={handlePreviousPage}
                    disabled={currentPage === 0}
                  >
                    Previous
                  </Button>
                  <Button onClick={handleNextPage}>Next</Button>
                </div>
              </div>
              {/* <div className="d-flex align-items-center justify-content-end w-25 gap-4 border boeder-1 p-2 ">
                <lable className="fs-4 w-50">Total Amount</lable>
                <span className="fs-4 fw-bold w-50 border boeder-2 rounded-2 p-1">
                  {totalAmountForSuccess}
                </span>
              </div> */}
            </div>
            <div className="mt-2 text-danger d-flex justify-content-evenly">
              <div>
                <span>U03 - Above Allowed Limit</span>
                <br />
                <span>U16 - Daily Limit Of Exceeded</span>
                <br />
                <span>U28 - Unable To Connect Sender Application </span>
                <br />
              </div>
              <div>
                <span>U30 - Unable To Debit</span>
                <br />
                <span>U31 - Denied By Reciever</span>
                <br />
                <span>U67 - Debit Timeout</span>
                <br />
              </div>
              <div>
                <span>U90 - Txn Deemed</span>
                <br />
                <span>U91 - Sender Bank Rejected</span>
                <br />
                <span>E05 - User Left Txn Incomplete</span>
                <br />
                <span> 000 - Received blank response code</span>
              </div>
            </div>
          </Card.Body>
        </div>
      </section>
      <MidFiter
        reportType={reportType}
        setShow2={setShow2}
        show2={show2}
        setLiveData={setTableData}
        setLiveBoxData={setData}
      />
      {showAdvanceFilter ? (
        <LivePayinAdvanceFilter
          setShowLoader={setShowLoader}
          setTransactionReportData={setTableData}
          setIsAdvanceFilterOpen={setShowAdvanceFilter}
        />
      ) : showMultipleFilter ? (
        <LivePayinMutipleFilter
          setShowLoader={setShowLoader}
          setTransactionReportData={setTableData}
          setShowMultipleFilter={setShowMultipleFilter}
          dropDownData={dropDownData}
          setLiveBoxData={setData}
          setDownloadCSVData={setDownloadCSVData}
          SIDData={SIDData}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default LivePayinReport;
