import React, { useEffect, useState } from "react";
import MerchantDetails from "../../../Merchant/MerchantDetails";
import FlagManagement from "../../../Merchant/MerchantManagement/FlagManagement";
import AllPayoutReport from "./AllPayoutReport";
import Withdraw from "../../../reports/Withdraw";
import InactiveServices from "../../InactiveServices";
import WithdrawAmount from "../../../Merchant/MerchantManagement/WithdrawAmount2";
import Withdrawl from "./Withdrawl";
import ServiceCharge from "../../../Merchant/MerchantManagement/ServiceCharge";
import AllVendor from "../ALL VENDOR REGISTRATION REQUEST/AllVendor";
import ChargebackUpload from "../../../reports/ChargebackUpload";
import ManageAmount from "../../../Merchant/MerchantManagement/ManageAmount";
import PayOutSection from "./PayOutSection";
import VendorSection from "./VendorSection";
import WithdrawSection from "./WithdrawSection";
import HoldandDepositSection from "../../../Merchant/MerchantManagement/Hold&DepositSection";
import { Row, Col, Card, Container } from "react-bootstrap";
import Routing from "../../../Merchant/MerchantManagement/PayInRouting";
import Reconciliation from "./Reconcilliation";
import MyLoader from "../../../common/MyLoader";
import RoutingSubtab from "./RoutingSubtab";
import WalletBalance from "./WalletBalance";
import MDRPercentageEdit from "./MDRPercentageEdit";
import { useSelector } from "react-redux";
import useCheckPrivilages from "../../../utils/checkPrivilages";
import CheckExportConst from "../CheckExportConst/CheckExportConst";
import DownloadCsv from "../../../common/DownloadCsv";
import DownloadExcel from "./DownloadExcel";
import NewTab from "../NewTab/NewTab";

const SubTabManage = ({ setShowLoader }) => {
  const [activePhase, setActivePhase] = useState("");
  // const [showLoader, setShowLoader] = useState(false);

  const { privilagesData } = useSelector((state) => state.privilages);

  const { hasPrivilagesRouting } = CheckExportConst();

  const hasPrivilages2681 = useCheckPrivilages(2681);
  const hasPrivilages2633 = useCheckPrivilages(2633);
  const hasPrivilages2634 = useCheckPrivilages(2634);
  const hasPrivilages2635 = useCheckPrivilages(2635);
  const hasPrivilages2636 = useCheckPrivilages(2636);
  const hasPrivilages2637 = useCheckPrivilages(2637);
  const hasPrivilages2638 = useCheckPrivilages(2638);
  const hasPrivilages176088 = useCheckPrivilages(176088);
  const hasPrivilages2683 = useCheckPrivilages(2783);
  const hasPrivilages2684 = useCheckPrivilages(2784);
  const hasPrivilages2685 = useCheckPrivilages(2785);
  const hasPrivilages2686 = useCheckPrivilages(2786);
  const hasPrivilages2688 = useCheckPrivilages(2788);
  const hasPrivilages2689 = useCheckPrivilages(2789);

  const hasPrivilages2691 = useCheckPrivilages(2691);
  const hasPrivilages2692 = useCheckPrivilages(2692);
  const hasPrivilages2693 = useCheckPrivilages(2693);
  const hasPrivilages2694 = useCheckPrivilages(2694);
  const hasPrivilages2696 = useCheckPrivilages(2696);
  const hasPrivilages2697 = useCheckPrivilages(2697);
  const hasPrivilages2698 = useCheckPrivilages(2698);
  const hasPrivilages2699 = useCheckPrivilages(2699);
  const hasPrivilages2700 = useCheckPrivilages(2700);
  const hasPrivilages2701 = useCheckPrivilages(2701);
  const hasPrivilages2706 = useCheckPrivilages(2706);
  const hasPrivilages2708 = useCheckPrivilages(2708);
  const hasPrivilages2709 = useCheckPrivilages(2709);
  const hasPrivilages2783 = useCheckPrivilages(2783);
  const hasPrivilages2784 = useCheckPrivilages(2784);
  const hasPrivilages2785 = useCheckPrivilages(2785);
  const hasPrivilages2786 = useCheckPrivilages(2786);
  const hasPrivilages2788 = useCheckPrivilages(2788);
  const hasPrivilages2789 = useCheckPrivilages(2789);

  useEffect(() => {
    setActivePhase("");
  }, []);

  // const {privilagesData}= useSelector(state=>state.privilages)
  // console.log(privilagesData)

  const handlePhaseClick = (phase) => {
    console.log(phase);
    setActivePhase(phase);
  };

  return (
    <>
      {/* {showLoader ? <MyLoader/> : ""} */}
      <section className="dashboard-header-sec subtab-card">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-3  ds-inner-card">
                  <Card.Body>
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn submenu-tab-admin">
                        {hasPrivilages2681 ? (
                          <li
                            className={activePhase === "Phase1" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase1")}
                          >
                            Details{activePhase === "Phase1"}
                          </li>
                        ) : null}

                        {hasPrivilagesRouting ? (
                          <li
                            className={activePhase === "Phase6" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase6")}
                          >
                            Routing
                          </li>
                        ) : null}

                        {hasPrivilages176088 ? (
                          <li
                            className={
                              activePhase === "Phase12" ? "active" : ""
                            }
                            onClick={() => handlePhaseClick("Phase12")}
                          >
                            MDR {activePhase === "Phase12"}
                          </li>
                        ) : null}

                        {hasPrivilages2691 ||
                        hasPrivilages2692 ||
                        hasPrivilages2693 ||
                        hasPrivilages2694 ? (
                          <li
                            className={activePhase === "Phase3" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase3")}
                          >
                            Hold & Deposit
                            {activePhase === "Phase3"}
                          </li>
                        ) : null}

                        {hasPrivilages2696 ? (
                          <li
                            className={activePhase === "Phase2" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase2")}
                          >
                            Flag{activePhase === "Phase2"}
                          </li>
                        ) : null}

                        {hasPrivilages2697 ? (
                          <li
                            className={activePhase === "Phase4" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase4")}
                          >
                            Pending Payout Request{activePhase === "Phase4"}
                          </li>
                        ) : null}

                        {hasPrivilages2706 ||
                        hasPrivilages2709 ||
                        hasPrivilages2708 ? (
                          <li
                            className={activePhase === "Phase5" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase5")}
                          >
                            Withdraw{activePhase === "Phase5"}
                          </li>
                        ) : null}

                        {hasPrivilages2698 ? (
                          <li
                            className={activePhase === "Phase8" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase8")}
                          >
                            pending Vendor Request{activePhase === "Phase8"}
                          </li>
                        ) : null}

                        {hasPrivilages2699 ? (
                          <li
                            className={activePhase === "Phase9" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase9")}
                          >
                            Upload Chargeback
                            {activePhase === "Phase9"}
                          </li>
                        ) : null}

                        {hasPrivilages2700 ? (
                          <li
                            className={
                              activePhase === "Phase10" ? "active" : ""
                            }
                            onClick={() => handlePhaseClick("Phase10")}
                          >
                            Reconciliation {activePhase === "Phase10"}
                          </li>
                        ) : null}

                        {hasPrivilages2701 ? (
                          <li
                            className={
                              activePhase === "Phase11" ? "active" : ""
                            }
                            onClick={() => handlePhaseClick("Phase11")}
                          >
                            Wallet Balance {activePhase === "Phase11"}
                          </li>
                        ) : null}
                        {/* <li
                            className={
                              activePhase === "Phase15" ? "active" : ""
                            }
                            onClick={() => handlePhaseClick("Phase15")}
                          >
                           New Tab {activePhase === "Phase15"}
                          </li> */}
                        
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
                {/* ./card */}
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      { activePhase === "" ? null : (
        <div className="admin-tab-content mat-bt-table">
          {activePhase === "Phase1" ? (
            <MerchantDetails setShowLoader={setShowLoader} />
          ) : activePhase === "Phase2" ? (
            <FlagManagement setShowLoader={setShowLoader} />
          ) : activePhase === "Phase3" ? (
            <HoldandDepositSection setShowLoader={setShowLoader} />
          ) : activePhase === "Phase4" ? (
            <PayOutSection setShowLoader={setShowLoader} />
          ) : activePhase === "Phase5" ? (
            <WithdrawSection setShowLoader={setShowLoader} />
          ) : activePhase === "Phase6" ? (
            <RoutingSubtab setShowLoader={setShowLoader} />
          ) : // ) : activePhase === "Phase7" ? (
          //   <ServiceCharge
          //   setShowLoader = {setShowLoader} />
          activePhase === "Phase8" ? (
            <VendorSection setShowLoader={setShowLoader} />
          ) : activePhase === "Phase9" ? (
            <ChargebackUpload setShowLoader={setShowLoader} />
          ) : activePhase === "Phase10" ? (
            <Reconciliation setShowLoader={setShowLoader} />
          ) : activePhase === "Phase11" ? (
            <WalletBalance setShowLoader={setShowLoader} />
          ) : activePhase === "Phase12" ? (
            <MDRPercentageEdit setShowLoader={setShowLoader} />
          ) : activePhase === "Phase13" ? (
            <DownloadExcel setShowLoader={setShowLoader} />
          ) : activePhase === "Phase15" ? (
            <NewTab setShowLoader={setShowLoader} />
          ) : (
            <InactiveServices location={"privages"} />
          )}
        </div>
      )}
    </>
  );
};

export default SubTabManage;

// && privilagesData.includes("Merchant-Details-View")
