import React, { useEffect, useState} from "react";
import axios from "axios";
import { useParams } from "react-router-dom"; 
import { useNavigate } from "react-router-dom";
import { SWIPELINC_API } from "../utils/constant";
import { Container, Row, Col, Card } from "react-bootstrap";
import "../stylesheet/ViewDetails.css";
import "../assets/css/viewFile1.css";
// import MyLoader from "../common/MyLoader";

const ViewFile1 = ({id,handleClose}) => {
  const [merchantData, setMerchantData] = useState([]);
  // const [showLoader, setShowLoader] = useState(Boolean);
  const token = localStorage.getItem("token");
  // const id = useParams();
  // console.log(id.id);
  // const navigate = useNavigate();
  console.log(id)
  useEffect(() => {
  // setShowLoader(true)
    axios
      .get(`${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getmerchantById/${id}`,
      {
        headers: {
          Authentication: `Bearer ${token}`,
        },
      }
      )
      .then((response) => {
       setMerchantData(response?.data.data);
      //  setShowLoader(false)
      })
      .catch((error) => {
        console.error("Error fetching merchant details:", error.message);
        // setShowLoader(false)
      });
  }, [id.id]);
  

  return (
    <>
    {/* {showLoader ? <MyLoader/> : ""} */}
    <section className="admin-view-details">
				<Container>
					<Card className="shadow border-0 mb-4 ds-header-card">
						<Card.Body>
							<Row>
							<Col xs={12} xl={12} lg={12} sm={12}>
									<div className="two-column-layout">
                  <div className="vertical-table mb-4">
                  
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Company Name</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.companyName}</div>
                  </div>

                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Merchant Master</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.masterMerchant}</div>
                  </div>

                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">MCC Code</div>
                    <div className="vertical-table-cell-value  text-left">  {merchantData.mccCode}</div>
                  </div>
              
                    <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Registration Type</div>
                    <div className="vertical-table-cell-value text-left">{merchantData.registrationType}</div>
                  </div>
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Business Category</div>
                    <div className="vertical-table-cell-value text-left">{merchantData.businessType}</div>
                  </div>
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Other Business Category</div>
                    <div className="vertical-table-cell-value text-left">{merchantData.businessTypeName}</div>
                  </div>
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Business Type</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.companyType}</div>
                  </div>
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Business As</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.businessAs}</div>
                  </div>
                 
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Company PAN Number</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.companyPanNumber}</div>
                  </div>
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">GST Number</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.gstNumber}</div>
                  </div>
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Authorized Person Name</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.authorizedPersonName}</div>
                  </div>
                <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">e-Mail ID</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.email}</div>
                  </div>
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Mobile Number</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.mobile}</div>
                  </div>
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Authorized Person PAN</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.authorizedPersonPan}</div>
                  </div>
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Aadhaar Number</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.aadharNumber}</div>
                  </div>
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Website URL</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.websiteUrl}</div>
                  </div>
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Application URL</div>
                    <div className="vertical-table-cell-value text-left">{merchantData.aapUrl}</div>
                  </div>
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Company Registered Address</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.companyAddress}</div>
                  </div>
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left"> Pin Code</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.companyAddressPinCode}</div>
                  </div>
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">City</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.companyAddressCity}</div>
                  </div>
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">State</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.companyAddressState}</div>
                  </div>
                </div>

                <div className="vertical-table">
                  {/* Second half of the table */}
               
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Authorized Signatory Address</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.registeredOfficeAddress}</div>
                  </div>
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Pin Code</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.registeredOfficeAddressPinCode}</div>
                  </div>
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">City</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.registeredOfficeAddressCity}</div>
                  </div>
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">State</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.registeredOfficeAddressState}</div>
                  </div>
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Company Communication Address</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.communicationAddress}</div>
                  </div>
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Pin Code</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.communicationAddressPinCode}</div>
                  </div>
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">City</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.communicationAddressCity}</div>
                  </div>
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">State</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.communicationAddressState}</div>
                  </div>
                
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Mode of Registration</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.modeOfRegistration}</div>
                  </div>
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Modification Date</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.modificationDate}</div>
                  </div>
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Submission Date</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.submitDate}</div>
                  </div>
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Submission Time</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.submitTime}</div>
                  </div>
                  <div className="vertical-table-row">
                    <div className="vertical-table-cell text-left">Referred By</div>
                    <div className="vertical-table-cell-value  text-left">{merchantData.referredBy}</div>
                  </div>
                    
                </div>
               
									</div>
								</Col>
							</Row>
              <Row>
        <div className="d-flex justify-content-end">
          <button type="button" class="btn btn-primary" onClick={handleClose}>
            Close
          </button>
        </div>
      </Row>
						</Card.Body>
					</Card>
				</Container>
			</section>
 </>
  );
};

export default ViewFile1;