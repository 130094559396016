import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import {
  GET_ALL_PRIVILAGES,
  GET_ALL_PRIVILAGES_BY_ID,
  GET_USER_MANAGEMENT_DATA_BY_ID,
} from "../../../utils/constant";
import { getAllFunctionForApiCall, updateUserData } from "../../../utils/ApiServices";
import swal from "sweetalert";
import { getTokenFromLocalStorage } from "../../../utils/tokenService";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import { isEmailIdRegexIsValid, getKeyPressed } from "../../../utils/helper";

const EditUser = ({ handleClose, UserId, setShowLoader }) => {
  const [loading, setLoading] = useState(false);
  const [UserData, setUserData] = useState({});
  const [privilagesData, setPrivilagesData] = useState();
  const [privilages, setPrivilages] = useState([]);
  const [pressedKeyName, setPressedKeyName] = useState("");
  const token = getTokenFromLocalStorage();

  const decodedToken = jwtDecode(token);
  const { masterId } = decodedToken;
  const [formDataInputs, setFormDataInputs] = useState({
    userName: "",
    masterMerchantName: "",
    password: "",
    MobileNumber: "",
    emailId: "",
  });
  const privilagesSlice = useSelector(state => state.privilages)
  useEffect(() => {
    setFormDataInputs({
      userName: UserData?.name,
      password: UserData?.password,
      MobileNumber: UserData?.mobile,
      emailId: UserData?.email,
    });
  }, [UserData]);

  const getAllPrivilages = async () => {
    try {
      const response = await getAllFunctionForApiCall(
        GET_ALL_PRIVILAGES,
        token
      );

      if (response.statusCode === 200) {
        setPrivilagesData(response?.data);

      } else {
        setPrivilagesData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserData = async () => {
    try {
      const response = await getAllFunctionForApiCall(
        GET_USER_MANAGEMENT_DATA_BY_ID + UserId,
        token
      );
      if (response.statusCode === 200) {
        setUserData(response?.data);
        const res = response?.data?.privilege?.map((item) => {
          console.log(item.adminPrivilegeSubTypeId);
          return item.adminPrivilegeSubTypeId;
        });

        setPrivilages(res);
      } else {
        setUserData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllPrivilagesbyID = async () => {
    try {
      const response = await getAllFunctionForApiCall(
        GET_ALL_PRIVILAGES_BY_ID + masterId,
        token
      );
      if (response.statusCode === 200) {
        const res = response?.data?.map((item) => {
          console.log(item.adminPrivilegeSubTypeId);
          return item.adminPrivilegeSubTypeId + "";
        });

        setPrivilages(res);

      } else {
        setPrivilages([]);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const handleformDataInputs = (e) => {
    const name = e.target.name;
    let value = e.target.value;
  
    if (name === "password" || name === "emailId") {
      setFormDataInputs({ ...formDataInputs, [name]: value });
    } else {
      value = value.toUpperCase();
      if (name === "MobileNumber") {
        // Remove any non-numeric characters
        value = value.replace(/\D/g, '');
      }
      setFormDataInputs({ ...formDataInputs, [name]: value });
    }
  };

  const handlePrivilages = (e) => {
    const adminPrivilegeSubTypeId = e.target.name;

    console.log("Checkbox checked:", e.target.checked);
    console.log("Privilages state before:", privilages);

    setPrivilages((prevPrivilages) => {
      if (e.target.checked) {
        if (!prevPrivilages.includes(adminPrivilegeSubTypeId)) {

          return [...prevPrivilages, parseInt(adminPrivilegeSubTypeId)];
        }
      } else {
        if (prevPrivilages.includes(parseInt(adminPrivilegeSubTypeId))) {
          return prevPrivilages.filter((id) => id !== parseInt(adminPrivilegeSubTypeId));
        }
      }

      return prevPrivilages;
    });

    console.log("Privilages state after:", privilages);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await getUserData();
        await getAllPrivilages();
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {

    if (formDataInputs.userName === "") {
      swal({
        title: "Alert!",
        text: "Please Enter User Name",
        icon: "error",
      });
      return;
    }
    if (formDataInputs.MobileNumber === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Mobile Number",
        icon: "error",
      });
      return;
    }
   
    if (formDataInputs.emailId === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Email Id",
        icon: "error",
      });
      return;
    }
    if (!privilages.length) {
      swal({
        title: "Alert!",
        text: "Please select atleast one privilage",
        icon: "error",
      });
      return;
    }
    if (!isEmailIdRegexIsValid(formDataInputs.emailId)) {
      swal({
        title: "Alert!",
        text: "Please Enter Valid Merchant E-Mail ID !",
        icon: "error",
      }).then(() => {
        setFormDataInputs({ ...formDataInputs});
      });
      return;
    }
    const privilegs = privilages?.map(item => {
      return parseInt(item)
    })
    const payload = {
      aggrigator_sub_user_Id: UserId,
      email: formDataInputs.emailId?.trim(),
      mobile: formDataInputs.MobileNumber?.trim(),
      name: formDataInputs.userName?.trim(),
      password: formDataInputs.password?.trim(),
      privilegeId: privilegs,
    };

    console.log(payload);

    e.preventDefault();
    const swalConfirm = await swal({
      title: "Are you sure?",
      text: "Do you want to submit the application?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (swalConfirm) {
      setShowLoader(true);
      const res = await updateUserData(payload);
      setShowLoader(false);

      if (res.statusCode === 200) {
        swal({
          title: "Success",
          text: "User Updated Successfully.",
          icon: "success",
        });
        setFormDataInputs({
          userName: "",
          masterMerchantName: "",
          password: "",
          MobileNumber: "",
          emailId: "",
        });
        setPrivilages([]);
        setPrivilagesData([]);
        getAllPrivilages();
        handleClose()
      } else {
        swal({
          title: "Failed",
          text: res.message,
          icon: "error",
        });
      }
    }
  };
  const handleValidations = (e) => {
    if (e.target.name === "email" && e.target.value !== "") {
      if (!isEmailIdRegexIsValid(formDataInputs.emailId)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Merchant E-Mail ID !",
          icon: "error",
        }).then(() => {
          formDataInputs({ ...formDataInputs, email: "" });
        });
        return;
      }
    }
  }

  return (
    <>
      {loading ? null : (
        <>
          <Card.Header className="d-flex justify-content-between">
            <Card.Title>User Management Edit</Card.Title>
            <Button onClick={handleClose}>Close</Button>
          </Card.Header>
          <Card className="shadow border-0 mb-4">
            <Card.Body>
              <div className="merchant-report-view">
                <div className="report-table-view">
                  <div>
                    <Row className="px-4">
                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="form-label">
                            User Name
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              className="form-control"
                              name="userName"
                              value={formDataInputs.userName}
                              onChange={handleformDataInputs}
                            />
                          </div>
                        </div>

                      </Col>

                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="form-label">
                            e-Mail Id
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              type="email"
                              className="form-control"
                              name="emailId"
                              value={formDataInputs.emailId}
                              onChange={handleformDataInputs}
                              onKeyDown={(e) =>
                                setPressedKeyName(getKeyPressed(e))
                              }
                              onBlur={handleValidations}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col xs={12} lg={4} md={6} className="mt-5">
              <div className="form-group">
                <label htmlFor="MobileNumber" className="form-label">
                  Merchant Mobile Number
                  <sup className="text-danger">*</sup>{" "}
                </label>
                <input
                  maxLength={10}
                  minLength={10}
                  name="MobileNumber"
                  value={formDataInputs.MobileNumber}
                  onChange={handleformDataInputs}
                  required
                  pattern="[0-9]{10}"
                  title="Please enter a 10-digit mobile number"
                  className="form-control"
                />
              </div>
            </Col>

                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="form-label">
                            Password
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              className="form-control"
                              name="password"
                              value={formDataInputs.password}
                              onChange={handleformDataInputs}
                            />
                          </div>
                        </div>
                        {/* ./form-group */}
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <lable className="fs-3 fw-bold mx-3 mt-2 mb-3">
                        Privilages
                      </lable>
                      <div className="d-flex flex-column gap-4">
                        {privilagesData &&
                          privilagesData?.map((item, i) => (
                            <div key={i} className="d-flex flex-column">
                              <Card.Header className="form-label mt-4 mb-3 fs-4 mx-1">
                                {item.privilegeName}
                              </Card.Header>
                              <div className="d-flex flex-wrap">
                                {item.subTypes?.map((item, i) => (
                                  privilagesSlice?.privilagesData?.includes(
                                    parseInt(item.adminPrivilegeSubTypeId)
                                  ) && (
                                    <div
                                      key={i}
                                      className="bg-light d-flex justify-content-center align-items-center gap-5 px-4 py-1 rounded rounded-5 mt-2 mx-2   form-group"
                                    >
                                      <label className="form-label mt-1 fs-4 mx-1">
                                        {item.subTypePrivilegeName}
                                      </label>
                                      <div className="s-lg form-check form-switch ">
                                        <input
                                          id="flexSwitchCheckDefault"
                                          className="form-check-input fs-4"
                                          type="checkbox"
                                          name={item.adminPrivilegeSubTypeId}
                                          checked={privilages.includes(item.adminPrivilegeSubTypeId)}
                                          onChange={handlePrivilages}
                                        />
                                      </div>
                                    </div>)
                                ))}
                              </div>
                            </div>
                          ))}
                      </div>
                    </Row>
                    <div className="d-flex justify-content-center mt-4">
                      <button
                        className="btn btn-primary"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};

export default EditUser;