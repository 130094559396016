import useCheckPrivilages from "../../../utils/checkPrivilages";

import React from "react";

const CheckExportConst = () => {
  const hasPrivilages2681 = useCheckPrivilages(2681);
  const hasPrivilages2682 = useCheckPrivilages(2682);
  const hasPrivilages2683 = useCheckPrivilages(2683);
  const hasPrivilages2684 = useCheckPrivilages(2684);
  const hasPrivilages2685 = useCheckPrivilages(2685);
  const hasPrivilages2686 = useCheckPrivilages(2686);
  const hasPrivilages2688 = useCheckPrivilages(2688);
  const hasPrivilages2689 = useCheckPrivilages(2689);
  const hasPrivilages2691 = useCheckPrivilages(2691);
  const hasPrivilages2692 = useCheckPrivilages(2692);
  const hasPrivilages2693 = useCheckPrivilages(2693);
  const hasPrivilages2694 = useCheckPrivilages(2694);
  const hasPrivilages2696 = useCheckPrivilages(2696);
  const hasPrivilages2697 = useCheckPrivilages(2697);
  const hasPrivilages2698 = useCheckPrivilages(2698);
  const hasPrivilages2699 = useCheckPrivilages(2699);
  const hasPrivilages2700 = useCheckPrivilages(2700);
  const hasPrivilages2701 = useCheckPrivilages(2701);
  const hasPrivilages2702 = useCheckPrivilages(2702);
  const hasPrivilages2703 = useCheckPrivilages(2703);
  const hasPrivilages2706 = useCheckPrivilages(2706);
  const hasPrivilages2708 = useCheckPrivilages(2708);
  const hasPrivilages2709 = useCheckPrivilages(2709);
  const hasPrivilages176088 = useCheckPrivilages(176088);

  console.log(hasPrivilages176088);

const hasPrivilagesRouting= hasPrivilages2683 ||
    hasPrivilages2684 ||
    hasPrivilages2685 ||
    hasPrivilages2686 ||
    hasPrivilages2688 ||
    hasPrivilages2689 ;
    

  const HasPrivilagesMerchantManagement = (hasPrivilages2681 ||
  hasPrivilages2682 ||
  hasPrivilages2683 ||
  hasPrivilages2684 ||
  hasPrivilages2685 ||
  hasPrivilages2686 ||
  hasPrivilages2688 ||
  hasPrivilages2689 ||
  hasPrivilages2691 ||
  hasPrivilages2692 ||
  hasPrivilages2693 ||
  hasPrivilages2694 ||
  hasPrivilages2696 ||
  hasPrivilages2697 ||
  hasPrivilages2698 ||
  hasPrivilages2699 ||
  hasPrivilages2700 ||
  hasPrivilages2701 ||
  hasPrivilages2706 ||
  hasPrivilages2708 ||
  hasPrivilages2709)

  console.log(hasPrivilagesRouting)

  return {
    hasPrivilages176088,
    hasPrivilages2681,
    hasPrivilages2684,
    hasPrivilages2685,
    hasPrivilages2686,
    hasPrivilages2688,
    hasPrivilages2689,
    hasPrivilages2691,
    hasPrivilages2692,
    hasPrivilages2693,
    hasPrivilages2694,
    hasPrivilages2696,
    hasPrivilages2697,
    hasPrivilages2698,
    hasPrivilages2699,
    hasPrivilages176088,
    hasPrivilages2700,
    hasPrivilages2701,
    hasPrivilages2702,
    hasPrivilages2703,
    hasPrivilages2706,
    hasPrivilages2708,
    hasPrivilages2709,
    HasPrivilagesMerchantManagement,
    hasPrivilagesRouting,
  };
};

export default CheckExportConst;
