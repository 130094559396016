import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import PayInRouting from "../../../Merchant/MerchantManagement/PayInRouting";
import InactiveServices from "../../InactiveServices";
import SubmittedRoutingPayin from "./SubmittedRoutingPayin";
import ViewMappedSID from "./ViewMappedSID ";
import PayoutRouting from "../../../Merchant/MerchantManagement/PayoutRouting";
import SubmittedRoutingPayout from "./SubmittedRoutingPayout";
import { useSelector } from "react-redux";
import useCheckPrivilages from "../../../utils/checkPrivilages";

const RoutingSubTabForPayout = ({ location, setShowLoader }) => {
  const [activePhase, setActivePhase] = useState("");

  const { privilagesData } = useSelector((state) => state?.privilages);

  const hasPrivilage2685 = useCheckPrivilages(2685);
  const hasPrivilage2686 = useCheckPrivilages(2686);

  useEffect(() => {
    setActivePhase("");
    //   setActiveSubtab("MerchantManagement");
  }, []);

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  return (
    <>
      <>
        <section className="dashboard-header-sec">
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4 ds-header-card">
                  <Card.Body>
                    {/* <Card.Title className="h3">Reports</Card.Title>  */}
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn">
                        {hasPrivilage2685 ? (
                          <li
                            className={activePhase === "Phase1" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase1")}
                          >
                            Routing
                          </li>
                        ) : null}
                        {hasPrivilage2686 ? (
                          <li
                            className={activePhase === "Phase2" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase2")}
                          >
                            Submitted
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </section>
        {activePhase === "" ? null : (
          <div>
            {activePhase === "Phase1" ? (
              <PayoutRouting
              
                location={location}
                setShowLoader={setShowLoader}
              />
            ) : activePhase === "Phase2" ? (
              <SubmittedRoutingPayout
              showEditButton={true}
              showEnaDisaButton = {true}
               setShowLoader={setShowLoader} />
            ) : (
              // ) : activePhase === "Phase3" ? (
              // <SubmittedRoutingPayin setShowLoader={setShowLoader} />
              //   <ViewMappedSID setShowLoader={setShowLoader} />
              <InactiveServices location={"priviges"} />
            )}
          </div>
        )}
      </>
    </>
  );
};

export default RoutingSubTabForPayout;
