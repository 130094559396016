import { Slider } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { SWIPELINC_API } from "../utils/constant";
import { styled } from "@mui/material/styles";
import swal from "sweetalert";

const CustomSlider = styled(Slider)({
  color: "#3e85f7",
  height: 8,
  " .MuiSlider-track": {
    border: "none",
  },
  " .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    ":focus, :hover, .Mui-active, .Mui-focusVisible": {
      boxShadow: "inherit",
    },
    ":before": {
      display: "none",
    },
  },
  ".MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#3e85f7",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

const FRManagement = ({setShowLoader}) => {
  const [merchantData, setMerchantData] = useState([]);
  const [callAgain, setCallAgain] = useState(!false);
  const [FRMData, setFRMData] = useState({
    location: 0,
    typeOfBusiness: 0,
    websiteAnalysis: 0,
    appAnalysis: 0,
    directorProfile: 0,
    companyProfile: 0,
    businessProfile: 0,
    chargebackProbability: 0,
    bankApprovalProbability: 0,
    ticketSize: 0,
    volume: 0,
    numberOfTickets: 0,
    mid:""
  });
  const token = localStorage.getItem("token");
  const handelChange = (e) => {
    const { name, value } = e.target;
    setFRMData({ ...FRMData, [name]: value });
  };

  const handelFRMSubmit = (e) => {
    e.preventDefault()
    if(FRMData.mid === "") {
      swal({
        title: "Alert!",
        text: "Please select company !",
        icon: "error",
      });
      return
    }
setShowLoader(true)
    axios
      .post(`${SWIPELINC_API}/MerchantAdminPanel/merchant/frm/save`, FRMData,
      {
        headers: {
          Authentication: `Bearer ${token}`,
        },
      }
      )
      .then((response) => {
       if (response.data.statusCode === 200) {
          swal({
            title: "Success!",
            text: response.data.message,
            icon: "success",
          });
          setShowLoader(false)
          setCallAgain(!callAgain)
          setFRMData({location: 0,
            typeOfBusiness: 0,
            websiteAnalysis: 0,
            appAnalysis: 0,
            directorProfile: 0,
            companyProfile: 0,
            businessProfile: 0,
            chargebackProbability: 0,
            bankApprovalProbability: 0,
            ticketSize: 0,
            volume: 0,
            numberOfTickets: 0,
            mid:""})
          return
        } else {
          setMerchantData(response.data.message);
        } 
        setShowLoader(false)
      })
      .catch((error) => {
        console.error("Error fetching applications:", error.message);
        setShowLoader(false)
      });


    console.log(FRMData);
  }

  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 5,
      label: "5",
    },
    {
      value: 6,
      label: "6",
    },
    {
      value: 7,
      label: "7",
    },
    {
      value: 8,
      label: "8",
    },
    {
      value: 9,
      label: "9",
    },
    {
      value: 10,
      label: "10",
    },
  ];

  useEffect(() => {
setShowLoader(true)
    axios
    .get(`${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/getallforFRM`, 
  {
    headers: {
    Authentication: `Bearer ${token}`,
  },
}
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          console.log("Approved applications:", response?.data.data);
          setMerchantData(response?.data.data);
        } else if (response.data.statusCode === 304) {
          setMerchantData(response?.data.data);
        } else {
          console.error("NO APPLICATIONS FOUND:", response.data.data);
        }
        setShowLoader(false)
      })
      .catch((error) => {
        console.error("Error fetching applications:", error.message);
        setShowLoader(false)
      });
  }, [callAgain]);

  return (
    <>
      <section className="lincpay-dashboard-sec merchant-admin-info-form company-add-form">
        <Container>
          <div className="cart-view-sec mt-4">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow-cst border-0 mb-3 chart-boxes merchant-info-form-sec">
                  <Card.Body>
                    <div className="merchant-form-info">
                      <form
                        className="form-section-form-group d-flex flex-column align-items-center"
                        // onSubmit={handleSubmit}
                      >
                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label className="form-label">
                              Please Select Company
                              <sup className="text-danger">*</sup>
                            </label>
                            <select
                              className="form-control"
                              name="mid"
                              value={FRMData.mid}
                              onChange={handelChange}
                            >
                              <option value="">
                                Select
                                <span className="star">*</span>
                              </option>
                              {merchantData?.map((merchant) => (
                                <option key={merchant.mid} value={merchant.mid}>
                                  {merchant.companyName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Row className="mt-3">
                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                Location
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="px-4">
                                <CustomSlider
                                  valueLabelDisplay="auto"
                                  aria-label="pretto slider"
                                  onChange={handelChange}
                                  name="location"
                                  marks={marks}
                                  defaultValue={0}
                                  value={FRMData.location}
                                  min={0}
                                  max={10}
                                  step={1}
                                />
                              </div>
                            </div>
                            {/* ./form-group */}
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                Types Of Business
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="px-4">
                                <CustomSlider
                                  valueLabelDisplay="auto"
                                  aria-label="pretto slider"
                                  onChange={handelChange}
                                  name="typeOfBusiness"
                                  marks={marks}
                                  defaultValue={0}
                                  value={FRMData.typeOfBusiness}
                                  min={0}
                                  max={10}
                                  step={1}
                                />
                              </div>
                            </div>
                            {/* ./form-group */}
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                Website Analysis
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="px-4">
                                <CustomSlider
                                  valueLabelDisplay="auto"
                                  aria-label="pretto slider"
                                  onChange={handelChange}
                                  name="websiteAnalysis"
                                  marks={marks}
                                  defaultValue={0}
                                  value={FRMData.websiteAnalysis}
                                  min={0}
                                  max={10}
                                  step={1}
                                />
                              </div>
                            </div>
                           
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                Application Analysis
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="px-4">
                                <CustomSlider
                                  valueLabelDisplay="auto"
                                  aria-label="pretto slider"
                                  onChange={handelChange}
                                  name="appAnalysis"
                                  marks={marks}
                                  defaultValue={0}
                                  value={FRMData.appAnalysis}
                                  min={0}
                                  max={10}
                                  step={1}
                                />
                              </div>
                            </div>
                            {/* ./form-group */}
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                Director's Profile
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="px-4">
                                <CustomSlider
                                  valueLabelDisplay="auto"
                                  aria-label="pretto slider"
                                  onChange={handelChange}
                                  name="directorProfile"
                                  marks={marks}
                                  defaultValue={0}
                                  value={FRMData.directorProfile}
                                  min={0}
                                  max={10}
                                  step={1}
                                />
                              </div>
                            </div>
                            {/* ./form-group */}
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                Company's Profile
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="px-4">
                                <CustomSlider
                                  valueLabelDisplay="auto"
                                  aria-label="pretto slider"
                                  onChange={handelChange}
                                  name="companyProfile"
                                  marks={marks}
                                  defaultValue={0}
                                  value={FRMData.companyProfile}
                                  min={0}
                                  max={10}
                                  step={1}
                                />
                              </div>
                            </div>
                           
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                Business Profile
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="px-4">
                                <CustomSlider
                                  valueLabelDisplay="auto"
                                  aria-label="pretto slider"
                                  onChange={handelChange}
                                  name="businessProfile"
                                  marks={marks}
                                  defaultValue={0}
                                  value={FRMData.businessProfile}
                                  min={0}
                                  max={10}
                                  step={1}
                                />
                              </div>
                            </div>
                            {/* ./form-group */}
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                Chargeback Probability
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="px-4">
                                <CustomSlider
                                  valueLabelDisplay="auto"
                                  aria-label="pretto slider"
                                  onChange={handelChange}
                                  name="chargebackProbability"
                                  marks={marks}
                                  defaultValue={0}
                                  value={FRMData.chargebackProbability}
                                  min={0}
                                  max={10}
                                  step={1}
                                />
                              </div>
                            </div>
                            {/* ./form-group */}
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                Bank Approval Probability
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="px-4">
                                <CustomSlider
                                  valueLabelDisplay="auto"
                                  aria-label="pretto slider"
                                  onChange={handelChange}
                                  name="bankApprovalProbability"
                                  marks={marks}
                                  defaultValue={0}
                                  value={FRMData.bankApprovalProbability}
                                  min={0}
                                  max={10}
                                  step={1}
                                />
                              </div>
                            </div>
                            {/* ./form-group */}
                          </Col>
                        </Row>
                        
                        <Card.Title className="h3 mt-5 d-flex flex-start text-primary">
                          <h4>Product Based</h4>
                        </Card.Title>
                        <p
                          className="bg-primary w-100"
                          style={{ height: "1px" }}
                        ></p>
                        <Row className="mt-3 w-100">
                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label  ">
                                Ticket Size
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="px-4">
                                <CustomSlider
                                  valueLabelDisplay="auto"
                                  aria-label="pretto slider"
                                  onChange={handelChange}
                                  name="ticketSize"
                                  marks={marks}
                                  defaultValue={0}
                                  value={FRMData.ticketSize}
                                  min={0}
                                  max={10}
                                  step={1}
                                />
                              </div>
                            </div>
                            {/* ./form-group */}
                          </Col>
                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                Volume
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="px-4">
                                <CustomSlider
                                  valueLabelDisplay="auto"
                                  aria-label="pretto slider"
                                  onChange={handelChange}
                                  name="volume"
                                  marks={marks}
                                  defaultValue={0}
                                  value={FRMData.volume}
                                  min={0}
                                  max={10}
                                  step={1}
                                />
                              </div>
                            </div>
                            {/* ./form-group */}
                          </Col>
                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                Number Of Tickets
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="px-4">
                                <CustomSlider
                                  valueLabelDisplay="auto"
                                  aria-label="pretto slider"
                                  onChange={handelChange}
                                  name="numberOfTickets"
                                  marks={marks}
                                  defaultValue={0}
                                  value={FRMData.numberOfTickets}
                                  min={0}
                                  max={10}
                                  step={1}
                                />
                              </div>
                            </div>
                            {/* ./form-group */}
                          </Col>
                        </Row>
                      </form>
                      <Col xs={12} lg={12} md={12}>
                        <div className="d-flex justify-content-end">
                          <div className="form-group  tool-bar-btn mb-0 mt-3">
                            <button
                              className="submit-button submit_link_info btn btn-primary"
                              onClick={handelFRMSubmit}
                              id="submitDetailsBtn"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </Card.Body>
                </Card>
                {/* Tab content end here */}
              </Col>
              {/* ./col */}
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default FRManagement;