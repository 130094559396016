import React, { useEffect, useState } from "react";
import axios from "axios";
import "../assets/css/style.css";
import Papa from "papaparse";
import { Calendar } from "react-calendar";
import { MDBDataTable } from "mdbreact";
import { FiDownload } from "react-icons/fi";
import { SWIPELINC_API } from "../utils/constant";

function PayInReport() {
  const [reportData, setReportData] = useState([]);

  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [filterValue, setFilterValue] = useState("");
  const [resetFilters, setResetFilters] = useState(false);
  //  const [isAdvancedFilterOpen, setIsAdvancedFilterOpen] = useState(false);
  const [isAdvancedFilterModalOpen, setIsAdvancedFilterModalOpen] =
    useState(false);
  const [filterButtonPosition, setFilterButtonPosition] = useState({
    top: 0,
    left: 0,
  });
  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    fetchPayInReport();
  }, []);

  const fetchPayInReport = async () => {
    try {
      const response = await axios.get(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getAllReport`,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);

      if (Array.isArray(response.data.responseData)) {
        setReportData(response.data.responseData);
        setFilteredData(response.data.responseData);
      } else {
        console.error("Response data is not an array:", response.data);
      }
    } catch (error) {
      console.error("Error fetching pay-in report:", error);
    }
  };
  const handleSearch = (searchTerm) => {
    const trimmedSearchTerm = searchTerm.trim();

    const filtered = reportData.filter((item) =>
      Object.values(item).some((value) => {
        if (value === null) {
          return false; // Skip null values
        }
        return value
          .toString()
          .toLowerCase()
          .includes(trimmedSearchTerm.toLowerCase());
      })
    );
    setFilteredData(filtered);
    setCurrentPage(1);
  };
  // LOGIC TO ADD DATE FILTER:
  const fetchDateReport = async () => {
    function formatDateToYYYYMMDD(inputDate) {
      const date = new Date(inputDate);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    }
    const sdate = formatDateToYYYYMMDD(startDate);

    const ldate = formatDateToYYYYMMDD(endDate);
    console.log(sdate + "date is");
    const payload = {
      amount: "",
      bankLocation: "",
      custIpAddress: "",
      custLocation: "",
      custRefNo: "",
      email: "",
      endDate: endDate,
      ifsc: "",
      mid: "",
      mobile: "",
      orderNo: "",
      paymentType: "",
      startDate: sdate,
      terminalId: "",
      txnDate: "",
      txnId: "",
      userVpa: "",
    };

    try {
      console.log(sdate.toString + "date is");
      const response = await axios.post(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/reports/getreportByCondition`,
        payload,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      );

      if (
        response.data.statusCode === 200 &&
        Array.isArray(response.data.responseData)
      ) {
        setReportData(response.data.responseData);
        setFilteredData(response.data.responseData);
      } else {
        console.error("No data found:", response.data.responseData);
      }
    } catch (error) {
      console.error("Error fetching pay-in report:", error);
    }
  };
  // LOGIC FOR ADVANCED FILTER :
  const handleAdvancedFilter = (filterType, filterValue) => {
    const trimmedFilterValue = filterValue.trim();

    const filtered = reportData.filter((item) => {
      if (!trimmedFilterValue) {
        return true;
      }

      if (
        filterType === "txn_id" ||
        filterType === "orderNo" ||
        filterType === "email" ||
        filterType === "mobileNo"
      ) {
        const value = item[filterType];
        return (
          value &&
          value.toLowerCase().includes(trimmedFilterValue.toLowerCase())
        );
      }

      return true;
    });

    setFilteredData(filtered);
    setCurrentPage(1);
  };

  const handleResetFilters = () => {
    setResetFilters(true);
    setFilteredData(reportData);
    setCurrentPage(1);
    setSelectedFilter(null);
    setFilterValue("");
  };

  const toggleAdvancedFilterModal = () => {
    const buttonRect = document
      .getElementById("advanced-filter-button")
      .getBoundingClientRect();
    setFilterButtonPosition({
      top: buttonRect.bottom + window.scrollY,
      left: buttonRect.left + window.scrollX,
    });
    setIsAdvancedFilterModalOpen(!isAdvancedFilterModalOpen);
  };

  const columns = [
    { label: "MID", field: "mid", width: "auto" },
    { label: "SID", field: "sid", width: "auto" },
    { label: "Company Name", field: "companyName", width: "auto" },
    { label: "Payment Type", field: "paymentType", width: "auto" },
    { label: "Transaction Date", field: "txnDate", width: "auto" },
    { label: "Transaction Time", field: "txnTime", width: "auto" },
    { label: "Terminal ID", field: "terminalId", width: "auto" },
    { label: "Transaction ID", field: "txn_id", width: "auto" },
    { label: "Amount", field: "amount", width: "auto" },
    { label: "Currency", field: "currency", width: "auto" },
    { label: "Transaction Status", field: "txnStatus", width: "auto" },
    { label: "Response Date", field: "responseDate", width: "auto" },
    { label: "UTR Number", field: "custRefNo", width: "auto" },
    { label: "Order Number", field: "orderNo", width: "auto" },
    { label: "Payer VPA", field: "userVpa", width: "auto" },
    { label: "Callback Response", field: "callbackRespJson", width: "auto" },
    { label: "Mobile Number", field: "mobileNo", width: "auto" },
    { label: "e-Mail", field: "email", width: "auto" },
    { label: "Customer IP Address", field: "customerIp", width: "auto" },
    { label: "Customer Location", field: "customerLocation", width: "auto" },
    { label: "Remark", field: "remark", width: "auto" },
    { label: "Customer IFSC", field: "customerIfsc", width: "auto" },
    { label: "Bank Location", field: "bankLocation", width: "auto" },
  ];

  const mappedData = filteredData.map((item) => {
    const mappedItem = {};
    columns.forEach((column) => {
      mappedItem[column.field] = item[column.field];
    });
    return mappedItem;
  });
  // HANDLE DOWNLOAD CSV:

  const handleDownloadCSV = () => {
    const table = document.getElementById("payin-datatable-container");
    const visibleRows = table.querySelectorAll(".dataTable tbody tr");

    const visibleData = [];

    visibleRows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.textContent.trim());
      });
      visibleData.push(rowData);
    });

    const headerLabels = columns.map((column) => column.label);
    const dataWithHeader = [headerLabels, ...visibleData];

    const csv = Papa.unparse(dataWithHeader);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "payin_report.csv";
    link.style.display = "none";

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  return (
    <>
      <main className="">
        <div className="reportSection">
          <div className="container">
            <div className="tabs-content active" id="tab1">
              <div className="card py-3">
                <div className="search-container">
                  {/* <div className="search-box">
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                      handleSearch(e.target.value);
                    }}
                  />
                  </div> */}
                  {/* Date filter: */}

                  <div className="filter-container">
                    <div className="date-filter">
                      <button
                        className={`date-filter-button ${
                          isDateFilterOpen ? "active" : ""
                        }`}
                        onClick={() => setIsDateFilterOpen(!isDateFilterOpen)}
                      >
                        Date Filter
                      </button>
                      {isDateFilterOpen && (
                        <div className="date-filter-modal">
                          <div className="date-filter-content">
                            <Calendar
                              onChange={(date) => setStartDate(date)}
                              value={startDate}
                              selectRange={false}
                            />
                            <Calendar
                              onChange={(date) => setEndDate(date)}
                              value={endDate}
                              selectRange={false}
                            />
                            <button
                              className="advanced-filterbutton"
                              onClick={() => {
                                fetchDateReport();
                                setIsDateFilterOpen(false);
                              }}
                            >
                              OK
                            </button>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* ADVANCED FILTER: */}
                    <div className="advanced-filter">
                      <button
                        id="advanced-filter-button"
                        className="advanced-filterbutton"
                        onClick={toggleAdvancedFilterModal}
                      >
                        {" "}
                        Advanced Filter
                      </button>
                      {isAdvancedFilterModalOpen && (
                        <div
                          className="advanced-filter-modal"
                          style={{
                            top: filterButtonPosition.top,
                            left: filterButtonPosition.left,
                          }}
                        >
                          <select
                            value={selectedFilter}
                            onChange={(e) => setSelectedFilter(e.target.value)}
                          >
                            <option value="">Select</option>
                            <option value="txn_id">Transaction ID</option>
                            <option value="orderNo">Order Number</option>
                            <option value="email">Email</option>
                            <option value="mobileNo">Mobile</option>
                          </select>

                          {selectedFilter && (
                            <input
                              type="text"
                              placeholder={`Enter ${selectedFilter}`}
                              value={filterValue}
                              onChange={(e) => setFilterValue(e.target.value)}
                            />
                          )}
                          <div className="advreset">
                            <button
                              className="advanced-filterbutton"
                              onClick={handleResetFilters}
                            >
                              Reset{" "}
                            </button>
                            <button
                              className="advanced-filterbutton"
                              onClick={() =>
                                handleAdvancedFilter(
                                  selectedFilter,
                                  filterValue
                                )
                              }
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* SORTING TABLE: */}
                <div id="payin-datatable-container">
                  <MDBDataTable
                    className=" dataTable overflow-x-scroll"
                    striped
                    bordered
                    hover
                    responsive
                    data={{
                      columns: columns,
                      rows: mappedData,
                    }}
                    searching={true}
                    entries={50}
                    exportToCSV
                    theadColor="#333"
                    paginationLabel={["Previous", "Next"]}
                    sortable={true}
                    footer="none"
                  />

                  <button
                    className="advanced-btn btn btn-primary text-capitlize"
                    onClick={handleDownloadCSV}
                  >
                    <FiDownload className="d-none" />
                    <i className="bi bi-arrow-down-circle"></i> Download CSV
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default PayInReport;
