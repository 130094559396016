import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import swal from "sweetalert";
import { SAVE_MERCHANT_PAYOUT_MASTER, SWIPELINC_API } from "../utils/constant";
import {
  fetchAllMerchantMaster,
  fetchAllVehicleMasterForm,
  getMechantForPayoutApiTest,
  getVehicleForMastersForms,
} from "../utils/ApiServices";

const PayoutMaster = ({ setShowLoader }) => {
  const [merchantData, setMerchantData] = useState([]);
  const [transferType, setTransferType] = useState("");
  const [isIfscError, setIsIfscError] = useState(false);
   const [mid, setMid] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [data, setData] = useState({});
  const [merchantMasterData, setMerchantMasterData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);

  const token = localStorage.getItem("token");

  const [formData, setFormData] = useState({
    mid: "",
    merchantName: "",
  });
  const [formDataInputs, setFormDataInputs] = useState({
    accountsModuleSecrete: "",

    clientIds: "",
    clientSecrete: "",
    ifsc: "",

    masterAliusId: "",
    masterVirtualAccount: "",
    mvaCustomerId: "",
    mvaMobileNumber: "",
    password: "",
    paymentModuleSecrete: "",
    userName: "",
    providerSecrete: "",
  });

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    setSelectedCompany(selectedCompany);

    const selectedMerchant = merchantData.find(
      (merchant) => merchant.merchantName === selectedCompany
    );

    if (selectedMerchant) {
      setMid(selectedMerchant.mid);
      setFormData({
        mid: selectedMerchant.mid || "",
        merchantName: selectedMerchant.merchantName || "",
      });
    } else {
      setMid("");
      setFormData({
        mid: "",
        merchantName: "",
      });
    }
  };

  function stopSpecialCharacterInput(input) {
    // Define a regular expression for alphanumeric characters
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;

    // Test the input against the regular expression
    return alphanumericRegex.test(input);
  }

  function setNumericInput(input) {
    // Define a regular expression for numeric characters
    const numericRegex = /^[0-9]+$/;

    // Test the input against the regular expression
    return numericRegex.test(input) ? input : "";
  }

  function setValidIFSCCode(input) {
    // Define a regular expression for a valid IFSC code
    const ifscRegex = /^[A-Z]{4}[0][A-Z0-9]{6}$/;

    // Test the input against the regular expression
    return ifscRegex.test(input) ? input : "";
  }

  const HandleIFSC = (e) => {
    if (e.target.name === "ifsc") {
      const ifscRegex = new RegExp(/^[a-zA-Z]{4}0[A-Z0-9]{6}$/);
      const isValidIfsc = ifscRegex.test(e.target.value);
      if (!isValidIfsc) {
        setIsIfscError(true);
        document.getElementById("ifscErr").classList.remove("d-none");

        return;
      } else {
        setIsIfscError(false);
        document.getElementById("ifscErr").classList.add("d-none");
      }
      setIsIfscError(false);
    }
  };

  const handleformDataInputs = (e) => {
    console.log(e.target.name, e.target.value);

    if (!e.target.value.length) {
      setFormDataInputs({
        ...formDataInputs,
        [e.target.name]: e.target.value,
      });
    }

    if (
      e.target.name === "accountsModuleSecrete" ||
      e.target.name === "paymentModuleSecrete" ||
      e.target.name === "masterVirtualAccount" ||
      e.target.name === "providerSecrete" ||
      e.target.name === "ifsc" ||
      e.target.name === "clientSecrete"
    ) {
      if (stopSpecialCharacterInput(e.target.value)) {
        console.log(stopSpecialCharacterInput(""));
        setFormDataInputs({
          ...formDataInputs,
          [e.target.name]: e.target.value,
        });
      } else {
        return;
      }
    } else if (
      e.target.name === "mvaMobileNumber" ||
      e.target.name === "mvaCustomerId"
    ) {
      if (setNumericInput(e.target.value)) {
        setFormDataInputs({
          ...formDataInputs,
          [e.target.name]: e.target.value === "" ? "" : e.target.value,
        });
      } else {
        return;
      }
    } else {
      setFormDataInputs({
        ...formDataInputs,
        [e.target.name]: e.target.value === "" ? "" : e.target.value,
      });
    }
  };
  useEffect(() => {
    const checkDuplicateOnTransferTypeChange = async () => {
      if (mid && transferType) {
        const isDuplicate = await checkDuplicateEntry();
        if (isDuplicate) {
          swal({
            title: "Duplicate Entry!",
            text: "Merchant is already associated with this transfer type",
            icon: "warning",
          });
        }
      }
    };

    checkDuplicateOnTransferTypeChange();
  }, [mid, transferType]);

  const checkDuplicateEntry = async () => {
    try {
      const response = await axios.post(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/merchantPayoutMaster/getMerchantPayoutMasterWithOneTransferType`,
        {
          mid: mid,
          transerType: transferType.toUpperCase(),
        },
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      );
      console.log("Response from checkDuplicateEntry:", response.data);
      if (
        response.data.statusCode === 200 &&
        response.data.status ===
        "Merchant is already associated with this transfer type"
      ) {
        console.log("Duplicate entry detected");
        swal({
          title: "Duplicate Entry!",
          text: response.data.status,
          icon: "warning",
        });
        return true;
      } else if (
        response.data.statusCode === 200 &&
        response.data.status ===
        "Merchant is not associated with this transfer type"
      ) {
        return false;
      } else {
        swal({
          title: "Not Associated!",
          text: response.data.status,
          icon: "info",
        });
      }
    } catch (error) {
      console.error("Error checking entry:", error);
    }
    console.log("Default case - No matching condition found");
    return false;
  };

  const handelDataChange = (e) => {
    if (e.target.name === "vehicle") {
      const selectedOption = e.target.options[e.target.selectedIndex];
      const master = selectedOption.getAttribute("data-master");
      const masterId = selectedOption.getAttribute("data-masterId");
      console.log(masterId);
      if (e.target.value === "") {
        setData({
          ...data,
          [e.target.name]: "",
          merchantMasterName: "",
          merchantMasterId: "",
        });
        return
      }
      setData({
        ...data,
        [e.target.name]: e.target.value,
        merchantMasterName: master,
        merchantMasterId: masterId,
      });
      console.log(data);
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }

    console.log(data);
  };

  const handleSubmit = async (e) => {
    const isDuplicateEntry = await checkDuplicateEntry();

    if (isDuplicateEntry) {
      console.log("Duplicate entry found. Not saving the payout.");
      return;
    } else {
      if (formData.merchantName === "") {
        swal({
          title: "Alert!",
          text: "Please Select Merchant",
          icon: "error",
        });
        return;
      }

      if (isIfscError) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid IFSC Code",
          icon: "error",
        });
        return;
      }

      if (transferType === "") {
        swal({
          title: "Alert!",
          text: "Please Select Transfer Type",
          icon: "error",
        });
        return;
      }

      if (formDataInputs.accountsModuleSecrete === "") {
        swal({
          title: "Alert!",
          text: "Please Enter Accounts Module Secret",
          icon: "error",
        });
        return;
      }
      if (formDataInputs.clientIds === "") {
        swal({
          title: "Alert!",
          text: "Please Enter Client Id",
          icon: "error",
        });
        return;
      }
      if (formDataInputs.clientSecrete === "") {
        swal({
          title: "Alert!",
          text: "Please Enter Client Secret",
          icon: "error",
        });
        return;
      }
      if (formDataInputs.ifsc === "") {
        swal({
          title: "Alert!",
          text: "Please Enter IFSC Code",
          icon: "error",
        });
        return;
      }
      if (formDataInputs.mvaCustomerId === "") {
        swal({
          title: "Alert!",
          text: "Please Enter MVA Customer Id",
          icon: "error",
        });
        return;
      }
      if (formDataInputs.masterAliusId === "") {
        swal({
          title: "Alert!",
          text: "Please Enter Master Alius Id",
          icon: "error",
        });
        return;
      }
      if (formDataInputs.masterVirtualAccount === "") {
        swal({
          title: "Alert!",
          text: "Please Enter Master Virtual Account",
          icon: "error",
        });
        return;
      }

      if (formDataInputs.userName === "") {
        swal({
          title: "Alert!",
          text: "Please Enter  User Name",
          icon: "error",
        });
        return;
      }

      if (formDataInputs.providerSecrete === "") {
        swal({
          title: "Alert!",
          text: "Please Enter Provider Secret",
          icon: "error",
        });
        return;
      }
      if (formDataInputs.paymentModuleSecrete === "") {
        swal({
          title: "Alert!",
          text: "Please Enter Payment Module Secret",
          icon: "error",
        });
        return;
      }

      if (formDataInputs.password === "") {
        swal({
          title: "Alert!",
          text: "Please Enter Password",
          icon: "error",
        });
        return;
      }
      if (formDataInputs.mvaMobileNumber === "") {
        swal({
          title: "Alert!",
          text: "Please Enter MVA Mobile Number",
          icon: "error",
        });
        return;
      }

      if (data.vehicle === "") {
        swal({
          title: "Alert!",
          text: "Please Select Vehicle Master",
          icon: "error",
        });
        return;
      }

 const payload = {
        ...formDataInputs,
        mid,
        transferType,
        vehichleMasterId: data.vehicle,
        masterMerchantId: data.merchantMasterId,
      }


      console.log(payload)

      e.preventDefault();
      const swalConfirm = await swal({
        title: "Are you sure?",
        text: "Do you want to submit the application?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });
      if (swalConfirm) {
        setShowLoader(true);
        axios
          .post(
            SAVE_MERCHANT_PAYOUT_MASTER,
            payload,
            {
              headers: {
                Authentication: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            console.log(response);

            if (response.data.statusCode === 200) {
              swal({
                title: "Success!",
                text: response.data.message,
                icon: "success",
              });
              setFormData({
                mid: "",
                merchantName: "",
              });
              setFormDataInputs({
                accountsModuleSecrete: "",
                clientIds: "",
                clientSecrete: "",
                ifsc: "",
                masterAliusId: "",
                masterVirtualAccount: "",
                mvaCustomerId: "",
                mvaMobileNumber: "",
                password: "",
                paymentModuleSecrete: "",
                userName: "",
                providerSecrete: "",
                
              });
              setData({
                merchantMaster:"",
                vehicle:"",
                
                merchantMasterName: "",
                merchantMasterId: "",
              });

              setSelectedCompany("");
            } else {
              swal({
                title: "Alert!",
                text: response.data.message,
                icon: "error",
              });
            }
            setShowLoader(false);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            setShowLoader(false);
          });
      }
    }
  };

  const getAllMerchantForApiTest = async () => {
    setShowLoader(true);
    let res = await getMechantForPayoutApiTest(token);
    setShowLoader(false);
    setMerchantData(res?.data);
  };

  useState(() => {
    getAllMerchantForApiTest();
  }, []);

  useEffect(() => {
    console.log(transferType);
  }, [transferType]);

  const getAllVehicle = async () => {
    try {
      const payload={
        serviceType:"PAYOUT"
      }
      const response = await getVehicleForMastersForms(payload,token); // Add the await keyword here

      if (response.statusCode === 200) {
        setVehicleData(response?.data);
      } else {
        setVehicleData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllMerchantMaster = async () => {
    try {
      const response = await fetchAllMerchantMaster(token); // Add the await keyword here
      if (response?.statusCode === 200) {
        setMerchantMasterData(response?.data);
      } else {
        setMerchantMasterData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllVehicle();
    getAllMerchantMaster();
  }, []);

 

  return (
    <Card className="shadow border-0 mb-4">
      <Card.Body>
        <div className="merchant-report-view">
          <div className="report-table-view">
            <div className="containerflag">
              <div className="row mt-4">
                <Row className="d-flex justify-content-center">
                  <Col xs={16} lg={8} md={12}>
                    <div>
                      <label htmlFor="email">Select A Merchant</label>
                      <select
                        id="companyDropdown"
                        className="form-control"
                        onChange={handleCompanyChange}
                        value={selectedCompany}
                      >
                        <option value="">Select a Merchant</option>
                        {merchantData?.map((merchant) => (
                          <option
                            key={merchant.mid}
                            value={merchant.merchantName}
                          >
                            {merchant.merchantName}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <div>
                        <div className="form-group mt-2">
                          <label htmlFor="email">Merchant MID</label>
                          <input
                            type="text"
                            className="form-control"
                            id="email"
                            placeholder="Company Name"
                            value={formData.mid}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                  <div className="w-50 d-flex justify-content-evenly gap-5 mt-5   ">
                    <div
                      className={`${transferType === "imps"
                          ? "rounded-5 text-light bg-dark "
                          : ""
                        }d-flex justify-content-evenly align-items-center gap-3 p-3 py-2`}
                    >
                      <input
                        type="radio"
                        className="form-check-input mb-1 "
                        name="bankDetails"
                        checked={transferType === "imps" ? true : false}
                        onChange={() => setTransferType("imps")}
                      />
                      <label htmlFor="detailsRadio">
                        <span> &nbsp;IMPS </span>
                      </label>
                    </div>
                    <div
                      className={`${transferType === "upi"
                          ? "rounded-5 text-light bg-dark "
                          : ""
                        }d-flex justify-content-evenly align-items-center gap-3 p-3 py-2`}
                    >
                      <input
                        type="radio"
                        className="form-check-input mb-1 "
                        name="bankDetails"
                        checked={transferType === "upi" ? true : false}
                        onChange={() => setTransferType("upi")}
                      />
                      <label htmlFor="detailsRadio">
                        <span> &nbsp;UPI </span>
                      </label>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
            <div>
              <Row className="px-4">
                <Col xs={12} lg={4} md={6} className="mt-5">
                  <div className="form-group">
                    <label htmlFor="email">Select A Vehicle</label>
                    <select
                      id="companyDropdown"
                      className="form-control"
                      onChange={handelDataChange}
                      name="vehicle"
                      value={data.vehicle}
                    >
                      <option value="">Select a Vehicle</option>
                      {vehicleData?.map((vehicle) => (
                        <option
                          key={vehicle.id}
                          data-master={vehicle.masterMerchantName}
                          data-masterId={vehicle.masterMerchantId}
                          value={vehicle.id}
                        >
                          {vehicle.vehicleName}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col xs={12} lg={4} md={6} className="mt-5">

                  <div className="form-group">
                    <label htmlFor="email">Select Master Merchant</label>
                    <input
                      id="companyDropdown"
                      className="form-control"
                      onChange={handelDataChange}
                      readOnly
                      name="merchantMaster"
                      value={data.merchantMasterName}
                    />
                  </div>

                </Col>
                <Col xs={12} lg={4} md={6} className="mt-5">
                  <div className="form-group">
                    <label className="form-label">
                      Account Module Secret
                      <sup className="text-danger">*</sup>
                    </label>
                    <div className="d-flex gap-2">
                      <input
                        className="form-control"
                        name="accountsModuleSecrete"
                        value={formDataInputs.accountsModuleSecrete}
                        onChange={handleformDataInputs}
                      />
                    </div>
                  </div>
                  {/* ./form-group */}
                </Col>

                <Col xs={12} lg={4} md={6} className="mt-5">
                  <div className="form-group">
                    <label className="form-label">
                      Client Id
                      <sup className="text-danger">*</sup>
                    </label>
                    <div className="d-flex gap-2">
                      <input
                        className="form-control"
                        name="clientIds"
                        value={formDataInputs.clientIds}
                        onChange={handleformDataInputs}
                      />
                    </div>
                  </div>
                  {/* ./form-group */}
                </Col>
                <Col xs={12} lg={4} md={6} className="mt-5">
                  <div className="form-group">
                    <label className="form-label">
                      Client Secret
                      <sup className="text-danger">*</sup>
                    </label>
                    <div className="d-flex gap-2">
                      <input
                        className="form-control"
                        name="clientSecrete"
                        value={formDataInputs.clientSecrete}
                        onChange={handleformDataInputs}
                      />
                    </div>
                  </div>
                  {/* ./form-group */}
                </Col>
                <Col xs={12} lg={4} md={6} className="mt-5">
                  <div className="form-group">
                    <label className="form-label">
                      IFSC
                      <sup className="text-danger">*</sup>
                    </label>
                    <div className="d-flex gap-2">
                      <input
                        className="form-control"
                        name="ifsc"
                        value={formDataInputs.ifsc}
                        onChange={handleformDataInputs}
                        onBlur={HandleIFSC}
                      />
                    </div>
                    <span id="ifscErr" className=" text-danger d-none">
                      *Please Enter Valid IFSC!{" "}
                    </span>
                  </div>
                </Col>

                <Col xs={12} lg={4} md={6} className="mt-5">
                  <div className="form-group">
                    <label className="form-label">
                      MVA Customer Id
                      <sup className="text-danger">*</sup>
                    </label>
                    <div className="d-flex gap-2">
                      <input
                        className="form-control"
                        name="mvaCustomerId"
                        value={formDataInputs.mvaCustomerId}
                        onChange={handleformDataInputs}
                      />
                    </div>
                  </div>
                  {/* ./form-group */}
                </Col>
                <Col xs={12} lg={4} md={6} className="mt-5">
                  <div className="form-group">
                    <label className="form-label">
                      Master Alius Id
                      <sup className="text-danger">*</sup>
                    </label>
                    <div className="d-flex gap-2">
                      <input
                        className="form-control"
                        name="masterAliusId"
                        value={formDataInputs.masterAliusId}
                        onChange={handleformDataInputs}
                      />
                    </div>
                  </div>
                  {/* ./form-group */}
                </Col>
                <Col xs={12} lg={4} md={6} className="mt-5">
                  <div className="form-group">
                    <label className="form-label">
                      Master Virtual Account
                      <sup className="text-danger">*</sup>
                    </label>
                    <div className="d-flex gap-2">
                      <input
                        className="form-control"
                        name="masterVirtualAccount"
                        value={formDataInputs.masterVirtualAccount}
                        onChange={handleformDataInputs}
                      />
                    </div>
                  </div>
                  {/* ./form-group */}
                </Col>

                <Col xs={12} lg={4} md={6} className="mt-5">
                  <div className="form-group">
                    <label className="form-label">
                      User Name
                      <sup className="text-danger">*</sup>
                    </label>
                    <div className="d-flex gap-2">
                      <input
                        className="form-control"
                        name="userName"
                        value={formDataInputs.userName}
                        onChange={handleformDataInputs}
                      />
                    </div>
                  </div>
                  {/* ./form-group */}
                </Col>
                <Col xs={12} lg={4} md={6} className="mt-5">
                  <div className="form-group">
                    <label className="form-label">
                      Provider Secret
                      <sup className="text-danger">*</sup>
                    </label>
                    <div className="d-flex gap-2">
                      <input
                        className="form-control"
                        name="providerSecrete"
                        value={formDataInputs.providerSecrete}
                        onChange={handleformDataInputs}
                      />
                    </div>
                  </div>
                  {/* ./form-group */}
                </Col>
                <Col xs={12} lg={4} md={6} className="mt-5">
                  <div className="form-group">
                    <label className="form-label">
                      Payment Module Secret
                      <sup className="text-danger">*</sup>
                    </label>
                    <div className="d-flex gap-2">
                      <input
                        className="form-control"
                        name="paymentModuleSecrete"
                        value={formDataInputs.paymentModuleSecrete}
                        onChange={handleformDataInputs}
                      />
                    </div>
                  </div>
                  {/* ./form-group */}
                </Col>
                <Col xs={12} lg={4} md={6} className="mt-5">
                  <div className="form-group">
                    <label className="form-label">
                      Password
                      <sup className="text-danger">*</sup>
                    </label>
                    <div className="d-flex gap-2">
                      <input
                        className="form-control"
                        name="password"
                        value={formDataInputs.password}
                        onChange={handleformDataInputs}
                      />
                    </div>
                  </div>
                  {/* ./form-group */}
                </Col>
                <Col xs={12} lg={4} md={6} className="mt-5">
                  <div className="form-group">
                    <label className="form-label">
                      MVA Mobile Number
                      <sup className="text-danger">*</sup>
                    </label>
                    <div className="d-flex gap-2">
                      <input
                        className="form-control"
                        maxLength={10}
                        minLength={10}
                        name="mvaMobileNumber"
                        value={formDataInputs.mvaMobileNumber}
                        onChange={handleformDataInputs}
                        pattern="[0-9]{10}"
                        title="Please enter a 10-digit mobile number"
                      />
                    </div>
                  </div>
                  {/* ./form-group */}
                </Col>
              </Row>
              <div className="d-flex justify-content-center mt-4">
                <button className="btn btn-primary" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PayoutMaster;
