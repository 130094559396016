import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import {
  getKeyPressed,
  isEmailIdRegexIsValid,
  isPanNumRegexIsValid,
  isWebsiteURLRegexIsValid,
  stopAlphabetInput,
  stopNumberCharInput,
  stopSpecialCharacterInput,
} from "../utils/helper";
import swal from "sweetalert";
import axios from "axios";
import { SWIPELINC_API } from "../utils/constant";
import styles from "./CreateMerchant.module.css";

import "../assets/css/CreateMerchant.css";
import { verifyMerchantEmailIsVerifiedOrNot, verifyMerchantMobileIsVerifiedOrNot } from "../utils/ApiServices";

const initialState = {
  email: "",
  mobNum: "",
  registrationTypeId: "",
  businessType: "",
  businessCategory: "",
  otherBusinessCategory: "",
  companyName: "",
  gstNumber: "",
  companyPanNumber: "",
  businessAs: "",
  website: "",
  appUrl: "",
  firstName: "",
  lastName: "",
  otp: "",
  panCardNumber: "",
  aadharNumber: "",
  // typeOfServices: "",
  isAadharNumberVerified: false,
  companyRegisteredAddress: "",
  companyRegisteredPincode: "",
  companyRegisteredState: "",
  companyRegisteredCity: "",
  companyRegisteredDistrict: "",
  isCommunicationAddSameAsRegistered: false,
  principleCommunicationAddress: "",
  principleCommunicationPincode: "",
  principleCommunicationState: "",
  principlCommunicationeCity: "",
  principlCommunicationeDistrict: "",
  isAuthorizedSignatoryAddSameAsComm: false,
  authorizedSignatoryAddress: "",
  authorizedSignatoryPincode: "",
  authorizedSignatoryState: "",
  authorizedSignatoryCity: "",
  authorizedSignatoryDistrict: "",
  typeOfServices: [],
  selectedPaymentTypeIds: [],
  fetchedRegisteredAddPinCodeValues: "",
  fetchedCommunicationAddPinCodeValues: "",
  fetchedAuthorizedAddPinCodeValues: "",
};

const CreateMerchantPhase1 = ({ setShowLoader }) => {
  const token = localStorage.getItem("token");
  // console.log(token);

  const [showComPanInput, setShowComPanInput] = useState(true);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [registrationType, setRegistrationTypes] = useState([]);
  const [businessCategories, setBusinessCategories] = useState([]);
  const [businessType, setBusinessTypes] = useState([]);
  const [pressedKeyName, setPressedKeyName] = useState("");
  const [fetchedRegisteredAddPinCodes, setFetchedRegisteredAddPinCodes] = useState([]);
  const [fetchedCommunicationAddPinCodes, setFetchedCommunicationAddPinCodes] = useState([]);
  const [fetchedAuthorizedAddPinCodes, setFetchedAuthorizedAddPinCodes] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [formData, setFormData] = useState(initialState);
  


  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (
      stopSpecialCharacterInput(pressedKeyName) &&
      (name === "businessAs" || name === "mobNum")
    ) {
      return;
    }

    // if (
    //   stopNumberCharInput(pressedKeyName) &&
    //   (name === "companyName")
    // ) {
    //   return;
    // }

    if (
      (stopSpecialCharacterInput(pressedKeyName) ||
        stopNumberCharInput(pressedKeyName)) &&
      name === "firstName"
    ) {
      return;
    }

    if (
      (stopSpecialCharacterInput(pressedKeyName) ||
        stopNumberCharInput(pressedKeyName)) &&
      name === "lastName"
    ) {
      return;
    }

    if (pressedKeyName === "Backspace" && name === "aadharNumber") {
      setFormData({ ...formData, [name]: value });
    }

    if (formData.aadharNumber?.length >= 12 && name === "aadharNumber") {
      return;
    }

    if (pressedKeyName === "Backspace" && name === "mobNum") {
      setFormData({ ...formData, [name]: value });
    }

    if (stopAlphabetInput(pressedKeyName) && name === "mobNum") {
      return;
    }

    if (formData.mobNum?.length >= 10 && name === "mobNum") {
      return;
    }

    if (pressedKeyName === "Backspace" && name === "otp") {
      setFormData({ ...formData, [name]: value });
    }

    if (formData.otp?.length >= 6 && name === "otp") {
      return;
    }

    setFormData({ ...formData, [name]: value?.toUpperCase() });
  };

  const verifyEmail = async (email) => {
    let emailRes = await verifyMerchantEmailIsVerifiedOrNot(email,token );
    console.log("tokennnnnnnn isssssss:",token)    
    if(emailRes.statusCode === 200) {
      swal({
        title: "Alert!",
        text: "Email Already registered*",
        icon: "error",
      });
      setFormData(initialState)
      return;
    }
  }

  const verifyMobile = async (mobile) => {
    let mobRes = await verifyMerchantMobileIsVerifiedOrNot(mobile);
    // console.log("mobRes", mobRes.statusCode);
    if(mobRes.statusCode === 200) {
      swal({
        title: "Alert!",
        text: "Mobile Number Already Registered !",
        icon: "error",
      });
      setFormData(initialState)
      return;
    }
  }

  const handelValidations = (e) => {

    if (e.target.name === "email" && e.target.value !== "") {
      if (!isEmailIdRegexIsValid(formData.email)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Merchant E-Mail ID !",
          icon: "error",
        }).then(() => {
          setFormData({ ...formData, email: "" });
        });
        return;
      } else {
        verifyEmail(e.target.value)
      }
    }

    if(e.target.name === "mobNum" && e.target.value !=="") {
      verifyMobile(e.target.value)
    }

    if (e.target.name === "companyPanNumber" && e.target.value !== "") {
      if (!isPanNumRegexIsValid(formData.companyPanNumber)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Company PAN Number !",
          icon: "error",
        }).then(() => {
          setFormData({ ...formData, companyPanNumber: "" });
        });
        return;
      }
    }

    if (e.target.name === "panCardNumber" && e.target.value !== "") {
      if (!isPanNumRegexIsValid(formData.panCardNumber)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid PAN Number !",
          icon: "error",
        }).then(() => {
          setFormData({ ...formData, panCardNumber: "" });
        });
        return;
      }
    }  
  };

  const fetchBusinessCategories = async () => {
    try {
      const response = await axios.get(
        `${SWIPELINC_API}MerchantPanel/merchant/business/getAllBusinessType`
      );
      const data = response?.data;

      setBusinessCategories(data.data);
    } catch (error) {
      console.log("Error fetching business categories:", error);
    }
  };

  const fetchRegistrationTypes = async () => {
    try {
      const response = await axios.get(
        `${SWIPELINC_API}MerchantPanel/merchant/registration/getAllRegistrationType`
      );
      const data = response?.data;

      setRegistrationTypes(data.data);
    } catch (error) {
      console.log("Error fetching business categories:", error);
    }
  };

  const handleBusinessCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    if (selectedCategory === "3") {
      setShowOtherInput(true);
    } else {
      setShowOtherInput(false);
    }
    handleChange(e);
  };

  const fetchBusinessTypes = async () => {
    try {
      const response = await axios.get(
        `${SWIPELINC_API}MerchantPanel/merchant/company/getAllCompanyType`
      );
      const data = response?.data;
      setBusinessTypes(data.data);
    } catch (error) {
      console.log("Error fetching business types:", error);
    }
  };

  useEffect(() => {
    fetchBusinessTypes();
    fetchBusinessCategories();
    fetchRegistrationTypes();
    fetchPaymentTypes();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.email === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Merchant E-Mail*",
        icon: "error",
      });
      return;
    }

    if (formData.mobNum === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Merchant Mobile Number*",
        icon: "error",
      });
      return;
    }

    if (formData.registrationTypeId === "") {
      swal({
        title: "Alert!",
        text: "Please Select Your Business Registration Type*",
        icon: "error",
      });
      return;
    }

    if (formData.businessType === "") {
      swal({
        title: "Alert!",
        text: "Please Select Your Business Type*",
        icon: "error",
      });
      return;
    }

    if (formData.businessCategory === "") {
      swal({
        title: "Alert!",
        text: " Please Select Your Business Category*",
        icon: "error",
      });
      return;
    }

    if (
      formData.businessCategory === "3" &&
      formData.otherBusinessCategory.trim() === ""
    ) {
      swal({
        title: "Alert!",
        text: "Please Fill Business Category*",
        icon: "error",
      });
      return;
    }

    if (formData.companyName.trim() === "") {
      swal({
        title: "Alert!",
        text: "Please Fill Company Name* ",
        icon: "error",
      });
      return;
    }

    if (formData.website.trim() !== "") {
      if (!isWebsiteURLRegexIsValid(formData.website)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Website URL*",
          icon: "error",
        });
        return;
      }
    }

    if (showComPanInput === true) {
      if (formData.companyPanNumber.trim() === "") {
        swal({
          title: "Alert!",
          text: "Please Fill Company PAN*",
          icon: "error",
        });
        return;
      }
    }

    if (formData.firstName.trim() === "") {
      swal({
        title: "Alert!",
        text: "Please Enter First Name*",
        icon: "error",
      });
      return;
    }

    if (formData.lastName.trim() === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Last Name*",
        icon: "error",
      });
      return;
    }

    if (formData.aadharNumber.trim() === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Aadhar Number*",
        icon: "error",
      });
      return;
    }

    if (formData.panCardNumber.trim() === "") {
      swal({
        title: "Alert!",
        text: " Please Enter PAN Number* ",
        icon: "error",
      });
      return;
    }

    if (!isEmailIdRegexIsValid(formData.email)) {
      swal({
        title: "Alert!",
        text: "Please Enter Valid Merchant E-Mail ID !",
        icon: "error",
      }).then(() => {
        setFormData({ ...formData, panCardNumber: "" });
      });
      return;
    }

    if (showComPanInput) {
      if (!isPanNumRegexIsValid(formData.companyPanNumber)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Company PAN Number !",
          icon: "error",
        }).then(() => {
          setFormData({ ...formData, companyPanNumber: "" });
        });
        return;
      }
    }

    if (!isPanNumRegexIsValid(formData.panCardNumber)) {
      swal({
        title: "Alert!",
        text: "Please Enter Valid PAN Number !",
        icon: "error",
      }).then(() => {
        setFormData({ ...formData, panCardNumber: "" });
      });
      return;
    }

    if (formData.companyRegisteredAddress === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Regisered Address*",
        icon: "error",
      });
      return;
    }

    if (formData.companyRegisteredPincode === "") {
      swal({
        title: "Alert!",
        text: " Please Enter Regisered Address Pincode *",
        icon: "error",
      });
      return;
    }

    if (formData.companyRegisteredDistrict === "") {
      swal({
        title: "Alert!",
        text: " Please Enter Locality*",
        icon: "error",
      });
      return;
    }

    if (formData.companyRegisteredState === "") {
      swal({
        title: "Alert!",
        text: "Please Enter State*",
        icon: "error",
      });
      return;
    }

    if (formData.companyRegisteredCity === "") {
      swal({
        title: "Alert!",
        text: "Please Enter City*",
        icon: "error",
      });
      return;
    }

    if (formData.principleCommunicationAddress === "") {
      swal({
        title: "Alert!",
        text: " Please Enter Communication Address*",
        icon: "error",
      });
      return;
    }

    if (formData.principleCommunicationPincode === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Regisered Address Pincode*",
        icon: "error",
      });
      return;
    }

    if (formData.principlCommunicationeDistrict === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Locality*",
        icon: "error",
      });
      return;
    }

    if (formData.principleCommunicationState === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Communication State*",
        icon: "error",
      });
      return;
    }

    if (formData.principlCommunicationeCity === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Communication City*",
        icon: "error",
      });
      return;
    }

    if (formData.authorizedSignatoryAddress === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Authorized Signatory Address*",
        icon: "error",
      });
      return;
    }

    if (formData.authorizedSignatoryPincode === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Authorized Signatory  PinCode*",
        icon: "error",
      });
      return;
    }

    if (formData.authorizedSignatoryDistrict === "") {
      swal({
        title: "Alert!",
        text: " Please Enter Authorized Signatory Locality*",
        icon: "error",
      });
      return;
    }

    if (formData.authorizedSignatoryState === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Authorized Signatory State*",
        icon: "error",
      });
      return;
    }

    if (formData.authorizedSignatoryCity === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Authorized Signatory City*",
        icon: "error",
      });
      return;
    }

    if (formData.companyRegisteredPincode.length > 6) {
      swal({
        title: "Alert!",
        text: "Company Registered Pincode must be lesser than 6 characters",
        icon: "error",
      });
      return;
    }

    //   checkLengthValidation();
    if (formData.typeOfServices.length === 0) {
      swal({
        title: "Alert!",
        text: "Please Choose Services You Want !",
        icon: "error",
      });
      return;
    }

    const payload = {
      aadhaarNumber: formData.aadharNumber,
      aapUrl: formData.appUrl,
      authorizedPersonFirstName: formData.firstName,
      authorizedPersonLastName: formData.lastName,
      authorizedPersonName: formData.firstName + " " + formData.lastName,
      authorizedPersonPan: formData.panCardNumber,
      businessAs: formData.businessAs,
      businessTypeId: formData.businessCategory,
      businessTypeName: formData.otherBusinessCategory,
      communicationAddress: formData.principleCommunicationAddress,
      communicationAddressCity: formData.principlCommunicationeCity,
      communicationAddressAreaName: formData.principlCommunicationeDistrict,
      communicationAddressPinCode: formData.principleCommunicationPincode,
      communicationAddressState: formData.principleCommunicationState,
      companyAddress: formData.companyRegisteredAddress,
      companyAddressCity: formData.companyRegisteredCity,
      companyAddressAreaName: formData.companyRegisteredDistrict,
      companyAddressPinCode: formData.companyRegisteredPincode,
      companyAddressState: formData.companyRegisteredState,
      companyName: formData.companyName,
      companyPanNumber: formData.companyPanNumber,
      companyTypeId: formData.businessType,
      email: formData.email,
      gstNumber: formData.gstNumber,
      mobile: formData.mobNum,
      newCompanyAddress: "string",
      registrationId: 0,
      paymentTypeId: formData.selectedPaymentTypeIds,
      referralNo: formData.referralNo,
      registeredOfficeAddress: formData.authorizedSignatoryAddress,
      registeredOfficeAddressCity: formData.authorizedSignatoryCity,
      registeredOfficeAddressPinCode: formData.authorizedSignatoryPincode,
      registeredOfficeAddressState: formData.authorizedSignatoryState,
      registeredOfficeAddressAreaName: formData.authorizedSignatoryDistrict,
      registrationTypeId: formData.registrationTypeId,
      websiteUrl: formData.website,
    };

    console.log("PAYLOAD-", payload);

    console.log(token);

    try {
      // Make the API request
      setShowLoader(true);
      const response = await axios.post(
        `${SWIPELINC_API}MerchantAdminPanel/merchant/onboard/save`,
        payload,
        {
          headers: {
            Authentication: "Bearer " + token,
          },
        }
      );
      setShowLoader(false);
      // console.log("API response:", response.data);
      if (response.data.statusCode === 200) {
        // Clear form values
        setFormData(initialState);
        swal({
          title: "Success!",
          text: "Your Application has been submitted Successfully !",
          icon: "success",
        });
      } else if (response.data.statusCode === 600) {
        setShowLoader(false);
        swal({
          title: "ALERT!",
          text: response?.data?.message,
          icon: "error",
        });
      } else {
        // alert("helo")
        setShowLoader(false);
        swal({
          title: "ALERT!",
          text: response?.data?.message,
          icon: "error",
        });
      }
    } catch (error) {
      // console.log("Error submitting form:", error);
      setShowLoader(false);
    }
  };

  const fetchPaymentTypes = async () => {
    try {
      const response = await axios.get(
        `${SWIPELINC_API}MerchantPanel/merchant/paymentType/getALLPaymentType`
      );
      const data = response?.data?.data;
      setPaymentTypes(data);
    } catch (error) {}
  };

  const handelPasteEvent = (event, name) => {
    let pastedText = (event.clipboardData || window.clipboardData).getData(
      "text"
    );

    const addressFieldLength = 200;
    const districtLength = 50;
    const StateLength = 50;
    const cityLength = 50;

    console.log(formData.companyRegisteredAddress?.length + pastedText?.length);

    if (
      (name === "companyRegisteredAddress" &&
        formData.companyRegisteredAddress?.length + pastedText?.length >
          addressFieldLength) ||
      (name === "authorizedSignatoryAddress" &&
        formData.authorizedSignatoryAddress?.length + pastedText?.length >
          addressFieldLength) ||
      (name === "principleCommunicationAddress" &&
        formData.principleCommunicationAddress?.length + pastedText?.length >
          addressFieldLength)
    ) {
      swal({
        title: "Alert!",
        text: `Address must be lesser than ${addressFieldLength} characters`,
        icon: "error",
      });
      return;
    }

    if (
      (name === "companyRegisteredDistrict" &&
        formData.companyRegisteredDistrict?.length + pastedText?.length >
          districtLength) ||
      (name === "principlCommunicationeDistrict" &&
        formData.principlCommunicationeDistrict?.length + pastedText?.length >
          districtLength) ||
      (name === "authorizedSignatoryDistrict" &&
        formData.authorizedSignatoryDistrict?.length + pastedText?.length >
          districtLength)
    ) {
      swal({
        title: "Alert!",
        text: `Locatity must be lesser than ${districtLength} characters`,
        icon: "error",
      });
      return;
    }

    if (
      (name === "companyRegisteredState" &&
        formData.companyRegisteredState?.length + pastedText?.length >
          StateLength) ||
      (name === "principleCommunicationState" &&
        formData.principleCommunicationState?.length + pastedText?.length >
          StateLength) ||
      (name === "authorizedSignatoryState" &&
        formData.authorizedSignatoryState?.length + pastedText?.length >
          StateLength)
    ) {
      swal({
        title: "Alert!",
        text: `State must be lesser than ${StateLength} characters`,
        icon: "error",
      });
      return;
    }

    if (
      (name === "companyRegisteredCity" && pastedText?.length > cityLength) ||
      (name === "principlCommunicationeCity" &&
        pastedText?.length > cityLength) ||
      (name === "authorizedSignatoryCity" && pastedText?.length > cityLength)
    ) {
      swal({
        title: "Alert!",
        text: `City must be lesser than ${cityLength} characters`,
        icon: "error",
      });
      return;
    }
  };

  const fetchCityStateByPincode = async (pincode, name) => {
    try {
      setShowLoader(true);
      const response = await axios.get(
        `${SWIPELINC_API}MerchantPanel/merchant/address/getCityStateByPinCode/${pincode}`
      );
      const data = response.data.data;
      setShowLoader(false);
      if (name === "companyRegisteredPincode") {
        setFetchedRegisteredAddPinCodes(data);
      } else if (name === "principleCommunicationPincode") {
        setFetchedCommunicationAddPinCodes(data);
      } else if (name === "authorizedSignatoryPincode") {
        setFetchedAuthorizedAddPinCodes(data);
      }
    } catch (error) {
      console.log("Error fetching city and state:", error);
    }
  };

  const handleInputChange = async (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;

    if (
      pressedKeyName === "Backspace" &&
      (name === "companyRegisteredAddress" ||
        name === "companyRegisteredPincode" ||
        name === "companyRegisteredDistrict" ||
        name === "companyRegisteredState" ||
        name === "companyRegisteredCity" ||
        name === "principleCommunicationAddress" ||
        name === "principleCommunicationPincode" ||
        name === "principlCommunicationeDistrict" ||
        name === "principleCommunicationState" ||
        name === "principlCommunicationeCity" ||
        name === "authorizedSignatoryAddress" ||
        name === "authorizedSignatoryPincode" ||
        name === "authorizedSignatoryDistrict" ||
        name === "authorizedSignatoryState" ||
        name === "authorizedSignatoryCity")
    ) {
      setFormData({ ...formData, [name]: value?.toUpperCase() });
    }

    // stop alphabets and special char input in pincode
    if (
      (stopAlphabetInput(pressedKeyName) ||
        stopSpecialCharacterInput(pressedKeyName)) &&
      (name === "companyRegisteredPincode" ||
        name === "principleCommunicationPincode" ||
        name === "authorizedSignatoryPincode")
    ) {
      return;
    }

    // stop special char input and number input and allow alphabets INPUT in
    // LOCALITY - STATE - CITY
    if (
      (stopNumberCharInput(pressedKeyName) ||
        stopSpecialCharacterInput(pressedKeyName)) &&
      (name === "companyRegisteredDistrict" ||
        name === "companyRegisteredState" ||
        name === "companyRegisteredCity" ||
        name === "principlCommunicationeDistrict" ||
        name === "principleCommunicationState" ||
        name === "principlCommunicationeCity" ||
        name === "authorizedSignatoryDistrict" ||
        name === "authorizedSignatoryState" ||
        name === "authorizedSignatoryCity")
    )
      return;

    const addressFieldLength = 200;
    const pincodeFieldLength = 6;
    const districtLength = 50;
    const StateLength = 50;
    const cityLength = 50;

    // handel VALIDATION ABOUT LENGTH CONSTRAINTS
    if (
      (formData.companyRegisteredAddress?.length >= addressFieldLength &&
        name === "companyRegisteredAddress") ||
      (formData.principleCommunicationAddress?.length >= addressFieldLength &&
        name === "principleCommunicationAddress") ||
      (formData.authorizedSignatoryAddress?.length >= addressFieldLength &&
        name === "authorizedSignatoryAddress") ||
      (formData.companyRegisteredPincode?.length >= pincodeFieldLength &&
        name === "companyRegisteredPincode") ||
      (formData.principleCommunicationPincode?.length >= pincodeFieldLength &&
        name === "principleCommunicationPincode") ||
      (formData.authorizedSignatoryPincode.length >= pincodeFieldLength &&
        name === "authorizedSignatoryPincode") ||
      (formData.companyRegisteredDistrict.length >= districtLength &&
        name === "companyRegisteredDistrict") ||
      (formData.principlCommunicationeDistrict.length >= districtLength &&
        name === "principlCommunicationeDistrict") ||
      (formData.authorizedSignatoryDistrict.length >= districtLength &&
        name === "authorizedSignatoryDistrict") ||
      (formData.companyRegisteredState.length >= StateLength &&
        name === "companyRegisteredState") ||
      (formData.principleCommunicationState?.length >= StateLength &&
        name === "principleCommunicationState") ||
      (formData.authorizedSignatoryState?.length >= StateLength &&
        name === "authorizedSignatoryState") ||
      (formData.companyRegisteredCity?.length >= cityLength &&
        name === "companyRegisteredCity") ||
      (formData.principlCommunicationeCity?.length >= cityLength &&
        name === "principlCommunicationeCity") ||
      (formData.authorizedSignatoryCity?.length >= cityLength &&
        name === "authorizedSignatoryCity")
    ) {
      return;
    }

    if (name === "typeOfServices") {
      // Get the selected payment type ID from the selected option
      const selectedPaymentType = paymentTypes.find(
        (paymentType) => paymentType.paymentservice === fieldValue
      );
    }

    // console.log(">>>>>>>>>>>>>>>",name,fieldValue);

    if(name ==="isCommunicationAddSameAsRegistered" || name === "isAuthorizedSignatoryAddSameAsComm" || name ==="hasReferralCode")
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: fieldValue,
    }));
    else
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: fieldValue?.toUpperCase(),
    }));


    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   [name]: fieldValue?.toUpperCase(),
    // }));

    if (name === "companyRegisteredPincode") {
      if (value?.length === 6) {
        fetchCityStateByPincode(value, name);
      }
    }

    if (name === "principleCommunicationPincode") {
      if (value?.length === 6) {
        fetchCityStateByPincode(value, name);
      }
    }

    if (name === "authorizedSignatoryPincode") {
      // alert("fetch 3 pin")
      if (value?.length === 6) {
        fetchCityStateByPincode(value, name);
      }
    }
    if (
      name === "fetchedCommunicationAddPinCodeValues" ||
      name === "fetchedRegisteredAddPinCodeValues" ||
      name === "fetchedAuthorizedAddPinCodeValues"
    ) {
      setFetchedPinCodesValue(e.target.value, name);
    }
  };

  const setFetchedPinCodesValue = (value, name) => {
    if (name === "fetchedRegisteredAddPinCodeValues") {
      let data = fetchedRegisteredAddPinCodes.filter(
        (elem) => elem.areaName === value
      );
      // console.log("data", data);
      if (data && data?.length > 0) {
        setFormData({
          ...formData,
          companyRegisteredState: data[0]?.stateName?.toUpperCase(),
          companyRegisteredCity: data[0]?.cityName?.toUpperCase(),
          companyRegisteredDistrict: data[0]?.areaName?.toUpperCase(),
          fetchedRegisteredAddPinCodeValues: value?.toUpperCase(),
        });
      } else {
        setFormData({
          ...formData,
          companyRegisteredState: "",
          companyRegisteredCity: "",
          companyRegisteredDistrict: "",
          fetchedRegisteredAddPinCodeValues: "",
        });
      }
    }

    if (name === "fetchedCommunicationAddPinCodeValues") {
      let data = fetchedCommunicationAddPinCodes.filter(
        (elem) => elem.areaName === value
      );
      if (data && data?.length > 0) {
        setFormData({
          ...formData,
          principleCommunicationState: data[0]?.stateName?.toUpperCase(),
          principlCommunicationeCity: data[0]?.cityName?.toUpperCase(),
          principlCommunicationeDistrict: data[0]?.areaName?.toUpperCase(),
          fetchedCommunicationAddPinCodeValues: value?.toUpperCase(),
        });
      } else {
        setFormData({
          ...formData,
          principleCommunicationState: "",
          principlCommunicationeCity: "",
          principlCommunicationeDistrict: "",
          fetchedCommunicationAddPinCodeValues: "",
        });
      }
    }

    if (name === "fetchedAuthorizedAddPinCodeValues") {
      let data = fetchedAuthorizedAddPinCodes.filter(
        (elem) => elem.areaName === value
      );

      console.log("data:", data);

      if (data && data?.length > 0) {
        setFormData({
          ...formData,
          authorizedSignatoryState: data[0]?.stateName?.toUpperCase(),
          authorizedSignatoryCity: data[0]?.cityName?.toUpperCase(),
          authorizedSignatoryDistrict: data[0]?.areaName?.toUpperCase(),
          fetchedAuthorizedAddPinCodeValues: value?.toUpperCase(),
        });
      } else {
        setFormData({
          ...formData,
          authorizedSignatoryState: "",
          authorizedSignatoryCity: "",
          authorizedSignatoryDistrict: "",
          fetchedAuthorizedAddPinCodeValues: "",
        });
      }
    }
  };

  const handelSameCommunicationAddress = () => {
    if (formData.isCommunicationAddSameAsRegistered === false) {
      setFormData({
        ...formData,
        principleCommunicationAddress: formData.companyRegisteredAddress,
        principleCommunicationPincode: formData.companyRegisteredPincode,
        principleCommunicationState: formData.companyRegisteredState,
        principlCommunicationeCity: formData.companyRegisteredCity,
        principlCommunicationeDistrict: formData.companyRegisteredDistrict,
      });
      setFetchedCommunicationAddPinCodes([]);
    }

    if (formData.isCommunicationAddSameAsRegistered === true) {
      setFormData({
        ...formData,
        principleCommunicationAddress: "",
        principleCommunicationPincode: "",
        principleCommunicationState: "",
        principlCommunicationeCity: "",
        principlCommunicationeDistrict: "",
      });
    }
  };

  const handelSameAuthorityAddress = () => {
    if (formData.isAuthorizedSignatoryAddSameAsComm === false) {
      setFormData({
        ...formData,
        authorizedSignatoryAddress: formData.principleCommunicationAddress,
        authorizedSignatoryPincode: formData.principleCommunicationPincode,
        authorizedSignatoryState: formData.principleCommunicationState,
        authorizedSignatoryCity: formData.principlCommunicationeCity,
        authorizedSignatoryDistrict: formData.principlCommunicationeDistrict,
      });
      setFetchedAuthorizedAddPinCodes([]);
    }

    if (formData.isAuthorizedSignatoryAddSameAsComm === true) {
      setFormData({
        ...formData,
        authorizedSignatoryAddress: "",
        authorizedSignatoryPincode: "",
        authorizedSignatoryState: "",
        authorizedSignatoryCity: "",
        authorizedSignatoryDistrict: "",
      });
    }
  };

  const handleTypeOfServicesChange = (event) => {
    const { value, checked } = event.target;
    let updatedTypeOfServices;

    if (checked) {
      // Add the selected value to the array
      updatedTypeOfServices = [...formData.typeOfServices, value];
    } else {
      // Remove the unselected value from the array
      updatedTypeOfServices = formData.typeOfServices.filter(
        (service) => service !== value
      );
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      typeOfServices: updatedTypeOfServices,
    }));

    const selectedPaymentTypeIds = paymentTypes?.filter((paymentType) =>
        updatedTypeOfServices?.includes(paymentType.paymentservice)
      )
      .map((selectedPaymentType) => selectedPaymentType.paymentTypeId);

    setFormData((prevFormData) => ({
      ...prevFormData,
      selectedPaymentTypeIds,
    }));
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="form-section-form-group">
        <section className="lincpay-dashboard-sec merchant-admin-info-form aadharform-sec">
          <div className="cart-view-sec mt-4">
            <Row>
              <Col xs={12} lg={12} md={12}>
                {/* Tab content start here */}
                <Card className="shadow-cst border-0 mb-3 chart-boxes merchant-info-form-sec">
                  <Card.Body>
                    <Card.Title className="h3">Company Details</Card.Title>
                    <div className="merchant-form-info">
                      <Row>
                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label htmlFor="email" className="form-label">
                              Merchant E-Mail
                              <sup className="text-danger">*</sup>{" "}
                            </label>
                            <input
                              type="email"
                              // id="companyName"
                              id="email"
                              name="email"
                              value={formData.email}
                              required
                              onChange={handleChange}
                              onKeyDown={(e) =>
                                setPressedKeyName(getKeyPressed(e))
                              }
                              onBlur={handelValidations}
                              className="form-control"
                            />
                          </div>
                        </Col>

                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label htmlFor="mobNum" className="form-label">
                              Merchant Mobile Number
                              <sup className="text-danger">*</sup>{" "}
                            </label>
                            <input
                              type="text"
                              // id="companyName"
                              id="mobNum"
                              name="mobNum"
                              value={formData.mobNum}
                              onChange={handleChange}
                              required
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              onBlur={handelValidations}
                              className="form-control"
                            />
                          </div>
                        </Col>

                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label
                              htmlFor="businessType"
                              className="form-label"
                            >
                              Registration Type
                              <sup className="text-danger">*</sup>
                            </label>
                            <select
                              id="merchantForm"
                              name="registrationTypeId"
                              value={formData.registrationTypeId}
                              onChange={handleChange}
                              className="form-control"
                              required
                            >
                              <option value="">
                                Select a Registration type*
                                {/* <span className="star">*</span> */}
                              </option>
                              {registrationType?.map((type) => (
                                <option
                                  key={type.registrationTypeId}
                                  value={type.registrationTypeId}
                                >
                                  {type.registrationType}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label
                              htmlFor="businessType"
                              className="form-label"
                            >
                              Business Type
                              <sup className="text-danger">*</sup>
                            </label>
                            <select
                              // id="businessType"
                              id="merchantForm"
                              name="businessType"
                              value={formData.businessType}
                              onChange={(e) => {
                                handleChange(e);
                                setShowComPanInput(
                                  e.target.value === "6" ? false : true
                                );
                              }}
                              className="form-control"
                              required
                            >
                              <option value="">
                                Select a business type*
                                {/* <span className="star">*</span> */}
                              </option>
                              {businessType?.map((type) => (
                                <option
                                  key={type.companyTypeId}
                                  value={type.companyTypeId}
                                >
                                  {type.companyType}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label
                              htmlFor="businessCategory"
                              className="form-label"
                            >
                              Business Category
                              <sup className="text-danger">*</sup>
                            </label>
                            <select
                              // id="businessCategory"
                              id="merchantForm"
                              name="businessCategory"
                              required
                              value={formData.businessCategory}
                              onChange={(e) => {
                                handleBusinessCategoryChange(e);
                              }}
                              className="form-control"
                            >
                              <option value="">
                                Select business categories*
                              </option>
                              {businessCategories?.map((category) => (
                                <option
                                  key={category.businessTypeId}
                                  value={category.businessTypeId}
                                >
                                  {category.businessType}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        {showOtherInput && (
                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label
                                htmlFor="otherBusinessCategory"
                                className="form-label"
                              >
                                Other Business Category
                                <sup className="text-danger">*</sup>{" "}
                              </label>
                              <input
                                type="text"
                                id="merchantForm"
                                className="form-control"
                                name="otherBusinessCategory"
                                value={formData.otherBusinessCategory}
                                onChange={handleChange}
                                required
                                // className="form-control"
                              />
                            </div>
                          </Col>
                        )}
                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label htmlFor="companyName" className="form-label">
                              Company Name
                              <sup className="text-danger">*</sup>{" "}
                            </label>
                            <input
                              type="text"
                              // id="companyName"
                              id="merchantForm"
                              name="companyName"
                              value={formData.companyName}
                              onChange={handleChange}
                              required
                              onKeyDown={(e) =>
                                setPressedKeyName(getKeyPressed(e))
                              }
                              className="form-control"
                            />

                            <span id="" className=" text-primary ">
                              *Note- The Company name must be similar to the
                              Bank Account Name
                            </span>
                          </div>
                        </Col>
                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label htmlFor="gstNumber" className="form-label">
                              GST Number
                            </label>
                            <div className="input-field-validate">
                              <input
                                type="text"
                                // id="gstNumber"
                                id="merchantForm2"
                                name="gstNumber"
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                }}
                                value={formData.gstNumber}
                                onChange={handleChange}
                                className="form-control"
                              />
                            </div>
                          </div>
                        </Col>
                        {!showComPanInput ? null : (
                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label
                                htmlFor="companyPanNumber"
                                className="form-label"
                              >
                                Company PAN Number
                                <sup className="text-danger">*</sup>
                              </label>
                              <div className="input-field-validate">
                                <input
                                  type="text"
                                  // id="companyPanNumber"
                                  id="merchantForm2"
                                  name="companyPanNumber"
                                  onKeyDown={(e) => {
                                    if (e.key === " ") {
                                      e.preventDefault();
                                    }
                                  }}
                                  value={formData.companyPanNumber}
                                  onChange={handleChange}
                                  onBlur={handelValidations}
                                  className="form-control"
                                  required
                                />
                              </div>
                            </div>
                          </Col>
                        )}

                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label htmlFor="businessAs" className="form-label">
                              Business As / Doing Business as :
                            </label>
                            <input
                              type="text"
                              // id="businessAs"
                              id="merchantForm2"
                              name="businessAs"
                              value={formData.businessAs}
                              onKeyDown={(e) => {
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              onChange={handleChange}
                              className="form-control"
                            />
                          </div>
                        </Col>

                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label htmlFor="website" className="form-label">
                              Website
                            </label>
                            <input
                              type="text"
                              // id="website"
                              id="merchantForm2"
                              onKeyDown={(e) => {
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              name="website"
                              value={formData.website}
                              onChange={handleChange}
                              className="form-control"
                            />
                          </div>
                        </Col>

                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label htmlFor="website" className="form-label">
                              App URL
                            </label>
                            <input
                              type="text"
                              // id="website"
                              id="merchantForm2"
                              onKeyDown={(e) => {
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              name="appUrl"
                              value={formData.appUrl}
                              onChange={handleChange}
                              className="form-control"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Card.Title className="h3 mt-3">
                        {" "}
                        Authorized Person Details
                      </Card.Title>
                      <Row>
                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label htmlFor="firstName" className="form-label">
                              First Name (As per Aadhaar)
                              <sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="text"
                              // id="firstName"
                              id="merchantForm"
                              onKeyDown={(e) => {
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              name="firstName"
                              value={formData.firstName}
                              onChange={handleChange}
                              className="form-control"
                              required
                            />
                          </div>
                          {/* ./form-group */}
                        </Col>
                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label htmlFor="lastName" className="form-label">
                              Last Name (As per Aadhaar)
                              <sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="text"
                              // id="lastName"
                              id="merchantForm"
                              name="lastName"
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              value={formData.lastName}
                              onChange={handleChange}
                              className="form-control"
                              required
                            />
                          </div>
                        </Col>

                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label
                              htmlFor="aadharNumber"
                              className="form-label"
                            >
                              Aadhaar Number
                              <sup className="text-danger">*</sup>
                            </label>
                            <div className="input-field-validate">
                              <input
                                type="number"
                                // id="aadharNumber"
                                id={styles.aadharForm}
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                name="aadharNumber"
                                value={formData.aadharNumber}
                                onChange={handleChange}
                                className="form-control"
                                required
                              />
                            </div>
                          </div>
                        </Col>

                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label
                              htmlFor="panCardNumber"
                              className="form-label"
                            >
                              PAN Card Number (Authorized Person)
                              <sup className="text-danger">*</sup>
                            </label>
                            <div className="input-field-validate">
                              <input
                                type="text"
                                // id="panCardNumber"
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                }}
                                id={styles.aadharForm3}
                                name="panCardNumber"
                                onChange={handleChange}
                                onBlur={handelValidations}
                                className="form-control"
                                value={formData.panCardNumber}
                                required
                              />
                            </div>
                          </div>
                        </Col>

                        {/* //--------------------------------------------------------------------------------------- */}
                      </Row>
                    </div>

                    <div className="merchant-form-info"></div>
                  </Card.Body>
                </Card>
                {/* Tab content end here */}
              </Col>
              {/* ./col */}
            </Row>
          </div>
        </section>

        <section className="lincpay-dashboard-sec merchant-admin-info-form company-add-form">
          <div className="cart-view-sec mt-4">
            <Row>
              <Col xs={12} lg={12} md={12}>
                {/* Tab content start here */}
                <Card className="shadow-cst border-0 mb-3 chart-boxes merchant-info-form-sec">
                  <Card.Body>
                    <Card.Title className="h3">
                      Company Address Details
                    </Card.Title>
                    <div className="merchant-form-info">
                      <Row>
                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label className="form-label">
                              Company Registered Address
                              <sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="text"
                              name="companyRegisteredAddress"
                              className="form-control"
                              value={formData.companyRegisteredAddress}
                              onPaste={(e) =>
                                handelPasteEvent(e, "companyRegisteredAddress")
                              }
                              onKeyDown={(e) =>
                                setPressedKeyName(getKeyPressed(e))
                              }
                              onChange={handleInputChange}
                              required
                            />
                            <div className="d-flex justify-content-end">
                              <span className="text-danger">
                                {`Remaining Characters ${
                                  200 -
                                  formData.companyRegisteredAddress?.length
                                } / 200`}{" "}
                              </span>
                            </div>
                          </div>
                          {/* ./form-group */}
                        </Col>
                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label className="form-label">
                              Pincode<sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="text"
                              name="companyRegisteredPincode"
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              className="form-control"
                              value={formData.companyRegisteredPincode}
                              onChange={handleInputChange}
                              required
                              pattern="\d{6}"
                            />
                          </div>
                        </Col>

                        {fetchedRegisteredAddPinCodes &&
                        fetchedRegisteredAddPinCodes?.length > 0 ? (
                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                Please Select Your Locality
                                <sup className="text-danger">*</sup>
                              </label>
                              <select
                                // id="businessType"
                                name="fetchedRegisteredAddPinCodeValues"
                                value={
                                  formData.fetchedRegisteredAddPinCodeValues
                                }
                                onChange={handleInputChange}
                              >
                                <option value="">
                                  Select
                                  <span className="star">*</span>
                                </option>
                                {fetchedRegisteredAddPinCodes?.map(
                                  (type, index) => (
                                    <option
                                      key={type.areaName}
                                      value={type.areaName}
                                    >
                                      {type.areaName}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label className="form-label">
                              Locality
                              <sup className="text-danger">*</sup>{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="companyRegisteredDistrict"
                              value={formData.companyRegisteredDistrict}
                              onPaste={(e) =>
                                handelPasteEvent(e, "companyRegisteredDistrict")
                              }
                              onKeyDown={(e) =>
                                setPressedKeyName(getKeyPressed(e))
                              }
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </Col>

                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label className="form-label">
                              State
                              <sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="text"
                              name="companyRegisteredState"
                              value={formData.companyRegisteredState}
                              onPaste={(e) =>
                                handelPasteEvent(e, "companyRegisteredState")
                              }
                              onKeyDown={(e) =>
                                setPressedKeyName(getKeyPressed(e))
                              }
                              onChange={handleInputChange}
                              className="form-control"
                              required
                            />
                          </div>
                        </Col>

                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label className="form-label">
                              City
                              <sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="companyRegisteredCity"
                              value={formData.companyRegisteredCity}
                              onPaste={(e) =>
                                handelPasteEvent(e, "companyRegisteredCity")
                              }
                              onKeyDown={(e) =>
                                setPressedKeyName(getKeyPressed(e))
                              }
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </Col>

                        <Col xs={12} lg={12} md={12}>
                          <div className="form-group">
                            <div className="input-field-validate check-box-decleration bg-light p-1">
                              <input
                                type="checkbox"
                                id="registeredAddressToggle"
                                className="form-check-input"
                                name="isCommunicationAddSameAsRegistered"
                                checked={
                                  formData.isCommunicationAddSameAsRegistered
                                }
                                onChange={handleInputChange}
                                onClick={handelSameCommunicationAddress}
                              />
                              <label
                                className="form-label"
                                htmlFor="registeredAddressToggle"
                              >
                                Same as Company Registered Address
                              </label>
                              {/* <span className="toggle-text">Same as Company Address</span> */}
                            </div>
                          </div>
                        </Col>

                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label className="form-label">
                              Company Communication Address
                              <sup className="text-danger">*</sup>{" "}
                            </label>
                            <input
                              type="text"
                              name="principleCommunicationAddress"
                              className="form-control"
                              value={formData.principleCommunicationAddress}
                              onPaste={(e) =>
                                handelPasteEvent(
                                  e,
                                  "principleCommunicationAddress"
                                )
                              }
                              onKeyDown={(e) => {
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              onChange={
                                formData.isCommunicationAddSameAsRegistered
                                  ? () => {}
                                  : handleInputChange
                              }
                              required={!formData.registeredAddressSame}
                            />
                            <div className="d-flex justify-content-end">
                              <span className="text-danger">
                                {`Remaining Characters ${
                                  200 -
                                  formData.principleCommunicationAddress?.length
                                } / 200`}{" "}
                              </span>
                            </div>
                          </div>
                        </Col>

                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label className="form-label">
                              Pincode
                              <sup className="text-danger">*</sup>{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              name="principleCommunicationPincode"
                              value={formData.principleCommunicationPincode}
                              onChange={
                                formData.isCommunicationAddSameAsRegistered
                                  ? () => {}
                                  : handleInputChange
                              }
                              required={!formData.registeredAddressSame}
                              pattern="\d{6}"
                            />
                          </div>
                        </Col>
                        {fetchedCommunicationAddPinCodes &&
                        fetchedCommunicationAddPinCodes?.length > 0 ? (
                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                Please Select Your Locality
                                <sup className="text-danger">*</sup>
                              </label>
                              <select
                                // id="businessType"
                                name="fetchedCommunicationAddPinCodeValues"
                                value={
                                  formData.fetchedCommunicationAddPinCodeValues
                                }
                                onChange={
                                  formData.isCommunicationAddSameAsRegistered
                                    ? () => {}
                                    : handleInputChange
                                }
                              >
                                <option value="">
                                  Select
                                  <span className="star">*</span>
                                </option>
                                {fetchedCommunicationAddPinCodes?.map(
                                  (type, index) => (
                                    <option
                                      key={type.areaName}
                                      value={type.areaName}
                                    >
                                      {type.areaName}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label className="form-label">
                              Locality
                              <sup className="text-danger">*</sup>{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="principlCommunicationeDistrict"
                              value={formData.principlCommunicationeDistrict}
                              onPaste={(e) =>
                                handelPasteEvent(
                                  e,
                                  "principlCommunicationeDistrict"
                                )
                              }
                              onKeyDown={(e) => {
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              onChange={
                                formData.isCommunicationAddSameAsRegistered
                                  ? () => {}
                                  : handleInputChange
                              }
                              required
                            />
                          </div>
                        </Col>

                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label className="form-label">
                              State
                              <sup className="text-danger">*</sup>{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="principleCommunicationState"
                              value={formData.principleCommunicationState}
                              onPaste={(e) =>
                                handelPasteEvent(
                                  e,
                                  "principleCommunicationState"
                                )
                              }
                              onKeyDown={(e) =>
                                setPressedKeyName(getKeyPressed(e))
                              }
                              onChange={
                                formData.isCommunicationAddSameAsRegistered
                                  ? () => {}
                                  : handleInputChange
                              }
                              required={!formData.registeredAddressSame}
                            />
                          </div>
                        </Col>

                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label className="form-label">
                              City
                              <sup className="text-danger">*</sup>{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="principlCommunicationeCity"
                              id="companyForm2"
                              value={formData.principlCommunicationeCity}
                              onPaste={(e) =>
                                handelPasteEvent(
                                  e,
                                  "principlCommunicationeCity"
                                )
                              }
                              onKeyDown={(e) =>
                                setPressedKeyName(getKeyPressed(e))
                              }
                              onChange={
                                formData.isCommunicationAddSameAsRegistered
                                  ? () => {}
                                  : handleInputChange
                              }
                              required={!formData.registeredAddressSame}
                            />
                          </div>
                        </Col>

                        <Col xs={12} lg={12} md={12}>
                          <div className="form-group">
                            <div className="input-field-validate check-box-decleration bg-light p-1">
                              <input
                                type="checkbox"
                                id="registeredAddressToggle"
                                className="form-check-input"
                                name="isAuthorizedSignatoryAddSameAsComm"
                                checked={
                                  formData.isAuthorizedSignatoryAddSameAsComm
                                }
                                onChange={handleInputChange}
                                onClick={handelSameAuthorityAddress}
                              />
                              <label
                                className="form-label"
                                htmlFor="registeredAddressToggle"
                              >
                                Same as Communication Address
                              </label>
                            </div>
                          </div>
                        </Col>

                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label className="form-label">
                              Authorized Signatory Address
                              <sup className="text-danger">*</sup>{" "}
                            </label>
                            <input
                              type="text"
                              name="authorizedSignatoryAddress"
                              className="form-control"
                              value={formData.authorizedSignatoryAddress}
                              onPaste={(e) =>
                                handelPasteEvent(
                                  e,
                                  "authorizedSignatoryAddress"
                                )
                              }
                              onKeyDown={(e) => {
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              onChange={
                                formData.isAuthorizedSignatoryAddSameAsComm
                                  ? () => {}
                                  : handleInputChange
                              }
                              required={!formData.registeredAddressSame}
                            />
                            <div className="d-flex justify-content-end">
                              <span className="text-danger">
                                {`Remaining Characters ${
                                  200 -
                                  formData.authorizedSignatoryAddress?.length
                                } / 200`}{" "}
                              </span>
                            </div>
                          </div>
                        </Col>

                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label className="form-label">
                              Pincode
                              <sup className="text-danger">*</sup>{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              name="authorizedSignatoryPincode"
                              value={formData.authorizedSignatoryPincode}
                              onChange={
                                formData.isAuthorizedSignatoryAddSameAsComm
                                  ? () => {}
                                  : handleInputChange
                              }
                              // required={!formData.registeredAddressSame}
                              pattern="\d{6}"
                            />
                          </div>
                        </Col>
                        {fetchedAuthorizedAddPinCodes &&
                        fetchedAuthorizedAddPinCodes?.length > 0 ? (
                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label className="form-label">
                                Please Select Your Locality
                                <sup className="text-danger">*</sup>
                              </label>
                              <select
                                // id="businessType"
                                name="fetchedAuthorizedAddPinCodeValues"
                                f
                                value={
                                  formData.fetchedAuthorizedAddPinCodeValues
                                }
                                onChange={
                                  formData.isAuthorizedSignatoryAddSameAsComm
                                    ? () => {}
                                    : handleInputChange
                                }
                              >
                                <option value="">
                                  Select
                                  <span className="star">*</span>
                                </option>
                                {fetchedAuthorizedAddPinCodes?.map(
                                  (type, index) => (
                                    <option
                                      key={type.areaName}
                                      value={type.areaName}
                                    >
                                      {type.areaName}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label className="form-label">
                              Locality
                              <sup className="text-danger">*</sup>{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="authorizedSignatoryDistrict"
                              value={formData.authorizedSignatoryDistrict}
                              onPaste={(e) =>
                                handelPasteEvent(
                                  e,
                                  "authorizedSignatoryDistrict"
                                )
                              }
                              onKeyDown={(e) =>
                                setPressedKeyName(getKeyPressed(e))
                              }
                              onChange={
                                formData.isAuthorizedSignatoryAddSameAsComm
                                  ? () => {}
                                  : handleInputChange
                              }
                              required
                            />
                          </div>
                        </Col>

                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label className="form-label">
                              State
                              <sup className="text-danger">*</sup>{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="authorizedSignatoryState"
                              value={formData.authorizedSignatoryState}
                              onPaste={(e) =>
                                handelPasteEvent(e, "authorizedSignatoryState")
                              }
                              onKeyDown={(e) =>
                                setPressedKeyName(getKeyPressed(e))
                              }
                              onChange={
                                formData.isAuthorizedSignatoryAddSameAsComm
                                  ? () => {}
                                  : handleInputChange
                              }
                              required={!formData.registeredAddressSame}
                            />
                          </div>
                        </Col>

                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label className="form-label">
                              City
                              <sup className="text-danger">*</sup>{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="authorizedSignatoryCity"
                              id="companyForm2"
                              value={formData.authorizedSignatoryCity}
                              onPaste={(e) =>
                                handelPasteEvent(e, "authorizedSignatoryCity")
                              }
                              onKeyDown={(e) =>
                                setPressedKeyName(getKeyPressed(e))
                              }
                              onChange={
                                formData.isAuthorizedSignatoryAddSameAsComm
                                  ? () => {}
                                  : handleInputChange
                              }
                              required={!formData.registeredAddressSame}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Card.Title className="h3 mt-3">
                        Services Offered
                      </Card.Title>
                      <Row>
                        <Col xs={12} lg={12} md={12}>
                          <div className="form-group">
                            <div className="checkbox-select-services">
                              {paymentTypes?.map((paymentType) => (
                                <div
                                  className="checkservices-type"
                                  key={paymentType.paymentTypeId}
                                >
                                  <input
                                    type="checkbox"
                                    style={{ border: "1px solid grey" }}
                                    name="typeOfServices"
                                    className="form-check-input"
                                    value={paymentType.paymentservice}
                                    checked={formData.typeOfServices.includes(
                                      paymentType.paymentservice
                                    )}
                                    onChange={handleTypeOfServicesChange}
                                  />
                                  <label className="form-label">
                                    {paymentType.paymentservice}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Col xs={12} lg={12} md={12}>
                        <div className="form-group">
                          <div className="input-field-validate check-box-decleration">
                            <input
                              type="checkbox"
                              style={{ border: "1px solid grey" }}
                              s
                              id="referralCodeToggle1"
                              className="form-check-input"
                              name="hasReferralCode"
                              checked={formData.hasReferralCode}
                              onChange={handleInputChange}
                            />
                            <label
                              className="form-label"
                              htmlFor="referralCodeToggle"
                            >
                              Do you have a referral code?
                            </label>
                            {/* <span className="toggle-text">Same as Company Address</span> */}
                          </div>
                        </div>
                      </Col>

                      {formData.hasReferralCode && (
                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label className="form-label">Referral Code</label>
                            <input
                              type="text"
                              name="referralNo"
                              className="form-control"
                              value={formData.referralNo}
                              onChange={handleInputChange}
                              required={formData.hasReferralCode}
                            />
                          </div>
                        </Col>
                      )}

                      <Col xs={12} lg={12} md={12}>
                        <div className="d-flex justify-content-end">
                          <div className="form-group  tool-bar-btn mb-0 mt-3">
                            <button
                              className="submit-button submit_link_info btn btn-primary"
                              //   onClick={handelFormSubmit}
                              id="submitDetailsBtn"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </Card.Body>
                </Card>
                {/* Tab content end here */}
              </Col>
              {/* ./col */}
            </Row>
          </div>
        </section>
      </form>
    </>
  );
};

export default CreateMerchantPhase1;